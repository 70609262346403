import buildUrl from '../utils/buildUrl';
import { baseUrl } from './baseUrl';

export const getDictionariesTypesRoute = () =>
  buildUrl({
    url: `${baseUrl}/dictionaries/dictionary-types`,
  });

export const getDictionariesTypeTerminRoute = (dicTypeId: string) =>
  buildUrl({
    url: `${baseUrl}/dictionaries/dictionaries/${dicTypeId}`,
  });

export const dictionariesTypeTerminRoute = () =>
  buildUrl({
    url: `${baseUrl}/dictionaries/dictionaries`,
  });
