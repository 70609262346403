import { Tooltip } from "antd";
import React, { FC } from "react";
import { IAdministrationPermissionTypeAlias } from "../interfaces/Administration";

interface IProps {
  repmissionTypeAlias: IAdministrationPermissionTypeAlias;
}

export const PermissionType: FC<IProps> = ({ repmissionTypeAlias }) => {
  switch (repmissionTypeAlias) {
    case "includedRolePermissions":
      return (
        <Tooltip title="Привилегия роли">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="4" y="4" width="16" height="16" rx="1" fill="white" />
            <rect
              x="4"
              y="4.00024"
              width="16"
              height="16"
              rx="1"
              fill="#315373"
            />
            <path
              d="M6 11.4L8.54927 11.4402L12.3408 15.4L10.9317 17L6 11.4Z"
              fill="white"
            />
            <path
              d="M15.4768 9L18 9L10.9547 17L9.54563 15.4L15.4768 9Z"
              fill="white"
            />
          </svg>
        </Tooltip>
      );
    case "excludedRolePermissions":
      return null;
    case "includedPermissions":
      return (
        <Tooltip title="Привилегия вне роли">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="4" y="4" width="16" height="16" rx="1" fill="white" />
            <rect
              x="3.88867"
              y="3.88867"
              width="16"
              height="16"
              rx="1"
              fill="#315373"
            />
            <rect
              x="18"
              y="11"
              width="2"
              height="12"
              transform="rotate(90 18 11)"
              fill="white"
            />
            <rect
              x="13"
              y="18"
              width="2"
              height="12"
              transform="rotate(-180 13 18)"
              fill="white"
            />
          </svg>
        </Tooltip>
      );
    case "excludedPermissions":
      return (
        <Tooltip title="Отозванная привилегия вне роли">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="4" y="4" width="16" height="16" rx="1" fill="white" />
            <rect
              x="3.88867"
              y="3.88867"
              width="16"
              height="16"
              rx="1"
              fill="#315373"
            />
            <rect
              x="18"
              y="11"
              width="2"
              height="12"
              transform="rotate(90 18 11)"
              fill="white"
            />
          </svg>
        </Tooltip>
      );
  }
};
