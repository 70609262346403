import React, { FC, useEffect, useRef } from 'react';
import { Modal, Form, Divider, Row, Col, Input, Select, Button } from 'antd';
import { useAppSelector } from '../../store/hooks';
import { useAdministrationProjectsContext } from '../../containers/AdministrationProjectsContainer/AdministrationProjectsContext';

interface ProjectProps {
  title: string;
  regionId: number;
}
interface IProps {
  closeModal: () => void;
  open: boolean;
  checkNameExisting: (props: ProjectProps) => boolean;
  errorModal: (props: ProjectProps) => any;
}

export const CreateProjectModal: FC<IProps> = ({ closeModal, open, checkNameExisting, errorModal }) => {
  const { region } = useAppSelector((state) => state.dictionaries);
  const { meUser } = useAppSelector((state) => state.persons);
  const { createProject } = useAdministrationProjectsContext();
  const ref = useRef<any>(null);
  const [createProjectForm] = Form.useForm();

  // Перемещение к ячейке редактирования наименования
  useEffect(() => {
    if (open && ref.current) {
      setTimeout(() => {
        ref.current.focus();
      }, 0);
    }
  }, [open, ref.current]);

  const close = () => {
    closeModal();
    createProjectForm.resetFields();
  };

  const onFinish = ({ title, regionId }) => {
    if (checkNameExisting({ title, regionId })) {
      return errorModal({ title, regionId });
    }
    createProject({ title, regionId });
    close();
  };

  return (
    <Modal open={open} width="850px" onCancel={close} footer={false} bodyStyle={{ backgroundColor: '#D0D8DA' }}>
      <h2 className="mb0 text-bold">Создание проекта</h2>
      <Divider className="thin-divider mt0" />
      <Form form={createProjectForm} onFinish={onFinish} layout="vertical">
        <Row gutter={20}>
          <Col span={17}>
            <Form.Item
              name="title"
              label="Название"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input placeholder="Название" ref={ref} />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item name="regionId" label="Субъект РФ" initialValue={meUser?.regionId}>
              <Select style={{ width: '100%' }}>
                {region.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt10">
          <Button onClick={closeModal}>Отмена</Button>
          <Button type="primary" htmlType="submit" className="ml20">
            Сохранить
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
