import buildUrl from '../utils/buildUrl';
import { baseUrl } from './baseUrl';

export const getSectionsRoute = (closed) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/sections/search`,
    searchParams: { closed },
  });

export const getSectionsIdRoute = (sectionId) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/sections/${sectionId}`,
  });

export const getSectionsIdFilesRoute = (sectionId) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/sections/${sectionId}/files`,
  });

export const downloadSectionsIdFileRoute = (sectionId, fileName) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/sections/${sectionId}/files/${fileName}`,
  });

export const deleteSectionsIdFileRoute = (sectionId, fileName) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/sections/${sectionId}/file/${fileName}`,
  });

export const editSectionIdRoute = (sectionId) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/sections/${sectionId}`,
  });

export const deleteSectionIdDateRoute = (sectionId) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/sections/${sectionId}`,
  });

export const createNewSectionRoute = () =>
  buildUrl({
    url: `${baseUrl}/applications/v1/sections`,
  });
