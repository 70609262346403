import React, { FC, useEffect, useRef, useState } from 'react';
import { Modal, Form, Divider, Row, Col, Input, Button } from 'antd';
import { useAppSelector } from '../../store/hooks';
import { useAdministrationRolesContext } from '../../containers/AdministrationRolesContainer/AdministrationRolesContext';
import { TransferIcon } from '../../lib/img/TransferIcon';
import { PermissionsNewRoleTable } from './PermissionsNewRoleTable';
import { OnRow } from './interfaces';
import { RolesPermissionsTableRecord } from '../../lib/interfaces/Roles';
import { AllPermissionsNewRoleTable } from './AllPermissionsNewRoleTable';

interface IProps {
  closeModal: () => void;
  open: boolean;
  checkNameExisting: (name: string, type: 'dsc' | 'alias') => boolean | undefined;
  errorRoleNameModal: (type: 'dsc' | 'alias') => void;
}

const tableHeight = 300;

export const CreateRoleModal: FC<IProps> = ({ closeModal, open, checkNameExisting, errorRoleNameModal }) => {
  const { createRole } = useAdministrationRolesContext();
  const ref = useRef<any>(null);
  const [createRoleForm] = Form.useForm();

  const { permissions } = useAppSelector((state) => state.roles);

  const scroll = {
    y: `${tableHeight - 60}px`,
  };

  // Перемещение к ячейке редактирования наименования
  useEffect(() => {
    if (open && ref.current) {
      setTimeout(() => {
        ref.current.focus();
      }, 0);
    }
  }, [open]);

  const close = () => {
    closeModal();
    createRoleForm.resetFields();
  };

  const [permissionsTableData, setPermissionsTableData] = useState<RolesPermissionsTableRecord[]>([]);

  const initializePermissionsTableData = () => {
    if (permissions) {
      const list: RolesPermissionsTableRecord[] = [...permissions.content]
        .map((permission) => ({
          id: permission.id || 0,
          key: permission.id || 0,
          alias: permission.alias,
          dsc: permission.dsc,
          checked: false,
          isParticipant: false,
        }))
        .sort((a, b) => (a.dsc > b.dsc ? 1 : -1));
      setPermissionsTableData(list);
    }
  };

  useEffect(() => {
    initializePermissionsTableData();
  }, [permissions]);

  const checkAllNewRolesPermissions = (checked: boolean): void => {
    setPermissionsTableData((prev) =>
      prev.map((permission) => {
        return {
          ...permission,
          checked: permission.isParticipant ? checked : permission.checked,
        };
      })
    );
  };

  const toggleCheckAllRolesPermissions = (id: number): void => {
    setPermissionsTableData((prev) =>
      prev.map((permission) => ({
        ...permission,
        checked: id === permission.id && permission.isParticipant ? !permission.checked : permission.checked,
      }))
    );
  };

  const onRowNewRolesPermissions: OnRow = ({ id }) => ({
    onClick: () => toggleCheckAllRolesPermissions(id),
  });

  const transferPermissions = (from: 'participants' | 'all') => {
    switch (from) {
      case 'participants':
        setPermissionsTableData((prev) =>
          prev.map((permission) =>
            permission.isParticipant && permission.checked
              ? {
                  ...permission,
                  checked: false,
                  isParticipant: false,
                }
              : permission
          )
        );
        break;
      case 'all':
        setPermissionsTableData((prev) =>
          prev.map((permission) =>
            !permission.isParticipant && permission.checked
              ? {
                  ...permission,
                  checked: false,
                  isParticipant: true,
                }
              : permission
          )
        );
    }
  };

  const checkAllNewRolesAllPermissions = (checked: boolean): void => {
    setPermissionsTableData((prev) =>
      prev.map((permission) => {
        return {
          ...permission,
          checked: !permission.isParticipant ? checked : permission.checked,
        };
      })
    );
  };

  const toggleCheckAllRolesAllPermissions = (id: number): void => {
    setPermissionsTableData((prev) =>
      prev.map((permission) => ({
        ...permission,
        checked: id === permission.id && !permission.isParticipant ? !permission.checked : permission.checked,
      }))
    );
  };

  const onRowNewRolesAllPermissions: OnRow = ({ id }) => ({
    onClick: () => toggleCheckAllRolesAllPermissions(id),
  });

  const permissionsNewRoleTableData = permissionsTableData.filter(({ isParticipant }) => isParticipant);

  const allPermissionsTableData = permissionsTableData.filter(({ isParticipant }) => !isParticipant);

  const onFinish = ({ dsc, alias }) => {
    if (checkNameExisting(dsc, 'dsc')) {
      return errorRoleNameModal('dsc');
    }
    if (checkNameExisting(alias, 'alias')) {
      return errorRoleNameModal('alias');
    }
    createRole({ dsc, alias, includedPermissions: permissionsNewRoleTableData.map((el) => el) });
    close();
  };

  return (
    <Modal
      open={open}
      width="1400px"
      onCancel={close}
      footer={false}
      bodyStyle={{ backgroundColor: '#D0D8DA' }}
      style={{ height: 559 }}
    >
      <h2 className="mb0 text-bold">Создание роли</h2>
      <Divider className="thin-divider mt0" />
      <Form form={createRoleForm} onFinish={onFinish} layout="vertical">
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              name="dsc"
              label="Название"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input placeholder="Название" ref={ref} maxLength={64} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              name="alias"
              label="Техническое название"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input placeholder="Техническое название" maxLength={64} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={15} style={{ height: `${tableHeight}px`, flexWrap: 'nowrap' }}>
          <Col style={{ width: '48.5%' }}>
            <PermissionsNewRoleTable
              dataSource={permissionsNewRoleTableData}
              scroll={scroll}
              onRow={onRowNewRolesPermissions}
              ckeckAll={checkAllNewRolesPermissions}
            />
          </Col>

          <Col>
            <Row>
              <Col style={{ marginTop: '140px' }}>
                <>
                  <TransferIcon
                    side="right"
                    active={permissionsNewRoleTableData.some(({ checked }) => checked)}
                    onClick={() => transferPermissions('participants')}
                  />
                  <TransferIcon
                    active={allPermissionsTableData.some(({ checked }) => checked)}
                    onClick={() => transferPermissions('all')}
                  />
                </>
              </Col>
            </Row>
          </Col>

          <Col style={{ width: '48.5%' }}>
            <AllPermissionsNewRoleTable
              scroll={scroll}
              dataSource={allPermissionsTableData}
              onRow={onRowNewRolesAllPermissions}
              ckeckAll={checkAllNewRolesAllPermissions}
            />
          </Col>
        </Row>

        <Row className="mt10">
          <Button onClick={closeModal}>Отмена</Button>
          <Button type="primary" htmlType="submit" className="ml20" style={{ width: 216 }}>
            Сохранить
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
