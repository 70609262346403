import React, { FC, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useMainContext } from '../MainContainer/MainContext';
import {
  AdministrationRolesContext,
  CreateRole,
  DeleteRole,
  LoadRolesPermissionsList,
  SaveCurrentRoleId,
  UpdateRole,
} from './AdministrationRolesContext';
import { AdministrationRoles } from '../../components/AdministrationRoles/AdministrationRoles';
import { getPermissions } from '../../store/slices/rolesSlice/rolesSlice';
import axios from 'axios';
import { Modal } from 'antd';
import {
  createRoleRoute,
  deleteRoleRoute,
  updateNameRoleRoute,
  updatePermissionsRoleRoute,
} from '../../lib/routes/roles';
import { setAdministrationCurrentRoleId } from '../../store/slices/administrationSlice/administrationSlice';

interface IProps {}

export const AdministrationRolesContainer: FC<IProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loadRoles } = useMainContext();

  const { roles } = useAppSelector((state) => state.roles);

  const { currentRoleId } = useAppSelector((state) => state.administration);

  const saveCurrentRoleId: SaveCurrentRoleId = (roleId: number) => {
    dispatch(setAdministrationCurrentRoleId(roleId));
  };

  const loadRolesPermissionsList: LoadRolesPermissionsList = () => {
    dispatch(getPermissions({ page: 0, size: 1000 }));
  };

  useEffect(() => {
    loadRoles({ page: 0, size: 1000 });
    loadRolesPermissionsList();
  }, []);

  // Переадресация к сохраненной роли
  useEffect(() => {
    if (currentRoleId) {
      navigate(`/administration/roles/${currentRoleId}`);
    } else if (roles && roles.content?.length > 0) {
      const sortRoles = [...roles.content].sort((a, b) => {
        return a.alias > b.alias ? 1 : -1;
      });
      navigate(`/administration/roles/${sortRoles[0].id}`);
    }
  }, [roles, currentRoleId]);

  const createRole: CreateRole = ({ includedPermissions, alias, dsc }) => {
    return axios
      .post(createRoleRoute(), {
        tenantId: 1,
        dsc,
        alias,
        includedPermissions,
      })
      .then(({ data }) => {
        loadRoles({ page: 0, size: 1000 }).then(() => {
          saveCurrentRoleId(data.id);
        });

        Modal.success({
          content: 'Роль создана',
        });
      })
      .catch(() => {
        Modal.error({
          content: 'При создании роли произошла ошибка. Пожалуйста, повторите попытку позднее.',
        });
      });
  };

  const updateRole: UpdateRole = async (editedRole, onChangeEditMode) => {
    try {
      await axios.patch(updateNameRoleRoute(editedRole.roleId), editedRole.body);
      await axios.put(
        updatePermissionsRoleRoute({
          roleId: editedRole.roleId,
          body: {
            include: editedRole.body.includedPermissions.map((perm) => perm.id),
          },
        })
      );

      loadRoles({ page: 0, size: 1000 });

      Modal.success({
        content: 'Роль отредактирована',
      });
      onChangeEditMode(false);
    } catch (e) {
      Modal.error({
        content: 'Произошла ошибка. Пожалуйста, попробуйте позднее.',
      });
      onChangeEditMode(true);
    }
  };

  const deleteRole: DeleteRole = async (roleId) => {
    return axios
      .delete(deleteRoleRoute(roleId))
      .then(() => {
        loadRoles({ page: 0, size: 1000 }).then(({ payload }) => {
          if (payload && payload.content.length > 0) {
            saveCurrentRoleId(
              [...payload.content].sort((a, b) => {
                return a.alias > b.alias ? 1 : -1;
              })[0].id
            );
          }
        });
        Modal.success({
          content: 'Роль удалена',
        });
      })
      .catch(() => {
        Modal.error({
          content: 'Произошла ошибка. Пожалуйста, попробуйте позднее',
        });
      });
  };

  return (
    <div className="pt20">
      <AdministrationRolesContext.Provider
        value={{ loadRolesPermissionsList, deleteRole, updateRole, createRole, saveCurrentRoleId }}
      >
        <Routes>
          <Route path="/" element={<AdministrationRoles />} />
          <Route path="/:roleId" element={<AdministrationRoles />} />
        </Routes>
      </AdministrationRolesContext.Provider>
    </div>
  );
};
