import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = [
  {
    text: 'Use Redux',
    completed: false,
    id: 0,
  },
];

const testSlice = createSlice({
  name: 'todos',
  initialState,
  reducers: {
    todoAdded(state, action: PayloadAction<string>) {
      state.push({
        id: state.reduce((maxId, todo) => Math.max(todo.id, maxId), -1) + 1,
        completed: false,
        text: action.payload,
      });
    },
  },
});

export const { todoAdded } = testSlice.actions;

export default testSlice.reducer;
