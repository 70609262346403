import React from 'react';
import { Checkbox, Divider, Form, Input, Row } from 'antd';
import { replaceFalse } from './replaceFalse';
import { useAppSelector } from '../../store/hooks';
import useLabel from './useLabel';
import { PersonTypes } from './PersonTypes';
import { MaskInputFromAntd } from '../common/MaskInputFromAntd';

const labelWidth = 251;

interface Props {
  isEditMode: boolean;
  isNewUser?: boolean;
}

function ReligionBlock(props: Props) {
  const { isEditMode, isNewUser } = props;
  const { personFullCard } = useAppSelector((state) => state.persons);
  const [makeLabel] = useLabel();

  return (
    <div className="block">
      <h3>Духовность и вероисповедание</h3>
      <Divider />
      {isEditMode || isNewUser ? (
        <Form.Item valuePropName="checked" name="blessed">
          <Checkbox>Благословение духовника</Checkbox>
        </Form.Item>
      ) : (
        <Row>
          {makeLabel('Благословение духовника', labelWidth)}
          <div className="row-value">{replaceFalse(personFullCard?.religion?.blessed, true)}</div>
        </Row>
      )}
      {isEditMode || isNewUser ? (
        <Form.Item name="church" label="Приходской храм" className="card-select">
          <Input />
        </Form.Item>
      ) : (
        <div className="info-row">
          <div>{makeLabel('Приходской храм', labelWidth)}</div>
          <div className="row-value info">{replaceFalse(personFullCard?.religion?.church)}</div>
        </div>
      )}
      {isEditMode || isNewUser ? (
        <Form.Item name="sacramentReason" label="Как часто причащается">
          <Input />
        </Form.Item>
      ) : (
        <div className="info-row">
          <div>{makeLabel('Как часто причащается', labelWidth)}</div>
          <div className="row-value info">{replaceFalse(personFullCard?.religion?.sacramentReason)}</div>
        </div>
      )}
      {isEditMode || isNewUser ? (
        <Form.Item name="baptismalName" label="Имя в крещении">
          <Input />
        </Form.Item>
      ) : (
        <div className="info-row">
          <div>{makeLabel('Имя в крещении', labelWidth)}</div>
          <div className="row-value info">{replaceFalse(personFullCard?.religion?.baptismalName)}</div>
        </div>
      )}
      {isEditMode || isNewUser ? (
        <Form.Item
          name="nameDays"
          label="Именины"
          rules={[
            {
              pattern: new RegExp(/^\d{2}\.\d{2}$/),
              message: 'Введите в формате ДД.ММ',
            },
          ]}
        >
          <MaskInputFromAntd allowClear className="mask-input" />
        </Form.Item>
      ) : (
        <div className="info-row">
          <div>{makeLabel('Именины', labelWidth)}</div>
          <div className="row-value info">{replaceFalse(personFullCard?.religion?.nameDays)}</div>
        </div>
      )}
      {isEditMode || isNewUser ? (
        <Form.Item name="religion" label="Вероисповедание">
          <Input />
        </Form.Item>
      ) : (
        <div className="info-row">
          <div>{makeLabel('Вероисповедание', labelWidth)}</div>
          <div className="row-value info">{replaceFalse(personFullCard?.religion?.religion)}</div>
        </div>
      )}
      {(personFullCard?.personTypeId === PersonTypes.Подопечный || isNewUser) &&
        (isEditMode || isNewUser ? (
          <Form.Item valuePropName="checked" name="needsPriest">
            <Checkbox>Нужно пригласить священника</Checkbox>
          </Form.Item>
        ) : (
          <div className="info-row">
            <div>{makeLabel('Нужно пригласить священника', labelWidth)}</div>
            <div className="row-value info">{replaceFalse(personFullCard?.religion?.needsPriest, true)}</div>
          </div>
        ))}
    </div>
  );
}

export default ReligionBlock;
