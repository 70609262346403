import { Checkbox, Col, Divider, Row, Table, Tooltip } from 'antd';
import React, { FC } from 'react';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { useAppSelector } from '../../store/hooks';
import { OnRow, Scroll } from './interfaces';
import { RolesPermissionsTableRecord } from '../../lib/interfaces/Roles';

interface IProps {
  editMode: boolean;
  dataSource: RolesPermissionsTableRecord[];
  scroll: Scroll;
  onRow: OnRow;
  ckeckAll: (checked: boolean) => void;
}

export const AllPermissionsTable: FC<IProps> = ({ editMode, dataSource, scroll, onRow, ckeckAll }) => {
  const { projectLoading } = useAppSelector((state) => state.projects);

  const renderCheckAll = () => {
    if (!editMode) return null;
    const checked = dataSource.length > 0 && dataSource.every(({ checked }) => checked);
    return (
      <Tooltip title="Выбрать все">
        <Checkbox onChange={(e) => ckeckAll(e.target.checked)} checked={checked} />
      </Tooltip>
    );
  };

  const columns = [
    {
      title: renderCheckAll,
      key: 'checked',
      dataIndex: 'checked',
      render: (checked: boolean) => (
        <Checkbox checked={checked} style={{ visibility: editMode ? 'visible' : 'hidden' }} />
      ),
      width: '26px',
    },
    {
      title: (
        <Row style={{ justifyContent: 'space-between', marginRight: 2 }}>
          <Col>{dataSource.length || 0} элем.</Col>
          <Col style={{ cursor: 'pointer' }} onClick={() => ckeckAll(true)}>
            Выбрать всё
          </Col>
        </Row>
      ),
      key: 'dsc',
      dataIndex: 'dsc',
      width: '100%',
    },
  ];

  return (
    <div>
      <h4 className="text-bold font-size-18 mb2">Доступные привилегии</h4>
      <Divider className="thin-divider mt0 mb0" />
      <Table
        size="small"
        loading={checkIsLoading(projectLoading)}
        columns={columns}
        dataSource={dataSource}
        className="striped-table"
        rowClassName={(r, i) => (i % 2 ? 'cursor-pointer' : 'cursor-pointer striped-table__oddRow')}
        pagination={false}
        onRow={onRow}
        showHeader={editMode}
        scroll={scroll}
      />
    </div>
  );
};
