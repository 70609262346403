import React, { FC, useEffect, useState } from 'react';
import { useAdministrationDictionariesContext } from '../../containers/AdministrationDictionariesContainer/AdministrationDictionariesContext';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { Col, Divider, Form, Input, Modal, Row, Select, Spin, Table, Tooltip } from 'antd';
import { useAppSelector } from '../../store/hooks';
import { ReactComponent as ArrowDownFigIcon } from '../../lib/img/arrow-down-fig.svg';
import './AdministrationDictionaries.less';
import AdministrationDictionariesList from './AdministrationDictionariesList/AdministrationDictionariesList';
import { useNavigate, useParams } from 'react-router-dom';
import { useAdministrationContext } from '../../containers/AdministrationContainer/AdministrationContext';
import { useMainContext } from '../../containers/MainContainer/MainContext';
import { DicTypesTermin } from '../../lib/interfaces/AdministrationDictionaries';
import { ReactComponent as DeleteTerminIcon } from '../../lib/img/basket.svg';
import { ReactComponent as EditTerminIcon } from '../../lib/img/pencil.svg';
import { ReactComponent as CancelTerminIcon } from '../../lib/img/cross.svg';
import { ReactComponent as SaveTerminIcon } from '../../lib/img/submitGreen.svg';
import { DeleteDictionariesTerminModal } from './DeleteDictionariesTerminModal';
import { CreateDictionariesTerminModal } from './CreateDictionariesTerminModal';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import selectFilterOption from '../../lib/utils/selectFilterOption';

interface IProps {}

export const AdministrationDictionaries: FC<IProps> = (props) => {
  const dicTypeId = useParams().dicTypeId;

  const [dicTypeIdTerminTableForm] = useForm();

  const navigate = useNavigate();
  const { loadDicTypesTermin, hasAccess } = useMainContext();
  const { deleteDictionariesTypeTermin, updateDictionariesTypeTermin } = useAdministrationDictionariesContext();
  const { saveCurrentLocation, setOpenModalDictionariesTypes } = useAdministrationContext();

  const [editingKey, setEditingKey] = useState('');
  const isEditing = (rec) => rec.key === editingKey;

  const {
    dicTypes,
    dicTypesLoading,
    dicTypesTermin,
    dicTypesTerminArchive,
    dicTypesTerminNotArchive,
    dicTypesTerminLoading,
    isArchivedTermins,
    openModalAdministrationDictionaries,
  } = useAppSelector((state) => state.administrationDictionaries);

  const { properties } = useAppSelector((state) => state.root);

  const systemDic: string[] = properties.system_dic.split(', ');

  const maxIdTermin = Math.max(...dicTypesTermin.map((termin) => termin?.id));

  // Выбранные значения из Select
  const [selectedDicTypes, setSelectedDicTypes] = useState<string[]>([]);

  const [selectedDicTypesTerminRowClick, setSelectedDicTypesTerminRowClick] = useState<DicTypesTermin>();

  const [tableDataDicTypesTermin, setTableDataDicTypesTermin] = useState<DicTypesTermin[]>([]);

  useEffect(() => {
    if (Array.isArray(dicTypesTermin)) {
      const newListTermins: DicTypesTermin[] = dicTypesTerminNotArchive.slice();

      if (isArchivedTermins) {
        newListTermins.push(...dicTypesTerminArchive);
      }

      setTableDataDicTypesTermin(newListTermins);
    }
  }, [dicTypesTermin, dicTypesTerminArchive, dicTypesTerminNotArchive, isArchivedTermins]);

  useEffect(() => {
    if (dicTypeId) {
      loadDicTypesTermin(dicTypeId);
    } else {
      setTableDataDicTypesTermin([]);
    }
  }, [dicTypeId]);

  const dicTypesList = [...dicTypes]
    .filter((dicType) => (selectedDicTypes.length > 0 ? selectedDicTypes.includes(dicType.id) : true))
    .sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    })
    .map((dicType) => {
      return { id: dicType.id, value: `${dicType.name}`, system_dic: systemDic?.includes(dicType.id) };
    });

  const dictionariesHeader = (
    <Row>
      <Col>
        <Spin spinning={checkIsLoading(dicTypesLoading)}>
          <Select
            className="ant-select"
            style={{ width: 335 }}
            suffixIcon={<ArrowDownFigIcon />}
            placeholder="Наименование.."
            showArrow
            allowClear
            maxTagCount={1}
            maxTagTextLength={26}
            mode="multiple"
            value={selectedDicTypes}
            onChange={(values) => setSelectedDicTypes(values)}
            filterOption={selectFilterOption}
          >
            {dicTypes.length > 0 &&
              [...dicTypes]
                .sort((a, b) => {
                  return a.name > b.name ? 1 : -1;
                })
                .map((dicType) => {
                  return (
                    <Select.Option key={dicType.id} value={dicType.id}>
                      {dicType.name}
                    </Select.Option>
                  );
                })}
          </Select>
        </Spin>
      </Col>
    </Row>
  );

  const onRowDicTypes = (item) => {
    navigate(`/administration/dictionaries/${item.id}`);
    saveCurrentLocation(`dictionaries/${item.id}`);
  };

  const isAccess = hasAccess(['ADMINISTRATION.DIC.WRITE']);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '8%',
    },
    {
      title: 'Значение',
      dataIndex: 'name',
      width: '30%',
      render: (name, record) => {
        const editable = isEditing(record);
        return (
          <>
            {editable ? (
              <Form.Item
                initialValue={name}
                name="name"
                className="form-item-required__hidden-error__mb0"
                rules={[
                  { required: true, message: '' },
                  {
                    validator(_, value) {
                      if (checkNameDicTypesTermin({ name: value })) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                    message: '',
                  },
                ]}
              >
                <Input placeholder="Введите значение" style={{ height: '70%' }} />
              </Form.Item>
            ) : (
              <>{name}</>
            )}
          </>
        );
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: '62%',
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div className="display-flex" style={{ justifyContent: 'end' }}>
            {record?.dsc !== 'archive' && !record.system_dic && isAccess ? (
              <>
                {editable ? (
                  <>
                    <Tooltip title="Сохранить" className="mr10" mouseLeaveDelay={0} placement="topRight">
                      <SaveTerminIcon
                        className="cursor-pointer"
                        onClick={() => {
                          setSelectedDicTypesTerminRowClick(record);
                          dicTypeIdTerminTableForm.submit();
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Отменить" className="mr10" mouseLeaveDelay={0} placement="topRight">
                      <CancelTerminIcon
                        className="cursor-pointer"
                        onClick={() => {
                          setEditingKey(() => '');
                          dicTypeIdTerminTableForm.resetFields();
                        }}
                      />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip title="Редактировать значение" className="mr10" mouseLeaveDelay={0} placement="topRight">
                      <EditTerminIcon
                        className="cursor-pointer"
                        onClick={() => {
                          dicTypeIdTerminTableForm.resetFields();
                          setEditingKey(() => record.key);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Удалить значение" className="mr10" mouseLeaveDelay={0} placement="topRight">
                      <DeleteTerminIcon
                        className="cursor-pointer"
                        onClick={() => {
                          setSelectedDicTypesTerminRowClick(record);
                          setOpenModalDictionariesTypes('delete');
                        }}
                      />
                    </Tooltip>
                  </>
                )}
              </>
            ) : null}
          </div>
        );
      },
    },
  ];

  const closeModal = () => {
    setOpenModalDictionariesTypes('');
  };

  const checkNameDicTypesTermin = ({ name }: { name: string }) => {
    const isNameExists = dicTypesTermin
      .filter((termin) => Number(termin.id) !== Number(editingKey))
      .some((termin) => termin.name.toLowerCase().trim() === name.toLowerCase().trim());
    return isNameExists;
  };

  const errorDicTypesTerminNameModal = () =>
    Modal.error({
      title: (
        <>
          <h2 className="mb0 text-bold">Ошибка в названии термина</h2>
          <Divider className="thin-divider mt0" />
        </>
      ),
      content: <div>Термин с таким значением уже существует. Пожалуйста, введите новые данные.</div>,
      bodyStyle: { width: '460px', backgroundColor: '#D0D8DA' },
    });

  const onClickDeleteTerminApply = () => {
    if (selectedDicTypesTerminRowClick?.id) {
      deleteDictionariesTypeTermin({
        ...selectedDicTypesTerminRowClick,
        fromDate: moment().format(),
        dsc: 'archive',
      }).then(() => {
        closeModal();
        setSelectedDicTypesTerminRowClick(undefined);
      });
    }
  };

  const onFinish = (values) => {
    if (selectedDicTypesTerminRowClick) {
      updateDictionariesTypeTermin({
        ...selectedDicTypesTerminRowClick,
        name: values.name,
        fromDate: moment().format(),
      }).then(() => {
        setEditingKey('');
        dicTypeIdTerminTableForm.resetFields();
        setSelectedDicTypesTerminRowClick(undefined);
      });
    }
  };

  const onFinishFailed = (val) => {
    if (!val.values.name) {
      return Modal.error({
        title: (
          <>
            <h2 className="mb0 text-bold">Ошибка в названии термина</h2>
            <Divider className="thin-divider mt0" />
          </>
        ),
        content: <div>Пожалуйста, заполните обязательные поля.</div>,
        bodyStyle: { width: '460px', backgroundColor: '#D0D8DA' },
      });
    }
    if (val.values.name) {
      return errorDicTypesTerminNameModal();
    }
  };

  return (
    <>
      {dicTypeId && <Divider className="thin-divider mt0 mb0" />}
      <div className={`administration-dictionaries ${dicTypeId ? '' : 'pt20'}`}>
        {!dicTypeId && (
          <>
            {dictionariesHeader}
            <AdministrationDictionariesList onRowDicTypes={onRowDicTypes} data={dicTypesList} />
          </>
        )}
        {dicTypeId && (
          <Form form={dicTypeIdTerminTableForm} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Table
              size="small"
              columns={columns}
              loading={checkIsLoading(dicTypesTerminLoading)}
              dataSource={tableDataDicTypesTermin}
              className="striped-table"
              rowClassName={(r, i) => {
                let className = i % 2 ? 'cursor-pointer' : 'cursor-pointer striped-table__oddRow';

                className += r.dsc === 'archive' ? ' archive' : '';

                className += isEditing(r) ? ' editing' : '';

                return className;
              }}
              pagination={false}
            />
          </Form>
        )}
        <DeleteDictionariesTerminModal
          closeModal={closeModal}
          open={openModalAdministrationDictionaries === 'delete'}
          onClickDeleteTerminApply={onClickDeleteTerminApply}
        />
        <CreateDictionariesTerminModal
          closeModal={closeModal}
          open={openModalAdministrationDictionaries === 'create'}
          maxIdTermin={maxIdTermin}
          checkNameDicTypesTermin={checkNameDicTypesTermin}
          errorDicTypesTerminNameModal={errorDicTypesTerminNameModal}
        />
      </div>
    </>
  );
};
