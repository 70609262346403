import React, { FC, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import {
  AddNewSection,
  ChangeArchivedCheck,
  ClosedSectionsIdDate,
  EditSectionsId,
  FetchSections,
  FetchSectionsId,
  FetchSectionsIdFiles,
  InfoContext,
} from './InfoContext';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  cleanSections,
  createNewSection,
  deleteSectionIdDate,
  getSections,
  getSectionsId,
  getSectionsIdFiles,
  updateSectionId,
} from '../../store/slices/sectionsSlice/sectionsSlice';
import { InfoMain } from '../../components/Info/InfoMain';

export const InfoContainer: FC = () => {
  const dispatch = useAppDispatch();
  const { meUserFull } = useAppSelector((state) => state.persons);
  const isUserNewVolunteer = meUserFull?.personStatusId === 1;

  const { sections, selectedSectionId } = useAppSelector((state) => state.sections);

  const [isArchivedCheck, setIsArchivedCheck] = useState(false);

  const fetchSections: FetchSections = (closed) => {
    dispatch(getSections(closed));
  };

  const fetchSectionsId: FetchSectionsId = (sectionId) => {
    dispatch(getSectionsId(sectionId));
  };

  const fetchSectionsIdFiles: FetchSectionsIdFiles = (sectionId) => {
    dispatch(getSectionsIdFiles(sectionId));
  };

  const editSectionId: EditSectionsId = (sectionId, params, onSuccess) => {
    dispatch(updateSectionId({ sectionId, body: params, onSuccess }));
  };

  const onChangeArchivedCheck: ChangeArchivedCheck = (check) => {
    setIsArchivedCheck(check);
    fetchSections(check);
  };

  const closedSectionIdDate: ClosedSectionsIdDate = (sectionId, params, onSuccess) => {
    dispatch(deleteSectionIdDate({ sectionId, body: params, onSuccess }));
  };

  const addNewSection: AddNewSection = (params, onSuccess) => {
    dispatch(createNewSection({ body: params, onSuccess }));
  };

  useEffect(() => {
    fetchSections(false);
    return () => {
      dispatch(cleanSections());
    };
  }, []);

  return (
    <InfoContext.Provider
      value={{
        isUserNewVolunteer,
        isArchivedCheck,
        onChangeArchivedCheck,
        fetchSections,
        fetchSectionsId,
        fetchSectionsIdFiles,
        editSectionId,
        closedSectionIdDate,
        addNewSection,
      }}
    >
      <Routes>
        {sections?.[0]?.id ? (
          <Route
            path=""
            element={<Navigate to={`/info/${selectedSectionId ? selectedSectionId : sections[0].id}`} />}
          />
        ) : null}
        <Route path="/" element={<InfoMain />} />
        <Route path=":sectionId" element={<InfoMain />} />
      </Routes>
    </InfoContext.Provider>
  );
};
