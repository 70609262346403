import React, { useEffect } from 'react';
import Files from '../../components/Files/Files';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { checkIsLoaded } from '../../lib/utils/checkLoadingStatus';
import { pageableSearchPersons } from '../../store/slices/personsSlice/personsSlice';
import { Spin } from 'antd';
import {
  getAwarenessSourceDictionary,
  getMaritalStatusDictionary,
  getPersonStatusesDictionary,
  getPersonTypesDictionary,
  getRegionDictionary,
  getSubwayStationDictionary,
} from '../../store/slices/dictionariesSlice/dictionariesSlice';
import { getProjects } from '../../store/slices/projectsSlice/projectsSlice';
import usePermissions from '../../lib/utils/usePermissions';
import { PERSONLIST_READ_ALLREGIONS } from '../../lib/const/permissions';
import { setSelectedTypesIdFilter } from '../../store/slices/rootSlice/rootSlice';

function FilesContainer() {
  const dispatch = useAppDispatch();
  const { meUserFull } = useAppSelector((state) => state.persons);
  const { filesSearchState, filesPage, filesInPrayListFilter, selectedTypesId } = useAppSelector((state) => state.root);
  const {
    subwayStationLoading,
    personTypesLoading,
    personStatusesLoading,
    maritalStatusLoading,
    regionLoading,
    awarenessSourceLoading,
  } = useAppSelector((state) => state.dictionaries);
  const [hasAccess] = usePermissions();

  // const [selectedTypesId, setSelectedTypesId] = useState<number | undefined>();
  const { projectsLoading } = useAppSelector((state) => state.projects);

  useEffect(() => {
    dispatch(
      pageableSearchPersons({
        page: filesPage - 1,
        search: {
          ...filesSearchState,
          inPrayList: filesInPrayListFilter ? true : undefined,
          personTypeIds: selectedTypesId ? [selectedTypesId] : null,
          regionId: hasAccess([PERSONLIST_READ_ALLREGIONS]) ? filesSearchState?.regionId : meUserFull?.regionId,
        },
      })
    );
  }, [filesPage, filesSearchState, filesInPrayListFilter, selectedTypesId,dispatch]);

  const setSelectedTypesId = (id: number | undefined) => {
    dispatch(setSelectedTypesIdFilter(id));
  };

  useEffect(() => {
    if (!checkIsLoaded(projectsLoading)) dispatch(getProjects({}));
    if (!checkIsLoaded(subwayStationLoading)) dispatch(getSubwayStationDictionary());
    if (!checkIsLoaded(personTypesLoading)) dispatch(getPersonTypesDictionary());
    if (!checkIsLoaded(maritalStatusLoading)) dispatch(getMaritalStatusDictionary());
    if (!checkIsLoaded(regionLoading)) dispatch(getRegionDictionary());
    if (!checkIsLoaded(personStatusesLoading)) dispatch(getPersonStatusesDictionary());
    if (!checkIsLoaded(awarenessSourceLoading)) dispatch(getAwarenessSourceDictionary());
  }, [dispatch]);

  const dictionariesLoaded = checkIsLoaded(
    subwayStationLoading,
    personTypesLoading,
    personStatusesLoading,
    maritalStatusLoading,
    regionLoading,
    projectsLoading,
    awarenessSourceLoading
  );

  return (
    <Spin spinning={!dictionariesLoaded} className="container">
      {dictionariesLoaded && <Files setSelectedTypesId={setSelectedTypesId} />}
    </Spin>
  );
}

export default FilesContainer;
