interface IFeaturesToggle {
  condition: boolean;
  value: any;
  alternativeValue: any;
}

const featuresToggle = (params: IFeaturesToggle) => {
  const { condition, value, alternativeValue } = params;
  if (condition && typeof value === 'function') {
    return value;
  }
  if (condition) {
    return value;
  }
  if (!condition && typeof alternativeValue === 'function') {
    return alternativeValue;
  }
  return alternativeValue;
};

export default featuresToggle;
