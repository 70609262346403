const selectFilterOption = (input, option) => {
  const children = option!.children as any;
  // Поиск для mode="multiple"
  if (typeof children === 'object') {
    return children.props.children.toLowerCase().startsWith(input.toLowerCase());
  }
  // Поиск для обычного select
  if (typeof children === 'string') {
    return children.toLowerCase().startsWith(input.toLowerCase());
  }
};

// Фильтрация для select с иконками
export const selectFilterOptionIcon = (input, option) => {
  const children = option!.children as any;
  // Поиск для обычного select
  if (typeof children === 'object') {
    return children.props.children[1].props.children.toLowerCase().startsWith(input.toLowerCase());
  }
};

export default selectFilterOption;
