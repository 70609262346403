import React, { FC, useEffect } from 'react';
import { Col, Divider, Row, Spin, Table } from 'antd';
import { ColumnType } from 'antd/es/table/interface';
import uniqueId from 'lodash/uniqueId';
import { useApplicationContainerContext } from '../../containers/ApplicationContainer/ApplicationContainerContext';
import { useAppSelector } from '../../store/hooks';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { toDisplayFormatWithTimeFull } from '../../lib/utils/formatDate';
import './Application.less';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ApplicationResponseHistoryItem } from '../../lib/interfaces/ApplicationResponseHistory';

export const ApplicationResponseHistory: FC = () => {
  const { getApplicationResponseHistory } = useApplicationContainerContext();

  useEffect(() => {
    getApplicationResponseHistory();
  }, []);

  const { applicationResponseHistory, applicationResponseHistoryLoading } = useAppSelector(
    (state) => state.applications
  );

  const tableDataPotentialExecutors = [...applicationResponseHistory.potentialExecutors]
    .sort((a, b) => (moment(a.actionDate).isBefore(b.actionDate) ? 1 : -1))
    .map((item) => ({
      ...item,
      actionTypeName: item.fio || '',
      actionDate: toDisplayFormatWithTimeFull(item.actionDate),
      key: uniqueId(),
    }));

  const tableDataChosenExecutors = [...applicationResponseHistory.chosenExecutors]
    .sort((a, b) => (moment(a.actionDate).isBefore(b.actionDate) ? 1 : -1))
    .map((item) => ({
      ...item,
      actionTypeName: item.fio || '',
      actionDate: toDisplayFormatWithTimeFull(item.actionDate),
      key: uniqueId(),
    }));

  const tableDataRefusedExecutors = [...applicationResponseHistory.refusedExecutors]
    .sort((a, b) => (moment(a.actionDate).isBefore(b.actionDate) ? 1 : -1))
    .map((item) => ({
      ...item,
      actionTypeName: item.fio || '',
      actionDate: toDisplayFormatWithTimeFull(item.actionDate),
      key: uniqueId(),
    }));

  const columns: ColumnType<ApplicationResponseHistoryItem>[] = [
    {
      key: 'actionDate',
      dataIndex: 'actionDate',
      title: 'Дата операции',
      width: '140px',
      className: 'application-history__firstColumn',
    },
    {
      key: 'fio',
      dataIndex: 'fio',
      title: 'ФИО',
      render: (fio, { personId }) => {
        return (
          <Link to={`/files/${personId}/general`} target="_blank">
            {fio}
          </Link>
        );
      },
    },
  ];

  const renderPotentialExecutorsTable = () => {
    return (
      <>
        <h3 className="text-bold mb3">Отклики</h3>
        <Divider className="thin-divider mt0 mb7" />
        <Table
          className="striped-table"
          columns={columns}
          size="small"
          dataSource={tableDataPotentialExecutors}
          rowClassName={(r, i) => (i % 2 ? 'pl20' : 'striped-table__oddRow')}
          pagination={false}
        />
      </>
    );
  };

  const renderChosenExecutorsTable = () => {
    return (
      <>
        <h3 className="text-bold mb3">Назначения</h3>
        <Divider className="thin-divider mt0 mb7" />
        <Table
          className="striped-table"
          columns={columns}
          size="small"
          dataSource={tableDataChosenExecutors}
          rowClassName={(r, i) => (i % 2 ? 'pl20' : 'striped-table__oddRow')}
          pagination={false}
        />
      </>
    );
  };

  const renderRefusedExecutorsTable = () => {
    return (
      <>
        <h3 className="text-bold mb3">Отказы исполнителей</h3>
        <Divider className="thin-divider mt0 mb7" />
        <Table
          className="striped-table"
          columns={columns}
          size="small"
          dataSource={tableDataRefusedExecutors}
          rowClassName={(r, i) => (i % 2 ? 'pl20' : 'striped-table__oddRow')}
          pagination={false}
        />
      </>
    );
  };

  return (
    <Spin spinning={checkIsLoading(applicationResponseHistoryLoading)}>
      <div className="application application-responseHistory">
        <Row className="mt10" style={{ height: '440px' }}>
          <Col span={8}>
            <Row gutter={10}>
              <Col span={23}>{renderPotentialExecutorsTable()}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={10}>
              <Col span={23}>{renderChosenExecutorsTable()}</Col>
            </Row>
          </Col>
          <Col span={8}>{renderRefusedExecutorsTable()}</Col>
        </Row>
      </div>
    </Spin>
  );
};
