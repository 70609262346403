import { BASE_URL, AUTH_URL, PROD_URL, STEND_URL } from '../const/urls';
import { Config } from '../types/config';

/* 
  В качестве baseUrl отдается текущий домен
  для localhost - https://api.sdn104.iflex.ru
  для start-prod (только для случаев, когда нужно это сделать на локальной машине) - https://api.miloserdieapp.ru
*/

const API = {
  BASE_URL,
  PROD_URL,
  AUTH_URL,
  STEND_URL,
  IS_LOAD_CONFIG: false,
};

const getBaseUrl = () => {
  const { protocol, host } = document.location;
  if (host.includes('localhost')) {
    if (process.env.REACT_APP_ENV === 'prod') return API.PROD_URL;
    return API.BASE_URL;
  }
  return `${protocol}//${host}`;
};

export let baseUrl = getBaseUrl();
export let securityBaseUrl = baseUrl.replace('api', 'auth');
export let standBaseUrl = baseUrl.replace('api.', '');

export const changeApi = (data: Config): void => {
  if (data.BASE_URL) {
    API.BASE_URL = data.BASE_URL;
  }

  if (data.PROD_URL) {
    API.PROD_URL = data.PROD_URL;
  }

  if (data.AUTH_URL) {
    API.AUTH_URL = data.AUTH_URL;
  }

  baseUrl = `${data.BASE_URL}`;
  securityBaseUrl = `${data.AUTH_URL}`;
  standBaseUrl = `${data.STEND_URL}`;
  API.IS_LOAD_CONFIG = true;
};
