import React from 'react';

export type ChangeArchivedCheck = (check: boolean) => void;
export type FetchSections = (closed: boolean) => void;
export type FetchSectionsId = (sectionId: string) => void;
export type FetchSectionsIdFiles = (sectionId: string) => void;
export type EditSectionsId = (
  sectionId: string,
  params: { section_name: string; section_body: string },
  onSuccess: () => void
) => void;
export type ClosedSectionsIdDate = (
  sectionId: string,
  params: { section_name: string; section_body: string },
  onSuccess: () => void
) => void;
export type AddNewSection = (params: { section_name: string; section_body: string }, onSuccess: (sectionId) => void) => void;

export interface IApplicationContainerContext {
  isUserNewVolunteer: boolean;
  isArchivedCheck: boolean;
  onChangeArchivedCheck: ChangeArchivedCheck;
  fetchSections: FetchSections;
  fetchSectionsId: FetchSectionsId;
  fetchSectionsIdFiles: FetchSectionsIdFiles;
  editSectionId: EditSectionsId;
  closedSectionIdDate: ClosedSectionsIdDate;
  addNewSection: AddNewSection;
}

export const InfoContext = React.createContext<IApplicationContainerContext | null>(null);

export const useInfoContext = () => {
  const context = React.useContext(InfoContext);
  if (context === null) {
    throw new Error('useContext must be inside a Provider with a value');
  }
  return context;
};
