import React from 'react';

export type SaveCurrentRoleId = (roleId: number) => void;

export type LoadRolesPermissionsList = () => void;
export type DeleteRole = (roleId: number) => Promise<any>;
export type UpdateRole = (
  editedRole: {
    roleId: number;
    body: {
      id: number;
      alias: string;
      dsc: string;
      tenantId: number;
      includedPermissions: { id: number; alias: string; dsc: string }[];
    };
  },
  onChangeEditMode: (value: boolean) => void
) => Promise<any>;
export type CreateRole = (createRole: {
  dsc: string;
  alias: string;
  includedPermissions: { id: number; alias: string; dsc: string }[];
}) => Promise<any>;

export interface IAdministrationRolesContext {
  loadRolesPermissionsList: LoadRolesPermissionsList;
  deleteRole: DeleteRole;
  updateRole: UpdateRole;
  createRole: CreateRole;
  saveCurrentRoleId: SaveCurrentRoleId;
}

export const AdministrationRolesContext = React.createContext<IAdministrationRolesContext | null>(null);

export const useAdministrationRolesContext = () => {
  const context = React.useContext(AdministrationRolesContext);
  if (context === null) {
    throw new Error('useContext must be inside a Provider with a value');
  }
  return context;
};
