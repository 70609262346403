import { Button, Checkbox, Col, Divider, Row, Table, TableProps } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useAdministrationPersonContext } from '../../containers/AdministrationPersonContainer/AdministrationPersonContext';
import { TransferIcon } from '../../lib/img/TransferIcon';
import { IPermissionTableRecord } from '../../lib/interfaces/Administration';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { hasAccess } from '../../lib/utils/usePermissions';
import { useAppSelector } from '../../store/hooks';

interface IProps {}

export const AdministrationPersonPermisionsBlock: FC<IProps> = () => {
  const { onSubmitBlockPermissions } = useAdministrationPersonContext();
  const { userLoading, user } = useAppSelector((state) => state.administration);
  const [editMode, setEditMode] = useState(false);

  const [availableTableData, setAvailableTableData] = useState<IPermissionTableRecord[]>([]);

  const [blockedTableData, setBlockeddTableData] = useState<IPermissionTableRecord[]>([]);

  const setDefaultAvailableTableData = () => {
    const blockedPermissionsIds = (user?.excludedPermissions || []).map(({ id }) => id);
    setAvailableTableData(
      (user?.includedRoles[0]?.includedPermissions || [])
        .map(({ id, dsc }) => ({
          checked: false,
          id,
          key: id,
          permissionName: dsc,
        }))
        .filter(({ id }) => !blockedPermissionsIds.includes(id))
        .sort((a, b) => (a.permissionName > b.permissionName ? 1 : -1))
    );
  };

  const setDefaultBlockedTableData = () => {
    setBlockeddTableData(
      (user?.excludedPermissions || [])
        .map(({ id, dsc }) => ({
          checked: false,
          id,
          key: id,
          permissionName: dsc,
        }))
        .sort((a, b) => (a.permissionName > b.permissionName ? 1 : -1))
    );
  };

  // Установка исходного списка доступных привилегий из роли
  useEffect(() => {
    setDefaultAvailableTableData();
  }, [user]);

  // Установка исходного списка заблокированных привилегий, не связанных с ролью
  useEffect(() => {
    setDefaultBlockedTableData();
  }, [user]);

  const columnsAssigned = [
    {
      key: 0,
      dataIndex: 'checked',
      render: (checked) => editMode && <Checkbox checked={checked} />,
      width: '28px',
    },
    {
      key: 1,
      dataIndex: 'permissionName',
    },
  ];

  const columnsAvailable = [
    {
      key: 0,
      dataIndex: 'checked',
      render: (checked) => editMode && <Checkbox checked={checked} />,
      width: '28px',
    },
    {
      key: 1,
      dataIndex: 'permissionName',
    },
  ];

  const handleDiscard = () => {
    setDefaultAvailableTableData();
    setDefaultBlockedTableData();
    setEditMode(false);
  };

  const toggleCheckAvailable = (id: number) => {
    setAvailableTableData((prev) =>
      prev.map((prevItem) => (prevItem.id === id ? { ...prevItem, checked: !prevItem.checked } : prevItem))
    );
  };

  const toggleCheckAvailableAll = () => {
    const currentChecked = availableTableData.filter(({ checked }) => checked).length === availableTableData.length;
    setAvailableTableData((prev) => prev.map((prevItem) => ({ ...prevItem, checked: !currentChecked })));
  };

  const toggleCheckBlocked = (id: number) => {
    setBlockeddTableData((prev) =>
      prev.map((prevItem) => (prevItem.id === id ? { ...prevItem, checked: !prevItem.checked } : prevItem))
    );
  };

  const toggleCheckBlockedAll = () => {
    const currentChecked = blockedTableData.filter(({ checked }) => checked).length === blockedTableData.length;
    setBlockeddTableData((prev) => prev.map((prevItem) => ({ ...prevItem, checked: !currentChecked })));
  };

  const transfer = (from: 'available' | 'blocked') => {
    switch (from) {
      case 'available':
        setAvailableTableData((prevAvailable) => {
          setBlockeddTableData((prevBlocked) =>
            [
              ...prevBlocked,
              ...prevAvailable.filter(({ checked }) => checked).map((item) => ({ ...item, checked: false })),
            ].sort((a, b) => (a.permissionName > b.permissionName ? 1 : -1))
          );
          return prevAvailable.filter(({ checked }) => !checked);
        });
        break;
      case 'blocked':
        setBlockeddTableData((prevBlocked) => {
          setAvailableTableData((prevAvailable) =>
            [
              ...prevAvailable,
              ...prevBlocked.filter(({ checked }) => checked).map((item) => ({ ...item, checked: false })),
            ].sort((a, b) => (a.permissionName > b.permissionName ? 1 : -1))
          );
          return prevBlocked.filter(({ checked }) => !checked);
        });
        break;
    }
  };

  const scroll: TableProps<any>['scroll'] = {
    y: '355px',
  };

  return (
    <div className="administrationPerson-roles">
      <Row gutter={15} style={{ height: '423px' }}>
        <Col span={12}>
          <h4 className="text-bold font-size-18 mb2">Привилегии, доступные в рамках роли</h4>
          <Divider className="thin-divider mt0 mb1" />
          <div
            className="administrationPerson-roles-checkAll font-roboto"
            onClick={editMode ? toggleCheckAvailableAll : () => {}}
          >
            <Checkbox
              className="ml10"
              style={editMode ? {} : { visibility: 'hidden' }}
              disabled={availableTableData.length === 0}
              checked={
                availableTableData.length > 0 &&
                availableTableData.filter(({ checked }) => checked).length === availableTableData.length
              }
            />
            <div className="ml11">{availableTableData.length} элем.</div>
            <div className="ml-auto mr10" style={editMode ? {} : { visibility: 'hidden' }}>
              Выбрать все
            </div>
          </div>
          <Table
            size="small"
            loading={checkIsLoading(userLoading)}
            showHeader={false}
            columns={columnsAvailable}
            dataSource={availableTableData}
            className="striped-table"
            rowClassName={(r, i) => (i % 2 ? 'cursor-pointer' : 'cursor-pointer striped-table__oddRow')}
            pagination={false}
            onRow={({ id }) => ({
              onClick: () => (editMode ? toggleCheckAvailable(id) : {}),
            })}
            scroll={scroll}
          />
        </Col>
        <Col span={1} className="mt149">
          {editMode && (
            <TransferIcon
              side="right"
              active={availableTableData.some(({ checked }) => checked)}
              onClick={() => transfer('available')}
            />
          )}
          {editMode && (
            <TransferIcon
              active={blockedTableData.some(({ checked }) => checked)}
              onClick={() => transfer('blocked')}
            />
          )}
        </Col>
        <Col span={11}>
          <h4 className="text-bold font-size-18 mb2">Заблокированные привилегии</h4>
          <Divider className="thin-divider mt0 mb1" />
          <div className="administrationPerson-roles-checkAll font-roboto" onClick={toggleCheckBlockedAll}>
            <Checkbox
              className="ml10"
              style={editMode ? {} : { visibility: 'hidden' }}
              disabled={blockedTableData.length === 0}
              checked={
                blockedTableData.length > 0 &&
                blockedTableData.filter(({ checked }) => checked).length === blockedTableData.length
              }
            />
            <div className="ml11">{blockedTableData.length} элем.</div>
            <div className="ml-auto mr10" style={editMode ? {} : { visibility: 'hidden' }}>
              Выбрать все
            </div>
          </div>
          <Table
            size="small"
            loading={checkIsLoading(userLoading)}
            showHeader={false}
            columns={columnsAssigned}
            dataSource={blockedTableData}
            className="striped-table"
            rowClassName={(r, i) => (i % 2 ? 'cursor-pointer' : 'cursor-pointer striped-table__oddRow')}
            pagination={false}
            onRow={({ id }) => ({
              onClick: () => (editMode ? toggleCheckBlocked(id) : {}),
            })}
            scroll={scroll}
          />
        </Col>
      </Row>
      <Row justify="end" gutter={20} className="mt20">
        {!editMode && hasAccess(['ADMINISTRATION.USER.WRITE']) && (
          <Col>
            <Button type="primary" onClick={() => setEditMode(true)}>
              Редактировать
            </Button>
          </Col>
        )}
        {editMode && (
          <>
            <Col>
              <Button onClick={handleDiscard}>Сбросить</Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  onSubmitBlockPermissions({
                    include: user?.includedPermissions.map(({ id }) => id) || [],
                    exclude: blockedTableData.map(({ id }) => id),
                  }).then(() => setEditMode(false));
                }}
              >
                Сохранить
              </Button>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
