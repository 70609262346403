import React, { FC, useEffect, useState } from 'react';
import { Badge, Button, Col, Dropdown, Row, Tooltip } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BellOutlined } from '@ant-design/icons';
import { ReactComponent as Exit } from '../../lib/img/exit.svg';
import LocalStorageService from '../../services/LocalStorageService';
import { useMainContext } from '../../containers/MainContainer/MainContext';
import './Navbar.less';
import { getFio } from '../../lib/utils/applications';
import { useAppSelector } from '../../store/hooks';
import NotificationsDropdownContainer from '../../containers/NotificationsDropdownContainer/NotificationsDropdownContainer';
import PersonPhoto from '../FileCard/PersonPhoto';
import axios from 'axios';
import { getPersonAvatarRoute } from '../../lib/routes/persons';

type MenuItem =
  | {
      name: 'Просьбы';
      route: 'applications/list';
      subRoute?: string;
      size: number;
    }
  | { name: 'Картотека'; route: 'files/list'; subRoute?: string; size: number }
  | {
      name: 'Администрирование';
      route: 'administration';
      subRoute?: string;
      size: number;
    }
  | { name: 'Инфо'; route: 'info'; subRoute?: string; size: number };

export const Navbar: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { hasAccess } = useMainContext();
  const { meUserFull } = useAppSelector((state) => state.persons);
  const { personTypes } = useAppSelector((state) => state.dictionaries);
  const isUserNewVolunteer = meUserFull?.personStatusId === 1 && meUserFull?.personTypeId === 3;
  // @ts-ignore
  const { unreadNotificationsCount } = useAppSelector((state) => state.notifications);
  const [image, setImage] = useState<any>();

  // Сохранение пути вкладки просьбы для возврата в карточку просьбы после перехода в другие разделы
  const [applicationsListSubRoute, setApplicationsListSubRoute] = useState<string>('');

  useEffect(() => {
    if (location.pathname.includes('applications')) {
      const formatLocation = location.pathname.replace('/applications/list/', '');
      setApplicationsListSubRoute(formatLocation === '/applications/list' ? '' : formatLocation);
    }
  }, [location]);

  useEffect(() => {
    axios.get(getPersonAvatarRoute(meUserFull?.id), { responseType: 'arraybuffer' }).then((res) => {
      setImage(Buffer.from(res.data, 'binary').toString('base64'));
    });
  }, []);

  const handleLogout = () => {
    LocalStorageService.clearToken();
    window.location.reload();
  };

  const menuData: MenuItem[] = [
    {
      name: 'Просьбы',
      route: 'applications/list',
      subRoute: applicationsListSubRoute,
      size: 85,
    },
    { name: 'Картотека', route: 'files/list', size: 92 },
    { name: 'Администрирование', route: 'administration', size: 162 },
    { name: 'Инфо', route: 'info', size: 61 },
  ];

  // Доступность пунктов меню
  const menuItemAccess = ({ route }: MenuItem): boolean => {
    switch (route) {
      case 'applications/list':
        // if (isUserNewVolunteer) return false;
        return true;
      case 'files/list':
        if (isUserNewVolunteer) return false;
        return hasAccess(['PERSONLIST.READ', 'PERSONLIST.READ.ALLREGIONS']);
      case 'administration':
        if (isUserNewVolunteer) return false;
        return hasAccess(['ADMINISTRATION.READ']);
      case 'info':
        return true;
    }
  };

  const getMenuItems = (): JSX.Element => {
    const section = location.pathname.split('/').filter(Boolean)[0];
    return (
      <div className="display-flex">
        {menuData.filter(menuItemAccess).map(({ route, name, size, subRoute }) => {
          const width = `${size}px`;
          const onClick = () => {
            navigate([route, subRoute].join('/'));
          };
          return (
            <div
              key={route}
              onClick={onClick}
              className="navbar-menu-item"
              style={
                route.split('/')[0] === section
                  ? {
                      width,
                      backgroundColor: 'white',
                      fontWeight: 'bold',
                    }
                  : { width }
              }
            >
              {name}
            </div>
          );
        })}
      </div>
    );
  };

  const [dropDownOpen, setDropDownOpen] = useState(false);

  const changeDropDown = (status: boolean): void => {
    setDropDownOpen(status);
  };

  return (
    <Row className="navbar navbar-menu ml20 mr20 mt20">
      <Col>
        <Tooltip title="На главную">
          <div className="logo-small mr20 cursor-pointer" onClick={() => navigate('/applications/list')}></div>
        </Tooltip>
      </Col>
      <Col>{getMenuItems()}</Col>
      <Col className="ml-auto mt3">
        {hasAccess(['APPLICATION.CREATE']) && !isUserNewVolunteer && (
          <Link to="/applications/create-application">
            <Button type="primary">Создать просьбу</Button>
          </Link>
        )}
      </Col>
      <Col>
        <Dropdown
          overlay={() => <NotificationsDropdownContainer changeDropDown={changeDropDown} />}
          trigger={['click']}
          open={dropDownOpen}
          onOpenChange={(e) => changeDropDown(e)}
          destroyPopupOnHide={true}
          className="ml22 mr22 mt11 font-size-20 "
        >
          <Badge count={unreadNotificationsCount} color="#315373">
            <BellOutlined className="dark-gray-color cursor-pointer" />
          </Badge>
        </Dropdown>
      </Col>
      <Col onClick={() => navigate('profile')} style={{ cursor: 'pointer' }}>
        <div className="font-size-14">
          {getFio({
            firstName: meUserFull?.firstName,
            middleName: meUserFull?.middleName,
            lastName: meUserFull?.lastName,
          })}
        </div>
        <div className="font-size-12 light-gray-color">
          {personTypes.find((item) => meUserFull?.personTypeId === item.id)?.name}
        </div>
      </Col>
      <Col className="ml15" onClick={() => navigate('profile')} style={{ cursor: 'pointer' }}>
        <PersonPhoto myProfile={true} image={image} mini={true} />
      </Col>
      <Col>
        <Tooltip title="Выйти">
          <Exit className="mt10 ml20 cursor-pointer" onClick={handleLogout} />
        </Tooltip>
      </Col>
    </Row>
  );
};
