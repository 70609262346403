import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import requestStatusCodes from '../../../lib/const/requestStatusCodes';
import { RequestStatuses } from '../../../lib/types/RequestStatuses';
import {
  createNewSectionRoute,
  deleteSectionIdDateRoute,
  editSectionIdRoute,
  getSectionsIdFilesRoute,
  getSectionsIdRoute,
  getSectionsRoute,
} from '../../../lib/routes/sections';
import { Sections } from '../../../lib/interfaces/Sections';
import { message } from 'antd';

interface InitialState {
  sections: Sections[];
  sectionsLoading: RequestStatuses;

  sectionIdData?: Sections;
  sectionIdDataLoading: RequestStatuses;

  updateSectionIdLoading: RequestStatuses;

  sectionIdFilesData: {
    name: string;
    size: 0;
  }[];

  sectionIdFilesDataLoading: RequestStatuses;

  selectedSection?: Sections;

  selectedSectionId?: number;
}

const initialState: InitialState = {
  sections: [],
  sectionsLoading: requestStatusCodes.idle,

  sectionIdData: undefined,
  sectionIdDataLoading: requestStatusCodes.idle,

  updateSectionIdLoading: requestStatusCodes.idle,

  sectionIdFilesData: [],
  sectionIdFilesDataLoading: requestStatusCodes.idle,

  selectedSection: undefined,

  selectedSectionId: undefined,
};

export const getSections = createAsyncThunk('sections/getSections', async (closed: boolean) => {
  try {
    const response = axios(getSectionsRoute(closed));
    const { data } = await response;
    if (data?.length === 0) {
      message.error(
        `${
          closed ? 'Закрытые разделы недоступны. ' : 'Нет доступных разделов. '
        }Пожалуйста, обратитесь к администратору.`
      );
    }
    return data;
  } catch (error) {
    message.error('При получении списка разделов возникла ошибка. Пожалуйста, повторите попытку позднее');
  }
});

export const getSectionsId = createAsyncThunk('sections/getSectionsId', async (sectionId: string) => {
  try {
    const response = axios(getSectionsIdRoute(sectionId));
    const { data } = await response;
    return data;
  } catch (error) {
    message.error('При получении данных раздела возникла ошибка. Пожалуйста, повторите попытку позднее');
  }
});

export const getSectionsIdFiles = createAsyncThunk('sections/getSectionsIdFiles', async (sectionId: string) => {
  try {
    const response = axios(getSectionsIdFilesRoute(sectionId));
    const { data } = await response;
    return data;
  } catch (error) {
    message.error('При получении файлов раздела возникла ошибка. Пожалуйста, повторите попытку позднее');
  }
});

export const updateSectionId = createAsyncThunk(
  'sections/updateSectionId',
  async (params: { sectionId; body: { section_name: string; section_body: string }; onSuccess: () => void }) => {
    try {
      const response = axios.patch(editSectionIdRoute(params.sectionId), params.body).then((res) => {
        params.onSuccess();
        return res;
      });
      const { data } = await response;
      return data;
    } catch (error) {
      message.error('При редактировании раздела возникла ошибка. Пожалуйста, повторите попытку позднее');
    }
  }
);

export const deleteSectionIdDate = createAsyncThunk(
  'sections/deleteSectionIdDate',
  async (params: { sectionId; body: { section_name: string; section_body: string }; onSuccess: () => void }) => {
    try {
      const response = axios.delete(deleteSectionIdDateRoute(params.sectionId), { data: params.body }).then((res) => {
        params.onSuccess();
        return res;
      });
      const { data } = await response;
      return data;
    } catch (error) {
      message.error('При изменении даты закрытия возникла ошибка. Пожалуйста, повторите попытку позднее');
    }
  }
);

export const createNewSection = createAsyncThunk(
  'sections/createNewSection',
  async (params: { body: { section_name: string; section_body: string }; onSuccess: (sectionId) => void }) => {
    try {
      const response = axios.post(createNewSectionRoute(), params.body).then((res) => {
        params.onSuccess(res?.data?.id);
        return res;
      });
      const { data } = await response;
      return data;
    } catch (error) {
      message.error('При добавлении раздела возникла ошибка. Пожалуйста, повторите попытку позднее');
    }
  }
);

const sectionsSlice = createSlice({
  name: 'sections',
  initialState,
  reducers: {
    cleanSections: (state) => ({
      ...state,
      sections: [],
      sectionsLoading: requestStatusCodes.idle,

      sectionIdData: undefined,
      sectionIdDataLoading: requestStatusCodes.idle,

      updateSectionIdLoading: requestStatusCodes.idle,

      sectionIdFilesData: [],
      sectionIdFilesDataLoading: requestStatusCodes.idle,

      selectedSection: undefined,
    }),
    setSelectedSectionId: (state, action) => ({
      ...state,
      selectedSectionId: action.payload,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(getSections.pending, (state) => {
        state.sectionsLoading = requestStatusCodes.pending;
      })
      .addCase(getSections.rejected, (state) => {
        state.sectionsLoading = requestStatusCodes.rejected;
      })
      .addCase(getSections.fulfilled, (state, { payload }) => {
        const copySections = [...payload];

        const defaultIndex = copySections?.findIndex((section) => section.is_default_section === true);
        const notDefaultIndex = copySections?.findIndex((section) => section.section_name === 'Служба поддержки');

        if (copySections.length > 0) {
          if (defaultIndex !== -1) {
            const defaultSection = copySections.splice(defaultIndex, 1)[0];
            copySections.sort((a, b) => a.section_name.localeCompare(b.section_name));
            copySections.unshift(defaultSection);
          } else {
            const notDefaultSection = copySections.splice(notDefaultIndex, 1)[0];
            copySections.sort((a, b) => a.section_name.localeCompare(b.section_name));
            copySections.unshift(notDefaultSection);
          }
        }

        state.sections = copySections;
        state.sectionsLoading = requestStatusCodes.fulfilled;
      })
      .addCase(getSectionsId.pending, (state) => {
        state.sectionIdDataLoading = requestStatusCodes.pending;
      })
      .addCase(getSectionsId.rejected, (state) => {
        state.sectionIdDataLoading = requestStatusCodes.rejected;
      })
      .addCase(getSectionsId.fulfilled, (state, { payload }) => {
        state.sectionIdData = payload;
        state.sectionIdDataLoading = requestStatusCodes.fulfilled;
      })
      .addCase(getSectionsIdFiles.pending, (state) => {
        state.sectionIdFilesDataLoading = requestStatusCodes.pending;
      })
      .addCase(getSectionsIdFiles.rejected, (state) => {
        state.sectionIdFilesDataLoading = requestStatusCodes.rejected;
      })
      .addCase(getSectionsIdFiles.fulfilled, (state, { payload }) => {
        state.sectionIdFilesData = payload;
        state.sectionIdFilesDataLoading = requestStatusCodes.fulfilled;
      })
      .addCase(updateSectionId.pending, (state) => {
        state.updateSectionIdLoading = requestStatusCodes.pending;
      })
      .addCase(updateSectionId.rejected, (state) => {
        state.updateSectionIdLoading = requestStatusCodes.rejected;
      })
      .addCase(updateSectionId.fulfilled, (state) => {
        state.updateSectionIdLoading = requestStatusCodes.fulfilled;
      });
  },
});

const { actions, reducer } = sectionsSlice;

const { cleanSections, setSelectedSectionId } = actions;

export { reducer, cleanSections, setSelectedSectionId };
