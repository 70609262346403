import React, { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Form, Input, Spin, Row, Col, Divider, Table, Checkbox, Button, Modal, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useApplicationContainerContext } from '../../containers/ApplicationContainer/ApplicationContainerContext';
import { checkIsLoaded, checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { useAppSelector } from '../../store/hooks';
import { getDictionaryItemById, getFio } from '../../lib/utils/applications';
import { TransferIcon } from '../../lib/img/TransferIcon';
import './Application.less';
import { PersonShort } from '../../lib/interfaces/PersonShort';
import { saveExecutorsRoute } from '../../lib/routes/applications';
import { appStatusAliases } from '../../lib/const/appStatusAliases';
import { useMainContext } from '../../containers/MainContainer/MainContext';

type ExecutorsListName = 'potentialExecutors' | 'allExecutors' | 'chosenExecutors';

interface Executor extends PersonShort {
  key: number;
  checked: boolean;
  potentialExecutor: boolean;
}

interface ExecutorsData {
  potentialExecutors: Executor[];
  allExecutors: Executor[];
  chosenExecutors: Executor[];
}

interface IProps {}

export const ApplicationExecutors: FC<IProps> = (props) => {
  const location = useLocation();
  const { hasAccess } = useMainContext();
  const { getExecutors, applicationId, editModeExecutors, setEditModeExecutors } = useApplicationContainerContext();

  const onExecutorsPage = location.pathname === `/applications/list/${applicationId}/executors`;

  const { executors, executorsLoading } = useAppSelector((state) => state.applications);
  const { application } = useAppSelector((state) => state.applications);
  const { personType } = useAppSelector((state) => state.dictionaries);
  const { meUserFull } = useAppSelector((state) => state.persons);

  const [savingExecutors, setSavingExecutors] = useState<boolean>(false);
  const [modalSatate, setModalSatate] = useState<{
    active: '' | 'error' | 'success';
    info?: { [key: string]: any };
  }>({ active: '' });
  // Фильтрация исполнителей для поиска
  const [searchExecutors, setSearchExecutors] = useState<string>('');

  const [executorsData, setExecutorsData] = useState<ExecutorsData>({
    allExecutors: [],
    chosenExecutors: [],
    potentialExecutors: [],
  });

  const setDefaultExecutorsData = () => {
    const prepareData = (executorsList: PersonShort[], potentialExecutor: boolean): Executor[] =>
      executorsList.map((executor) => ({
        ...executor,
        key: executor.id,
        checked: false,
        potentialExecutor,
      }));
    setExecutorsData({
      allExecutors: prepareData(executors.allExecutors, false),
      chosenExecutors: prepareData(executors.chosenExecutors, false),
      potentialExecutors: prepareData(executors.potentialExecutors, true),
    });
  };

  // Исходное заполнение списка исполнителей
  useEffect(() => {
    if (checkIsLoaded(executorsLoading)) {
      setDefaultExecutorsData();
    }
  }, [executorsLoading]);

  // console.log({ executorsData });

  const toggleCheckExecutor = (id: number) => {
    const updateList = (list: Executor[]) =>
      list.map((executor) => ({
        ...executor,
        checked: executor.id === id ? !executor.checked : executor.checked,
      }));
    setExecutorsData((prev) => ({
      allExecutors: updateList(prev.allExecutors),
      chosenExecutors: updateList(prev.chosenExecutors),
      potentialExecutors: updateList(prev.potentialExecutors),
    }));
  };

  const columns: ColumnsType<any> = [
    {
      key: 'checked',
      dataIndex: 'checked',
      render: (checked) => (
        <Checkbox checked={checked} style={{ visibility: editModeExecutors ? 'visible' : 'hidden' }} />
      ),
      width: '26px',
    },
    {
      key: 'fio',
      title: 'ФИО',
      render: (v, { id, lastName, firstName, middleName }) => {
        const fio = getFio({ lastName, firstName, middleName });
        return (
          <Link to={`/files/${id}/general`} target="_blank">
            {fio}
          </Link>
        );
      },
      width: '70%',
    },
    {
      key: 'personTypeName',
      title: 'Тип',
      render: (v, record) => {
        const personTypeName = getDictionaryItemById(personType, record.personTypeId)?.name;
        return personTypeName;
      },
      width: '30%',
    },
  ];

  const renderSearch = () => {
    return (
      <Form>
        <Form.Item className="application-executors__search">
          <Input
            placeholder="Введите ФИО"
            allowClear
            onChange={(e) => {
              setSearchExecutors(e.target.value);
            }}
          />
        </Form.Item>
      </Form>
    );
  };

  const transferExecutors = (from: ExecutorsListName) => {
    setExecutorsData((prev) => {
      const transferredExecutors = prev[from]
        .filter(({ checked }) => checked)
        .map((executor) => ({
          ...executor,
          checked: false,
        }));
      switch (from) {
        case 'potentialExecutors':
          return {
            ...prev,
            [from]: prev[from].filter(({ checked }) => !checked),
            chosenExecutors: [...prev.chosenExecutors, ...transferredExecutors],
          };
        case 'chosenExecutors':
          return {
            potentialExecutors: [
              ...prev['potentialExecutors'],
              ...transferredExecutors.filter(({ potentialExecutor }) => potentialExecutor),
            ],
            [from]: prev[from].filter(({ checked }) => !checked),
            allExecutors: [
              ...prev['allExecutors'],
              ...transferredExecutors.filter(({ potentialExecutor }) => !potentialExecutor),
            ],
          };
        case 'allExecutors':
          return {
            ...prev,
            [from]: prev[from].filter(({ checked }) => !checked),
            chosenExecutors: [...prev['chosenExecutors'], ...transferredExecutors],
          };
        default:
          return prev;
      }
    });
  };

  const saveExecutors = () => {
    const url = saveExecutorsRoute({ applicationId });
    setSavingExecutors(true);
    const executorsIds = executorsData.chosenExecutors.map(({ id }) => id);
    axios
      .patch(url, executorsIds)
      .then(() => {
        // message.success("Список исполнителей отредактирован", 3);
        Modal.success({
          title: 'Список исполнителей отредактирован',
          content: 'Для передачи просьбы выбранным добровольцам нажмите кнопку "Передать на исполнение"',
          width: '530px',
        });
        setEditModeExecutors(false);
        getExecutors();
      })
      .catch((e: any) => {
        const { detail, data, code } = e?.response?.data;
        setModalSatate({
          active: 'error',
          info: { detail, data, code },
        });
      })
      .finally(() => {
        setSavingExecutors(false);
      });
  };

  const getRowClassName = (r, i) => (i % 2 ? 'cursor-pointer' : 'cursor-pointer striped-table__oddRow');

  const onRow = ({ id }) => ({
    onClick: () => (editModeExecutors ? toggleCheckExecutor(id) : {}),
  });

  const yScrollSize = `350px`;

  const renderPotentialExecutorsTable = () => {
    return (
      <>
        <h3 className="text-bold mb3">Потенциальные исполнители</h3>
        <Divider className="thin-divider mt0 mb7" />
        <Table
          className="striped-table"
          columns={columns}
          size="small"
          dataSource={executorsData?.potentialExecutors}
          rowClassName={getRowClassName}
          pagination={false}
          onRow={onRow}
          scroll={{
            y: executorsData?.potentialExecutors?.length > 12 ? yScrollSize : undefined,
          }}
        />
      </>
    );
  };

  const renderApplicationExecutorsTable = () => {
    return (
      <>
        <h3 className="text-bold mb3">Выбранные исполнители</h3>
        <Divider className="thin-divider mt0 mb7" />
        <Table
          className="striped-table"
          columns={columns}
          size="small"
          dataSource={executorsData?.chosenExecutors}
          rowClassName={getRowClassName}
          pagination={false}
          onRow={onRow}
          scroll={{
            y: executorsData?.chosenExecutors?.length > 12 ? yScrollSize : undefined,
          }}
        />
      </>
    );
  };

  // Условия вывода кнопки изменения исполнителей
  const hasExecutorsChangeAccess = (): boolean => {
    if (!onExecutorsPage || editModeExecutors) return false;
    const { accepted, available, pending, done } = appStatusAliases;
    const hasAllowedStatus =
      application?.statusId && [accepted, available, pending, done].includes(application.statusId);
    if (!hasAllowedStatus) return false;
    if (hasAccess(['APPLICATION.WRITE.ALLREGIONS'])) {
      return true;
    }
    if (hasAccess(['APPLICATION.WRITE.MYREGION'])) {
      return application?.regionId === meUserFull?.regionId;
    }
    if (
      hasAccess(['APPLICATION.WRITE']) &&
      application?.project?.id &&
      meUserFull?.projectIds &&
      Array.isArray(meUserFull.projectIds)
    ) {
      return meUserFull.projectIds.includes(application.project.id);
    }
    return false;
  };

  const renderExecutorsTable = () => {
    const allExecutors = executorsData?.allExecutors.filter((executor) => {
      const fio = getFio(executor).toLocaleLowerCase();
      if (!searchExecutors) return true;
      return fio.includes(searchExecutors.toLocaleLowerCase());
    });
    return (
      <>
        <h3 className="text-bold mb3">Таблица исполнителей</h3>
        <Divider className="thin-divider mt0 mb3" />
        <Table
          className="striped-table"
          columns={columns.map((column) => {
            if (column.key === 'fio') {
              return {
                ...column,
                title: <>{renderSearch()}</>,
              };
            }
            return column;
          })}
          size="small"
          dataSource={allExecutors}
          rowClassName={getRowClassName}
          pagination={false}
          onRow={onRow}
          scroll={{
            y: executorsData?.allExecutors?.length > 12 ? yScrollSize : undefined,
          }}
        />
      </>
    );
  };

  const renderSaveExecutorsErrorModal = () => {
    const closeModal = () => {
      setModalSatate({ active: '' });
    };

    const errorUsers = modalSatate.info?.data?.map(({ fio }) => fio).join(', ');

    const codesError = {
      '1204': `Данные пользователи: ${errorUsers}, находятся в списке для молитв.
       Назначение невозможно. Сохранение списка исполнителей возможно только после 
       удаления указанных пользователей из «Выбранных исполнителей».`,
      '1203': `Данные пользователи: ${errorUsers}, в данный момент являются
      исполнителями пяти просьб. Назначение на шестую просьбу невозможно.
      Сохранение списка исполнителей возможно только после удаления
      указанных пользователей из «Выбранных исполнителей».`,
    };

    const errorMessage =
      codesError[modalSatate.info?.code] ||
      'При редактировании списка исполнителей возникла ошибка. Пожалуйста, повторите попытку позднее.';

    return (
      <Modal
        visible={modalSatate.active === 'error'}
        width="588px"
        footer={null}
        centered
        closable={false}
        bodyStyle={{ backgroundColor: '#F4F4F4' }}
        onCancel={closeModal}
      >
        <span className="font-roboto">{errorMessage}</span>
        <Row gutter={20} className="mt17">
          <Col className="ml-auto mr-auto">
            <Button onClick={closeModal} type="primary" style={{ width: '160px' }}>
              OK
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };

  return (
    <Spin spinning={checkIsLoading(executorsLoading) || savingExecutors}>
      <div className="application application-executors">
        <Row className="mt10" style={{ height: '440px' }}>
          <Col span={8}>
            <Row gutter={10}>
              <Col span={22}>{renderPotentialExecutorsTable()}</Col>
              <Col span={2} className="mt193">
                {editModeExecutors && (
                  <TransferIcon
                    side="right"
                    active={executorsData?.potentialExecutors.some(({ checked }) => checked)}
                    onClick={() => transferExecutors('potentialExecutors')}
                  />
                )}
                {editModeExecutors && (
                  <TransferIcon
                    active={executorsData?.chosenExecutors.some(
                      ({ checked, potentialExecutor }) => checked && potentialExecutor
                    )}
                    onClick={() => transferExecutors('chosenExecutors')}
                  />
                )}
              </Col>
            </Row>
            <Row></Row>
          </Col>
          <Col span={8}>
            <Row gutter={10}>
              <Col span={22}>{renderApplicationExecutorsTable()}</Col>
              <Col span={2} className="mt193">
                {editModeExecutors && (
                  <TransferIcon
                    side="right"
                    active={executorsData?.chosenExecutors.some(({ checked }) => checked)}
                    onClick={() => transferExecutors('chosenExecutors')}
                  />
                )}
                {editModeExecutors && (
                  <TransferIcon
                    active={executorsData?.allExecutors.some(
                      ({ checked, potentialExecutor }) => checked && !potentialExecutor
                    )}
                    onClick={() => transferExecutors('allExecutors')}
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col span={8}>{renderExecutorsTable()}</Col>
        </Row>
        <Row>
          <Col span={16}>
            {editModeExecutors && (
              <Row gutter={20}>
                {/* <Col>
                  <Button onClick={setDefaultExecutorsData}>
                    Очистить выбор
                  </Button>
                </Col> */}
                <Col>
                  <Button
                    onClick={() => {
                      setDefaultExecutorsData();
                      setEditModeExecutors(false);
                    }}
                  >
                    Отмена
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" onClick={saveExecutors}>
                    Сохранить
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
          <Col span={8}>
            {hasExecutorsChangeAccess() && (
              <Tooltip
                title={
                  application?.statusId === 5
                    ? 'Для выбора исполнителей переведите просьбу на следующий этап, нажав на кнопку "Просьба исполнена" или "Просьба не исполнена"'
                    : ''
                }
              >
                <Button
                  type="primary"
                  onClick={() => setEditModeExecutors(true)}
                  disabled={application?.statusId === 5}
                >
                  Выбрать исполнителей
                </Button>
              </Tooltip>
            )}
          </Col>
        </Row>
        {renderSaveExecutorsErrorModal()}
        {/* {renderSaveExecutorsSuccessModal()} */}
      </div>
    </Spin>
  );
};
