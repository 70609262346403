import axios from 'axios';
import { getAuthRoute } from '../lib/routes/routes';
import JwtDecode from 'jwt-decode';
import LocalStorageService from './LocalStorageService';
import { TokenObject } from '../lib/interfaces/TokenObject';
import { AnyObject } from '../lib/interfaces/AnyObject';

interface IData {
  access_token: string;
  jti: string;
  refresh_token: string;
  scope: 'read write';
  token_type: 'bearer';
}

interface IAuthResponse {
  config: Object;
  data: IData;
  headers: AnyObject;
  request: XMLHttpRequest;
  status: number;
  statusText: string;
}

interface IAuthService {
  getCurrentUser: () => TokenObject | null;
  login: (login: string, password: string) => Promise<IAuthResponse>;
}

const AuthService = {
  login(login, password) {
    return axios.post(getAuthRoute(), `username=${login}&password=${password}&tenant_id=1&grant_type=password`, {
      auth: {
        username: 'web',
        password: 'R@d7vJD6uR',
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  },
  getCurrentUser() {
    const token = LocalStorageService.getAccessToken();
    if (token) return JwtDecode<TokenObject>(token);
    return null;
  },
  refreshToken() {
    axios
      .post(getAuthRoute(), `grant_type=refresh_token&refresh_token=${LocalStorageService.getRefreshToken()}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          GrandType: 'refresh_token',
        },
        auth: { username: 'client', password: 'secret' },
      })
      .then((res) => {
        LocalStorageService.setToken(res?.data);
      })
      .catch(() => {
        LocalStorageService.setObject('isAuth', false);
      });
  },
};

export default AuthService;
