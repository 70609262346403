import React from 'react';
import { ApplicationsMapSearchType, ApplicationsSearchType } from '../../lib/interfaces/SearchApplicationsBody';
import { SearchPersonBody } from '../../lib/interfaces/SearchPersonBody';
import { HasAccess } from '../../lib/utils/usePermissions';
import { SearchApplicationsState } from './useSearchApplications';
import { DicIconKeys, DicIconName } from '../../lib/utils/collectionSvg';

export type LoadResponsibles = ({ projectIds, personStatusIds }: SearchPersonBody) => void;
export type SetSearchType = React.Dispatch<React.SetStateAction<ApplicationsSearchType>>;
export type SetMapSearchType = React.Dispatch<React.SetStateAction<ApplicationsMapSearchType>>;
export type LoadProjects = (searchParams?: { regionId?: number; participantId?: number }) => Promise<any>;
export type LoadDicTypes = () => Promise<any>;
export type LoadDicTypesTermin = (dicTypeId: string) => Promise<any>;

export type LoadProjectById = (projectId: number) => void;

export type LoadRoles = (searchParams: { page: number; size: number }) => Promise<any>;

export type HandleCleanProjects = () => void;

export type FindDynamicSVGReactElement = (
  dicId: DicIconKeys,
  dopIconName: DicIconName,
  typeIco: 'iconName' | 'mapIconName',
  subjectId?: number
) => JSX.Element;

export type ImportSVGIconStringForImg = (
  dicId: DicIconKeys,
  dopIconName: DicIconName,
  typeIco: 'iconName' | 'mapIconName',
  subjectId?: number
) => string;

interface IMainContext {
  loadResponsibles: LoadResponsibles;
  searchApplicationsState: SearchApplicationsState;
  searchType: ApplicationsSearchType;
  mapSearchType: ApplicationsMapSearchType;
  setSearchType: SetSearchType;
  setMapSearchType: SetMapSearchType;
  hasAccess: HasAccess;
  loadProjects: LoadProjects;
  loadDicTypes: LoadDicTypes;
  loadDicTypesTermin: LoadDicTypesTermin;
  loadProjectById: LoadProjectById;
  handleCleanProjects: HandleCleanProjects;
  loadRoles: LoadRoles;
  windowDimensions: {
    width: number;
    height: number;
  };
  findDynamicSVGReactElement: FindDynamicSVGReactElement;
  importSVGIconStringForImg: ImportSVGIconStringForImg;
  searchApplicationsMapStateAll: SearchApplicationsState;
}

export const MainContext = React.createContext<IMainContext | null>(null);

export const useMainContext = () => {
  const context = React.useContext(MainContext);
  if (context === null) {
    throw new Error('useContext must be inside a Provider with a value');
  }
  return context;
};
