import React, { FC, useEffect, useState } from 'react';
import { Button, Col, Divider, Row, Spin, Form, message, Upload, Modal, Tooltip } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import uniqueId from 'lodash/uniqueId';
import axios from 'axios';
import { getDictionaryItemById, getFio, getSex } from '../../lib/utils/applications';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { toDisplayFormat } from '../../lib/utils/formatDate';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useApplicationContainerContext } from '../../containers/ApplicationContainer/ApplicationContainerContext';
import { ReactComponent as UploadFileIcon } from '../../lib/img/upload-file-icon.svg';
import { ReactComponent as DeleteFileIcon } from '../../lib/img/delete-file-icon.svg';
import { useMainContext } from '../../containers/MainContainer/MainContext';
import { ApplicationMainAddress } from './ApplicationMainAddress';
import { ApplicationMainProcessing } from './ApplicationMainProcessing';
import './Application.less';
import {
  applicationsFavoritesChangeRoute,
  deleteApplicationFileRoute,
  uploadApplicationFileRoute,
} from '../../lib/routes/applications';
import CheckFavoriteApplication from '../common/CheckApplication';
import { ReactComponent as CopyApplicationIcon } from '../../lib/img/copy.svg';
import { setApplicationCopy } from '../../store/slices/applicationsSlice/applicationsSlice';
import FireBaseService from '../../services/FireBaseService/FireBaseService';
import { ReactComponent as FileDoc } from '../../lib/img/formats/doc.svg';
import { ReactComponent as FileDocx } from '../../lib/img/formats/docx.svg';
import { ReactComponent as FileJpeg } from '../../lib/img/formats/jpeg.svg';
import { ReactComponent as FileJpg } from '../../lib/img/formats/jpg.svg';
import { ReactComponent as FilePdf } from '../../lib/img/formats/pdf.svg';
import { ReactComponent as FilePng } from '../../lib/img/formats/png.svg';
import { ReactComponent as FileXls } from '../../lib/img/formats/xls.svg';
import { ReactComponent as FileXlsx } from '../../lib/img/formats/xlsx.svg';

export const ApplicationMain: FC = () => {
  const { hasAccess, loadResponsibles } = useMainContext();
  const {
    getApplication,
    getApplicationFilesList,
    downloadApplicationFile,
    meIsChosenExecutor,
    applicationId,
    editModeMain,
    setEditModeMain,
    onFinishEditing,
    setLoadingData,
    loadingData,
    editApplicationForm,
  } = useApplicationContainerContext();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { application, applicationLoading, applicationFilesList, applicationFilesListLoading } = useAppSelector(
    (state) => state.applications
  );
  const { personCategory } = useAppSelector((state) => state.dictionaries);
  const fio = getFio({
    firstName: application?.ward?.firstName,
    lastName: application?.ward?.lastName,
    middleName: application?.ward?.middleName,
  });

  const { meUserFull } = useAppSelector((state) => state.persons);

  const [applicationMainState, setApplicationMainState] = useState({ activeModal: '' });

  const [deletedFileName, setDeletedFileName] = useState<string>('');

  // Установка initialValues для редактирования, т.к. прямая передача в Form работает некорректно
  // а также загрузка ответственных для текущего региона
  useEffect(() => {
    if (editModeMain) {
      if (application?.project?.id) {
        loadResponsibles({ projectIds: [application.project.id], personStatusIds: [3] });
      }
      editApplicationForm.setFieldsValue({
        unstructuredAddress: application?.address?.unstructuredAddress,
        subwayStationId: application?.address?.subwayStationId,
        apartment: application?.address?.apartment,
        intercomCode: application?.address?.intercomCode,
        entrance: application?.address?.entrance,
        floor: application?.address?.floor,
        phones: application?.phones,
        subjectId: application?.subjectId,
        // regularityAssistanceId может прийти с 0
        regularityAssistanceId: application?.regularityAssistanceId ? application.regularityAssistanceId : undefined,
        regionId: application?.regionId,
        projectId: application?.project?.id,
        // responsiblePersonId может прийти с 0
        responsiblePersonId: application?.responsible?.id ? application.responsible.id : undefined,
        numberOfPerformers: application?.numberOfPerformers,
        appText: application?.text,
        deadline: application?.deadline ? moment(application.deadline) : null,
        urgent: application?.urgent,
        executionDate: application?.executionDate ? moment(application.executionDate) : null,
        executionTime: application?.executionTime,
        additionalAddress: application?.additionalAddress,
        appTypeId: application?.appTypeId,
      });
    }
  }, [application, editModeMain]);

  const renderPersonalData = () => {
    const birthDate = application?.ward?.birthDate
      ? toDisplayFormat(moment(application.ward.birthDate, 'YYYY-MM-DD'))
      : '';
    const category =
      application && Array.isArray(application.ward?.personCategory)
        ? application?.ward?.personCategory
            .map((categoryId) => getDictionaryItemById(personCategory, categoryId)?.name)
            .join(', ')
        : '';

    return (
      <div>
        <h3 className="text-bold mb3">Личные данные</h3>
        <Divider className="thin-divider mt0 mb7" />
        {(meIsChosenExecutor || hasAccess(['APPLICATIONS.READ.FULLACCESS'])) && (
          <>
            <Row>
              <Col className="display-flex">
                <div className="application-main__dataLabel">ФИО:</div>
                <div className="application-main__dataValue" title={fio}>
                  {fio}
                </div>
              </Col>
            </Row>
            <Row>
              <div className="application-main__dataLabel">Дата рождения:</div>
              <div className="application-main__dataValue">{birthDate}</div>
            </Row>
            <Row>
              <div className="application-main__dataLabel">Пол:</div>
              <div className="application-main__dataValue">{getSex(application?.ward?.sex)}</div>
            </Row>
          </>
        )}
        <Row gutter={15}>
          <Col span={5}>
            <div className="application-main__dataLabel">Категория:</div>
          </Col>
          <Col span={19}>
            <div className="application-main__dataValue" title={category}>
              {category}
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const renderServiceData = () => {
    const mmoWard = application?.ward?.mmoWard ? 'Да' : 'Нет';
    return (
      <div className="mt15">
        <h3 className="text-bold mb3">Служебная информация</h3>
        <Divider className="thin-divider mt0 mb7" />
        <Row>
          <Col className="display-flex">
            <div className="application-main__dataLabel">Подопечный ММО:</div>
            <div className="application-main__dataValue">{mmoWard}</div>
          </Col>
        </Row>
        <Row>
          <Col className="application-main__dataLabel">Анкета подопечного:</Col>
          <Col span={13}>
            <div className="application-main__dataValue" title={fio}>
              <Link to={`/files/${application?.ward?.id}/general`} target="_blank">
                {fio}
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const fileComponent = {
    doc: FileDoc,
    docx: FileDocx,
    jpeg: FileJpeg,
    jpg: FileJpg,
    pdf: FilePdf,
    png: FilePng,
    xls: FileXls,
    xlsx: FileXlsx,
  };

  const renderAttachments = () => {
    const filesList = applicationFilesList.map((file) => {
      const fileName = file.name.replace('/files/', '');
      const fileFormat = file.name.split('.').pop();

      const SpecificFile = fileFormat && fileComponent[fileFormat];
      return (
        <Col key={uniqueId()}>
          {hasAccess(['APPLICATION.FILES.DELETE']) && (
            <Tooltip title="Удалить файл">
              <DeleteFileIcon
                style={{ position: 'relative', top: '-23px', left: '36px' }}
                className="cursor-pointer"
                onClick={() => {
                  setDeletedFileName(file.name);
                }}
              />
            </Tooltip>
          )}
          <Tooltip title="Скачать файл">
            <SpecificFile
              className="cursor-pointer"
              title={fileName}
              onClick={() => {
                if (hasAccess(['APPLICATION.FILES.DOWNLOAD'])) {
                  downloadApplicationFile({ fileName });
                } else {
                  message.error('Скачивание файлов недоступно', 3);
                }
              }}
            />
          </Tooltip>
        </Col>
      );
    });

    const acceptedTypes =
      '.pdf, .doc, .docx, .png, .jpg, .jpeg, .xls, .xlsx, msword, vnd.openxmlformats-officedocument.wordprocessingml.document, vnd.openxmlformats-officedocument.spreadsheetml.sheet, vnd.ms-excel';

    const uploadFiles = ({ file }) => {
      const fileType = file.name.split('.')[file.name.split('.').length - 1];

      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error('Размер файла не должен превышать 5Мб.');
        return;
      }

      if (acceptedTypes.includes(fileType)) {
        if (file.originFileObj !== undefined) {
          const formData = new FormData();
          formData.append('files', file.originFileObj);
          setLoadingData(true);
          axios
            .post(uploadApplicationFileRoute({ applicationId }), formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then(() => {
              message.success('Файл добавлен', 3);
              getApplicationFilesList();
            })
            .catch(() => {
              message.error(
                <>
                  <div>При загрузке файла возникла ошибка.</div>
                  <div>Пожалуйста, повторите попытку позднее</div>
                </>,
                3
              );
            })
            .finally(() => {
              setLoadingData(false);
            });
        }
      } else {
        message.error(
          <>
            <div>Данный формат файлов не поддерживается.</div>
            <div>Пожалуйста, загрузите файл pdf, doc, docx, png, jpg, jpeg, xls, xlsx</div>
          </>,
          3
        );
      }
    };

    return (
      <>
        <h3 className="text-bold mb3">Вложения</h3>
        <Divider className="thin-divider mt0 mb7" />
        <Row className="pl10 mt20 pb20" gutter={20}>
          <Col className="display-flex" span={24}>
            {filesList}
            {hasAccess(['APPLICATION.FILES.UPLOAD']) && (
              <Upload
                accept={acceptedTypes}
                multiple
                showUploadList={false}
                customRequest={() => {}}
                onChange={uploadFiles}
              >
                <UploadFileIcon
                  style={{
                    cursor: 'pointer',
                    marginLeft: '15px',
                  }}
                />
              </Upload>
            )}
          </Col>
          {hasAccess(['APPLICATION.COPY.MYREGION', 'APPLICATION.COPY.ALLREGIONS']) && (
            <Col span={24} className="display-flex" style={{ justifyContent: 'end' }}>
              <Tooltip
                title={
                  application?.ward?.inPrayList ? 'Подопечный находится в списке для молитв, копирование запрещено' : ''
                }
                style={{ fontSize: 12 }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    FireBaseService.createLogEventFireBase('Событие. Копировать просьбу');
                    handleCopy();
                  }}
                  disabled={application?.ward?.inPrayList}
                >
                  <div className="display-flex">
                    <CopyApplicationIcon className="ml5" />
                    <div className="ml6">Копировать просьбу</div>
                  </div>
                </Button>
              </Tooltip>
            </Col>
          )}
        </Row>
      </>
    );
  };

  const renderDeleteFileModal = () => {
    const handleCloseModal = () => {
      setDeletedFileName('');
    };
    const handleDelete = () => {
      setLoadingData(true);
      axios
        .delete(
          deleteApplicationFileRoute({
            applicationId,
            fileName: deletedFileName,
          })
        )
        .then(() => {
          message.success('Файл удален');
          getApplicationFilesList();
        })
        .catch(() => {
          message.error(
            <>
              <div>При удалении файла возникла ошибка.</div>
              <div>Пожалуйста, повторите попытку позднее</div>
            </>,
            3
          );
        })
        .finally(() => {
          handleCloseModal();
          setLoadingData(false);
        });
    };
    return (
      <Modal visible={Boolean(deletedFileName)} width="690px" footer={null} onCancel={handleCloseModal}>
        <h2>Удаление файла</h2>
        <Divider className="thin-divider mt10" />
        <div>Вы действительно хотите удалить выбранный файл: {deletedFileName}?</div>
        <Row gutter={20} className="mt20 mb10">
          <Col className="ml-auto">
            <Button onClick={handleCloseModal}>Отмена</Button>
          </Col>
          <Col className="mr-auto">
            <Button type="primary" onClick={handleDelete}>
              Удалить
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };

  const handleCopy = () => {
    dispatch(
      setApplicationCopy({
        id: application?.ward?.id,
        firstName: application?.ward?.firstName,
        lastName: application?.ward?.lastName,
        middleName: application?.ward?.middleName,
        birthDate: application?.ward?.birthDate,
        official: {
          mmoWard: application?.ward?.mmoWard,
        },
        sex: application?.ward?.sex,
        phones: application?.phones,
        personCategory: application?.ward?.personCategory,
        address: application?.address,
        additionalAddress: application?.additionalAddress,
        subjectId: application?.subjectId,
        regularityAssistanceId: application?.regularityAssistanceId,
        regionId: application?.regionId,
        projectId: application?.project?.id,
        responsiblePersonId: application?.responsible?.id,
        numberOfPerformers: application?.numberOfPerformers,
        deadline: application?.deadline,
        executionDate: application?.executionDate,
        executionTime: application?.executionTime,
        urgent: application?.urgent,
        text: application?.text,
        appTypeId: application?.appTypeId,
      })
    );
    window.scrollTo(0, 0);
    navigate(`/applications/create-application/${application?.id}`);
  };

  const renderIsSubmitFailureModal = () => {
    const closeModal = () => {
      setApplicationMainState({ activeModal: '' });
    };

    return (
      <Modal
        visible={applicationMainState.activeModal === 'isSubmitFailure'}
        width="500px"
        footer={null}
        onCancel={closeModal}
        centered
        bodyStyle={{ backgroundColor: '#F4F4F4' }}
      >
        <span className="font-roboto">Пожалуйста, заполните обязательные поля.</span>
        <Row style={{ marginTop: '20px' }}>
          <Col className="ml-auto mr-auto mt17">
            <Button type="primary" onClick={closeModal} style={{ width: '180px' }}>
              OK
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };

  const findRequiredErrorField = () => {
    setApplicationMainState({ activeModal: 'isSubmitFailure' });
  };

  const onChangeFavoritesChecked = async (idApp: string, checked: boolean) => {
    FireBaseService.createLogEventFireBase('Событие. Добавить просьбу в избранное');

    if (checked) {
      await axios
        .delete(applicationsFavoritesChangeRoute({ applicationId: idApp }))
        .then((response) => {
          getApplication();
        })
        .catch(() => {
          message.destroy();
          message.error(
            <>
              <div>При удалении просьбы из избранного возникла ошибка.</div>
              <div>Пожалуйста, повторите попытку позднее</div>
            </>,
            3
          );
        });
    } else {
      await axios
        .post(applicationsFavoritesChangeRoute({ applicationId: idApp }))
        .then((response) => {
          getApplication();
        })
        .catch(() => {
          message.destroy();
          message.error(
            <>
              <div>При добавлении просьбы в избранного возникла ошибка.</div>
              <div>Пожалуйста, повторите попытку позднее</div>
            </>,
            3
          );
        });
    }
  };

  const favoriteCheckRender = () => {
    const findId = application?.liked?.some((id) => id === meUserFull?.id);
    return (
      <CheckFavoriteApplication
        checked={!!findId}
        onCheck={() => onChangeFavoritesChecked(applicationId, !!findId)}
        style={{ position: 'absolute', top: 0, right: 20, zIndex: 1 }}
      />
    );
  };

  return (
    <Spin spinning={checkIsLoading(applicationLoading, applicationFilesListLoading) || loadingData}>
      <Form
        className="application application-main"
        layout="vertical"
        form={editApplicationForm}
        onFinish={onFinishEditing}
        onFinishFailed={findRequiredErrorField}
      >
        <div className="ml20 mr20 pt15">
          {favoriteCheckRender()}
          <Row gutter={20}>
            <Col span={6}>
              {renderPersonalData()}
              {hasAccess(['APPLICATION.READ.TECHINFO']) && renderServiceData()}
            </Col>
            <Col span={18}>
              <ApplicationMainAddress editModeMain={editModeMain} editApplicationForm={editApplicationForm} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ApplicationMainProcessing editModeMain={editModeMain} editApplicationForm={editApplicationForm} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>{hasAccess(['APPLICATION.FILES.READ']) && !editModeMain && renderAttachments()}</Col>
          </Row>
          {editModeMain && (
            <Row className="pb20 mt20" gutter={20}>
              <Col>
                <Button onClick={() => setEditModeMain(false)}>Отмена</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">
                  Сохранить
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </Form>
      {renderDeleteFileModal()}
      {renderIsSubmitFailureModal()}
    </Spin>
  );
};
