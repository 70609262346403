import React, { FC, useState } from 'react';
import { Modal, Divider, Form, Input, Checkbox, Row, Col, DatePicker, Select, Button, Spin } from 'antd';
import { displayFormat } from '../../../lib/utils/formatDate';
import { MaskedInput } from 'antd-mask-input';
import usePhoneMask from '../../../lib/utils/usePhoneMask';
import { useAppSelector } from '../../../store/hooks';
import { useAdministrationContext } from '../../../containers/AdministrationContainer/AdministrationContext';
import { emailPatternMain, passwordPattern } from '../../../lib/const/patterns';
import { PersonNew } from '../../../lib/interfaces/PersonNew';
import { useNavigate } from 'react-router-dom';
import { fioValidator } from '../../../lib/utils/fioValidator';
import moment from 'moment';
import InputMask from 'react-input-mask';
import { datePikerMaskDefault } from '../../../lib/const/masks';

interface IProps {
  visible: boolean;
  closeModal: () => void;
}

export const CreatePerson: FC<IProps> = ({ visible, closeModal }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [personCreating, setPersonCreating] = useState(false);
  const { phoneMask, getUnmaskedPhoneNumber, phoneInputPlaceholder, phoneValidator } = usePhoneMask();
  const { checkLoginExistence, createPerson } = useAdministrationContext();

  const { region, personType } = useAppSelector((state) => state.dictionaries);

  const onChangeWithoutMiddleName = (e) => {
    if (e.target.checked) form.resetFields(['middleName']);
  };

  const onReset = () => {
    closeModal();
    form.resetFields();
  };

  const onCreate = (values) => {
    setPersonCreating(true);
    const newPerson: PersonNew = {
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      email: values.email,
      birthDate: values.birthDate.format('YYYY-MM-DD'),
      personTypeId: values.personTypeId,
      phone: values.phone,
      regionId: values.regionId,
    };

    if (values.withoutMiddleName) {
      delete newPerson.middleName;
    }

    createPerson(newPerson)
      .then(({ data }) => {
        navigate(`/administration/persons/${data.id}`);
      })
      .finally(() => {
        setPersonCreating(false);
      });
  };

  return (
    <Modal visible={visible} bodyStyle={{ backgroundColor: '#F4F4F4' }} footer={null} onCancel={onReset}>
      <Spin spinning={personCreating}>
        <h2 className="mb0 text-bold">Создание нового пользователя</h2>
        <Divider className="thin-divider mt0" />
        <Form layout="vertical" form={form} onFinish={onCreate}>
          <Form.Item
            label="Фамилия"
            name="lastName"
            rules={[
              { required: true, message: '' },
              {
                validator: fioValidator,
                message: '',
              },
            ]}
            className="form-item-required__hidden-error"
          >
            <Input placeholder="Введите фамилию" />
          </Form.Item>
          <Form.Item
            label="Имя"
            name="firstName"
            rules={[
              { required: true, message: '' },
              {
                validator: fioValidator,
                message: '',
              },
            ]}
            className="form-item-required__hidden-error"
          >
            <Input placeholder="Введите имя" />
          </Form.Item>
          <Form.Item dependencies={['withoutMiddleName']} className="form-item-dependencies">
            {({ getFieldValue }) => {
              const withoutMiddleName = getFieldValue('withoutMiddleName');
              return (
                <Form.Item
                  label="Отчество"
                  name="middleName"
                  rules={[
                    {
                      required: !withoutMiddleName,
                      message: '',
                    },
                    {
                      validator: !withoutMiddleName ? fioValidator : undefined,
                      message: '',
                    },
                  ]}
                  className="form-item-required__hidden-error mb0"
                >
                  <Input disabled={withoutMiddleName} placeholder={withoutMiddleName ? '' : 'Введите отчество'} />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item name="withoutMiddleName" valuePropName="checked" className="mb5">
            <Checkbox className="ml5" onChange={onChangeWithoutMiddleName}>
              Нет отчества
            </Checkbox>
          </Form.Item>
          <Row gutter={20}>
            <Col>
              <Form.Item
                name="birthDate"
                rules={[{ required: true, message: '' }]}
                label="Дата рождения"
                className="form-item-required__hidden-error"
              >
                <DatePicker
                  format={displayFormat}
                  disabledDate={(date) => date.isAfter(moment().add(1, 'day').subtract(18, 'years'))}
                  inputRender={(props) => {
                    return <InputMask {...props} mask={datePikerMaskDefault} maskChar={null} />;
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="phone"
                label="Tелефон"
                rules={[
                  {
                    required: true,
                    validator: phoneValidator,
                    message: '',
                  },
                ]}
                getValueFromEvent={({ maskedValue }) => getUnmaskedPhoneNumber(maskedValue)}
                className="form-item-required__hidden-error"
              >
                <MaskedInput
                  mask={[{ mask: phoneMask }]}
                  name="phone"
                  //TODO Eugene. Разобраться: без указания prefix=" " не срабатывает подсветка валидации
                  prefix=" "
                  placeholder={phoneInputPlaceholder}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Субъект"
                rules={[{ required: true, message: '' }]}
                name="regionId"
                className="form-item-required__hidden-error"
              >
                <Select placeholder="Выберите из списка">
                  {region.map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Логин"
            name="email"
            className="form-item-required__error"
            rules={[
              {
                required: true,
                message: '',
              },
              {
                message: 'Введите корректный Email',
                pattern: emailPatternMain,
              },
            ]}
          >
            <Input
              placeholder="Введите логин (электронная почта)"
              maxLength={64}
              onBlur={(e) => {
                const login = e.target.value;
                if (login && emailPatternMain.test(login)) {
                  checkLoginExistence(login).then(({ data }) => {
                    if (data.exists) {
                      form.setFields([{ name: 'login', errors: [' '] }]);
                      Modal.error({
                        title: 'Пользователь с данным Email уже зарегистрирован.',
                        content: 'Пожалуйста, введите другой Email',
                        width: '550px',
                      });
                    }
                  });
                }
              }}
            />
          </Form.Item>
          <Form.Item className="mb5">
            Надежный пароль должен содержать:
            <ul className="administration-personList-password-rules">
              <li>Не менее 8 символов</li>
              <li>Цифры</li>
              <li>Хотя бы одну букву</li>
            </ul>
          </Form.Item>
          <Form.Item
            label="Пароль"
            name="password"
            rules={[
              { required: true, message: '' },
              {
                pattern: passwordPattern,
                message: 'Пароль ненадежный, введите другой пароль',
              },
            ]}
            className="form-item-required__error"
          >
            <Input placeholder="Введите пароль" maxLength={64} />
          </Form.Item>
          <Form.Item
            label="Тип анкеты"
            name="personTypeId"
            rules={[{ required: true, message: '' }]}
            className="form-item-required__hidden-error"
          >
            <Select placeholder="Выберите из списка">
              {personType
                .filter(({ id }) => ![3, 4].includes(id))
                .map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Row justify="center" gutter={20}>
            <Col>
              <Button onClick={onReset} className="administration-users-searchBtn">
                Отмена
              </Button>
            </Col>
            <Col>
              <Button className="administration-users-searchBtn" type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};
