import { Button, Col, Modal, Row } from 'antd';
import React, { FC } from 'react';

interface IProps {
  closeModal: () => void;
  open: boolean;
  onClickDeleteRole: () => void;
}

export const DeleteRoleModal: FC<IProps> = ({ open, closeModal, onClickDeleteRole }) => {
  return (
    <Modal
      open={open}
      width="534px"
      onCancel={closeModal}
      footer={false}
      bodyStyle={{ backgroundColor: '#F4F4F4', fontFamily: 'Roboto, sans-serif' }}
    >
      <div className="mb20">Вы действительно хотите удалить выбранную роль?</div>
      <Row justify="center" gutter={20}>
        <Col>
          <Button onClick={closeModal}>Отмена</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={onClickDeleteRole} style={{ width: 216 }}>
            Удалить
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
