export const fioValidator = (v, text: string) => {
  const permitted = new RegExp(/^[ёЁa-zA-Zа-яА-Я-\/ ]+$/g);
  const mandatory = new RegExp(/[a-zA-Zа-яА-ЯёЁ]/g);
  if (text.length === 0) {
    return Promise.resolve();
  }
  if (permitted.test(text)) {
    if (mandatory.test(text)) {
      return Promise.resolve();
    }
    return Promise.reject();
  }
  return Promise.reject();
};
