import React, { useCallback } from 'react';
import { Checkbox, Col, DatePicker, Divider, Form, Input, Row } from 'antd';
import { replaceFalse } from './replaceFalse';
import { useAppSelector } from '../../store/hooks';
import useLabel from './useLabel';
import { PersonTypes } from './PersonTypes';
import { displayFormat, toDisplayFormat } from '../../lib/utils/formatDate';
import moment from 'moment';
import { datePikerMaskDefault } from '../../lib/const/masks';
import InputMask from 'react-input-mask';

interface Props {
  isEditMode: boolean;
  isNewUser?: boolean;
}

function ServiceInformationBlock(props: Props) {
  const { isEditMode, isNewUser } = props;
  const { personFullCard } = useAppSelector((state) => state.persons);
  const [makeLabel] = useLabel();
  const { awarenessSource } = useAppSelector((state) => state.dictionaries);

  const getSortedAwarenessSource = useCallback(() => {
    return [...awarenessSource]
      ?.sort((a, b) => {
        if (a?.id === 5) return 1;
        if (b?.id === 5) return -1;
        return 0;
      })
      .filter((el) => !el.deleted);
  }, [awarenessSource]);

  return (
    <div className="block">
      <h3>Служебная информация</h3>
      <Divider />
      <Row justify="space-between" gutter={30}>
        <Col span={6}>
          {!isNewUser && !isEditMode && (
            <Row>
              {makeLabel('Дата создания', 213)}
              {toDisplayFormat(moment(personFullCard?.createDate))}
            </Row>
          )}
          {(personFullCard?.personTypeId === PersonTypes.Подопечный || isNewUser) &&
            (isEditMode || isNewUser ? (
              <Form.Item valuePropName="checked" name="mmoWard" className="mt14">
                <Checkbox>Подопечный ММО</Checkbox>
              </Form.Item>
            ) : (
              <Row>
                {makeLabel('Подопечный ММО', 213)}
                {replaceFalse(personFullCard?.official?.mmoWard, true)}
              </Row>
            ))}
          {(personFullCard?.personTypeId === PersonTypes.Подопечный || isNewUser) &&
            (isEditMode || isNewUser ? (
              <Form.Item valuePropName="checked" name="constWard">
                <Checkbox>Постоянный подопечный</Checkbox>
              </Form.Item>
            ) : (
              <Row>
                {makeLabel('Постоянный подопечный', 213)}
                {replaceFalse(personFullCard?.official?.constWard, true)}
              </Row>
            ))}
        </Col>
        {personFullCard?.personTypeId === PersonTypes.Доброволец && (
          <Col span={6}>
            {isEditMode || isNewUser ? (
              <Form.Item name="volunteerReason" label="Почему вы решили стать добровольцем">
                <Input />
              </Form.Item>
            ) : (
              <Row>
                {makeLabel('Почему вы решили стать добровольцем', 317)}
                {replaceFalse(personFullCard?.official?.volunteerReason)}
              </Row>
            )}
            {isEditMode || isNewUser ? (
              <>
                <Row>
                  <Form.Item name="awarenessSource" label="Откуда вы узнали про добровольцев?">
                    <Checkbox.Group className="mb10" style={{ width: 200 }}>
                      {getSortedAwarenessSource()?.map((el) => (
                        <Row>
                          <Checkbox value={el?.id}>{el?.name}</Checkbox>
                        </Row>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item className="mt5" dependencies={['awarenessSource']}>
                    {({ getFieldValue }) =>
                      getFieldValue('awarenessSource')?.includes(5) ? (
                        <Form.Item name="otherSource" style={{ margin: '0' }}>
                          <Input placeholder="Ваш источник" allowClear />
                        </Form.Item>
                      ) : (
                        <Input placeholder="Ваш источник" disabled />
                      )
                    }
                  </Form.Item>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  {makeLabel('Откуда вы узнали про добровольцев?', 317)}
                  <div style={{ whiteSpace: 'break-spaces' }}>
                    {awarenessSource
                      .filter(
                        (item) =>
                          personFullCard?.official?.awarenessSource &&
                          personFullCard?.official?.awarenessSource.includes(item.id) &&
                          item.id !== 5
                      )
                      .map((item) => item.name)
                      .join(',')}
                  </div>
                </Row>
                <Row>
                  {makeLabel('Ваш источник', 317)}
                  {replaceFalse(personFullCard?.official?.otherSource)}
                </Row>
              </>
            )}
            {isEditMode || isNewUser ? (
              <Form.Item name="priestFio" label="ФИО священника?">
                <Input />
              </Form.Item>
            ) : (
              <Row>
                {makeLabel('ФИО священника', 317)}
                {replaceFalse(personFullCard?.official?.priestFio)}
              </Row>
            )}
            {isEditMode || isNewUser ? (
              <Form.Item name="priestComment" label="Комментарий священника">
                <Input.TextArea rows={4} />
              </Form.Item>
            ) : (
              <Row>
                {makeLabel('Комментарий священника', 317)}
                {replaceFalse(personFullCard?.official?.priestComment)}
              </Row>
            )}
          </Col>
        )}
        {personFullCard?.personTypeId === PersonTypes.Доброволец && (
          <Col span={6}>
            {isEditMode || isNewUser ? (
              <Form.Item name="interviewDate" label="Дата собеседования">
                <DatePicker
                  inputRender={(props) => {
                    return <InputMask {...props} mask={datePikerMaskDefault} maskChar={null} />;
                  }}
                />
              </Form.Item>
            ) : (
              <Row>
                {makeLabel('Дата собеседования', 230)}
                {replaceFalse(personFullCard?.official?.interviewDate)}
              </Row>
            )}
            {isEditMode || isNewUser ? (
              <Form.Item name="trainingDate" label="Дата прохождения тренинга">
                <DatePicker
                  format={displayFormat}
                  inputRender={(props) => {
                    return <InputMask {...props} mask={datePikerMaskDefault} maskChar={null} />;
                  }}
                />
              </Form.Item>
            ) : (
              <Row>
                {makeLabel('Дата прохождения тренинга', 230)}
                {replaceFalse(personFullCard?.official?.trainingDate)}
              </Row>
            )}
            {isEditMode || isNewUser ? (
              <Form.Item valuePropName="checked" name="passedTraining">
                <Checkbox>Прошёл тренинг</Checkbox>
              </Form.Item>
            ) : (
              <Row>
                {makeLabel('Прошёл тренинг', 230)}
                {replaceFalse(personFullCard?.official?.passedTraining, true)}
              </Row>
            )}
          </Col>
        )}
        {personFullCard?.personTypeId === PersonTypes.Доброволец && (
          <Col span={6}>
            {isEditMode || isNewUser ? (
              <Form.Item name="interviewerFio" label="ФИО интервьюера">
                <Input />
              </Form.Item>
            ) : (
              <Row>
                {makeLabel('ФИО интервьюера', 231)}
                {replaceFalse(personFullCard?.official?.interviewerFio)}
              </Row>
            )}
            {isEditMode || isNewUser ? (
              <Form.Item name="coordFio" label="ФИО координатора">
                <Input />
              </Form.Item>
            ) : (
              <Row>
                {makeLabel('ФИО координатора', 231)}
                {replaceFalse(personFullCard?.official?.coordFio)}
              </Row>
            )}
            {isEditMode || isNewUser ? (
              <Form.Item name="coordComment" label="Комментарий координатора">
                <Input.TextArea rows={4} />
              </Form.Item>
            ) : (
              <Row>
                {makeLabel('Комментарий координатора', 231)}
                {replaceFalse(personFullCard?.official?.coordComment)}
              </Row>
            )}
          </Col>
        )}
      </Row>
    </div>
  );
}

export default ServiceInformationBlock;
