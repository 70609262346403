import { useEffect, useRef, useState } from 'react';
import { DicIconKeys, DicIconName } from './collectionSvg';

interface UseDynamicSVGImportOptions {
  onCompleted?: (name: DicIconName, SvgIcon: React.FC<React.SVGProps<SVGSVGElement>> | undefined) => void;
  onError?: (err: Error) => void;
}

function useDynamicSVGImport(name: DicIconName, dicId: DicIconKeys, options: UseDynamicSVGImportOptions = {}) {
  const ImportedIconRef = useRef<React.FC<React.SVGProps<SVGSVGElement>>>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const { onCompleted, onError } = options;
  useEffect(() => {
    setLoading(true);
    const importIcon = async (): Promise<void> => {
      try {
        ImportedIconRef.current = (
          await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../img/icon/${dicId}/${name}.svg`)
        ).default;
        onCompleted?.(name, ImportedIconRef.current);
      } catch (err) {
        onError?.(err as Error);
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    importIcon();
  }, [name, dicId, onCompleted, onError]);

  return { error, loading, SvgIcon: ImportedIconRef.current };
}

export default useDynamicSVGImport;
