import React, { FC } from 'react';
import { Row, Col, Form, Select, Button } from 'antd';
import { useAppSelector } from '../../store/hooks';
import { useMainContext } from '../../containers/MainContainer/MainContext';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import selectFilterOption, { selectFilterOptionIcon } from '../../lib/utils/selectFilterOption';
import './MapSearchApplications.less';

interface IProps {}

export const MapSearchApplications: FC<IProps> = () => {
  const { personCategory, appSubject, region } = useAppSelector((state) => state.dictionaries);
  const { projects, projectsLoading } = useAppSelector((state) => state.projects);
  const { hasAccess, searchApplicationsState, findDynamicSVGReactElement } = useMainContext();

  const onChangeRegionId = () => {
    searchApplicationsState.handleSetSearchFormFields({
      projectIds: [],
    });
  };

  return (
    <Form
      fields={searchApplicationsState.searchFormFields}
      onValuesChange={searchApplicationsState.handleSetSearchFormFields}
      onFinish={searchApplicationsState.getApplicationsList}
      className="ml20 mr20 mb10 applications-map-search"
    >
      <Row gutter={20}>
        <Col span={3}>
          <Form.Item name="regionId">
            <Select
              placeholder="Субъект РФ"
              onSelect={onChangeRegionId}
              allowClear
              onClear={onChangeRegionId}
              disabled={!hasAccess(['APPLICATIONS.READ.ALLREGIONS'])}
            >
              {region.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item dependencies={['regionId', 'projectIds']} className="form-item-dependencies">
            {() => {
              const regionId = searchApplicationsState.getSearchFormFieldValue('regionId');
              const projectIds = searchApplicationsState.getSearchFormFieldValue('projectIds');
              // Вывод отображения всех выбранных проектов
              const getProjectTitle = () => {
                if (!regionId) return 'Необходимо выбрать субъект РФ';
                if (!Array.isArray(projects)) return 'Нет доступных проектов';
                return projectIds.map((projectId) => projects.find((item) => item.id === projectId)?.title).join('\n');
              };

              return (
                <div title={getProjectTitle()}>
                  <Form.Item name="projectIds">
                    <Select
                      placeholder={projects.length === 0 ? 'Нет доступных проектов' : 'Проект'}
                      allowClear
                      mode="multiple"
                      maxTagCount={2}
                      maxTagTextLength={15}
                      disabled={!regionId || projects.length === 0}
                      loading={checkIsLoading(projectsLoading)}
                      showSearch
                      filterOption={selectFilterOption}
                      showArrow={true}
                    >
                      {Array.isArray(projects) &&
                        projects
                          .filter((project) => project.regionId === regionId)
                          .map(({ id, title }) => (
                            <Select.Option key={id} value={id}>
                              {title}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </div>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="subjectId">
            <Select placeholder="Вид помощи" allowClear showSearch filterOption={selectFilterOptionIcon}>
              {Array.isArray(appSubject) &&
                [...appSubject]
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      <div
                        className="display-flex"
                        style={{ justifyContent: 'center', alignItems: 'center' }}
                        title={name}
                      >
                        <div
                          className="display-flex icon-application-select"
                          style={{ alignItems: 'center', minWidth: 20, maxWidth: 20 }}
                        >
                          {findDynamicSVGReactElement('app_subject', 'help', 'iconName', id)}
                        </div>
                        <span className="ml5 ant-select-item-option-content">{name}</span>
                      </div>
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="personCategoryIds">
            <Select
              placeholder="Категория"
              allowClear
              mode="multiple"
              maxTagCount={2}
              maxTagTextLength={15}
              showSearch
              filterOption={selectFilterOption}
              showArrow={true}
            >
              {personCategory.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  <span>{name}</span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col className="ml-auto">
          <Button onClick={searchApplicationsState.resetSearchFormFields}>Сбросить</Button>
        </Col>
        <Col>
          <Button type="primary" htmlType="submit">
            Применить
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
