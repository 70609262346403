import React, { FC } from 'react';
import { Checkbox, Select } from 'antd';
import { AnyObject } from '../../lib/interfaces/AnyObject';

/* Повторяет функционал Select с добавлением возможности "Выбрать все" */

interface IProps {
  allValues: (string | number)[];
  style?: AnyObject;
  onChange?: (value: (string | number)[]) => void;
  placeholder?: string;
  allowClear?: boolean;
  showSearch?: boolean;
  disabled?: boolean;
  showArrow?: boolean;
  maxTagCount?: number;
  maxTagTextLength?: number;
  filterOption?: boolean | ((inputValue: string, option: any) => boolean);
  value?: (string | number)[];
  title?: string;
  children?: any;
  loading?: boolean;
  tagRender?: (props: any) => any;
  onSelect?: () => void;
  onClear?: () => void;
}

const MultipleSelect: FC<IProps> = ({
  allValues,
  children,
  style,
  onChange,
  placeholder,
  allowClear,
  showSearch,
  maxTagCount,
  maxTagTextLength,
  filterOption,
  disabled,
  showArrow,
  value = [],
  title,
  loading,
  tagRender,
  onSelect,
  onClear,
}) => {
  const onSelectAll = (selected: boolean) => {
    if (onChange) {
      onChange(selected ? allValues : []);
    }
  };
  return (
    <div title={title}>
      <Select
        mode="multiple"
        style={style ? { width: '100%', ...style } : { width: '100%' }}
        dropdownRender={(originNode) => (
          <>
            <Checkbox
              checked={value.length === allValues.length}
              style={{ width: '100%', marginLeft: '10px' }}
              onChange={(e) => onSelectAll(e.target.checked)}
            >
              Выбрать все
            </Checkbox>
            {originNode}
          </>
        )}
        onChange={onChange}
        placeholder={placeholder}
        allowClear={allowClear}
        showSearch={showSearch}
        maxTagCount={maxTagCount}
        maxTagTextLength={maxTagTextLength}
        filterOption={filterOption}
        disabled={disabled}
        showArrow={showArrow}
        value={value}
        loading={loading}
        tagRender={tagRender}
        onSelect={onSelect}
        onClear={onClear}
      >
        {children}
      </Select>
    </div>
  );
};

export default MultipleSelect;
