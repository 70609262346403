import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getAllNotifications } from '../../store/slices/notificationsSlice/notificationsSlice';
import Notifications from '../../components/Notifications/Notifications';

function NotificationsContainer(props) {
  const dispatch = useAppDispatch();
  const { meUserFull } = useAppSelector((state) => state.persons);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (meUserFull?.userId) {
      dispatch(getAllNotifications({ page: page - 1, size: 20, userId: meUserFull.userId }));
    }
  }, [meUserFull, page, dispatch]);

  return (
    <div className="container">
      <Notifications page={page} setPage={setPage} />
    </div>
  );
}

export default NotificationsContainer;
