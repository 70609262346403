import { SearchParams } from '../interfaces/SearchParams';
import { SearchPersonParams } from '../interfaces/SearchPersonParams';
import buildUrl from '../utils/buildUrl';
import { baseUrl } from './baseUrl';

export const createUserRoute = () =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/registration/volunteer`,
  });

export const createUserBaseRoute = () =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/registration`,
  });

export const editPersonRoute = (personId) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/${personId}`,
  });

export const archivePersonRoute = (personId) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/${personId}/archive`,
  });

export const createWardRoute = () =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/ward`,
  });

export const searchPersonRoute = (searchParams: SearchPersonParams): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/search`,
    searchParams,
  });

export const searchPersonPOSTRoute = (searchParams: SearchParams = {}): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/search`,
    searchParams,
  });

export const pageableSearchPersonRoute = (searchParams: SearchParams = {}): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/search`,
    searchParams,
  });

export const fullPersonDataRoute = (personId: number): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/${personId}/full`,
  });

export const participationRoute = (personId: number): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/${personId}/participation`,
  });

export const fetchMeUserRoute = () =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/me`,
  });

export const getCardCommentsRoute = (personId) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/${personId}/comments`,
  });

export const getCardHistoryRoute = (personId) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/${personId}/history`,
  });

export const postCardCommentRoute = (personId) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/comments/PERSON/${personId}`,
  });

export const getPersonPhotoRoute = (personId) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/${personId}/photo`,
  });

export const getPersonProfileShortInfoRoute = () =>
  buildUrl({
    url: `${baseUrl}/applications/v1/profile`,
  });

export const getPersonFilesRoute = (personId) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/${personId}/files`,
  });

export const deletePersonFileRoute = ({ personId, fileName }: { personId: string; fileName: string }): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/${personId}/file/${fileName}`,
  });

export const uploadPersonFileRoute = ({ personId }: { personId: string }): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/${personId}/files`,
  });

export const downloadPersonFileRoute = ({ personId, fileName }: { personId: string; fileName: string }): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/${personId}/files/${fileName}`,
  });

export const getPersonAvatarRoute = (personId) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/${personId}/avatar`,
  });

export const updatePersonProjectsVolunteer = (personId) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/${personId}/projectsVolunteer`,
  });
