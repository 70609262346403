import { Button, Col, Modal, Row } from 'antd';
import React, { FC } from 'react';

interface IProps {
  closeModal: () => void;
  open: boolean;
  listPersonForRoleError: string[];
}

export const ErrorDeleteRoleModal: FC<IProps> = ({ open, listPersonForRoleError, closeModal }) => {
  return (
    <Modal
      open={open}
      width="534px"
      onCancel={closeModal}
      footer={false}
      bodyStyle={{ backgroundColor: '#F4F4F4', fontFamily: 'Roboto, sans-serif' }}
    >
      <div className="mb10">
        Удаление роли невозможно, так как она принадлежит
        <br /> пользователям со следующими логинами:
      </div>
      <div style={{ maxHeight: '200px', overflow: 'auto' }}>
        {listPersonForRoleError.map((login) => (
          <div className="mb5">{login}</div>
        ))}
      </div>
      <div className="mb20 mt10">Для удаления роли снимите ее с указанных пользователей.</div>
      <Row justify="center" gutter={20}>
        <Col>
          <Button type="primary" onClick={closeModal}>
            OK
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
