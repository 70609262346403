import React, { useEffect } from 'react';
import FileHistory from '../../components/FileHistory/FileHistory';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getCardHistory } from '../../store/slices/personsSlice/personsSlice';
import { Spin } from 'antd';
import { checkIsLoading, checkIsLoaded } from '../../lib/utils/checkLoadingStatus';
import { getActionTypesDictionary } from '../../store/slices/dictionariesSlice/dictionariesSlice';

function FileHistoryContainer() {
  const { fileId } = useParams();
  const dispatch = useAppDispatch();
  const { fileHistoryLoading } = useAppSelector((state) => state.persons);
  const { actionTypesLoading } = useAppSelector((state) => state.dictionaries);

  useEffect(() => {
    if (fileId) {
      dispatch(getCardHistory({ personId: fileId }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!checkIsLoaded(actionTypesLoading)) dispatch(getActionTypesDictionary());
  }, [dispatch]);

  return (
    <Spin spinning={checkIsLoading(fileHistoryLoading, actionTypesLoading)} className="container_height">
      {checkIsLoaded(fileHistoryLoading, actionTypesLoading) && <FileHistory />}
    </Spin>
  );
}

export default FileHistoryContainer;
