import React from 'react';
import { ReactComponent as LockDicIcon } from '../../../lib/img/lock.svg';
import './AdministrationDictionariesList.less';

type ListData = {
  id: number | string;
  value: string;
  system_dic: boolean;
};

interface Props {
  data: ListData[];
  onRowDicTypes: (item: ListData) => void;
}

function AdministrationDictionariesList(props: Props) {
  const { data, onRowDicTypes } = props;

  const isEvenClassname = (num: number): string => {
    if (num % 2 === 0) return 'row even-row';
    return 'row';
  };

  return (
    <div className="dictionaries-list">
      <div className="list-rows">
        {data.map((item, index) => {
          return (
            <div
              onClick={() => onRowDicTypes(item)}
              className={isEvenClassname(index)}
              key={item.id}
              style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', maxHeight: 28 }}
            >
              <div>{item.value}</div>
              {item.system_dic && (
                <div style={{ marginTop: '-2px' }}>
                  <LockDicIcon />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AdministrationDictionariesList;
