import buildUrl from '../utils/buildUrl';
import { baseUrl } from './baseUrl';
import { DicTypeId } from '../interfaces/DicTypeId';

export const getDictionaryByType = (type: DicTypeId) =>
  buildUrl({
    url: `${baseUrl}/gateway/v1/dictionaries/${type}`,
    searchParams: {
      includeDeleted: true,
    },
  });

export const getDictionariesByType = (types: DicTypeId[]) =>
  buildUrl({
    url: `${baseUrl}/gateway/v1/dictionaries/`,
    searchParams: {
      type: types,
      includeDeleted: true,
    },
  });

export const getFullDictionariesByType = (types: DicTypeId[]) =>
  buildUrl({
    url: `${baseUrl}/dictionaries/dictionaries/`,
    searchParams: {
      type: types,
    },
  });
