interface RequestStatusCodes {
  pending: 'pending';
  fulfilled: 'fulfilled';
  rejected: 'rejected';
  idle: 'idle';
}

const requestStatusCodes: RequestStatusCodes = {
  pending: 'pending',
  fulfilled: 'fulfilled',
  rejected: 'rejected',
  idle: 'idle',
};

export default requestStatusCodes;
