export const appStatusAliases = {
  neew: 1, // Новая (так поименована, т.к. new - зарезервированное слово js)
  accepted: 2, // Принята в обработку
  available: 3, // Доступна для исполнения
  done: 4, // Исполнена
  pending: 5, // На исполнении
  closed: 6, // Закрыта
};

export enum AppStatusAliasesEnum {
  'Новая' = 1, // Новая (так поименована, т.к. new - зарезервированное слово js)
  'Принята в обработку', // Принята в обработку
  'Доступна для исполнения', // Доступна для исполнения
  'Исполнена', // Исполнена
  'На исполнении', // На исполнении
  'Закрыта', // Закрыта
}
