import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, message, Divider, Space } from 'antd';
import AuthService from '../../services/AuthService';
import LocalStorageService from '../../services/LocalStorageService';
import './LoginPage.less';
import { useAppSelector } from '../../store/hooks';
import { ReactComponent as AppStore } from '../../lib/img/stores/appstore.svg';
import { ReactComponent as GoogleStore } from '../../lib/img/stores/google.svg';
import { ReactComponent as CompanyLogo } from '../../lib/img/logo-iflex.svg';

const LoginPage = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const { properties } = useAppSelector((state) => state.root);

  const handleSubmit = ({ login, password }) => {
    setErrorMessage('');
    const lowerLogin = login.toLowerCase();
    AuthService.login(lowerLogin, password)
      .then((response) => {
        LocalStorageService.setToken(response.data);
        LocalStorageService.setObject('isAuth', true);
        window.location.reload();
      })
      .catch((e) => {
        if (e.response.status === 400) {
          message.error('Неверные имя пользователя и/или пароль', 3);
        }
      });
  };

  const handleFormError = (v, fields): void => {
    const hasErrors = fields.some(({ errors }) => errors.length > 0);
    if (hasErrors) {
      setErrorMessage('Все поля обязательны для заполнения');
    } else {
      setErrorMessage('');
    }
  };

  return (
    <div className="login-page-container">
      <div className="login-page-innerContainer">
        <div className="logo mb15" />
        <h1>Войти в систему</h1>
        <Divider />
        <Form
          name="basic"
          initialValues={{ remember: true }}
          layout="vertical"
          onFinish={handleSubmit}
          onFieldsChange={handleFormError}
        >
          <Form.Item label="Электронная почта" name="login" rules={[{ required: true, message: '' }]}>
            <Input maxLength={64} placeholder="Введите E-mail" />
          </Form.Item>
          <Form.Item label="Пароль" name="password" rules={[{ required: true, message: '' }]}>
            <Input.Password maxLength={32} placeholder="Введите пароль" />
          </Form.Item>
          <div className="login-page-infoBox">
            <span className="error">{errorMessage}</span>
            <Link to="/password-forgotten" className="text-bold">
              Забыли пароль?
            </Link>
          </div>
          <Form.Item className="mt20 mb10">
            <Button type="primary" htmlType="submit">
              Войти
            </Button>
          </Form.Item>
        </Form>
        <span>Нет аккаунта?&nbsp;&nbsp;</span>
        <Link className="text-bold text-underline" to="/create-user">
          Зарегистрироваться
        </Link>
        <div className="text-center mt15">
          <a className="text-bold" href={`${properties.privacy_policy}`} target="_blank" rel="noopener noreferrer">
            Политика конфиденциальности
          </a>
        </div>
        <Space className="mt30">
          <a href={`${properties.app_store_market}`} target="_blank" rel="noopener noreferrer">
            <AppStore />
          </a>
          <a href={`${properties.google_play_market}`} target="_blank" rel="noopener noreferrer">
            <GoogleStore height={49} />
          </a>
        </Space>
        <div className="company-row mt30">
          <div className="company-title">Разработчик</div>
          <a
            className="company-logo ml6"
            href={`${properties.iflex_website_adress}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CompanyLogo />
            &nbsp;айФлекс
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
