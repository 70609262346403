import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoginPage from '../components/LoginPage/LoginPage';
import CreateUserContainer from '../containers/CreateUserContainer/CreateUserContainer';
import PasswordForgotten from '../components/PasswordForgotten/PasswordForgotten';
import PasswordRecovery from '../components/PasswordRecovery/PasswordRecovery';
import { ApplicationsList } from '../components/ApplicationsList/ApplicationsList';
import { ApplicationContainer } from '../containers/ApplicationContainer/ApplicationContainer';
import { CreateApplicationContainer } from '../containers/CreateApplicationContainer/CreateApplicationContainer';
import FilesContainer from '../containers/FilesContainer/FilesContainer';
import NewWardContainer from '../containers/NewWardContainer/NewWardContainer';
import MapContainer from '../containers/MapContainer/MapContainer';
import FileCardContainer from '../containers/FileCardContainer/FileCardContainer';
import FileMainContainer from '../containers/FileMainContainer/FileMainContainer';
import FileCommentsContainer from '../containers/FileCommentsContainer/FileCommentsContainer';
import FileProjectsContainer from '../containers/FileProjectsContainer/FileProjectsContainer';
import FileHistoryContainer from '../containers/FileHistoryContainer/FileHistoryContainer';
import { useAppSelector } from '../store/hooks';
import UserProfileContainer from '../containers/UserProfileContainer/UserProfileContainer';
import { InfoContainer } from '../containers/InfoContainer/InfoContainer';
import NotificationsContainer from '../containers/NotificationsContainer/NotificationsContainer';
import usePermissions from '../lib/utils/usePermissions';
import { HasNotPermissions } from '../components/HasNotPermission/HasNotPermissions';
import { PERSONLIST_READ, PERSONLIST_READ_ALLREGIONS, PERSON_CREATE } from '../lib/const/permissions';
import AdministrationContainer from '../containers/AdministrationContainer/AdministrationContainer';

interface Props {
  isAuth: boolean;
}

function AppRoutes(props: Props) {
  const { meUserFull } = useAppSelector((state) => state.persons);
  const isUserNewVolunteer = meUserFull?.personStatusId === 1 && meUserFull?.personTypeId === 3;
  const isSchoolOfVolunteer = meUserFull?.personStatusId === 2;

  const [hasAccess] = usePermissions();

  if (props.isAuth) {
    if (isUserNewVolunteer || isSchoolOfVolunteer) {
      return (
        <Routes>
          <Route path="*" element={<Navigate to="applications/map" />} />
          <Route path="applications">
            <Route path="" element={<Navigate to="list" />} />
            <Route path="list">
              <Route path="" element={<ApplicationsList />} />
              <Route path=":applicationId/*" element={<ApplicationContainer />} />
            </Route>
            <Route path="map" element={<MapContainer />} />
            <Route path="create-application" element={<CreateApplicationContainer />} />
            <Route path="create-application/:applicationId" element={<CreateApplicationContainer />} />
          </Route>
          <Route path="files">
            <Route path="" element={<Navigate to="list" />} />
            <Route
              path="list"
              element={
                hasAccess([PERSONLIST_READ, PERSONLIST_READ_ALLREGIONS]) ? <FilesContainer /> : <HasNotPermissions />
              }
            />
            <Route path=":fileId" element={<FileMainContainer />}>
              <Route path="general" element={<FileCardContainer />} />
              <Route path="comments" element={<FileCommentsContainer />} />
              <Route path="projects" element={<FileProjectsContainer />} />
              <Route path="history" element={<FileHistoryContainer />} />
            </Route>
            <Route
              path="new-ward"
              element={hasAccess([PERSON_CREATE]) ? <NewWardContainer /> : <HasNotPermissions />}
            />
          </Route>
          <Route path="profile" element={<UserProfileContainer />} />
          <Route path="notifications" element={<NotificationsContainer />} />
          <Route path="info/*" element={<InfoContainer />} />
        </Routes>
      );
    }
    return (
      <Routes>
        <Route path="*" element={<Navigate to="applications/map" />} />
        <Route path="applications">
          <Route path="" element={<Navigate to="list" />} />
          <Route path="list">
            <Route path="" element={<ApplicationsList />} />
            <Route path=":applicationId/*" element={<ApplicationContainer />} />
          </Route>
          <Route path="map" element={<MapContainer />} />
          <Route path="create-application" element={<CreateApplicationContainer />} />
          <Route path="create-application/:applicationId" element={<CreateApplicationContainer />} />
        </Route>
        <Route path="files">
          <Route path="" element={<Navigate to="list" />} />
          <Route path="list" element={<FilesContainer />} />
          <Route path=":fileId" element={<FileMainContainer />}>
            <Route path="general" element={<FileCardContainer />} />
            <Route path="comments" element={<FileCommentsContainer />} />
            <Route path="projects" element={<FileProjectsContainer />} />
            <Route path="history" element={<FileHistoryContainer />} />
          </Route>
          <Route path="new-ward" element={hasAccess([PERSON_CREATE]) ? <NewWardContainer /> : <HasNotPermissions />} />
        </Route>
        <Route path="administration/*" element={<AdministrationContainer />} />
        <Route path="profile" element={<UserProfileContainer />} />
        <Route path="notifications" element={<NotificationsContainer />} />
        <Route path="info/*" element={<InfoContainer />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="*" element={<Navigate to="login" />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="create-user" element={<CreateUserContainer />} />
      <Route path="password-forgotten" element={<PasswordForgotten />} />
      <Route path="change_password" element={<PasswordRecovery />} />
    </Routes>
  );
}

export default AppRoutes;
