import React, { FC, useEffect, useState } from 'react';
import { Row, Col, Table, Button, Divider, Input, Form, Select, TablePaginationConfig } from 'antd';
import { useMainContext } from '../../../containers/MainContainer/MainContext';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getDictionaryItemById, getFio } from '../../../lib/utils/applications';
import { setAdministrationPersonListSearchParams } from '../../../store/slices/administrationSlice/administrationSlice';
import '../Administration.less';
import { CreatePerson } from './CreatePerson';
import { useNavigate } from 'react-router-dom';
import { useAdministrationContext } from '../../../containers/AdministrationContainer/AdministrationContext';
import { IPersonListSearchParams } from '../../../lib/interfaces/Administration';
import { checkIsLoading } from '../../../lib/utils/checkLoadingStatus';

interface IProps {}

export const AdministrationPersonList: FC<IProps> = () => {
  const navigate = useNavigate();
  const { hasAccess } = useMainContext();
  const { resetAdministrationPersonListSearch, saveCurrentLocation } = useAdministrationContext();
  const dispatch = useAppDispatch();
  const { personList, personListSearchParams, personListLoading } = useAppSelector((state) => state.administration);

  const [visibleAddUser, setVisibleAddUser] = useState(false);

  const [searchForm] = Form.useForm();

  const { personType } = useAppSelector((state) => state.dictionaries);

  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    setTableData(personList.content.map((user) => ({ ...user, key: user.id })));
  }, [personList]);

  // console.log(personListSearchParams);

  const columns = [
    {
      title: 'ФИО',
      width: '33%',
      render: (v, record) => getFio(record),
    },
    {
      title: 'Логин',
      dataIndex: 'login',
      width: '23%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '25%',
    },
    {
      title: 'Тип анкеты',
      dataIndex: 'personTypeId',
      width: '19%',
      render: (personTypeId) => getDictionaryItemById(personType, personTypeId)?.name,
    },
  ];

  const pagination: TablePaginationConfig = {
    current: personListSearchParams.page,
    defaultPageSize: personListSearchParams.size,
    total: personList.totalElements,
    onChange: (page) => {
      dispatch(
        setAdministrationPersonListSearchParams({
          page,
          force: !personListSearchParams.force,
        })
      );
    },
    showSizeChanger: false,
    position: ['bottomCenter'],
  };

  const renderFilter = () => {
    const onSearch = (values: IPersonListSearchParams) => {
      dispatch(
        setAdministrationPersonListSearchParams({
          ...values,
          fio: values.fio.trim(),
          login: values.login.trim(),
          email: values.email.trim(),
          page: 1,
          force: !personListSearchParams.force,
        })
      );
    };
    const onReset = () => {
      searchForm.setFieldsValue({
        fio: '',
        email: '',
        login: '',
        personTypeIds: [],
      });
      resetAdministrationPersonListSearch();
    };
    return (
      <>
        <h4 className="text-bold font-size-18">Поиск</h4>
        <Divider className="thin-divider mt0 mb10" />
        <Form layout="vertical" onFinish={onSearch} form={searchForm} initialValues={personListSearchParams}>
          <Form.Item label="ФИО" name="fio">
            <Input placeholder="Введите ФИО" />
          </Form.Item>
          <Form.Item label="Логин" name="login">
            <Input placeholder="Введите логин" />
          </Form.Item>
          <Form.Item label="Email" name="email">
            <Input placeholder="Введите электронный адрес" />
          </Form.Item>
          <Form.Item label="Тип анкеты" name="personTypeIds">
            <Select placeholder="Тип анкеты" mode="multiple" showArrow maxTagCount={1}>
              {personType
                .filter(({ id }) => id !== 4)
                .map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Row justify="center" gutter={20}>
            <Col>
              <Button onClick={onReset} className="administration-personList-searchBtn">
                Сбросить
              </Button>
            </Col>
            <Col>
              <Button className="administration-personList-searchBtn" type="primary" htmlType="submit">
                Применить
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  return (
    <div className="administration-personList">
      {hasAccess(['ADMINISTRATION.ADDUSER']) && (
        <Row justify="end">
          <Button className="administration-personList-addBtn" onClick={() => setVisibleAddUser(true)}>
            Добавить пользователя
          </Button>
        </Row>
      )}
      <Row gutter={15}>
        <Col span={5}>{renderFilter()}</Col>
        <Col span={19}>
          <Table
            size="small"
            columns={columns}
            loading={checkIsLoading(personListLoading)}
            dataSource={tableData}
            className="striped-table"
            rowClassName={(r, i) => (i % 2 ? 'cursor-pointer' : 'cursor-pointer striped-table__oddRow')}
            pagination={pagination}
            onRow={({ id }) => ({
              onClick: () => {
                navigate(`/administration/persons/${id}/profile`);
                saveCurrentLocation(`persons/${id}/profile`);
              },
            })}
          />
        </Col>
      </Row>
      <CreatePerson visible={visibleAddUser} closeModal={() => setVisibleAddUser(false)} />
    </div>
  );
};
