import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Radio, Row, Col, Button, Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import {
  HandleSetPersonToForm,
  HandleSetCreateApplicationState,
  ICreateApplicationState,
} from './interfacesCreateApplication';
import { useCreateApplicationContainerContext } from '../../containers/CreateApplicationContainer/CreateApplicationContainerContext';
import { getFio } from '../../lib/utils/applications';
import { Person } from '../../lib/interfaces/Person';
import { ReactComponent as InPrayListIcon } from '../../lib/img/in-pray-list-icon.svg';

interface IProps {
  handleSetCreateApplicationState: HandleSetCreateApplicationState;
  createApplicationState: ICreateApplicationState;
  handleSetPersonToForm: HandleSetPersonToForm;
  handleProceed: () => void;
}

export const SearchResultPersonModal: FC<IProps> = ({
  handleSetCreateApplicationState,
  createApplicationState,
  handleSetPersonToForm,
  handleProceed,
}) => {
  const { loadFullPersonData } = useCreateApplicationContainerContext();

  const [selectedPersonId, setSelectedPersonId] = useState<number>(0);
  const [tableData, setTableData] = useState<Person[]>([]);

  useEffect(() => {
    const data =
      createApplicationState.searchedPersons?.map(({ id, address, firstName, middleName, lastName, inPrayList }) => ({
        id,
        key: id,
        fio: getFio({ firstName, middleName, lastName }),
        address,
        inPrayList,
      })) || [];
    setTableData(data);
  }, [createApplicationState.searchedPersons]);

  const closeModal = () => {
    handleSetCreateApplicationState({ activeModal: '' });
    setSelectedPersonId(0);
  };

  const handleLink = () => {
    if (Array.isArray(createApplicationState.searchedPersons)) {
      const selectedPerson = createApplicationState.searchedPersons.find(
        (searchedPerson) => searchedPerson.id === selectedPersonId
      );
      if (selectedPerson) {
        if (selectedPerson.inPrayList) {
          handleSetCreateApplicationState({
            activeModal: 'inPrayList',
          });
        } else if (selectedPerson.id) {
          handleSetCreateApplicationState({ isLoadingData: true });
          loadFullPersonData(selectedPerson.id).then((fullPersonData) => {
            handleSetPersonToForm(fullPersonData);
            handleSetCreateApplicationState({
              isLoadingData: false,
            });
          });
        }
      }
    }
  };

  const columns: ColumnsType<any> = [
    {
      key: 'spaceStart',
      width: '20px',
    },
    {
      key: 'radio',
      dataIndex: 'id',
      render: (id) => <Radio checked={id === selectedPersonId} />,
      width: '30px',
    },
    {
      key: 'fio',
      dataIndex: 'fio',
      title: 'ФИО',
      render: (fio, rec) => (
        <Link to={`/files/${rec?.id}/general`} onClick={(e) => e.stopPropagation()} target="_blank">
          {fio}
        </Link>
      ),
      width: '220px',
    },
    {
      key: 'address',
      dataIndex: 'address',
      title: 'Адрес',
      render: (address) => (address ? address.unstructuredAddress : ''),
    },
    {
      key: 'inPrayList',
      dataIndex: 'inPrayList',
      title: 'В списке для молитв',
      render: (inPrayList) => (inPrayList ? <InPrayListIcon className="inPrayListIcon" /> : <></>),
      width: '140px',
    },
    {
      key: 'spaceEnd',
      width: '20px',
    },
  ];

  return (
    <Modal
      visible={createApplicationState.activeModal === 'searchResult'}
      className="create-application__searchPerson"
      width="690px"
      footer={null}
      onCancel={closeModal}
      centered
      bodyStyle={{ backgroundColor: '#D0D8DA', padding: 0 }}
    >
      <div className="font-roboto pt20 mr20 ml20 mb10">
        При заполнении просьбы были найдены совпадения с Подопечными. Для связи просьбы с одним из них выберите
        подопечного из списка и нажмите «Связать».
      </div>
      <Table
        className="striped-table"
        rowClassName={(r, i) => (i % 2 ? 'cursor-pointer' : 'cursor-pointer striped-table__oddRow')}
        columns={columns}
        dataSource={tableData}
        size="small"
        onRow={({ id }) => ({
          onClick: () => {
            // Повторный клик по строке с выбранным подопечным отменяет выбор
            setSelectedPersonId(selectedPersonId === id ? 0 : id);
          },
        })}
        pagination={false}
      />
      <Row gutter={20} className="mt20 pb20">
        <Col className="ml-auto">
          <Button
            type="primary"
            onClick={handleProceed}
            disabled={Boolean(selectedPersonId)}
            style={{ width: '216px' }}
          >
            Создать новую анкету
          </Button>
        </Col>
        <Col className="mr-auto">
          <Button type="primary" disabled={!Boolean(selectedPersonId)} onClick={handleLink}>
            Связать
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
