import React from 'react';
import { Col, Divider, Form, Input, Row, Select } from 'antd';
import { replaceFalse } from './replaceFalse';
import { useAppSelector } from '../../store/hooks';
import useLabel from './useLabel';
import { PhonesTable } from '../PhonesTable/PhonesTable';
import SuggestInput from '../SuggestInput/SuggestInput';
import usePermissions from '../../lib/utils/usePermissions';
import { PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS } from '../../lib/const/permissions';
import { PhonesTableDisplay } from '../PhonesTableDisplay/PhonesTableDisplay';
import { ReactComponent as ArrowDownFigIcon } from '../../lib/img/arrow-down-fig.svg';

const labelWidth = 127;

interface Props {
  isEditMode: boolean;
  isNewUser?: boolean;
}

function PersonContactsBlock(props: Props) {
  const { isEditMode, isNewUser } = props;
  const { personFullCard } = useAppSelector((state) => state.persons);
  const { region, subwayStation } = useAppSelector((state) => state.dictionaries);
  const [makeLabel] = useLabel();
  const [hasAccess] = usePermissions();

  return (
    <div className="block">
      <h3>Контактные и адресные данные</h3>
      <Divider />
      <Row>
        <Col>
          {isEditMode || isNewUser ? (
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: 'email',
                  message: 'Неверный формат',
                },
              ]}
            >
              <Input />
            </Form.Item>
          ) : (
            <>
              {makeLabel('Email', labelWidth)}
              {`${replaceFalse(personFullCard?.email)}`}
            </>
          )}
        </Col>
      </Row>
      <Row>
        {isEditMode || isNewUser ? (
          <Form.Item label="Номера телефонов" required={true}>
            <PhonesTable required={true} />
          </Form.Item>
        ) : (
          <>
            {makeLabel('Телефон', labelWidth)}
            <PhonesTableDisplay phones={personFullCard?.phones} />
          </>
        )}
      </Row>
      {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
        <Row>
          <Col>
            {isEditMode || isNewUser ? (
              <Form.Item name="subwayStationId" label="Метро" className="card-select">
                <Select
                  suffixIcon={<ArrowDownFigIcon />}
                  showSearch={true}
                  filterOption={(input, option) =>
                    (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                  }
                >
                  {subwayStation
                    .filter((el) => !el.deleted)
                    .map((value) => (
                      <Select.Option key={value.id} value={value.id}>
                        {value.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            ) : (
              <>
                {makeLabel('Метро', labelWidth)}
                {`${replaceFalse(
                  subwayStation.find((sub) => sub.id === personFullCard?.address?.subwayStationId)?.name
                )}`}
              </>
            )}
          </Col>
        </Row>
      )}
      {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
        <Row>
          {isEditMode || isNewUser ? (
            <Form.Item name="intercomCode" label="Код домофона" rules={[{ max: 15, message: '' }]}>
              <Input />
            </Form.Item>
          ) : (
            <>
              {makeLabel('Код домофона', labelWidth)}
              {`${replaceFalse(personFullCard?.address?.intercomCode)}`}
            </>
          )}
        </Row>
      )}
      {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
        <Row>
          {isEditMode || isNewUser ? (
            <Form.Item name="regionId" label="Субъект РФ" className="card-select" rules={[{ required: true }]}>
              <Select suffixIcon={<ArrowDownFigIcon />}>
                {region
                  .filter((el) => !el.deleted)
                  .map((value) => (
                    <Select.Option key={value.id} value={value.id}>
                      {value.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          ) : (
            <>
              {makeLabel('Субъект РФ', labelWidth)}
              {`${region.find((region) => region.id === personFullCard?.regionId)?.name}`}
            </>
          )}
        </Row>
      )}
      {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
        <Row>
          {isEditMode || isNewUser ? (
            <Form.Item name="entrance" label="Подъезд">
              <Input />
            </Form.Item>
          ) : (
            <>
              {makeLabel('Подъезд', labelWidth)}
              {`${replaceFalse(personFullCard?.address?.entrance)}`}
            </>
          )}
        </Row>
      )}
      {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
        <Row>
          {isEditMode || isNewUser ? (
            <SuggestInput name="unstructuredAddress" required={true} />
          ) : (
            <>
              {makeLabel('Адрес', labelWidth)}
              <div className="row-value">{`${replaceFalse(personFullCard?.address?.unstructuredAddress)}`}</div>
            </>
          )}
        </Row>
      )}
      {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
        <Row>
          {isEditMode || isNewUser ? (
            <Form.Item name="apartment" label="Квартира" rules={[{ required: true, message: '' }]}>
              <Input />
            </Form.Item>
          ) : (
            <>
              {makeLabel('Квартира', labelWidth)}
              {`${replaceFalse(personFullCard?.address?.apartment)}`}
            </>
          )}
        </Row>
      )}
      {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
        <Row>
          {isEditMode || isNewUser ? (
            <Form.Item name="floor" label="Этаж">
              <Input />
            </Form.Item>
          ) : (
            <>
              {makeLabel('Этаж', labelWidth)}
              {`${replaceFalse(personFullCard?.address?.floor)}`}
            </>
          )}
        </Row>
      )}
    </div>
  );
}

export default PersonContactsBlock;
