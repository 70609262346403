import React, { FC, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Col, Divider, Modal, Row, Spin, Form, Input, message, Select } from 'antd';
import moment from 'moment';
import { useApplicationContainerContext } from '../../containers/ApplicationContainer/ApplicationContainerContext';
import { ReactComponent as BackArrow } from '../../lib/img/back-arrow.svg';
import { ReactComponent as ShareIco } from '../../lib/img/share.svg';
import { ReactComponent as PencilIcon } from '../../lib/img/pencil.svg';
import { toDisplayFormat } from '../../lib/utils/formatDate';
import { useAppSelector } from '../../store/hooks';
import { useMainContext } from '../../containers/MainContainer/MainContext';
import { lifeCycleApplicationRoute } from '../../lib/routes/applications';
import { ApplicationLifeCycleActionName } from '../../lib/interfaces/ApplicationLifeCycleActionName';
import { appStatusAliases } from '../../lib/const/appStatusAliases';
import { ApplicationMenuItem } from './ApplicationMenuItem';
import './Application.less';
import { standBaseUrl } from '../../lib/routes/baseUrl';
import { getDictionaryItemById } from '../../lib/utils/applications';
import FireBaseService from '../../services/FireBaseService/FireBaseService';
import { AppTypes } from '../../lib/const/appTypes';

interface IProps {}

export const ApplicationNavbar: FC<IProps> = (props) => {
  const location = useLocation();
  const { hasAccess } = useMainContext();
  const {
    applicationId,
    getApplication,
    getApplicationFilesList,
    getExecutors,
    meIsChosenExecutor,
    meIsPotentialExecutor,
    meIsResponsible,
    hasAccessToSection,
    editModeMain,
    setEditModeMain,
  } = useApplicationContainerContext();
  const navigate = useNavigate();
  const { properties } = useAppSelector((state) => state.root);
  const { application } = useAppSelector((state) => state.applications);
  const { appStatus, appCloseReason, appSubject } = useAppSelector((state) => state.dictionaries);
  const { meUserFull } = useAppSelector((state) => state.persons);
  const { executors } = useAppSelector((state) => state.applications);
  const [dataLoading, setDataLoading] = useState(false);
  const [activeModal, setActiveModal] = useState<'' | 'close' | 'return' | 'cancel' | 'fulfill'>('');
  const [reasonClosingForm] = Form.useForm();

  const refTitleApplicationDiv = useRef<HTMLDivElement>(null);

  // Статусы просьбы
  const { neew, accepted, available, done, pending } = appStatusAliases;

  const menuFields: ApplicationMenuItem[] = [
    { name: 'Основное', route: 'main', size: 89 },
    { name: 'Исполнители', route: 'executors', size: 112 },
    { name: 'Комментарии', route: 'comments', size: 108 },
    { name: 'История изменений', route: 'history', size: 156 },
    { name: 'История откликов', route: 'history-response', size: 150 },
  ];

  const menu = (
    <div className="application-navbar-menu">
      {menuFields
        .filter(({ route }) => hasAccessToSection(route))
        .map(({ route, name, size }) => {
          const width = `${size}px`;
          const path = `/applications/list/${applicationId}/${route}`;
          const onClick = () => {
            if (route === 'history-response') {
              FireBaseService.createLogEventFireBase('Событие. Перейти на вкладку История откликов');
            }
            navigate(path);
          };
          return (
            <div
              key={route}
              onClick={onClick}
              className="application-navbar-menu-item"
              style={
                path === location.pathname
                  ? {
                      width,
                      backgroundColor: 'white',
                      fontWeight: 'bold',
                    }
                  : { width }
              }
            >
              {name}
            </div>
          );
        })}
    </div>
  );

  const onBack = () => {
    navigate('/applications/list');
  };

  const onShare = async () => {
    const instance = axios.create();
    delete instance.defaults.headers.common['Authorization'];

    FireBaseService.createLogEventFireBase('Событие. Поделиться просьбой');

    instance
      .post('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAKTPT8AM2a3tz6wJU8mp2tpOgnRyKzKwA', {
        dynamicLinkInfo: {
          domainUriPrefix: 'https://miloserdie.page.link',
          link: `${standBaseUrl}/applications/list/${applicationId}/main`,
          androidInfo: {
            androidPackageName: 'ru.iflex.mobile.charity',
          },
          iosInfo: {
            iosBundleId: 'ru.miloserdie.volunteer',
            iosAppStoreId: '6443472208',
          },
          socialMetaTagInfo: {
            socialTitle: 'Милосердие',
            socialDescription: 'Перейдите по ссылке, чтобы открыть просьбу в приложении',
            socialImageLink: properties.charity_link,
          },
        },
      })
      .then((response) => {
        const subject = getDictionaryItemById(appSubject, application?.subjectId)?.name;

        const text = `Просьба в ${application?.project?.title}. Вид помощи: ${subject}.\n` + response.data?.shortLink;

        navigator.clipboard
          .writeText(text)
          .then(() => {
            Modal.success({
              content: <div className="font-roboto">Ссылка на просьбу успешно скопирована</div>,
              className: 'password-forgotten-info-modal',
              width: '534px',
              bodyStyle: {
                backgroundColor: '#F4F4F4',
                paddingTop: '20px',
                marginTop: '111px',
              },
              okButtonProps: {
                style: {
                  marginRight: '158px',
                },
              },
              onOk: () => {
                Modal.destroyAll();
              },
            });
          })
          .catch((err) => {
            Modal.error({
              content: <div>Ошибка копирования ссылки на просьбу</div>,
              className: 'password-forgotten-info-modal',
              width: '534px',
              bodyStyle: {
                backgroundColor: '#F4F4F4',
                paddingTop: '20px',
                marginTop: '111px',
              },
              okButtonProps: {
                style: {
                  marginRight: '158px',
                },
              },
              onOk: () => {
                Modal.destroyAll();
              },
            });
          });
      })
      .catch(() => {
        Modal.error({
          content: <div>Ошибка создания ссылки на просьбу</div>,
          className: 'password-forgotten-info-modal',
          width: '534px',
          bodyStyle: {
            backgroundColor: '#F4F4F4',
            paddingTop: '20px',
            marginTop: '111px',
          },
          okButtonProps: {
            style: {
              marginRight: '158px',
            },
          },
          onOk: () => {
            Modal.destroyAll();
          },
        });
      });
    return;
  };

  // Изменение статуса просьбы
  const handleChangeStatus = (
    actionName: ApplicationLifeCycleActionName,
    bodyRequest?: { text: string; appCloseReasonId?: number }
  ) => {
    setDataLoading(true);
    axios
      .post(lifeCycleApplicationRoute({ applicationId, actionName }), bodyRequest)
      .then(() => {
        Promise.all([
          hasAccess(['APPLICATION.FILES.READ']) && getApplicationFilesList(),
          getApplication(),
          getExecutors(),
        ]).then(() => {
          setDataLoading(false);
          const messageContent = {
            respond: 'Спасибо! Ваш отклик отправлен на рассмотрение.',
            cancel: 'Вы отказались от выполнения просьбы.',
          };
          message.success({
            content: messageContent[actionName] || 'Статус просьбы обновлён',
            duration: 3,
          });
        });
      })
      .catch(() => {
        setDataLoading(false);
        message.error(
          {
            content: 'Произошла ошибка. Пожалуйста, повторите попытку позднее',
          },
          3
        );
      });
  };

  // Наличие доступа к изменению статуса
  const hasStatusChangeAccess = ((): boolean => {
    if (hasAccess(['APPLICATION.STATUSCHANGE.ALLREGIONS'])) {
      return true;
    }
    if (hasAccess(['APPLICATION.STATUSCHANGE.MYREGION'])) {
      return application?.regionId === meUserFull?.regionId;
    }
    if (
      hasAccess(['APPLICATION.STATUSCHANGE']) &&
      application?.project?.id &&
      meUserFull?.projectIds &&
      Array.isArray(meUserFull.projectIds)
    ) {
      return meUserFull.projectIds.includes(application.project.id);
    }
    return false;
  })();

  const onMainPage = location.pathname === `/applications/list/${applicationId}/main`;

  const onExecutorsPage = location.pathname === `/applications/list/${applicationId}/executors`;

  // Список кнопка: [статусы], в которых кнопка должна выводиться
  const allowedStatuses: {
    [key in ApplicationLifeCycleActionName]: number[];
  } = {
    accept: [neew], // Принять в обработку
    appoint: [accepted, available, done], // Передать на исполнение
    close: [accepted, available, done], // Закрыть просьбу
    fulfill: [pending], // Просьба исполнена
    return: [pending], // Просьба не исполнена
    share: [accepted, done], // Передать в общий доступ
    cancel: [accepted, available, pending], // Отказ от исполнения
    respond: [available], // Откликнуться
  };

  // Правила вывода кнопок жизненного цикла
  const buttonAccess = (btnName: ApplicationLifeCycleActionName): boolean => {
    // Доступность кнопки в определенном статусе просьбы
    const hasAllowedStatus: boolean = application?.statusId
      ? allowedStatuses[btnName].includes(application.statusId)
      : false;

    const isDonateApp = application?.appTypeId === AppTypes['Денежная'];

    if (!hasAllowedStatus) return false;
    switch (btnName) {
      case 'accept':
        const noResponsible = !Boolean(application?.responsible?.id);
        return (hasStatusChangeAccess && meIsResponsible) || (hasStatusChangeAccess && noResponsible);
      case 'appoint':
        const hasChosenExecutors = executors.chosenExecutors.length > 0;
        return !isDonateApp && hasStatusChangeAccess && onExecutorsPage && hasChosenExecutors;
      case 'cancel':
        return meIsPotentialExecutor || meIsChosenExecutor;
      case 'close':
        return hasStatusChangeAccess;
      case 'fulfill':
        return !isDonateApp && (meIsChosenExecutor || hasStatusChangeAccess);
      case 'respond':
        return hasAccess(['APPLICATION.RESPOND']) && !isDonateApp && !meIsChosenExecutor && !meIsPotentialExecutor;
      case 'return':
        return !isDonateApp && (meIsChosenExecutor || hasStatusChangeAccess);
      case 'share':
        return hasStatusChangeAccess;
      default:
        return false;
    }
  };

  // Доступность кнопки редактирования
  const editButtonAccess = (): boolean => {
    if (!onMainPage || editModeMain) return false;
    if (application?.statusId === appStatusAliases.closed) return false;
    if (hasAccess(['APPLICATION.WRITE.ALLREGIONS'])) {
      return true;
    }
    if (hasAccess(['APPLICATION.WRITE.MYREGION'])) {
      return application?.regionId === meUserFull?.regionId;
    }
    if (
      hasAccess(['APPLICATION.WRITE']) &&
      application?.project?.id &&
      meUserFull?.projectIds &&
      Array.isArray(meUserFull.projectIds)
    ) {
      return meUserFull.projectIds.includes(application.project.id);
    }
    return false;
  };

  const renderButtons = () => {
    return (
      <Row gutter={20} className="mb9">
        {buttonAccess('accept') && (
          <Col>
            <Button type="primary" onClick={() => handleChangeStatus('accept')}>
              Принять в обработку
            </Button>
          </Col>
        )}
        {buttonAccess('close') && (
          <Col>
            <Button onClick={() => setActiveModal('close')}>Закрыть просьбу</Button>
          </Col>
        )}
        {buttonAccess('share') && (
          <Col>
            <Button type="primary" style={{ width: '225px' }} onClick={() => handleChangeStatus('share')}>
              Передать в общий доступ
            </Button>
          </Col>
        )}
        {buttonAccess('appoint') && (
          <Col>
            <Button type="primary" style={{ width: '225px' }} onClick={() => handleChangeStatus('appoint')}>
              Передать на исполнение
            </Button>
          </Col>
        )}
        {buttonAccess('cancel') && (
          <Col>
            <Button style={{ width: '225px' }} onClick={() => setActiveModal('cancel')}>
              Отказ от исполнения
            </Button>
          </Col>
        )}
        {buttonAccess('return') && (
          <Col>
            <Button style={{ width: '225px' }} onClick={() => setActiveModal('return')}>
              Просьба не исполнена
            </Button>
          </Col>
        )}
        {buttonAccess('respond') && (
          <Col>
            <Button type="primary" style={{ width: '225px' }} onClick={() => handleChangeStatus('respond')}>
              Откликнуться
            </Button>
          </Col>
        )}
        {buttonAccess('fulfill') && (
          <Col>
            <Button type="primary" style={{ width: '225px' }} onClick={() => setActiveModal('fulfill')}>
              Просьба исполнена
            </Button>
          </Col>
        )}
        {editButtonAccess() && (
          <Button type="primary" className="ml-auto display-flex" onClick={() => setEditModeMain(true)}>
            <PencilIcon className="ml20" />
            <div>Редактировать</div>
          </Button>
        )}
      </Row>
    );
  };

  const renderReasonCloseModal = () => {
    const closeModal = () => {
      setActiveModal('');
      reasonClosingForm.resetFields();
    };

    const onFinish = ({ text, appCloseReasonId }) => {
      handleChangeStatus('close', { text, appCloseReasonId });
      closeModal();
    };

    return (
      <Modal
        visible={activeModal === 'close'}
        width="650px"
        onCancel={closeModal}
        footer={false}
        centered
        bodyStyle={{ backgroundColor: '#D0D8DA' }}
      >
        <h2 className="mb0 text-bold">Причина закрытия просьбы</h2>
        <Divider className="thin-divider mt0" />
        <Form form={reasonClosingForm} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="appCloseReasonId"
            label="Выберите причину закрытия просьбы"
            className="form-item-required__hidden-error"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Select>
              {appCloseReason
                .filter((el) => !el.deleted)
                .map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="text"
            className="application-main__dscTextArea mb15"
            label="Комментарий"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input.TextArea placeholder="Укажите детали закрытия просьбы" autoSize={{ minRows: 3, maxRows: 3 }} />
          </Form.Item>
          <Button onClick={closeModal}>Отмена</Button>
          <Button type="primary" htmlType="submit" className="ml20">
            Сохранить
          </Button>
        </Form>
      </Modal>
    );
  };

  const renderReasonReturnModal = () => {
    const closeModal = () => {
      setActiveModal('');
      reasonClosingForm.resetFields();
    };

    const onFinish = ({ text }) => {
      handleChangeStatus('return', { text });
      closeModal();
    };

    return (
      <Modal
        visible={activeModal === 'return'}
        width="650px"
        onCancel={closeModal}
        footer={false}
        centered
        bodyStyle={{ backgroundColor: '#D0D8DA' }}
      >
        <h2 className="mb0 text-bold">Причина невыполнения просьбы</h2>
        <Divider className="thin-divider mt0" />
        <Form form={reasonClosingForm} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="text"
            label="Введите причину невыполнения просьбы"
            className="application-main__dscTextArea mb40"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input.TextArea placeholder="Причина" autoSize={{ minRows: 3, maxRows: 3 }} />
          </Form.Item>
          <Button onClick={closeModal}>Отмена</Button>
          <Button type="primary" htmlType="submit" className="ml20">
            Сохранить
          </Button>
        </Form>
      </Modal>
    );
  };

  const renderReasonCancelModal = () => {
    const closeModal = () => {
      setActiveModal('');
      reasonClosingForm.resetFields();
    };

    const onFinish = ({ text }) => {
      handleChangeStatus('cancel', { text });
      closeModal();
    };

    return (
      <Modal
        visible={activeModal === 'cancel'}
        width="650px"
        onCancel={closeModal}
        footer={false}
        centered
        bodyStyle={{ backgroundColor: '#D0D8DA' }}
      >
        <h2 className="mb0 text-bold">Причина отказа</h2>
        <Divider className="thin-divider mt0" />
        <Form form={reasonClosingForm} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="text"
            label="Введите причину отказа"
            className="application-main__dscTextArea mb40"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input.TextArea placeholder="Причина" autoSize={{ minRows: 3, maxRows: 3 }} />
          </Form.Item>
          <Button onClick={closeModal}>Отмена</Button>
          <Button type="primary" htmlType="submit" className="ml20">
            Сохранить
          </Button>
        </Form>
      </Modal>
    );
  };

  const renderReasonFulfillModal = () => {
    const closeModal = () => {
      setActiveModal('');
      reasonClosingForm.resetFields();
    };

    const onFinish = ({ text }) => {
      handleChangeStatus('fulfill', { text });
      closeModal();
    };

    return (
      <Modal
        visible={activeModal === 'fulfill'}
        width="650px"
        onCancel={closeModal}
        footer={false}
        centered
        bodyStyle={{ backgroundColor: '#D0D8DA' }}
      >
        <h2 className="mb0 text-bold">Результат исполнения просьбы</h2>
        <Divider className="thin-divider mt0" />
        <Form form={reasonClosingForm} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="text"
            label="Введите результат исполнения просьбы"
            className="application-main__dscTextArea mb40"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input.TextArea placeholder="Результат" autoSize={{ minRows: 3, maxRows: 3 }} />
          </Form.Item>
          <Button onClick={closeModal}>Отмена</Button>
          <Button type="primary" htmlType="submit" className="ml20">
            Сохранить
          </Button>
        </Form>
      </Modal>
    );
  };

  return (
    <Spin spinning={dataLoading}>
      <div className="application application-navbar mt20">
        <div
          ref={refTitleApplicationDiv}
          className="display-flex"
          style={{ justifyContent: 'space-between', marginRight: '-15px' }}
        >
          <div className="display-flex">
            <BackArrow className="ml6 mt8 mr10 cursor-pointer" onClick={onBack} />
            <h2 className="text-bold mb5">Просьба подопечного</h2>
          </div>
          <div>
            <ShareIco className="ml6 mt12 mr10 cursor-pointer" onClick={onShare} />
          </div>
        </div>
        <div
          style={{
            width: refTitleApplicationDiv.current ? refTitleApplicationDiv.current?.offsetWidth - 44 : '97%',
          }}
        >
          <Divider className="thin-divider mt0 mb10 ml40" />
        </div>
        {hasAccessToSection('main') && (
          <>
            {renderButtons()}
            <Row className="mb9 font-roboto">
              <Col span={7}>
                <span>Статус:</span>
                <span className="pl15">{appStatus.find(({ id }) => id === application?.statusId)?.name}</span>
              </Col>
              <Col span={7}>
                <span>Дата получения:</span>
                <span className="pl15">
                  {application?.createDate ? toDisplayFormat(moment(application.createDate, 'YYYY-MM-DD')) : ''}
                </span>
              </Col>
              {application?.statusId === 6 && (
                <Col span={10}>
                  <span>Дата закрытия:</span>
                  <span className="pl15">
                    {application?.closeDate ? toDisplayFormat(moment(application?.closeDate, 'YYYY-MM-DD')) : ''}
                  </span>
                  <span className="pl10">-</span>
                  <span className="pl10">
                    {appCloseReason.find(({ id }) => id === application?.closeReasonId)?.name}
                  </span>
                </Col>
              )}
            </Row>
            {menu}
            {renderReasonCloseModal()}
            {renderReasonReturnModal()}
            {renderReasonCancelModal()}
            {renderReasonFulfillModal()}
          </>
        )}
      </div>
    </Spin>
  );
};
