import moment from 'moment';

export const displayFormat = 'DD.MM.YYYY';
export const sendingFormat = 'YYYY-MM-DD';
export const infinityStartDateHttpFormat = '1970-01-01T00:00:00.000+00:00';
export const infinityDateHttpFormat = '2999-12-31T23:59:59.999+00:00';

export const toDisplayFormat = (date) => {
  if (date) {
    return moment(date).format(displayFormat);
  }
  throw new Error('Invalid date');
};

export const toSendingFormat = (date) => {
  if (date) {
    return moment(date).format(sendingFormat);
  }
  throw new Error('Invalid date');
};

export const toDisplayFormatWithTime = (date) => {
  if (date) {
    return moment(date).format(`${displayFormat} HH:mm`);
  }
  throw new Error('Invalid date');
};

export const toDisplayFormatWithTimeFull = (date) => {
  if (date) {
    return moment(date).format(`${displayFormat} HH:mm:ss`);
  }
  throw new Error('Invalid date');
};

export const toHttpFormat = (date) => {
  if (date) {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  }
  throw new Error('Invalid date');
};

export const toHttpFormatStartOfDay = (date) => {
  if (date) {
    return moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  }
  throw new Error('Invalid date');
};

export const toHttpFormatEndOfDay = (date) => {
  if (date) {
    return moment(date).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  }
  throw new Error('Invalid date');
};

export const toYearMonthDayLineFormat = (date) => {
  if (date) {
    return moment(date).format('YYYY-MM-DD');
  }
  throw new Error('Invalid date');
};

export default {
  toDisplayFormat,
  toDisplayFormatWithTime,
  toHttpFormat,
  toHttpFormatStartOfDay,
  toHttpFormatEndOfDay,
  toYearMonthDayLineFormat,
};
