import React, { useEffect } from 'react';
import { Checkbox, Col, Divider, Modal, Row, Spin } from 'antd';
import Map from '../../components/Map/Map';
import { useMainContext } from '../MainContainer/MainContext';
import { ApplicationsMapSearchType } from '../../lib/interfaces/SearchApplicationsBody';
import { MapSearchApplications } from '../../components/Map/MapSearchApplications';
import { useDictionaries } from '../../lib/utils/useDictionaries';
import { checkIsLoaded, checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { useAppSelector } from '../../store/hooks';
import { ReactComponent as BackArrow } from '../../lib/img/back-arrow.svg';
import { useNavigate } from 'react-router-dom';
import ApplicationTypeIdButtons from '../../components/ApplicationTypeIdButtons/ApplicationTypeIdButtons';

function MapContainer() {
  const { searchApplicationsState, mapSearchType, setMapSearchType, loadProjects } = useMainContext();
  const { loadDictionaries } = useDictionaries();
  const { projectsLoading } = useAppSelector((state) => state.projects);
  const { appStatusLoading } = useAppSelector((state) => state.dictionaries);
  const { meUserFull } = useAppSelector((state) => state.persons);
  const { applicationsMyCheck } = useAppSelector((state) => state.applications);

  const navigate = useNavigate();

  // Получение списка просьб по изменению все/мои
  useEffect(() => {
    searchApplicationsState.getApplicationsList();
  }, [mapSearchType]);

  // Загрузка словарей
  useEffect(() => {
    loadDictionaries(['app_status', 'app_subject', 'person_category', 'region']);
    if (!checkIsLoaded(projectsLoading)) loadProjects();
  }, []);

  const getMenuItemStyle = (key: ApplicationsMapSearchType) => {
    if (key === mapSearchType) {
      return {
        backgroundColor: 'white',
        fontWeight: 'bold',
      };
    }
    return { boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.15) inset' };
  };

  const changeSearchType = (key: ApplicationsMapSearchType) => {
    switch (key) {
      case 'MAP_MY':
        applicationsMyCheck
          ? setMapSearchType(key)
          : Modal.error({
              content: 'Нет просьб для просмотра в этом разделе',
              width: '534px',
              icon: null,
            });
        break;

      case 'MAP_ALL':
        setMapSearchType(key);
        break;

      default:
        break;
    }
  };

  const renderMenu = () => {
    return (
      <div>
        <Row
          wrap={false}
          justify="start"
          className="applications-list-header ml20 mr20 pt20 pb20"
          // style={{ width: '100%' }}
        >
          <Col span={8} style={{ display: 'flex', flexWrap: 'nowrap' }}>
            <div className="applications-list-menu">
              <div
                className="applications-list-menu-item applications-list-menu-item-left"
                style={getMenuItemStyle('MAP_ALL')}
                onClick={() => {
                  changeSearchType('MAP_ALL');
                }}
              >
                Все просьбы
              </div>
              <div
                className="applications-list-menu-item applications-list-menu-item-right"
                style={getMenuItemStyle('MAP_MY')}
                onClick={() => {
                  changeSearchType('MAP_MY');
                }}
              >
                Мои просьбы
              </div>
            </div>
            <Checkbox
              className="ml24 mt5"
              onChange={(e) => {
                searchApplicationsState.handleSetSearchFormFields({
                  urgent: e.target.checked,
                });
              }}
              checked={searchApplicationsState.getSearchFormFieldValue('urgent')}
            >
              Срочная просьба
            </Checkbox>
            <Checkbox
              className="ml24 mt5"
              onChange={(e) => {
                searchApplicationsState.handleSetSearchFormFields({
                  liked: e.target.checked ? [meUserFull?.id] : undefined,
                });
              }}
              checked={searchApplicationsState.getSearchFormFieldValue('liked')}
            >
              Избранное
            </Checkbox>
          </Col>

          <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
            <ApplicationTypeIdButtons
              searchApplicationsState={searchApplicationsState}
              // style={{ position: 'relative', marginLeft: '-40px' }}
            />
          </Col>

          <Col span={8}></Col>
        </Row>
      </div>
    );
  };

  const onBack = () => {
    navigate('/applications/list');
  };

  return (
    <Spin spinning={checkIsLoading(appStatusLoading)}>
      <div className="display-flex">
        <BackArrow className="ml6 mt8 mr10 cursor-pointer" onClick={onBack} />
        <h2 className="text-bold mb5">Поиск на карте</h2>
      </div>
      <div style={{ width: 453 }}>
        <Divider className="thin-divider mt0 mb0 ml40" />
      </div>
      <div className="container" style={{ backgroundColor: 'white' }}>
        {renderMenu()}
        <MapSearchApplications />
        {checkIsLoaded(appStatusLoading) && <Map />}
      </div>
    </Spin>
  );
}

export default MapContainer;
