import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import requestStatusCodes from '../../../lib/const/requestStatusCodes';
import { RequestStatuses } from '../../../lib/types/RequestStatuses';
import { Person } from '../../../lib/interfaces/Person';
import {
  fetchMeUserRoute,
  fullPersonDataRoute,
  getCardCommentsRoute,
  getCardHistoryRoute,
  getPersonFilesRoute,
  getPersonPhotoRoute,
  getPersonProfileShortInfoRoute,
  pageableSearchPersonRoute,
  participationRoute,
  searchPersonPOSTRoute,
} from '../../../lib/routes/persons';
import { SearchPersonBody } from '../../../lib/interfaces/SearchPersonBody';
import { MeUser } from '../../../lib/interfaces/MeUser';
import { Comment } from '../../../lib/interfaces/Comment';
import { History } from '../../../lib/interfaces/History';
import { PersonShortProfile } from '../../../lib/interfaces/PersonShortProfile';

interface InitialState {
  responsibles: Person[];
  responsiblesLoading: RequestStatuses;
  persons: Person[];
  personsLoading: RequestStatuses;
  totalPersons: number;
  meUser?: MeUser;
  meUserLoading: RequestStatuses;
  meUserFull?: Person;
  meUserFullLoading: RequestStatuses;
  personFullCard: Person | null;
  personFullCardLoading: RequestStatuses;
  personCardComments: Comment[];
  personCardCommentsLoading: RequestStatuses;
  fileHistory: History[];
  fileHistoryLoading: RequestStatuses;
  personPhoto: string[] | null;
  personPhotoLoading: RequestStatuses;
  personProfileShortInfo: PersonShortProfile | null;
  personProfileShortInfoLoading: RequestStatuses;
  participation: {
    wards: any;
    applications: any;
    executors: any;
  };
  participationLoading: RequestStatuses;
  files: File[];
  filesLoading: RequestStatuses;
}

const initialState: InitialState = {
  responsibles: [],
  responsiblesLoading: requestStatusCodes.idle,
  persons: [],
  personsLoading: requestStatusCodes.idle,
  totalPersons: 0,
  meUserLoading: requestStatusCodes.idle,
  meUserFullLoading: requestStatusCodes.idle,
  personFullCard: null,
  personFullCardLoading: requestStatusCodes.idle,
  personCardComments: [],
  personCardCommentsLoading: requestStatusCodes.idle,
  fileHistory: [],
  fileHistoryLoading: requestStatusCodes.idle,
  personPhoto: null,
  personPhotoLoading: requestStatusCodes.idle,
  personProfileShortInfo: null,
  personProfileShortInfoLoading: requestStatusCodes.idle,
  participation: {
    wards: [],
    applications: [],
    executors: [],
  },
  participationLoading: requestStatusCodes.idle,
  files: [],
  filesLoading: requestStatusCodes.idle,
};

export const getResponsibles = createAsyncThunk(
  'persons/getResponsibles',
  async ({ projectIds, personStatusIds }: SearchPersonBody) => {
    const response = axios.post(searchPersonPOSTRoute(), { projectIds, personStatusIds });
    const { data } = await response;
    return data;
  }
);

const getPersonFullCard = createAsyncThunk('persons/getPersonFullCard', async ({ id }: { id: number }) => {
  const response = axios.get(fullPersonDataRoute(id));
  const { data } = await response;
  return { person: data };
});

const getPersonParticipation = createAsyncThunk('persons/getPersonParticipation', async ({ id }: { id: number }) => {
  const response = axios.get(participationRoute(id));
  const { data } = await response;
  return { person: data };
});

const pageableSearchPersons = createAsyncThunk(
  'persons/pageableSearchPersons',
  async ({ page, search = {}, sort = ['createDate|desc'] }: { page: number; search?: any; sort?: any }) => {
    // @ts-ignore
    const response = axios.post(pageableSearchPersonRoute({ page, size: 10, sort }), search);
    const { data } = await response;
    return { persons: data.content, totalPersons: data.totalElements };
  }
);

export const fetchMeUser = createAsyncThunk('persons/fetchMeUser', async () => {
  const response = axios.get(fetchMeUserRoute());
  const { data } = await response;
  return data;
});

export const fetchMeUserFull = createAsyncThunk(
  'persons/fetchMeUserFull',
  async ({ personId }: { personId: number }) => {
    const response = axios.get(fullPersonDataRoute(personId));
    const { data } = await response;
    return data;
  }
);

const getCardComments = createAsyncThunk(
  'persons/getCardComments',
  async ({ personId }: { personId: number | string }) => {
    const response = axios.get(getCardCommentsRoute(personId));
    const { data } = await response;
    return data.comments;
  }
);

const getCardHistory = createAsyncThunk(
  'persons/getCardHistory',
  async ({ personId }: { personId: number | string }) => {
    const response = axios.get(getCardHistoryRoute(personId));
    const { data } = await response;
    return data;
  }
);

const getPersonPhoto = createAsyncThunk(
  'persons/getPersonPhoto',
  async ({ personId }: { personId: number | string }) => {
    const response = axios.get(getPersonPhotoRoute(personId));
    const { data } = await response;
    return data;
  }
);

const getPersonProfileShortInfo = createAsyncThunk('persons/getPersonProfileShortInfo', async () => {
  const response = axios.get(getPersonProfileShortInfoRoute());
  const { data } = await response;
  return data;
});

const getPersonFiles = createAsyncThunk(
  'persons/getPersonFiles',
  async ({ personId }: { personId: number | string }) => {
    const response = axios.get(getPersonFilesRoute(personId));
    const { data } = await response;
    return data;
  }
);

const personsSlice = createSlice({
  name: 'persons',
  initialState,
  reducers: {
    savePersonFullCard: (state, action) => {
      state.personFullCard = action.payload;
    },
    cleanPersonFullCard: (state) => {
      state.personFullCard = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getResponsibles.pending, (state) => {
        state.responsiblesLoading = requestStatusCodes.pending;
      })
      .addCase(getResponsibles.rejected, (state) => {
        state.responsiblesLoading = requestStatusCodes.rejected;
      })
      .addCase(getResponsibles.fulfilled, (state, { payload }) => {
        state.responsiblesLoading = requestStatusCodes.fulfilled;
        state.responsibles = payload.content;
      })
      .addCase(pageableSearchPersons.pending, (state) => {
        state.personsLoading = requestStatusCodes.pending;
      })
      .addCase(pageableSearchPersons.rejected, (state) => {
        state.personsLoading = requestStatusCodes.rejected;
      })
      .addCase(
        pageableSearchPersons.fulfilled,
        (state, action: PayloadAction<{ persons: Person[]; totalPersons: number }>) => {
          state.personsLoading = requestStatusCodes.fulfilled;
          state.persons = action.payload.persons;
          state.totalPersons = action.payload.totalPersons;
        }
      )
      .addCase(fetchMeUser.pending, (state) => {
        state.meUserLoading = requestStatusCodes.pending;
      })
      .addCase(fetchMeUser.rejected, (state) => {
        state.meUserLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchMeUser.fulfilled, (state, { payload }) => {
        state.meUserLoading = requestStatusCodes.fulfilled;
        state.meUser = payload;
      })
      .addCase(fetchMeUserFull.pending, (state) => {
        state.meUserFullLoading = requestStatusCodes.pending;
      })
      .addCase(fetchMeUserFull.rejected, (state) => {
        state.meUserFullLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchMeUserFull.fulfilled, (state, { payload }) => {
        state.meUserFullLoading = requestStatusCodes.fulfilled;
        state.meUserFull = payload;
      })
      .addCase(getPersonFullCard.pending, (state) => {
        state.personFullCardLoading = requestStatusCodes.pending;
      })
      .addCase(getPersonFullCard.rejected, (state) => {
        state.personFullCardLoading = requestStatusCodes.rejected;
      })
      .addCase(getPersonFullCard.fulfilled, (state, action) => {
        state.personFullCardLoading = requestStatusCodes.fulfilled;
        state.personFullCard = action.payload.person;
      })
      .addCase(getCardComments.pending, (state) => {
        state.personCardCommentsLoading = requestStatusCodes.pending;
      })
      .addCase(getCardComments.rejected, (state) => {
        state.personCardCommentsLoading = requestStatusCodes.rejected;
      })
      .addCase(getCardComments.fulfilled, (state, action) => {
        state.personCardCommentsLoading = requestStatusCodes.fulfilled;
        state.personCardComments = action.payload;
      })
      .addCase(getCardHistory.pending, (state) => {
        state.fileHistoryLoading = requestStatusCodes.pending;
      })
      .addCase(getCardHistory.rejected, (state) => {
        state.fileHistoryLoading = requestStatusCodes.rejected;
      })
      .addCase(getCardHistory.fulfilled, (state, action) => {
        state.fileHistoryLoading = requestStatusCodes.fulfilled;
        state.fileHistory = action.payload;
      })
      .addCase(getPersonPhoto.pending, (state) => {
        state.personPhotoLoading = requestStatusCodes.pending;
      })
      .addCase(getPersonPhoto.rejected, (state) => {
        state.personPhotoLoading = requestStatusCodes.rejected;
      })
      .addCase(getPersonPhoto.fulfilled, (state, action) => {
        state.personPhotoLoading = requestStatusCodes.fulfilled;
        state.personPhoto = action.payload;
      })
      .addCase(getPersonParticipation.pending, (state) => {
        state.participationLoading = requestStatusCodes.pending;
      })
      .addCase(getPersonParticipation.rejected, (state) => {
        state.participationLoading = requestStatusCodes.rejected;
      })
      .addCase(getPersonParticipation.fulfilled, (state, action) => {
        state.participationLoading = requestStatusCodes.fulfilled;
        state.participation = action.payload.person;
      })
      .addCase(getPersonFiles.pending, (state) => {
        state.filesLoading = requestStatusCodes.pending;
      })
      .addCase(getPersonFiles.rejected, (state) => {
        state.filesLoading = requestStatusCodes.rejected;
      })
      .addCase(getPersonFiles.fulfilled, (state, action) => {
        state.filesLoading = requestStatusCodes.fulfilled;
        state.files = action.payload;
      })
      .addCase(getPersonProfileShortInfo.pending, (state) => {
        state.personProfileShortInfoLoading = requestStatusCodes.pending;
      })
      .addCase(getPersonProfileShortInfo.rejected, (state) => {
        state.personProfileShortInfoLoading = requestStatusCodes.rejected;
      })
      .addCase(getPersonProfileShortInfo.fulfilled, (state, action) => {
        state.personProfileShortInfoLoading = requestStatusCodes.fulfilled;
        state.personProfileShortInfo = action.payload;
      });
  },
});

const { actions, reducer } = personsSlice;

const { savePersonFullCard, cleanPersonFullCard } = actions;

export {
  reducer,
  pageableSearchPersons,
  getPersonFullCard,
  getCardComments,
  getCardHistory,
  getPersonPhoto,
  getPersonProfileShortInfo,
  savePersonFullCard,
  getPersonParticipation,
  cleanPersonFullCard,
  getPersonFiles,
};
