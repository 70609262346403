import React from 'react';

export type SaveCurrentProjectId = (projectId: number) => void;

export type LoadProjectParticipants = (personIds: number[]) => void;
export type CleanProjectParticipants = () => void;
export type LoadProjectPersonList = (regionId: number) => void;
export type CreateProject = (props: { title: string; regionId: number }) => void;
export type DeleteProject = (projectId: number) => Promise<any>;

export type UpdateProject = (editedProject: {
  id: number;
  regionId: number;
  projectTypeId: number;
  title: string;
  participants: number[];
}) => Promise<any>;

export interface IAdministrationProjectsContext {
  saveCurrentProjectId: SaveCurrentProjectId;
  loadProjectParticipants: LoadProjectParticipants;
  cleanProjectParticipants: CleanProjectParticipants;
  loadProjectPersonList: LoadProjectPersonList;
  createProject: CreateProject;
  updateProject: UpdateProject;
  deleteProject: DeleteProject;
}

export const AdministrationProjectsContext = React.createContext<IAdministrationProjectsContext | null>(null);

export const useAdministrationProjectsContext = () => {
  const context = React.useContext(AdministrationProjectsContext);
  if (context === null) {
    throw new Error('useContext must be inside a Provider with a value');
  }
  return context;
};
