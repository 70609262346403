import React, { FC, useEffect, useState } from 'react';
import { CreateUser } from '../../components/CreateUser/CreateUser';
import axios from 'axios';
import { createUserRoute } from '../../lib/routes/persons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  getAwarenessSourceDictionary,
  getMaritalStatusDictionary,
  getRegionDictionary,
  getSubwayStationDictionary,
} from '../../store/slices/dictionariesSlice/dictionariesSlice';
import { checkIsLoaded } from '../../lib/utils/checkLoadingStatus';
import { Spin, message } from 'antd';
import CreateUserModal from '../../components/CreateUserModal/CreateUserModal';
import { toYearMonthDayLineFormat } from '../../lib/utils/formatDate';

const CreateUserContainer: FC = () => {
  const dispatch = useAppDispatch();
  const { maritalStatusLoading, regionLoading, subwayStationLoading, awarenessSourceLoading } = useAppSelector(
    (state) => state.dictionaries
  );
  const [createUserModalVisible, setCreateUserModalVisible] = useState(false);

  useEffect(() => {
    if (!checkIsLoaded(maritalStatusLoading, regionLoading, subwayStationLoading, awarenessSourceLoading)) {
      dispatch(getMaritalStatusDictionary());
      dispatch(getRegionDictionary());
      dispatch(getSubwayStationDictionary());
      dispatch(getAwarenessSourceDictionary());
    }
  }, [dispatch]);

  const createUser = (userData) => {
    const newUser = {
      password: userData.password,
      firstName: userData.firstName,
      lastName: userData.lastName,
      middleName: userData?.noMiddleName ? '' : userData.middleName,
      maritalStatusId: userData?.maritalStatusId,
      birthDate: toYearMonthDayLineFormat(userData.birthDate),
      regionId: userData.regionId,
      email: userData?.email && String(userData.email).toLowerCase(),
      profession: userData?.profession,
      chronicDiseases: userData?.chronicDiseases,
      reason: userData?.volunteerReason,
      otherSource: userData?.otherSource,
      church: userData?.church,
      phone: userData?.phone,
      sourceId: userData?.awarenessSource,
      customSource: userData?.otherSource,
      address: {
        subwayStationId: userData?.subwayStationId,
        apartment: userData?.apartment,
        unstructuredAddress: userData.unstructuredAddress,
      },
    };

    axios
      .post(createUserRoute(), newUser)
      .then(() => {
        setCreateUserModalVisible(true);
      })
      .catch((e) => {
        message.error(e?.response?.data?.message, 3);
      });
  };

  const handleModalOk = () => {
    window.location.replace('/login');
  };

  if (checkIsLoaded(maritalStatusLoading, regionLoading, subwayStationLoading)) {
    return (
      <>
        <CreateUserModal isOpened={createUserModalVisible} handleOk={handleModalOk} />
        <CreateUser createUser={createUser} />
      </>
    );
  }
  return <Spin />;
};

export default CreateUserContainer;
