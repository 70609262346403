import React from 'react';
import { ReactComponent as ListPoint } from '../../lib/img/list-point.svg';

const getListItem = (name: string): React.ReactElement<any, any> => (
  <div className="mt10">
    <ListPoint className="mr10" />
    {name}
  </div>
);

interface Props {
  shortVersion?: boolean;
}

function PasswordHint(props: Props) {
  const { shortVersion } = props;
  return (
    <div className="font-roboto text-left ml5">
      {shortVersion ? (
        <>Надежный пароль должен содержать:</>
      ) : (
        <>
          Для восстановления доступа к аккаунту, создайте новый
          <br />
          пароль. Надежный пароль должен содержать:
        </>
      )}
      {getListItem('Не менее 8 символов')}
      {getListItem('Цифры')}
      {getListItem('Хотя бы одну букву')}
    </div>
  );
}

export default PasswordHint;
