import React, { FC, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './Info.less';
import { useInfoContext } from '../../containers/InfoContainer/InfoContext';
import { useAppSelector } from '../../store/hooks';
import { Button, Checkbox, Col, Divider, Form, Input, Modal, Row } from 'antd';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { hasAccess } from '../../lib/utils/usePermissions';

export const InfoNavbar: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isCreate, setIsCreate] = useState(false);
  const [isError, setIsError] = useState(false);

  const [createForm] = useForm();

  const { sections } = useAppSelector((state) => state.sections);

  const { isArchivedCheck, onChangeArchivedCheck, addNewSection, fetchSections } = useInfoContext();

  const startDay = moment().startOf('day');

  const closeModal = () => {
    setIsCreate(false);
    setIsError(false);
    createForm.resetFields();
  };

  const onFinish = (fields) => {
    addNewSection(fields, (sectionId) => {
      fetchSections(isArchivedCheck);
      closeModal();
      navigate(`/info/${sectionId}`);
    });
  };

  const onFinishFailed = ({ errorFields }) => {
    const findBodyError = errorFields?.find((el) => el.name?.includes('section_body'));
    if (findBodyError) {
      setIsError(true);
    }
    console.log(errorFields);
  };

  const onValuesChange = (fields) => {
    if (fields?.section_body?.length > 0) {
      setIsError(false);
    }
    if (fields?.section_body?.length === 0) {
      setIsError(true);
    }
  };

  const menu = (
    <div className="info-navbar-menu">
      {hasAccess(['INFO.ADDSECTION']) && (
        <Row className="ml20 mb20">
          <Col>
            <Button style={{ backgroundColor: '#F4F4F4' }} onClick={() => setIsCreate(true)}>
              Добавить новый раздел
            </Button>
          </Col>
        </Row>
      )}
      {hasAccess(['INFO.READ.CLOSED']) && (
        <Row className="ml20 mb20">
          <Col>
            <Checkbox checked={isArchivedCheck} onChange={(e) => onChangeArchivedCheck(e.target.checked)}>
              Показать архивные
            </Checkbox>
          </Col>
        </Row>
      )}

      {sections.map((el) => {
        const path = `/info/${el.id}`;
        const onClick = () => {
          navigate(path);
          window.scrollTo(0, 0);
        };
        return (
          <div
            key={el.id}
            onClick={onClick}
            className="info-navbar-menu-item"
            style={
              path === location.pathname
                ? {
                    backgroundColor: '#315373',
                    color: 'white',
                  }
                : {
                    backgroundColor: moment(el.toDate).isBefore(startDay) ? '#D0D8DA' : '',
                    color: moment(el.toDate).isBefore(startDay) ? 'white' : '',
                  }
            }
          >
            {el.section_name}
          </div>
        );
      })}

      <Modal
        open={isCreate}
        width="690px"
        onCancel={closeModal}
        footer={false}
        bodyStyle={{ backgroundColor: '#F4F4F4' }}
        style={{ height: 559 }}
      >
        <h2 className="mb0 text-bold">Добавление нового раздела</h2>
        <Divider className="thin-divider mt0" />
        <Form 
          form={createForm}
          onFinish={onFinish}
          layout="vertical"
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
        >
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                name="section_name"
                label="Заголовок"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input placeholder="Новое значение" maxLength={64} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                name="section_body"
                label="Описание"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <TextArea style={{ border: isError ? '1px solid red' : '' }} placeholder="Новое значение" rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="center" className="mt10">
            <Button onClick={closeModal}>Отмена</Button>
            <Button type="primary" htmlType="submit" className="ml20" style={{ width: 216 }}>
              Сохранить
            </Button>
          </Row>
        </Form>
      </Modal>
    </div>
  );
  return <div className="info-navbar">{menu}</div>;
};
