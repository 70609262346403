import {
  useAppDispatch,
  // useAppSelector
} from '../../store/hooks';
import { getDictionaries } from '../../store/slices/dictionariesSlice/dictionariesSlice';
import { DicTypeId } from '../interfaces/DicTypeId';
// import { camelizeString } from "./camelizeString";
// import requestStatusCodes from "../../lib/const/requestStatusCodes";

// Сервис загрузки словарей. Производит проверку ранее загруженных словарей. Если уже загружен, повторно не загружается
export const useDictionaries = () => {
  const dispatch = useAppDispatch();
  // const dictionariesState = useAppSelector((state) => state.dictionaries);
  const loadDictionaries = (dicTypeIds: DicTypeId[]) => {
    const loadedDicTypeIds = dicTypeIds;
    /* 
      В соотстветствии с таской 56783, проверка на загруженность отключена,
      в связи с этим использование данного хука смысла не имеет, нужно использовать метод из слайса напрямую.
    */
    // .filter((dicTypeId) => {
    //   const dicName = camelizeString(dicTypeId);
    //   return (
    //     dictionariesState[`${dicName}Loading`] !== requestStatusCodes.fulfilled
    //   );
    // });
    if (loadedDicTypeIds.length) {
      dispatch(getDictionaries(loadedDicTypeIds));
    }
  };
  return { loadDictionaries };
};
