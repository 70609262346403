import { Table } from "antd";
import React, { useState } from "react";
import { useAppSelector } from "../../store/hooks";
import "./PermissionDescriptions.less"

const styles = {
  container: { display: "flex", paddingTop: "20px" },
  block: { width: "630px", height: "600px", border: "1px solid #CCDDEE" },
  blockTitle: {
    color: "#48433F",
    fontSize: "18px",
    fontWeight: 700,
    marginBottom: "6px",
  },
  rightBlock: { marginLeft: "32px" },
  dsc: {},
};

const PermissionDescriptions = () => {
  const { allPermissions } = useAppSelector((state) => state.administration);
  const [permissionDsc, setPermissionDsc] = useState("");

  const columns = [
    {
      title: "Привилегии",
      dataIndex: "alias",
      key: "alias",
    },
  ];

  const data = allPermissions.map((item) => ({
    key: item.id,
    alias: item.alias,
    dsc: item.dsc,
  }));

  return (
    <div style={styles.container}>
      <div>
        <div style={styles.blockTitle}>Привилегии</div>
        <Table
          sticky
          pagination={false}
          size="small"
          rowClassName={(record, index) => {
            if (index % 2 === 0) return "row-dark";
            return "row-light";
          }}
          columns={columns}
          dataSource={data}
          showHeader={false}
          scroll={{ y: 600 }}
          onRow={(record) => ({
            onClick: () => {
              setPermissionDsc(record.dsc);
            },
          })}
          style={styles.block}
        />
      </div>
      <div style={styles.rightBlock}>
        <div style={styles.blockTitle}>Описание привилегии</div>
        <div style={{ ...styles.block, paddingLeft: 10 }}>
          <p style={styles.dsc}>{permissionDsc}</p>
        </div>
      </div>
    </div>
  );
};

export default PermissionDescriptions;
