import React, { FC } from 'react';
import type { ColumnsType } from 'antd/lib/table';
import { Table } from 'antd';
import usePhoneMask from '../../lib/utils/usePhoneMask';
import { useAppSelector } from '../../store/hooks';
import { Phone } from '../../lib/interfaces/Phone';

interface IProps {
  phones?: Phone[];
}

export const PhonesTableDisplay: FC<IProps> = ({ phones = [] }) => {
  const { getMaskedPhoneNumber } = usePhoneMask();

  const { phoneType } = useAppSelector((state) => state.dictionaries);

  const columns: ColumnsType<any> = [
    {
      title: 'Телефон',
      key: 'phone',
      dataIndex: 'phone',
      width: '23%',
      render: (phoneNumber) => getMaskedPhoneNumber(phoneNumber),
    },
    {
      title: 'Тип',
      key: 'phoneTypeId',
      dataIndex: 'phoneTypeId',
      width: '23%',
      render: (phoneTypeId) => phoneType.find(({ id }) => id === phoneTypeId)?.name,
    },
    {
      title: 'Комментарий',
      key: 'dsc',
      dataIndex: 'dsc',
    },
  ];

  return (
    <Table
      size="small"
      className="table__no-hover striped-table bordered-table"
      columns={columns}
      dataSource={phones.map((phone) => ({ ...phone, key: phone.id }))}
      pagination={false}
      scroll={{ y: '120px' }}
      rowClassName={(r, i) => (i % 2 ? '' : 'striped-table__oddRow')}
    />
  );
};
