import React from "react";
import { Permission } from "../../lib/interfaces/Permission";
import { hasAccess } from "../../lib/utils/usePermissions";
import { HasNotPermissions } from "../HasNotPermission/HasNotPermissions";

export function checkPermission<T>(
  Child: React.ComponentType<T>,
  permissions: Permission[]
) {
  return class extends React.Component<T, {}> {
    static displayName = `checkPermission(${Child.displayName || Child.name})`;

    render() {
      return hasAccess(permissions) ? (
        <Child {...this.props} />
      ) : (
        <HasNotPermissions />
      );
    }
  };
}
