import { Button, Checkbox, Col, Divider, Row, Table, Tooltip } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useAdministrationPersonContext } from '../../containers/AdministrationPersonContainer/AdministrationPersonContext';
import { TransferIcon } from '../../lib/img/TransferIcon';
import { IRoleTableRecord } from '../../lib/interfaces/Administration';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { hasAccess } from '../../lib/utils/usePermissions';
import { useAppSelector } from '../../store/hooks';

interface IProps {}

export const AdministrationPersonRoles: FC<IProps> = (props) => {
  const { onSubmitAssignRole } = useAdministrationPersonContext();
  const { userLoading, user, allRoles, allRolesLoading } = useAppSelector((state) => state.administration);
  const [editMode, setEditMode] = useState(false);

  const [assignedTableData, setAssignedTableData] = useState<IRoleTableRecord[]>([]);

  const assignedRoleId = assignedTableData[0]?.id;

  const [availableTableData, setAvailableTableData] = useState<IRoleTableRecord[]>([]);

  const setDefaultAssignedTableData = () => {
    setAssignedTableData(
      (user?.includedRoles || []).map(({ id, dsc }) => ({
        checked: false,
        id,
        key: id,
        roleName: (dsc && dsc.replace('Роль ', '')) || '',
      }))
    );
  };

  const setDefaultAvailableTableData = () => {
    setAvailableTableData(
      allRoles
        .map(({ id, dsc }) => ({
          checked: false,
          id,
          key: id,
          roleName: (dsc && dsc.replace('Роль ', '')) || '',
        }))
        .filter(({ id }) => id !== user?.includedRoles[0]?.id)
        .sort((a, b) => (a.id > b.id ? 1 : -1))
    );
  };

  // Установка исходного списка назначенных ролей
  useEffect(() => {
    setDefaultAssignedTableData();
  }, [user]);

  // Установка исходного списка доступных ролей
  useEffect(() => {
    setDefaultAvailableTableData();
  }, [allRoles, user]);

  const columnsAssigned = [
    {
      key: 0,
      dataIndex: 'checked',
      render: (checked) => editMode && <Checkbox checked={checked} />,
      width: '28px',
    },
    {
      key: 1,
      dataIndex: 'roleName',
    },
  ];

  const columnsAvailable = [
    {
      key: 0,
      dataIndex: 'checked',
      render: (checked) =>
        editMode && (
          <Tooltip title={assignedRoleId ? 'Для назначения новой роли, удалите назначенную' : ''}>
            <Checkbox checked={checked} disabled={Boolean(assignedRoleId)} />
          </Tooltip>
        ),
      width: '28px',
    },
    {
      key: 1,
      dataIndex: 'roleName',
      render: (roleName) => (
        <Tooltip title={assignedRoleId && editMode ? 'Для назначения новой роли, удалите назначенную' : ''}>
          <div>{roleName}</div>
        </Tooltip>
      ),
    },
  ];

  const handleDiscard = () => {
    setDefaultAssignedTableData();
    setDefaultAvailableTableData();
    setEditMode(false);
  };

  const toggleCheckAssigned = (id: number) => {
    setAssignedTableData((prev) =>
      prev.map((item) => ({
        ...item,
        checked: item.id === id ? !item.checked : false,
      }))
    );
    setAvailableTableData((prev) =>
      prev.map((item) => ({
        ...item,
        checked: false,
      }))
    );
  };

  const toggleCheckAvailable = (id: number) => {
    setAvailableTableData((prev) =>
      prev.map((item) => ({
        ...item,
        checked: item.id === id ? !item.checked : false,
      }))
    );
    setAssignedTableData((prev) =>
      prev.map((item) => ({
        ...item,
        checked: false,
      }))
    );
  };

  const transfer = (from: 'assigned' | 'available') => {
    switch (from) {
      case 'assigned':
        setAssignedTableData((prevAssigned) => {
          setAvailableTableData((prevAvailable) =>
            [
              ...prevAvailable,
              ...prevAssigned.filter(({ checked }) => checked).map((item) => ({ ...item, checked: false })),
            ].sort((a, b) => (a.id > b.id ? 1 : -1))
          );
          return prevAssigned.filter(({ checked }) => !checked);
        });
      case 'available':
        setAvailableTableData((prevAvailable) => {
          setAssignedTableData((prevAssigned) => [
            ...prevAssigned,
            ...prevAvailable.filter(({ checked }) => checked).map((item) => ({ ...item, checked: false })),
          ]);
          return prevAvailable.filter(({ checked }) => !checked);
        });
    }
  };

  return (
    <div className="administrationPerson-roles">
      <Row gutter={15} style={{ height: '423px' }}>
        <Col span={12}>
          <h4 className="text-bold font-size-18 mb3">Назначенная роль</h4>
          <Divider className="thin-divider mt0 mb10" />
          <Table
            size="small"
            loading={checkIsLoading(userLoading)}
            showHeader={false}
            columns={columnsAssigned}
            dataSource={assignedTableData}
            className="striped-table"
            rowClassName={(r, i) => (i % 2 ? 'cursor-pointer' : 'cursor-pointer striped-table__oddRow')}
            pagination={false}
            onRow={({ id }) => ({
              onClick: () => (editMode ? toggleCheckAssigned(id) : {}),
            })}
          />
        </Col>
        <Col span={1} className="mt149">
          {editMode && (
            <TransferIcon
              side="right"
              active={assignedTableData.some(({ checked }) => checked)}
              onClick={() => transfer('assigned')}
            />
          )}
          {editMode && (
            <TransferIcon
              active={availableTableData.some(({ checked }) => checked)}
              onClick={() => transfer('available')}
            />
          )}
        </Col>
        <Col span={11}>
          <h4 className="text-bold font-size-18 mb3">Доступные роли</h4>
          <Divider className="thin-divider mt0 mb10" />
          <Table
            size="small"
            loading={checkIsLoading(allRolesLoading)}
            showHeader={false}
            columns={columnsAvailable}
            dataSource={availableTableData}
            className="striped-table"
            rowClassName={(r, i) => (i % 2 ? 'cursor-pointer' : 'cursor-pointer striped-table__oddRow')}
            pagination={false}
            onRow={({ id }) => ({
              onClick: () => (editMode && !assignedRoleId ? toggleCheckAvailable(id) : {}),
            })}
          />
        </Col>
      </Row>
      <Row justify="end" gutter={20} className="mt20">
        {!editMode && hasAccess(['ADMINISTRATION.USER.WRITE']) && (
          <Col>
            <Button type="primary" onClick={() => setEditMode(true)}>
              Редактировать
            </Button>
          </Col>
        )}
        {editMode && (
          <>
            <Col>
              <Button onClick={handleDiscard}>Сбросить</Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() =>
                  onSubmitAssignRole({
                    assignedRoleId,
                  }).then(() => setEditMode(false))
                }
              >
                Сохранить
              </Button>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
