import React, { FC, useState } from 'react';
import { Form, Modal, Button, Spin, DatePicker, Select, Row, Col, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { applicationsReportRoute } from '../../lib/routes/applications';
import { displayFormat } from '../../lib/utils/formatDate';
import { useAppSelector } from '../../store/hooks';
import MultipleSelect from '../common/MultipleSelect';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import selectFilterOption from '../../lib/utils/selectFilterOption';
import { useMainContext } from '../../containers/MainContainer/MainContext';

interface IProps {
  visible: boolean;
  closeModal: () => void;
}

export const ApplicationsReportModal: FC<IProps> = ({ visible, closeModal }) => {
  const { region, appStatus, appStatusLoading } = useAppSelector((state) => state.dictionaries);
  const { projects, projectsLoading } = useAppSelector((state) => state.projects);
  const [reportForm] = Form.useForm();
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const { hasAccess } = useMainContext();
  const { meUserFull } = useAppSelector((state) => state.persons);

  const handleCloseModal = () => {
    reportForm.resetFields();
    closeModal();
  };

  const onSelectRegionId = () => {
    reportForm.resetFields(['projectIds']);
  };

  const onFinish = (values) => {
    setIsLoadingReport(true);
    const requestData = {
      [values.type === 2 ? 'dateFrom' : 'createFrom']: values.range[0].format('YYYY-MM-DD'),
      [values.type === 2 ? 'dateTo' : 'createTo']: values.range[1].format('YYYY-MM-DD'),
      regionIds: values.regionIds,
      projectIds: values.projectIds,
      appStatusIds: values.appStatusIds,
    };

    axios
      .post(applicationsReportRoute(), requestData, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        saveAs(data, `Отчет_${moment().format('DD_MM_YYYY')}`);
        handleCloseModal();
        message.success('Отчёт сформирован', 3);
      })
      .catch(() => {
        message.error('Произошла ошибка. Пожалуйста, попробуйте сформировать отчёт позднее', 3);
      })
      .finally(() => {
        setIsLoadingReport(false);
      });
  };

  const hasAccessOnlyOwnRegion = hasAccess(['APPLICATIONS.REPORT']) && !hasAccess(['APPLICATIONS.REPORT.ALLREGIONS']);

  return (
    <Modal visible={visible} footer={false} onCancel={handleCloseModal} width="480px" bodyStyle={{ width: '450px' }}>
      <Spin spinning={isLoadingReport}>
        <Form
          form={reportForm}
          onFinish={onFinish}
          initialValues={hasAccessOnlyOwnRegion ? { regionIds: [meUserFull?.regionId] } : {}}
        >
          <Form.Item
            name="type"
            label="Выгрузка по"
            labelCol={{ span: 9 }}
            labelAlign="left"
            rules={[{ required: true, message: '' }]}
          >
            <Select placeholder="Выберите из списка" allowClear={false} style={{ width: 216 }}>
              {[
                { id: 1, name: 'Дата создания' },
                { id: 2, name: 'Дата последнего изменения' },
              ].map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            dependencies={['type']}
            className="form-item-dependencies"
            rules={[{ required: true, message: '' }]}
          >
            {({ getFieldValue }) => {
              const type = getFieldValue('type');
              return (
                <Form.Item
                  name="range"
                  label="Период выгрузки"
                  labelCol={{ span: 9 }}
                  labelAlign="left"
                  rules={[{ required: true, message: '' }]}
                >
                  <DatePicker.RangePicker
                    allowClear
                    format={displayFormat}
                    placeholder={['с', 'по']}
                    disabledDate={(date) => date.isAfter(moment())}
                    defaultPickerValue={[moment().subtract(1, 'month'), moment()]}
                    disabled={!type}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item
            name="regionIds"
            label="Субъект"
            rules={[{ required: true, message: '' }]}
            labelCol={{ span: 9 }}
            labelAlign="left"
          >
            <MultipleSelect
              placeholder="Выберите из списка"
              allowClear
              allValues={region.map(({ id }) => id)}
              maxTagCount={1}
              maxTagTextLength={11}
              disabled={!hasAccess(['APPLICATIONS.REPORT.ALLREGIONS'])}
              showSearch
              showArrow
              filterOption={selectFilterOption}
              style={{ width: 216 }}
              onChange={onSelectRegionId}
            >
              {region.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </MultipleSelect>
          </Form.Item>

          <Form.Item
            dependencies={['appStatusIds']}
            className="form-item-dependencies"
            rules={[{ required: true, message: '' }]}
          >
            {({ getFieldValue }) => {
              const appStatusIds = getFieldValue('appStatusIds');
              // Вывод отображения всех выбранных статусов
              const getProjectTitle = () => {
                return appStatusIds?.map((statusId) => appStatus.find((item) => item.id === statusId)?.name).join('\n');
              };

              return (
                <div title={getProjectTitle()}>
                  <Form.Item
                    name="appStatusIds"
                    label="Статус"
                    labelCol={{ span: 9 }}
                    labelAlign="left"
                    rules={[{ required: true, message: '' }]}
                  >
                    <MultipleSelect
                      placeholder="Выберите из списка"
                      allowClear
                      allValues={appStatus.map(({ id }) => id)}
                      maxTagCount={1}
                      maxTagTextLength={11}
                      disabled={appStatus.length === 0}
                      loading={checkIsLoading(appStatusLoading)}
                      showSearch
                      showArrow
                      filterOption={selectFilterOption}
                      style={{ width: 216 }}
                    >
                      {Array.isArray(appStatus) &&
                        appStatus.map(({ id, name }) => (
                          <Select.Option key={id} value={id}>
                            {name}
                          </Select.Option>
                        ))}
                    </MultipleSelect>
                  </Form.Item>
                </div>
              );
            }}
          </Form.Item>

          <Form.Item
            dependencies={['regionIds']}
            className="form-item-dependencies"
            rules={[{ required: true, message: '' }]}
          >
            {({ getFieldValue }) => {
              const regionIds = getFieldValue('regionIds');
              const projectIds = getFieldValue('projectIds');
              console.table({ regionIds, projectIds });
              // Вывод отображения всех выбранных проектов
              const getProjectTitle = () => {
                if (!regionIds || regionIds?.length === 0) return 'Необходимо выбрать субъект РФ';
                if (!Array.isArray(projects)) return 'Нет доступных проектов';
                return projectIds?.map((projectId) => projects.find((item) => item.id === projectId)?.title).join('\n');
              };

              const getPlaceholder = () => {
                if (!regionIds || regionIds?.length === 0) return 'Необходимо выбрать субъект РФ';
                if (!Array.isArray(projects)) return 'Нет доступных проектов';
                if (!projectIds?.length) return 'Выберите из списка';
                return '';
              };

              const filteredProjects = projects?.filter(
                (project) => regionIds && regionIds.length > 0 && regionIds?.some((id) => id === project.regionId)
              );

              return (
                <div title={getProjectTitle()}>
                  <Form.Item
                    name="projectIds"
                    label="Проект"
                    labelCol={{ span: 9 }}
                    labelAlign="left"
                    rules={[{ required: true, message: '' }]}
                  >
                    <MultipleSelect
                      placeholder={getPlaceholder()}
                      allowClear
                      allValues={filteredProjects.map(({ id }) => id)}
                      maxTagCount={1}
                      maxTagTextLength={11}
                      disabled={
                        !regionIds || regionIds?.length === 0 || projects.length === 0 || filteredProjects.length === 0
                      }
                      loading={checkIsLoading(projectsLoading)}
                      showSearch
                      showArrow
                      filterOption={selectFilterOption}
                      style={{ width: 216 }}
                    >
                      {Array.isArray(projects) &&
                        filteredProjects.map(({ id, title }) => (
                          <Select.Option key={id} value={id}>
                            {title}
                          </Select.Option>
                        ))}
                    </MultipleSelect>
                  </Form.Item>
                </div>
              );
            }}
          </Form.Item>

          <Row justify="center" gutter={20} className="mt10">
            <Col>
              <Button onClick={handleCloseModal}>Отмена</Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">
                Сформировать
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};
