import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';
import { CreateApplication } from '../../components/CreateApplication/CreateApplication';
import {
  CreateApplicationContainerContext,
  HandleCreateApplication,
  HandleSearchPerson,
  LoadFullPersonData,
} from './CreateApplicationContainerContext';
import { createApplicationRoute } from '../../lib/routes/applications';
import { fullPersonDataRoute, searchPersonRoute } from '../../lib/routes/persons';
import { useDictionaries } from '../../lib/utils/useDictionaries';
import { checkIsLoaded } from '../../lib/utils/checkLoadingStatus';
import { useAppSelector } from '../../store/hooks';
import { useMainContext } from '../MainContainer/MainContext';

interface IProps {}

export const CreateApplicationContainer: FC<IProps> = (props) => {
  const navigate = useNavigate();
  const { loadDictionaries } = useDictionaries();
  const { projectsLoading } = useAppSelector((state) => state.projects);
  const { loadProjects } = useMainContext();

  // Загрузка словарей
  useEffect(() => {
    loadDictionaries([
      'app_subject',
      'person_category',
      'region',
      'regularity_of_assistance',
      'subway_station',
      'phone_type',
      'app_type',
    ]);
    if (!checkIsLoaded(projectsLoading)) loadProjects();
  }, []);

  const handleSearchPerson: HandleSearchPerson = async (searchOptions) => {
    const url = searchPersonRoute(searchOptions);
    const { data } = await axios.get(url);
    return data.content;
  };

  const loadFullPersonData: LoadFullPersonData = async (personId) => {
    const url = fullPersonDataRoute(personId);
    const { data } = await axios.get(url);
    return data;
  };

  const handleCreateApplication: HandleCreateApplication = (application) => {
    const isUserExist = Boolean(application.person?.id); // если передается id, значит пользователь существует и новая анкета не создается
    const url = createApplicationRoute();
    message.info('Создание просьбы');
    return axios
      .post(url, application)
      .then(({ data }) => {
        message.destroy();
        message.success({
          content: (
            <>
              <div className="font-roboto">Просьба успешно создана</div>
              {!isUserExist && <div className="font-roboto">Анкета успешно добавлена</div>}
            </>
          ),
          duration: 3,
          onClose: () => navigate(`/applications/list/${data.id}`),
        });
        return data;
      })
      .catch(() => {
        message.destroy();
        message.error('Ошибка создания просьбы', 3);
        throw new Error('Ошибка создания просьбы');
      });
    // TODO Eugene. Добавить вызов метода загрузки просьб
  };

  return (
    <CreateApplicationContainerContext.Provider
      value={{
        handleCreateApplication,
        handleSearchPerson,
        loadFullPersonData,
      }}
    >
      <CreateApplication />
    </CreateApplicationContainerContext.Provider>
  );
};
