import buildUrl from '../utils/buildUrl';
import { baseUrl } from './baseUrl';

export const getSubscriptionsRoute = (userId) =>
  buildUrl({
    url: `${baseUrl}/subscriptions/subscription/search?userId=${userId}&notificationTypeId=15`,
  });

export const updateSubscriptionsRoute = () =>
  buildUrl({
    url: `${baseUrl}/subscriptions/subscription/save-subscription-settings`,
  });
