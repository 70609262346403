import { Button, Col, Divider, Form, Input, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { FC } from 'react';
import { useAdministrationDictionariesContext } from '../../containers/AdministrationDictionariesContainer/AdministrationDictionariesContext';
import { useParams } from 'react-router';
import moment from 'moment';

interface IProps {
  closeModal: () => void;
  open: boolean;
  maxIdTermin: number;
  checkNameDicTypesTermin: (props: { name: string }) => boolean;
  errorDicTypesTerminNameModal: () => void;
}

export const CreateDictionariesTerminModal: FC<IProps> = ({
  open,
  closeModal,
  maxIdTermin,
  checkNameDicTypesTermin,
  errorDicTypesTerminNameModal,
}) => {
  const { createDictionariesTypeTermin } = useAdministrationDictionariesContext();
  const dicTypeId = useParams().dicTypeId;

  const [createDictionariesTerminForm] = useForm();

  const close = () => {
    closeModal();
    createDictionariesTerminForm.resetFields();
  };

  const onFinish = ({ name }) => {
    if (dicTypeId && maxIdTermin) {
      createDictionariesTypeTermin({ dicTypeId, fromDate: moment().format(), name, id: maxIdTermin + 1 }).then(() => {
        close();
      });
    }
  };

  const onFinishFailed = (val) => {
    if (!val.values.name) {
      return Modal.error({
        title: (
          <>
            <h2 className="mb0 text-bold">Ошибка в названии термина</h2>
            <Divider className="thin-divider mt0" />
          </>
        ),
        content: <div>Пожалуйста, заполните обязательные поля.</div>,
        bodyStyle: { width: '460px', backgroundColor: '#D0D8DA' },
      });
    }
    if (val.values.name) {
      return errorDicTypesTerminNameModal();
    }
  };

  return (
    <Modal open={open} width="690px" onCancel={close} footer={false} bodyStyle={{ backgroundColor: '#F4F4F4' }}>
      <h2 className="mb0 text-bold">Добавление нового значения справочника</h2>
      <Divider className="thin-divider mt0" />
      <Form form={createDictionariesTerminForm} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item
          label="Значение справочника"
          name="name"
          labelCol={{ span: 24 }}
          rules={[
            { required: true, message: '' },
            {
              validator(rule, value, callback) {
                if (checkNameDicTypesTermin({ name: value })) {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
              message: '',
            },
          ]}
        >
          <Input placeholder="Введите значение" style={{ width: '100%' }} />
        </Form.Item>
        <Row justify="center" gutter={20}>
          <Col>
            <Button onClick={close}>Отмена</Button>
          </Col>
          <Col>
            <Button
              type="primary"
              style={{ width: 216 }}
              onClick={() => {
                // deleteProject(projectId).then(closeModal);
                createDictionariesTerminForm.submit();
              }}
            >
              Сохранить
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
