import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Tooltip } from 'antd';
import { useMainContext } from '../../containers/MainContainer/MainContext';
import { AdministrationUserMenuItem } from '../../lib/interfaces/Administration';
import { ReactComponent as BackArrow } from '../../lib/img/back-arrow.svg';
import { useAdministrationContext } from '../../containers/AdministrationContainer/AdministrationContext';
import './Administration.less';

type HasAccessToSection = (menuItem: AdministrationUserMenuItem) => boolean;

interface IProps {
  personId?: string;
}

export const AdministrationPersonNavbar: FC<IProps> = ({ personId }) => {
  const navigate = useNavigate();
  const { hasAccess } = useMainContext();
  const { saveCurrentLocation } = useAdministrationContext();

  // Доступ к разделам карточки
  const hasAccessToSection: HasAccessToSection = ({ route }) => {
    switch (route) {
      case 'profile':
      case 'roles':
      case 'permissionsadd':
      case 'permissionsblock':
        return hasAccess(['ADMINISTRATION.READ']);
    }
  };

  const menuFields: AdministrationUserMenuItem[] = [
    { name: 'Профиль', route: 'profile', size: 88 },
    { name: 'Назначение/Отмена ролей', route: 'roles', size: 205 },
    {
      name: 'Назначение/Отмена привилегий',
      route: 'permissionsadd',
      size: 245,
    },
    { name: 'Блокировка привилегий', route: 'permissionsblock', size: 192 },
  ];

  const menu = (
    <div className="administration-navbar-menu">
      {menuFields
        .filter((menuItem) => hasAccessToSection(menuItem))
        .map(({ route, name, size }) => {
          const width = `${size}px`;
          const path = `/administration/persons/${personId}/${route}`;
          const onClick = () => {
            navigate(path);
            saveCurrentLocation(`persons/${personId}/${route}`);
          };
          return (
            <div
              key={route}
              onClick={onClick}
              className="administration-navbar-menu-item"
              style={
                path === window.location.pathname
                  ? {
                      width,
                      backgroundColor: 'white',
                      fontWeight: 'bold',
                    }
                  : { width }
              }
            >
              {name}
            </div>
          );
        })}
    </div>
  );

  return (
    <div className="administration-navbar">
      <div className="display-flex">
        <Tooltip title="К списку пользователей">
          <BackArrow
            className="ml6 mt8 mr10 cursor-pointer"
            onClick={() => {
              navigate('/administration/persons');
              saveCurrentLocation('persons');
            }}
          />
        </Tooltip>
        <h2 className="text-bold mb5">Параметры пользователя</h2>
      </div>
      <div style={{ width: '453px' }}>
        <Divider className="thin-divider mt0 mb10 ml34" />
      </div>
      {menu}
    </div>
  );
};
