import { configureStore } from '@reduxjs/toolkit';
import testReducer from './slices/testSlice/testSlice';
import { reducer as projectsReducer } from './slices/projectsSlice/projectsSlice';
import { reducer as personsSlice } from './slices/personsSlice/personsSlice';
import { reducer as dictionariesReducer } from './slices/dictionariesSlice/dictionariesSlice';
import { reducer as applicationsSlice } from './slices/applicationsSlice/applicationsSlice';
import { reducer as notificationsSlice } from './slices/notificationsSlice/notificationsSlice';
import { reducer as rootSlice } from './slices/rootSlice/rootSlice';
import { reducer as rolesSlice } from './slices/rolesSlice/rolesSlice';
import { reducer as administrationDictionariesSlice } from './slices/administrationDictionariesSlice/administrationDictionariesSlice';
import { reducer as administrationSlice } from './slices/administrationSlice/administrationSlice';
import { reducer as subscriptionsSlice } from './slices/subscriptionsSlice/subscriptionsSlice';
import { reducer as sectionsSlice } from './slices/sectionsSlice/sectionsSlice';
import { reducer as configSlice } from './slices/configSlice/configSlice';

export const store = configureStore({
  reducer: {
    test: testReducer,
    dictionaries: dictionariesReducer,
    projects: projectsReducer,
    administrationDictionaries: administrationDictionariesSlice,
    persons: personsSlice,
    applications: applicationsSlice,
    administration: administrationSlice,
    notifications: notificationsSlice,
    root: rootSlice,
    subscriptions: subscriptionsSlice,
    roles: rolesSlice,
    sections: sectionsSlice,
    config: configSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
