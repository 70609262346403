import React, { MutableRefObject, useEffect, useState } from 'react';
import { YMaps, Map as YMap, Placemark, Clusterer } from '@pbe/react-yandex-maps';
import { useAppSelector } from '../../store/hooks';
import './Map.less';
import { Spin } from 'antd';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { createRoot } from 'react-dom/client';
import { flushSync } from 'react-dom';
import { Application } from '../../lib/interfaces/Application';
import { useMainContext } from '../../containers/MainContainer/MainContext';

interface ApplicationFormat extends Application {
  iconImageOffset: number[];
}

function Map() {
  const { applicationsList, applicationsListLoading } = useAppSelector((state) => state.applications);

  const { appStatus, appSubject } = useAppSelector((state) => state.dictionaries);

  const { findDynamicSVGReactElement, importSVGIconStringForImg } = useMainContext();

  const formatApplicationsList: ApplicationFormat[] = [];

  applicationsList.content.forEach((el) => {
    const findDupplicatedPoints = [...formatApplicationsList]
      .reverse()
      .find(
        (elFormat) =>
          elFormat.address?.coordinates?.point.latitude === el.address?.coordinates?.point.latitude &&
          elFormat.address?.coordinates?.point.longitude === el.address?.coordinates?.point.longitude
      );
    formatApplicationsList.push({
      ...el,
      iconImageOffset: !findDupplicatedPoints
        ? [-15, 0]
        : [Number(findDupplicatedPoints.iconImageOffset[0]) + 5, Number(findDupplicatedPoints.iconImageOffset[1]) - 15],
    });
  });

  const linkHeader = (path?: string, appText?: string, subjectId?: number): JSX.Element => {
    return (
      <a
        data-subjectId={subjectId}
        className="NotCustomYandexHeaderLink ml10"
        style={{ textDecoration: 'underline' }}
        href={path}
      >
        {appText}
      </a>
    );
  };

  const [instanceRef, setInstanceRef] = useState<any>();

  const onOpenNewTab = (path) => {
    window.open(path, '_blank');
  };

  // При клике на балун не кластера, открывать новую вкладку с просьбой
  const handleMainContentClick = (path) => () => onOpenNewTab(path);

  // При клике на балун правый кластера, открывать новую вкладку с просьбой
  const handleMainContentClusterClick = () => {
    const findHeaderNavClusterItem = document.querySelector('.ymaps-2-1-79-b-cluster-tabs__section_type_content');
    const findHeaderLinkPath = findHeaderNavClusterItem?.querySelector('a')?.pathname;
    onOpenNewTab(findHeaderLinkPath);
  };

  // Добавляем обработчик клика на балун который открывается в кластере
  const handleHeaderNavItemClick = () => {
    const findHeaderNavClusterItem = document.querySelector('.ymaps-2-1-79-b-cluster-tabs__section_type_content');

    findHeaderNavClusterItem?.removeEventListener('click', handleMainContentClusterClick);
    findHeaderNavClusterItem?.addEventListener('click', handleMainContentClusterClick);
  };

  // Добавляем обработчик клика на каждый элемент в списке меток кластера, чтобы при клике на item, вешать обработчик на весь контент балуна с права
  const addHeaderNavEventListeners = () => {
    const findHeaderNavClusterItems = document.querySelectorAll('.ymaps-2-1-79-b-cluster-tabs__menu-item');

    findHeaderNavClusterItems.forEach((headerNavItem) => {
      headerNavItem?.removeEventListener('click', handleHeaderNavItemClick);
      headerNavItem?.addEventListener('click', handleHeaderNavItemClick);
    });
  };

  // Добавляем обработчик клика на балун который открывается не в кластере
  const addMainContentEventListeners = () => {
    const findMainContent = document.querySelectorAll('.ymaps-2-1-79-balloon-content__header');

    findMainContent.forEach((mainContent) => {
      const findHeaderLink = mainContent.querySelector('a');
      const path = findHeaderLink?.pathname;

      mainContent.parentElement?.addEventListener('click', handleMainContentClick(path));
    });
  };

  useEffect(() => {
    if (instanceRef) {
      instanceRef?.events.add('balloonopen', (e) => {
        const findHeaders = document.querySelectorAll('.NotCustomYandexHeader');

        if (findHeaders.length > 0) {
          findHeaders.forEach((header) => {
            const findHeaderLink = header.querySelector('a');

            const path = findHeaderLink?.pathname;
            const appText = findHeaderLink?.innerText;
            const subjectId = Number(findHeaderLink?.dataset?.subjectid);

            const rootHeader = createRoot(header);

            flushSync(() => {
              rootHeader.render(
                <>
                  <div
                    className="display-flex"
                    style={{ minWidth: 25, maxWidth: 25, justifyContent: 'center', alignItems: 'center' }}
                  >
                    {findDynamicSVGReactElement('app_subject', 'help', 'iconName', subjectId)}
                  </div>

                  {linkHeader(path, appText, subjectId)}
                </>
              );
            });
          });
        }

        addHeaderNavEventListeners();
        addMainContentEventListeners();
      });
    }
  }, [instanceRef]);

  const subjectFindName = (subjectId) => appSubject.find((status) => status.id === subjectId)?.name;

  const getPointData = (app) => {
    return {
      balloonContentHeader: `
      <div class="NotCustomYandexHeader" style="display:flex">
        <a data-subjectId="${app.subjectId}" className="NotCustomYandexHeaderLink ml10" href=/applications/list/${
        app.id
      }/main style="text-decoration: underline">${subjectFindName(app?.subjectId)}</a>
      </div>
        `,

      balloonContentBody: `<div class="my-ballon__body">${app?.address.unstructuredAddress}</div>`,
      balloonContentFooter: `<div style="font-weight: bold; color: black">${
        appStatus.find((status) => status.id === app.statusId)?.name
      }</div>`,
    };
  };

  const [mapRef, setMapRef] = useState<ymaps.Map>();
  const [placemarkRef, setPlacemarkRef] = useState<ymaps.Map>();

  useEffect(() => {
    if (!mapRef || !placemarkRef) {
      return;
    }

    mapRef?.events.add('click', () => {
      mapRef?.balloon.close();
    });
  }, [mapRef, placemarkRef]);

  return (
    <Spin spinning={checkIsLoading(applicationsListLoading)}>
      <YMaps>
        <YMap
          defaultState={{
            center: [55.751574, 37.573856],
            zoom: 11,
          }}
          height="80vh"
          width="98vw"
          // modules={["package.full"]}
          modules={['geoObject.addon.balloon', 'package.full']}
          instanceRef={setMapRef}
        >
          <Clusterer
            options={{
              clusterIconLayout: 'default#pieChart',
              clusterIconPieChartRadius: 12,
              clusterIconPieChartCoreRadius: 10,
              clusterIconPieChartStrokeWidth: 1,
              clusterIconColor: 'red',
              clusterDisableClickZoom: true,
              clusterHideIconOnBalloonOpen: false,
              geoObjectHideIconOnBalloonOpen: false,
              // minClusterSize: 20,
              maxZoom: 17,
            }}
            instanceRef={setInstanceRef}
          >
            {formatApplicationsList.map((app) => {
              if (app.address && app.address.coordinates) {
                return (
                  <Placemark
                    key={app.id}
                    geometry={[app.address.coordinates.point.latitude, app.address.coordinates.point.longitude]}
                    properties={getPointData(app)}
                    options={{
                      iconLayout: 'default#image',
                      iconImageHref: importSVGIconStringForImg('app_subject', 'help_map', 'mapIconName', app.subjectId),
                      // iconImageSize: [30, 42],
                      iconImageOffset: app.iconImageOffset,
                      // balloonMinHeight: 143,
                      balloonMinWidth: 343,
                    }}
                    numbers={[10, 100]}
                    instanceRef={setPlacemarkRef}
                  />
                );
              }
              return null;
            })}
          </Clusterer>
        </YMap>
      </YMaps>
    </Spin>
  );
}

export default Map;
