import { ApplicationLifeCycleActionName } from '../interfaces/ApplicationLifeCycleActionName';
import { SearchParams } from '../interfaces/SearchParams';
import buildUrl from '../utils/buildUrl';
import { baseUrl } from './baseUrl';

export const createApplicationRoute = (): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/applications`,
  });

export const searchApplicationsListRoute = ({ page = 0, size = 20, sort = 'asc' }: SearchParams): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/applications/search`,
    searchParams: { page, size, sort },
  });

export const fetchApplicationRoute = ({ applicationId }: { applicationId: string }): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/applications/${applicationId}/card`,
  });

export const fetchApplicationFilesListRoute = ({ applicationId }: { applicationId: string }): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/applications/${applicationId}/files`,
  });

export const downloadApplicationFileRoute = ({
  applicationId,
  fileName,
}: {
  applicationId: string;
  fileName: string;
}): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/applications/${applicationId}/files/${fileName}`,
  });

export const deleteApplicationFileRoute = ({
  applicationId,
  fileName,
}: {
  applicationId: string;
  fileName: string;
}): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/applications/${applicationId}/file/${fileName}`,
  });

export const uploadApplicationFileRoute = ({ applicationId }: { applicationId: string }): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/applications/${applicationId}/files`,
  });

export const fetchApplicationCommentsRoute = ({ applicationId }: { applicationId: string }): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/applications/${applicationId}/comments`,
  });

export const fetchExecutorsRoute = ({ applicationId }: { applicationId: string }): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/applications/${applicationId}/executors`,
  });

export const fetchApplicationHistoryRoute = ({ applicationId }: { applicationId: string }): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/applications/${applicationId}/history`,
  });

export const fetchApplicationResponseHistoryRoute = ({ applicationId }: { applicationId: string }): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/applications/${applicationId}/response-history`,
  });

export const saveExecutorsRoute = ({ applicationId }: { applicationId: string }): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/applications/${applicationId}/executors`,
  });

export const lifeCycleApplicationRoute = ({
  applicationId,
  actionName,
}: {
  applicationId: string;
  actionName: ApplicationLifeCycleActionName;
}): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/applications/${applicationId}/${actionName}`,
  });

export const updateApplicationRoute = ({ applicationId }: { applicationId: string }): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/applications/${applicationId}`,
  });

export const createCommentRoute = ({ applicationId }: { applicationId: string }): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/comments/APPLICATION/${applicationId}`,
  });

export const geocodingRoute = ({ address }: { address: string }) =>
  buildUrl({
    url: `${baseUrl}/applications/geocoding`,
    searchParams: { address },
  });

export const applicationsReportRoute = () =>
  buildUrl({
    url: `${baseUrl}/applications/v1/report`,
  });

export const applicationsFavoritesChangeRoute = ({ applicationId }: { applicationId: string }) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/applications/${applicationId}/like`,
  });

export const fetchApplicationIconsRoute = ({ dicType }: { dicType: string }): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/icons`,
    searchParams: { dicType },
  });

export const cardsReportRoute = () =>
  buildUrl({
    url: `${baseUrl}/applications/v1/report/persons`,
  });
