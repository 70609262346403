import { baseUrl } from '../routes/baseUrl';
import { BASE_URL } from '../const/urls';

const featuresConfig = {
  roleSystem: (() => {
    switch (baseUrl) {
      case BASE_URL:
        return true;
      default:
        return true;
    }
  })(),
};

export default featuresConfig;
