import React from 'react';

export type CreateDictionariesTypeTermin = (props: {
  id: number;
  fromDate: string;
  dicTypeId: string;
  name: string;
}) => Promise<any>;

export type UpdateDictionariesTypeTermin = (editBody: {
  id: number;
  fromDate: string;
  dicTypeId: string;
  name: string;
  abbrev: string;
  parentDicId: number;
  dsc: string;
  alias: string;
}) => Promise<any>;

export type DeleteDictionariesTypeTermin = (deleteBody: {
  id: number;
  fromDate: string;
  dicTypeId: string;
  name: string;
  abbrev: string;
  parentDicId: number;
  dsc: string;
  alias: string;
}) => Promise<any>;

export interface IAdministrationDictionariesContext {
  createDictionariesTypeTermin: CreateDictionariesTypeTermin;
  updateDictionariesTypeTermin: UpdateDictionariesTypeTermin;
  deleteDictionariesTypeTermin: DeleteDictionariesTypeTermin;
}

export const AdministrationDictionariesContext = React.createContext<IAdministrationDictionariesContext | null>(null);

export const useAdministrationDictionariesContext = () => {
  const context = React.useContext(AdministrationDictionariesContext);
  if (context === null) {
    throw new Error('useContext must be inside a Provider with a value');
  }
  return context;
};
