import React from 'react';
import { Button, message, Space } from 'antd';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import './FileCard.less';
import axios from 'axios';
import { archivePersonRoute } from '../../lib/routes/persons';
import moment from 'moment';
import { useForm } from 'antd/es/form/Form';
import UserForm from '../UserForm/UserForm';
import { useParams } from 'react-router-dom';
import { getPersonFullCard } from '../../store/slices/personsSlice/personsSlice';
import usePermissions from '../../lib/utils/usePermissions';
import {
  PERSON_READ_ALLREGIONS,
  PERSON_READ_FULLACCESS,
  PERSON_WRITE,
  PERSON_WRITE_ALLREGIONS,
} from '../../lib/const/permissions';
import FilesList from '../FilesList/FilesList';

interface Props {
  isEditMode: boolean;
  isNewWard?: boolean;
  setIsEditMode: (mode: boolean) => void;
  editPerson: (fields) => void;
  image: any;
  handleNewImage: (file, route) => void;
}

function FileCard(props: Props) {
  const { setIsEditMode, editPerson, isEditMode, image, handleNewImage } = props;
  const { personFullCard } = useAppSelector((state) => state.persons);
  const dispatch = useAppDispatch();
  const { fileId } = useParams();
  const [hasAccess] = usePermissions();
  const isArchived = personFullCard?.personStatusId === 5;

  const [form] = useForm();

  const initialValues = {
    ...personFullCard,
    ...personFullCard?.education,
    ...personFullCard?.religion,
    ...personFullCard?.health,
    ...personFullCard?.address,
    ...personFullCard?.social,
    ...personFullCard?.official,
    noMiddleName: !personFullCard?.middleName || personFullCard?.middleName === '',
    religion: personFullCard?.religion?.religion,
    birthDate: personFullCard?.birthDate ? moment(personFullCard.birthDate) : undefined,
    deathDate: personFullCard?.deathDate ? moment(personFullCard.deathDate) : undefined,
    interviewDate: personFullCard?.official?.interviewDate ? moment(personFullCard.official.interviewDate) : undefined,
    trainingDate: personFullCard?.official?.trainingDate ? moment(personFullCard.official.trainingDate) : undefined,
  };

  const handleArchive = () => {
    axios
      .patch(archivePersonRoute(personFullCard?.id))
      .then(() => {
        message.success('Перенесен в архив');
      })
      .then(() => {
        if (fileId) {
          dispatch(getPersonFullCard({ id: Number(fileId) }));
        }
      });
  };

  const renderButtons = () => {
    if (isArchived) return null;
    if (isEditMode) {
      return (
        <Space className="mt20 mb20">
          <Button
            onClick={() => {
              setIsEditMode(false);
              form.resetFields();
            }}
          >
            Отмена
          </Button>
          <Button
            type="primary"
            onClick={() => {
              form
                .validateFields()
                .then(() => {
                  editPerson(form.getFieldsValue());
                })
                .catch((e) => {
                  console.log(e);
                });
            }}
          >
            Сохранить
          </Button>
        </Space>
      );
    }
    if (hasAccess([PERSON_WRITE_ALLREGIONS, PERSON_WRITE])) {
      return (
        <Button className="mt20 mb20" type="primary" onClick={() => setIsEditMode(true)}>
          Редактировать
        </Button>
      );
    }
    return null;
  };

  return (
    <>
      <div className="card ml20 mr20">
        <UserForm
          initialValues={initialValues}
          isEditMode={isEditMode}
          form={form}
          handleArchive={handleArchive}
          image={image}
          handleNewImage={handleNewImage}
        />
        {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && <FilesList />}
        {renderButtons()}
      </div>
    </>
  );
}

export default FileCard;
