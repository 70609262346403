import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import requestStatusCodes from '../../../lib/const/requestStatusCodes';
import { RequestStatuses } from '../../../lib/types/RequestStatuses';
import { RolesResponse, PermissionsResponse } from '../../../lib/interfaces/Roles';
import { permissionsRoute, rolesRoute } from '../../../lib/routes/roles';

interface InitialState {
  roles: RolesResponse | null;
  rolesLoading: RequestStatuses;

  permissions: PermissionsResponse | null;
  permissionsLoading: RequestStatuses;
}

const initialState: InitialState = {
  roles: null,
  rolesLoading: requestStatusCodes.idle,

  permissions: null,
  permissionsLoading: requestStatusCodes.idle,
};

export const getRoles = createAsyncThunk('roles/getRoles', async (searchParams: { page?: number; size: number }) => {
  const response = axios.get(rolesRoute(searchParams));
  const { data } = await response;
  return data;
});

export const getPermissions = createAsyncThunk(
  'roles/getPermissions',
  async (searchParams: { page?: number; size: number }) => {
    const response = axios.get(permissionsRoute(searchParams));
    const { data } = await response;
    return data;
  }
);

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    cleanRoles: (state) => {
      state.roles = null;
      state.rolesLoading = requestStatusCodes.idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getRoles.pending, (state) => {
        state.rolesLoading = requestStatusCodes.pending;
      })
      .addCase(getRoles.rejected, (state) => {
        state.rolesLoading = requestStatusCodes.rejected;
      })
      .addCase(getRoles.fulfilled, (state, { payload }) => {
        state.rolesLoading = requestStatusCodes.fulfilled;
        state.roles = payload;
      })

      .addCase(getPermissions.pending, (state) => {
        state.permissionsLoading = requestStatusCodes.pending;
      })
      .addCase(getPermissions.rejected, (state) => {
        state.permissionsLoading = requestStatusCodes.rejected;
      })
      .addCase(getPermissions.fulfilled, (state, { payload }) => {
        state.permissionsLoading = requestStatusCodes.fulfilled;
        state.permissions = payload;
      });
  },
});

const { reducer, actions } = rolesSlice;
const { cleanRoles } = actions;

export { reducer, cleanRoles };
