import React, { FC, useState, useEffect } from 'react';
import { Button, Col, Divider, Form, Input, Modal, Row, Spin } from 'antd';
import { useMainContext } from '../../containers/MainContainer/MainContext';
import { ProjectParticipantsTableRecord, ProjectTableRecord } from '../../lib/interfaces/Project';
import { useAppSelector } from '../../store/hooks';
import './AdministrationProjects.less';
import { ProjectsTable } from './ProjectsTable';
import { useParams } from 'react-router-dom';
import { useAdministrationProjectsContext } from '../../containers/AdministrationProjectsContainer/AdministrationProjectsContext';
import { getDictionaryItemById, getFio } from '../../lib/utils/applications';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { EditButtonTitle } from '../common/EditButtonTitle';
import { ParticipantsTable } from './ParticipantsTable';
import { CheckAllPersons, OnRow, SearchPerson, ToggleCheckPerson } from './interfaces';
import { AllPersonsTable } from './AllPersonsTable';
import { TransferIcon } from '../../lib/img/TransferIcon';
import { CreateProjectModal } from './CreateProjectModal';
import { DeleteProjectModal } from './DeleteProjectModal';

const tableHeight = 450;

interface IProps {}

export const AdministrationProjects: FC<IProps> = (props) => {
  const projectId = Number(useParams().projectId);
  const { hasAccess, loadProjectById } = useMainContext();
  const {
    saveCurrentProjectId,
    loadProjectParticipants,
    cleanProjectParticipants,
    loadProjectPersonList,
    updateProject,
  } = useAdministrationProjectsContext();

  const [editMode, setEditMode] = useState(false);
  const [activeModal, setActiveModal] = useState<'create' | 'delete' | ''>('');
  const { projects, project, projectLoading } = useAppSelector((state) => state.projects);

  const { currentProjectId, projectParticipants, projectPersonList } = useAppSelector((state) => state.administration);
  const { region, personType } = useAppSelector((state) => state.dictionaries);
  const [searchParticipantsInput, setSearchParticipantsInput] = useState<string>('');
  const [searchPersonListInput, setSearchPersonListInput] = useState<string>('');
  const [projectForm] = Form.useForm();

  // Сохранение id проекта, при первом входе
  useEffect(() => {
    if (projectId && !currentProjectId) {
      saveCurrentProjectId(projectId);
    }
  }, [projectId, currentProjectId]);

  const [projectsTableData, setProjectsTableData] = useState<ProjectTableRecord[]>([]);

  // Заполнение таблицы проектов
  useEffect(() => {
    setProjectsTableData(
      projects
        .map((project, idx) => ({
          ...project,
          checked: projectId === project.id,
          key: project.id,
        }))
        .sort((a, b) => {
          if (a.projectTypeId === b.projectTypeId) {
            return a.title > b.title ? 1 : -1;
          }
          return a.projectTypeId > b.projectTypeId ? 1 : -1;
        })
    );
  }, [projectId, projects]);

  // Загрузка текущего проекта
  useEffect(() => {
    if (projectId) {
      loadProjectById(projectId);
    }
  }, [projectId]);

  // Сброс поиска и режима редактирования при переключении проекта
  useEffect(() => {
    setSearchParticipantsInput('');
    setEditMode(false);
  }, [projectId]);

  // Фиксация имени выбранного проекта для последующего редактирования
  useEffect(() => {
    if (project?.title) {
      projectForm.setFieldsValue({ title: project.title });
    }
  }, [project]);

  // Загрузка данных участников проекта
  useEffect(() => {
    if (project?.participants.length) {
      loadProjectParticipants(project.participants);
    } else {
      cleanProjectParticipants();
    }
  }, [project]);

  const [personTabledata, setPersonTableData] = useState<ProjectParticipantsTableRecord[]>([]);

  const initializePersonTableData = () => {
    const participantIds = projectParticipants.content.map(({ id }) => id);
    const list: ProjectParticipantsTableRecord[] = [
      ...projectParticipants.content,
      ...projectPersonList.content.filter(({ id }) => !participantIds.includes(id)),
    ]
      .map((person) => ({
        id: person.id || 0,
        key: person.id || 0,
        fio: getFio(person),
        checked: false,
        typeName: getDictionaryItemById(personType, person.personTypeId)?.name || '',
        isParticipant: participantIds.includes(person.id),
      }))
      .sort((a, b) => (a.fio > b.fio ? 1 : -1));
    setPersonTableData(list);
  };

  // Заполнение данных участников проекта и всех пользователей
  useEffect(() => {
    initializePersonTableData();
  }, [projectPersonList, projectParticipants, personType]);

  // Загрузка списка всех пользователей
  useEffect(() => {
    if (project?.regionId) {
      loadProjectPersonList(project.regionId);
    }
  }, [project?.regionId]);

  const scroll = {
    y: `${tableHeight - 60}px`,
  };

  const participantsTableData = personTabledata
    .filter(({ isParticipant }) => isParticipant)
    .filter(({ fio }) => fio.toLowerCase().includes(searchParticipantsInput.toLocaleLowerCase().trim()));

  const allPersonsTableData = personTabledata
    .filter(({ isParticipant }) => !isParticipant)
    .filter(({ fio }) => fio.toLowerCase().includes(searchPersonListInput.toLocaleLowerCase().trim()));

  const checkAllParticipants: CheckAllPersons = (checked) => {
    setPersonTableData((prev) =>
      prev.map((person) => {
        const visible = person.fio.toLowerCase().includes(searchParticipantsInput.toLocaleLowerCase().trim());
        return {
          ...person,
          checked: person.isParticipant && visible ? checked : person.checked,
        };
      })
    );
  };

  const toggleCheckParticipant: ToggleCheckPerson = (id) => {
    setPersonTableData((prev) =>
      prev.map((person) => ({
        ...person,
        checked: id === person.id && person.isParticipant ? !person.checked : person.checked,
      }))
    );
  };

  const onRowParticipant: OnRow = ({ id }) => ({
    onClick: () => (editMode ? toggleCheckParticipant(id) : {}),
  });

  const searchParticipants: SearchPerson = (name) => {
    setSearchParticipantsInput(name);
  };

  const checkAllPersons: CheckAllPersons = (checked) => {
    setPersonTableData((prev) =>
      prev.map((person) => {
        const visible = person.fio.toLowerCase().includes(searchPersonListInput.toLocaleLowerCase().trim());
        return {
          ...person,
          checked: !person.isParticipant && visible ? checked : person.checked,
        };
      })
    );
  };

  const toggleCheckPerson: ToggleCheckPerson = (id) => {
    setPersonTableData((prev) =>
      prev.map((person) => ({
        ...person,
        checked: id === person.id && !person.isParticipant ? !person.checked : person.checked,
      }))
    );
  };

  const onRowPerson: OnRow = ({ id }) => ({
    onClick: () => (editMode ? toggleCheckPerson(id) : {}),
  });

  const searchProjectPerson: SearchPerson = (name) => {
    setSearchPersonListInput(name);
  };

  const transferPersons = (from: 'participants' | 'all') => {
    switch (from) {
      case 'participants':
        setPersonTableData((prev) =>
          prev.map((person) =>
            person.isParticipant && person.checked
              ? {
                  ...person,
                  checked: false,
                  isParticipant: false,
                }
              : person
          )
        );
        break;
      case 'all':
        setPersonTableData((prev) =>
          prev.map((person) =>
            !person.isParticipant && person.checked
              ? {
                  ...person,
                  checked: false,
                  isParticipant: true,
                }
              : person
          )
        );
    }
  };

  const checkNameExisting = ({ title, regionId }: { title: string; regionId: number }) => {
    const isNameExists = projects
      .filter((projectItem) => projectItem.regionId === regionId)
      // Если редактирование проекта, игнорирование в исключениях названия текущего проекта
      .filter(({ id }) => (activeModal === '' ? id !== project?.id : true))
      .some((projectItem) => projectItem.title.toLowerCase().trim() === title.toLowerCase().trim());
    return isNameExists;
  };

  const errorProjectNameModal = ({ title, regionId }) =>
    Modal.error({
      title: (
        <>
          <h2 className="mb0 text-bold">Ошибка в названии проекта</h2>
          <Divider className="thin-divider mt0" />
        </>
      ),
      content: (
        <>
          <div>
            {`Проект "${title.trim()}" уже существует в регионе "${getDictionaryItemById(region, regionId)?.name}"`}
          </div>
          <div>Пожалуйста, измените название.</div>
        </>
      ),
      bodyStyle: { width: '850px', backgroundColor: '#D0D8DA' },
    });

  const submitEdit = ({ title }) => {
    const regionId = project?.regionId || 0;
    if (checkNameExisting({ title, regionId })) {
      return errorProjectNameModal({ title, regionId });
    }
    if (!project) return;
    const participants = personTabledata.filter(({ isParticipant }) => isParticipant).map(({ id }) => id);
    updateProject({
      id: project.id,
      regionId: project.regionId,
      title,
      projectTypeId: project.projectTypeId,
      participants,
    }).then(() => {
      loadProjectById(projectId);
      setEditMode(false);
    });
  };

  const projectHeader = (
    <Row gutter={15}>
      <Col span={8}>
        {hasAccess(['ADMINISTRATION.ADDPROJECT']) && (
          <Button className="administration-projects-addBtn" onClick={() => setActiveModal('create')} type="primary">
            Создать проект
          </Button>
        )}
      </Col>
      <Col span={16} className="mt3">
        <Spin spinning={checkIsLoading(projectLoading)}>
          <Row>
            <Col span={15} className="text-bold font-size-18">
              <Form form={projectForm} className="mr20" onFinish={submitEdit}>
                {editMode ? (
                  <Form.Item
                    className="mb0"
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: '',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                ) : (
                  <Form.Item className="mb0">{project?.title}</Form.Item>
                )}
              </Form>
            </Col>
            <Col span={3} style={{ textAlign: 'end' }}>
              <div className="font-size-14 font-roboto mr6 pt5">Субъект РФ:</div>
            </Col>
            <Col span={6}>
              <div className="font-size-14 font-roboto pt5">
                {project && getDictionaryItemById(region, project.regionId)?.name}
              </div>
            </Col>
          </Row>
        </Spin>
        <Divider className="thin-divider mt5" />
      </Col>
    </Row>
  );

  const discardEdit = () => {
    setEditMode(false);
    initializePersonTableData();
    projectForm.resetFields();
  };

  const buttons = (
    <Row className="mt20" justify="end">
      {editMode ? (
        <>
          <Button className="mr20" onClick={discardEdit}>
            Отменить
          </Button>
          <Button type="primary" onClick={projectForm.submit}>
            Сохранить
          </Button>
        </>
      ) : (
        <>
          <Button
            className="mr20"
            onClick={() => {
              setActiveModal('delete');
            }}
          >
            Удалить проект
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setEditMode(true);
            }}
          >
            <EditButtonTitle />
          </Button>
        </>
      )}
    </Row>
  );

  const closeModal = () => {
    setActiveModal('');
  };

  return (
    <div className="administration-projects">
      {projectHeader}
      <Row gutter={15} style={{ height: `${tableHeight}px` }}>
        <Col span={7}>
          <ProjectsTable dataSource={projectsTableData} scroll={scroll} />
        </Col>
        <Col span={8}>
          <ParticipantsTable
            editMode={editMode}
            dataSource={participantsTableData}
            scroll={scroll}
            onRow={onRowParticipant}
            ckeckAll={checkAllParticipants}
            searchParticipants={searchParticipants}
            searchParticipantsInput={searchParticipantsInput}
          />
        </Col>
        <Col span={9}>
          <Row gutter={10}>
            <Col span={2} className="mt193">
              {editMode && (
                <>
                  <TransferIcon
                    side="right"
                    active={participantsTableData.some(({ checked }) => checked)}
                    onClick={() => transferPersons('participants')}
                  />
                  <TransferIcon
                    active={allPersonsTableData.some(({ checked }) => checked)}
                    onClick={() => transferPersons('all')}
                  />
                </>
              )}
            </Col>
            <Col span={22}>
              <AllPersonsTable
                editMode={editMode}
                scroll={scroll}
                dataSource={allPersonsTableData}
                onRow={onRowPerson}
                ckeckAll={checkAllPersons}
                searchProjectPerson={searchProjectPerson}
                searchPersonListInput={searchPersonListInput}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {buttons}
      <CreateProjectModal
        closeModal={closeModal}
        open={activeModal === 'create'}
        checkNameExisting={checkNameExisting}
        errorModal={errorProjectNameModal}
      />
      <DeleteProjectModal
        closeModal={closeModal}
        open={activeModal === 'delete'}
        selectedProjectName={project?.title || ''}
        projectId={projectId}
      />
    </div>
  );
};
