import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import usePermissions from '../../lib/utils/usePermissions';
import {
  PERSON_READ,
  PERSON_READ_FULLACCESS,
  PERSON_READ_HISTORY,
  PERSON_READ_MYCOMMENTS,
  PERSON_READ_PROJECTS,
} from '../../lib/const/permissions';

function FileTabs(props) {
  const { fileId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [hasAccess] = usePermissions();

  const menuFields = [
    { name: 'Основное', route: 'general', size: 89, hasAccess: hasAccess([PERSON_READ_FULLACCESS, PERSON_READ]) },
    {
      name: 'Комментарии',
      route: 'comments',
      size: 108,
      hasAccess: hasAccess([PERSON_READ_FULLACCESS, PERSON_READ_MYCOMMENTS]),
    },
    {
      name: 'Участие',
      route: 'projects',
      size: 89,
      hasAccess: hasAccess([PERSON_READ_FULLACCESS, PERSON_READ_PROJECTS]),
    },
    {
      name: 'История изменений',
      route: 'history',
      size: 156,
      hasAccess: hasAccess([PERSON_READ_FULLACCESS, PERSON_READ_HISTORY]),
    },
  ];

  return (
    <div className="application-navbar-menu">
      {menuFields.map(({ route, name, size, hasAccess }) => {
        if (hasAccess) {
          const width = `${size}px`;
          const path = `/files/${fileId}/${route}`;
          const onClick = () => {
            navigate(path);
          };
          return (
            <div
              key={route}
              onClick={onClick}
              className="application-navbar-menu-item"
              style={
                path === location.pathname
                  ? {
                      width,
                      backgroundColor: 'white',
                      fontWeight: 'bold',
                    }
                  : { width }
              }
            >
              {name}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

export default FileTabs;
