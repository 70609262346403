import React, { FC, useEffect, useState } from 'react';
import { YMaps, Map } from '@pbe/react-yandex-maps';
import { Input, Form, Modal } from 'antd';
import axios from 'axios';
import { geocodingRoute } from '../../lib/routes/applications';
import { baseApiKey } from '../../lib/routes/baseApiKey';
import { uniqueId } from 'lodash';

interface IProps {
  name: string;
  index?: number | string;
  style?: { [key: string]: string } | {};
  onChange?: (value: string) => void;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  disableOnBlur?: boolean;
  isCheckedSelectOnFront?: boolean;
  refValueUnstructuredAddress?: React.MutableRefObject<string>;
}

const SuggestInput: FC<IProps> = ({
  name,
  index,
  onChange,
  style = {},
  disabled,
  required = false,
  placeholder,
  disableOnBlur = false,
  isCheckedSelectOnFront = false,
  refValueUnstructuredAddress,
}) => {
  let loadSuggest: any;

  const [unicId, setUnicId] = useState(uniqueId());

  useEffect(() => {
    setUnicId(uniqueId());
  }, [index]);

  return (
    <>
      <Form.Item
        shouldUpdate
        className="phones-table form-item-dependencies"
        wrapperCol={{ span: 24 }}
        required={required}
      >
        {(form) => {
          const checkAddress = async (address: string) => {
            // Проверка адреса на корректность
            const checkAddressResult: { success: boolean; message?: string } = await axios
              .get(geocodingRoute({ address }))
              .then(() => ({
                success: true,
              }))
              .catch(({ response }) => ({
                success: false,
                message: response.data.message,
              }));

            if (!checkAddressResult.success) {
              form.setFields([{ name, errors: [''], validating: false }]);
              Modal.error({
                content: (
                  <>
                    <div>{checkAddressResult.message?.replace('. Уточните запрос', '')}</div>
                    <div
                      style={{
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      Пожалуйста, выберите адрес из выпадающего списка
                    </div>
                  </>
                ),
                bodyStyle: {
                  width: '600px',
                  backgroundColor: '#F4F4F4',
                },
              });
            }
          };

          loadSuggest = (ymaps) => {
            const map = new ymaps.SuggestView(index !== undefined ? `suggest_${unicId}` : 'suggest');
            map.events.add('select', (e) => {
              const selectedAddress = e.originalEvent.item.value;
              if (index !== undefined) {
                form.setFields([{ name: ['additionalAddress', index, name], value: selectedAddress }]);
              } else {
                form.setFieldsValue({ [name]: selectedAddress });
              }
              !disableOnBlur && checkAddress(selectedAddress);
              onChange && onChange(selectedAddress);
              if (refValueUnstructuredAddress) {
                refValueUnstructuredAddress.current = selectedAddress;
              }
            });
          };

          return (
            <Form.Item
              name={index !== undefined ? [index, name] : name}
              label="Адрес"
              rules={[{ required, message: '' }]}
              style={{ width: '100%', ...style }}
            >
              <Input
                style={{ width: '100%' }}
                type="text"
                className="form-control"
                allowClear
                id={index !== undefined ? `suggest_${unicId}` : 'suggest'}
                placeholder={placeholder}
                onPressEnter={(e) => e.preventDefault()}
                onBlur={(e) => {
                  if (isCheckedSelectOnFront) {
                    setTimeout(() => {
                      if (
                        refValueUnstructuredAddress &&
                        refValueUnstructuredAddress.current.trim() !== e.target.value.trim()
                      ) {
                        form.setFieldsValue({ [name]: '' });
                        form.validateFields([name]);
                      }
                    }, 100);
                  }
                }}
                onChange={(e) => {
                  if (onChange) onChange(e.target.value);
                }}
                disabled={disabled}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      <YMaps query={{ suggest_apikey: baseApiKey }}>
        <div style={{ display: 'none' }}>
          <Map
            key={unicId}
            onLoad={(ymaps) => unicId && loadSuggest(ymaps)}
            defaultState={{
              center: [55.751574, 37.573856],
              zoom: 9,
            }}
            modules={['SuggestView']}
          />
        </div>
      </YMaps>
    </>
  );
};

export default SuggestInput;
