import React from 'react';
import { Col, Form, Row } from 'antd';
import GeneralBlock from '../FileCard/GeneralBlock';
import PersonalInfoBlock from '../FileCard/PersonalInfoBlock';
import PersonContactsBlock from '../FileCard/PersonContactsBlock';
import WorkEducationBlock from '../FileCard/WorkEducationBlock';
import ReligionBlock from '../FileCard/ReligionBlock';
import HealthBlock from '../FileCard/HealthBlock';
import ServiceInformationBlock from '../FileCard/ServiceInformationBlock';
import { PersonTypes } from '../FileCard/PersonTypes';
import SocialBlock from '../FileCard/SocialBlock';
import { useAppSelector } from '../../store/hooks';
import './UserForm.less';
import usePermissions from '../../lib/utils/usePermissions';
import { PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS } from '../../lib/const/permissions';

const rowStyles = {
  marginTop: 10,
};

interface Props {
  initialValues: any;
  isEditMode: boolean;
  isNewUser?: boolean;
  form: any;
  handleArchive?: () => void;
  image?: any;
  handleNewImage: (file, route) => void;
}

function UserForm(props: Props) {
  const { initialValues, isEditMode, form, handleArchive, isNewUser, image, handleNewImage } = props;
  const [hasAccess] = usePermissions();
  const { personFullCard } = useAppSelector((state) => state.persons);

  return (
    <Form layout="vertical" form={form} initialValues={initialValues} className="user-form-component">
      <Row style={rowStyles} gutter={10}>
        <Col span={24}>
          <GeneralBlock isEditMode={isEditMode} handleArchive={handleArchive} isNewUser={isNewUser} />
        </Col>
      </Row>
      <Row style={rowStyles} gutter={10}>
        <Col span={14}>
          <PersonalInfoBlock
            isEditMode={isEditMode}
            isNewUser={isNewUser}
            image={image}
            handleNewImage={handleNewImage}
          />
        </Col>
        <Col span={10}>
          <PersonContactsBlock isEditMode={isEditMode} isNewUser={isNewUser} />
        </Col>
      </Row>
      {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
        <Row style={rowStyles} gutter={10}>
          <Col span={14}>
            <WorkEducationBlock isEditMode={isEditMode} isNewUser={isNewUser} />
          </Col>
          <Col span={10}>
            <ReligionBlock isEditMode={isEditMode} isNewUser={isNewUser} />
          </Col>
        </Row>
      )}
      {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
        <Row style={rowStyles} gutter={10}>
          <Col span={24}>
            <HealthBlock isEditMode={isEditMode} isNewUser={isNewUser} />
          </Col>
        </Row>
      )}
      {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) && (
        <Row style={rowStyles} gutter={10}>
          <Col span={24}>
            <ServiceInformationBlock isEditMode={isEditMode} isNewUser={isNewUser} />
          </Col>
        </Row>
      )}
      {hasAccess([PERSON_READ_ALLREGIONS, PERSON_READ_FULLACCESS]) &&
        (personFullCard?.personTypeId === PersonTypes.Подопечный || isNewUser) && (
          <Row style={rowStyles} gutter={10}>
            <Col span={24}>
              <SocialBlock isEditMode={isEditMode} isNewUser={isNewUser} />
            </Col>
          </Row>
        )}
    </Form>
  );
}

export default UserForm;
