import React, { FC, useRef, useEffect } from 'react';
import { Divider, Radio, Table } from 'antd';
import { RolesTableRecord } from '../../lib/interfaces/Roles';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { useAppSelector } from '../../store/hooks';
import { Scroll } from './interfaces';
import { useAdministrationRolesContext } from '../../containers/AdministrationRolesContainer/AdministrationRolesContext';

interface IProps {
  dataSource: RolesTableRecord[];
  scroll: Scroll;
}

export const RolesTable: FC<IProps> = ({ dataSource, scroll }) => {
  const ref = useRef<any>(null);
  const { saveCurrentRoleId } = useAdministrationRolesContext();
  const { rolesLoading } = useAppSelector((state) => state.roles);

  // Перемещение скролла, чтобы выбранный проект был в видимой части
  useEffect(() => {
    if (ref?.current?.input) {
      ref.current.input.scrollIntoView({ block: 'nearest' });
    }
  }, []);

  const rolesColumns = [
    {
      key: 'checked',
      dataIndex: 'checked',
      render: (checked: boolean) => <Radio checked={checked} ref={checked ? ref : null} />,
      width: '28px',
    },
    {
      key: 'dsc',
      dataIndex: 'dsc',
    },
  ];

  return (
    <div>
      <h4 className="text-bold font-size-18 mb2">Роли</h4>
      <Divider className="thin-divider mt0 mb1" />
      <Table
        size="small"
        loading={checkIsLoading(rolesLoading)}
        columns={rolesColumns}
        dataSource={dataSource}
        className="striped-table"
        rowClassName={(r, i) => (i % 2 ? 'cursor-pointer' : 'cursor-pointer striped-table__oddRow')}
        pagination={false}
        showHeader={false}
        onRow={({ id }) => ({
          onClick: () => {
            saveCurrentRoleId(id);
          },
        })}
        scroll={scroll}
      />
    </div>
  );
};
