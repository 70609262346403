import React, { FC, useEffect, useState } from 'react';
import { Divider, Table } from 'antd';
import './AdministrationPerson.less';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { useAppSelector } from '../../store/hooks';
import { IPermissionTableRecord } from '../../lib/interfaces/Administration';
import { PermissionType } from '../../lib/img/PermissionType';

interface IProps {}

export const AdministrationPersonPermissionList: FC<IProps> = (props) => {
  const { userLoading, user } = useAppSelector((state) => state.administration);
  const { authPermission } = useAppSelector((state) => state.dictionaries);
  const [tableData, setTableData] = useState<IPermissionTableRecord[]>();
  // console.log(tableData);
  // Список permissions для отображения
  useEffect(() => {
    const data: IPermissionTableRecord[] = [];
    user?.includedRoles.forEach(({ includedPermissions, excludedPermissions }) => {
      includedPermissions.forEach(({ id, dsc }) => {
        data.push({
          repmissionTypeAlias: 'includedRolePermissions',
          key: `1_${id}`,
          id,
          permissionName: dsc,
        });
      });
      excludedPermissions.forEach(({ id, dsc }) => {
        data.push({
          repmissionTypeAlias: 'excludedRolePermissions',
          key: `2_${id}`,
          id,
          permissionName: dsc,
        });
      });
    });
    user?.includedPermissions.forEach(({ id, dsc }) => {
      data.push({
        repmissionTypeAlias: 'includedPermissions',
        key: `3_${id}`,
        id,
        permissionName: dsc,
      });
    });
    user?.excludedPermissions.forEach(({ id, dsc }) => {
      data.push({
        repmissionTypeAlias: 'excludedPermissions',
        key: `4_${id}`,
        id,
        permissionName: dsc,
      });
    });
    setTableData(data.sort((a, b) => (a.permissionName > b.permissionName ? 1 : -1)));
  }, [user, authPermission]);

  const columns = [
    {
      key: 0,
      dataIndex: 'repmissionTypeAlias',
      render: (repmissionTypeAlias) => <PermissionType repmissionTypeAlias={repmissionTypeAlias} />,
      width: '25px',
    },
    {
      key: 1,
      dataIndex: 'permissionName',
    },
  ];

  return (
    <>
      <h4 className="text-bold font-size-18 mb3">Привилегии пользователя</h4>
      <Divider className="thin-divider mt0 mb10" />
      <Table
        size="small"
        loading={checkIsLoading(userLoading)}
        showHeader={false}
        columns={columns}
        dataSource={tableData}
        className="striped-table"
        rowClassName={(r, i) => (i % 2 ? '' : 'striped-table__oddRow')}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 16,
        }}
      />
    </>
  );
};
