import React from 'react';
import { Button, Space } from 'antd';
import './NewWard.less';
import UserForm from '../UserForm/UserForm';
import { useForm } from 'antd/es/form/Form';
import { useNavigate } from 'react-router-dom';

interface Props {
  form: any;
  createWard: (fields) => void;
}

const NewWard = (props: Props) => {
  const { form, createWard } = props;
  const navigate = useNavigate();

  return (
    <div className="ml20 mr20">
      <h2>Добавление новой анкеты</h2>
      <UserForm initialValues={{}} isEditMode={false} form={form} isNewUser={true} handleNewImage={() => null} />
      <Space className="mt20 mb20">
        <Button
          type="primary"
          onClick={() => {
            navigate('/files');
          }}
        >
          Отменить
        </Button>
        <Button
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then(() => {
                createWard(form.getFieldsValue());
              })
              .catch((e) => console.log(e));
          }}
        >
          Сохранить
        </Button>
      </Space>
    </div>
  );
};

export default NewWard;
