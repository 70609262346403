import { Checkbox, Divider, Input, Table, Tooltip } from 'antd';
import React, { FC } from 'react';
import { ProjectParticipantsTableRecord } from '../../lib/interfaces/Project';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { useAppSelector } from '../../store/hooks';
import { CheckAllPersons, Scroll, OnRow, SearchPerson } from './interfaces';

interface IProps {
  editMode: boolean;
  dataSource: ProjectParticipantsTableRecord[];
  scroll: Scroll;
  onRow: OnRow;
  ckeckAll: CheckAllPersons;
  searchParticipants: SearchPerson;
  searchParticipantsInput: string;
}

export const ParticipantsTable: FC<IProps> = ({
  editMode,
  dataSource,
  scroll,
  onRow,
  ckeckAll,
  searchParticipants,
  searchParticipantsInput,
}) => {
  const { projectLoading } = useAppSelector((state) => state.projects);

  const renderCheckAll = () => {
    if (!editMode) return null;
    const checked = dataSource.length > 0 && dataSource.every(({ checked }) => checked);
    return (
      <Tooltip title="Выбрать все">
        <Checkbox onChange={(e) => ckeckAll(e.target.checked)} checked={checked} />
      </Tooltip>
    );
  };

  const renderSearch = (
    <div className="pr8">
      <Input
        placeholder="Введите ФИО"
        onChange={(e) => {
          searchParticipants(e.target.value);
        }}
        value={searchParticipantsInput}
        allowClear
      />
    </div>
  );

  const columns = [
    {
      title: renderCheckAll,
      key: 'checked',
      dataIndex: 'checked',
      render: (checked: boolean) => (
        <Checkbox checked={checked} style={{ visibility: editMode ? 'visible' : 'hidden' }} />
      ),
      width: '26px',
    },
    {
      title: renderSearch,
      key: 'fio',
      dataIndex: 'fio',
    },
    {
      title: 'Тип',
      key: 'typeName',
      dataIndex: 'typeName',
      width: '25%',
    },
  ];

  return (
    <div>
      <h4 className="text-bold font-size-18 mb2">Участники проекта</h4>
      <Divider className="thin-divider mt0 mb0" />
      <Table
        size="small"
        loading={checkIsLoading(projectLoading)}
        columns={columns}
        dataSource={dataSource}
        className="striped-table"
        rowClassName={(r, i) => (i % 2 ? 'cursor-pointer' : 'cursor-pointer striped-table__oddRow')}
        pagination={false}
        onRow={onRow}
        scroll={scroll}
      />
    </div>
  );
};
