import accompany from '../../lib/img/icon/app_subject/accompany.svg';
import accompany_map from '../../lib/img/icon/app_subject/accompany_map.svg';
import attend_event from '../../lib/img/icon/app_subject/attend_event.svg';
import attend_event_map from '../../lib/img/icon/app_subject/attend_event_map.svg';
import buy_donate from '../../lib/img/icon/app_subject/buy_donate.svg';
import buy_donate_map from '../../lib/img/icon/app_subject/buy_donate_map.svg';
import care_in_hospital from '../../lib/img/icon/app_subject/care_in_hospital.svg';
import care_in_hospital_map from '../../lib/img/icon/app_subject/care_in_hospital_map.svg';
import cleaning from '../../lib/img/icon/app_subject/cleaning.svg';
import cleaning_map from '../../lib/img/icon/app_subject/cleaning_map.svg';
import congratulate from '../../lib/img/icon/app_subject/congratulate.svg';
import congratulate_map from '../../lib/img/icon/app_subject/congratulate_map.svg';
import cook_food from '../../lib/img/icon/app_subject/cook_food.svg';
import cook_food_map from '../../lib/img/icon/app_subject/cook_food_map.svg';
import feed_the_ward from '../../lib/img/icon/app_subject/feed_the_ward.svg';
import feed_the_ward_map from '../../lib/img/icon/app_subject/feed_the_ward_map.svg';
import grocery_set from '../../lib/img/icon/app_subject/grocery_set.svg';
import grocery_set_map from '../../lib/img/icon/app_subject/grocery_set_map.svg';
import haircut from '../../lib/img/icon/app_subject/haircut.svg';
import haircut_map from '../../lib/img/icon/app_subject/haircut_map.svg';
import help from '../../lib/img/icon/app_subject/help.svg';
import help_map from '../../lib/img/icon/app_subject/help_map.svg';
import help_studies from '../../lib/img/icon/app_subject/help_studies.svg';
import help_studies_map from '../../lib/img/icon/app_subject/help_studies_map.svg';
import holiday_help from '../../lib/img/icon/app_subject/holiday_help.svg';
import holiday_help_map from '../../lib/img/icon/app_subject/holiday_help_map.svg';
import redecorating from '../../lib/img/icon/app_subject/redecorating.svg';
import redecorating_map from '../../lib/img/icon/app_subject/redecorating_map.svg';
import transfer_a_person from '../../lib/img/icon/app_subject/transfer_a_person.svg';
import transfer_a_person_map from '../../lib/img/icon/app_subject/transfer_a_person_map.svg';
import walk_disabled_person from '../../lib/img/icon/app_subject/walk_disabled_person.svg';
import walk_disabled_person_map from '../../lib/img/icon/app_subject/walk_disabled_person_map.svg';
import walk_kids from '../../lib/img/icon/app_subject/walk_kids.svg';
import walk_kids_map from '../../lib/img/icon/app_subject/walk_kids_map.svg';
import wash_hygiene from '../../lib/img/icon/app_subject/wash_hygiene.svg';
import wash_hygiene_map from '../../lib/img/icon/app_subject/wash_hygiene_map.svg';
import worship from '../../lib/img/icon/app_subject/worship.svg';
import worship_map from '../../lib/img/icon/app_subject/worship_map.svg';

export const collectionSvg = {
  app_subject: {
    accompany,
    accompany_map,
    attend_event,
    attend_event_map,
    buy_donate,
    buy_donate_map,
    care_in_hospital,
    care_in_hospital_map,
    cleaning,
    cleaning_map,
    congratulate,
    congratulate_map,
    cook_food,
    cook_food_map,
    feed_the_ward,
    feed_the_ward_map,
    grocery_set,
    grocery_set_map,
    haircut,
    haircut_map,
    help,
    help_map,
    help_studies,
    help_studies_map,
    holiday_help,
    holiday_help_map,
    redecorating,
    redecorating_map,
    transfer_a_person,
    transfer_a_person_map,
    walk_disabled_person,
    walk_disabled_person_map,
    walk_kids,
    walk_kids_map,
    wash_hygiene,
    wash_hygiene_map,
    worship,
    worship_map,
  },
};

export type DicIconKeys = keyof typeof collectionSvg;

export type DicIconName = keyof (typeof collectionSvg)[DicIconKeys];
