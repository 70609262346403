import React, { FC } from 'react';
import { ReactComponent as PencilIcon } from '../../lib/img/pencil.svg';

export const EditButtonTitle: FC = () => {
  return (
    <div>
      <div style={{ width: '141px' }} className="display-flex ml-auto mr-auto">
        <PencilIcon className="mr6" />
        <div>Редактировать</div>
      </div>
    </div>
  );
};
