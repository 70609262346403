import React, { useState } from 'react';
import useDynamicSVGImport from '../../lib/utils/useDynamicSVGImport';
import { Spin } from 'antd';
import { DicIconKeys, DicIconName } from '../../lib/utils/collectionSvg';

interface UseDynamicSVGImportOptions {
  onCompleted?: (name: DicIconName, SvgIcon: React.FC<React.SVGProps<SVGSVGElement>> | undefined) => void;
  onError?: (err: Error) => void;
}

interface IconProps {
  name: DicIconName;
  dopIcon: DicIconName;
  dicId: DicIconKeys;
  onCompleted?: UseDynamicSVGImportOptions['onCompleted'];
  onError?: UseDynamicSVGImportOptions['onError'];
}

export const DynamicSVGReactElement = ({
  name,
  dopIcon,
  dicId,
  onCompleted,
  onError,
  ...rest
}: IconProps): JSX.Element | null => {
  const { error, loading, SvgIcon } = useDynamicSVGImport(name, dicId, {
    onCompleted,
    onError,
  });

  const { SvgIcon: SvgIconDop } = useDynamicSVGImport(dopIcon, dicId, {
    onCompleted,
    onError,
  });

  const [force, setForce] = useState(true);

  setTimeout(() => {
    setForce(false);
  }, 1);

  if (error) {
    return <>{error.message}</>;
  }
  if (loading) {
    return <Spin spinning />;
  }
  if (SvgIcon) {
    return <SvgIcon {...rest} />;
  }
  if (!SvgIcon && !loading && SvgIconDop) {
    return <SvgIconDop {...rest} />;
  }

  return null;
};
