// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { FireBaseServiceEvent } from './FireBaseServiceTypes';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAKTPT8AM2a3tz6wJU8mp2tpOgnRyKzKwA',
  authDomain: 'miloserdie-97013.firebaseapp.com',
  projectId: 'miloserdie-97013',
  storageBucket: 'miloserdie-97013.appspot.com',
  messagingSenderId: '388016365039',
  appId: '1:388016365039:web:0acdcbd109b48bb866aa1a',
  measurementId: 'G-DVHVT75XDY',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const FireBaseService: FireBaseServiceEvent = {
  createLogEventFireBase: (nameEvent) => {
    logEvent(analytics, 'Action', { name: nameEvent });
  },
};

export default FireBaseService;
