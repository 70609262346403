import React from 'react';
import { Person } from '../../lib/interfaces/Person';
import { SearchPersonParams } from '../../lib/interfaces/SearchPersonParams';
import { CreatedApplication } from '../../lib/interfaces/CreatedApplication';

export type HandleCreateApplication = (application: CreatedApplication) => Promise<Person>;
export type HandleSearchPerson = (searchOptions: SearchPersonParams) => Promise<Person[]>;
export type LoadFullPersonData = (personId: number) => Promise<Person>;

export interface ICreateApplicationContainerContext {
  handleCreateApplication: HandleCreateApplication;
  handleSearchPerson: HandleSearchPerson;
  loadFullPersonData: LoadFullPersonData;
}

export const CreateApplicationContainerContext = React.createContext<ICreateApplicationContainerContext | null>(null);

export const useCreateApplicationContainerContext = () => {
  const context = React.useContext(CreateApplicationContainerContext);
  if (context === null) {
    throw new Error('useContext must be inside a Provider with a value');
  }
  return context;
};
