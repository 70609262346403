import { ours, prod } from '../const/api';

// В качестве baseApiKey отдается suggest_api текущего домена

const getBaseApiKey = () => {
  const { host } = document.location;
  if (host.includes('localhost')) {
    if (process.env.REACT_APP_ENV === 'prod') return prod;
    return ours;
  }
  if (host.includes('sdn104.iflex.ru')) {
    return ours;
  }
  return prod;
};

export const baseApiKey = getBaseApiKey();
