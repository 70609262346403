import React, { useState } from 'react';
import { Button, Col, Divider, Form, Row, Select, Typography } from 'antd';
import { useAppSelector } from '../../store/hooks';
import './FileProjects.less';
import List from '../List/List';
import { PersonTypes } from '../FileCard/PersonTypes';
import { getDictionaryItemById } from '../../lib/utils/applications';
import { useForm } from 'antd/lib/form/Form';
import { ReactComponent as PencilIcon } from '../../lib/img/pencil.svg';
import { ReactComponent as CrossIcon } from '../../lib/img/cross.svg';
import { ReactComponent as SubmitIcon } from '../../lib/img/submit-green.svg';
import usePermissions from '../../lib/utils/usePermissions';
import FireBaseService from '../../services/FireBaseService/FireBaseService';

const { Paragraph } = Typography;

interface IProps {
  editProjectsVolunteer: (fields, callBack) => Promise<void>;
}

function FileProjects({ editProjectsVolunteer }: IProps) {
  const { personFullCard } = useAppSelector((state) => state.persons);
  const { personStatuses, personTypes, appSubject } = useAppSelector((state) => state.dictionaries);
  const { projects } = useAppSelector((state) => state.projects);
  const { participation } = useAppSelector((state) => state.persons);

  const [hasAccess] = usePermissions();

  const projectsTitles = () =>
    projects
      .flatMap((p) => {
        if (personFullCard?.projectIds && personFullCard.projectIds.includes(p.id)) return p.title;
        return [];
      })
      .filter((item) => item !== undefined)
      .join(', ');

  const projectsTitlesVolunteer = projects
    .flatMap((p) => {
      if (personFullCard?.projectIdsVolunteer && personFullCard.projectIdsVolunteer.includes(p.id)) return p.title;
      return [];
    })
    .filter((item) => item !== undefined)
    .join(', ');

  const applications = participation.applications.map((app) => {
    const appSubjectName = getDictionaryItemById(appSubject, app?.subjectId)?.name || '';
    const appText = app.text || '';
    return {
      id: app.id,
      value: `${appSubjectName}${appSubjectName && appText ? ' - ' : ''}${appText}`,
    };
  });
  const wards = participation.wards.map((ward) => {
    return { id: ward.id, value: `${ward.lastName} ${ward.firstName} ${ward?.middleName ? ward.middleName : ''}` };
  });
  const executors = participation.executors.map((executor) => {
    return {
      id: executor.id,
      value: `${executor.lastName} ${executor.firstName} ${executor?.middleName ? executor.middleName : ''}`,
    };
  });

  const [isEditGeneralBlock, setIsEditGeneralBlock] = useState(false);
  const [formGeneralBlock] = useForm();

  const onFinishGeneralBlock = (values) => {
    editProjectsVolunteer &&
      editProjectsVolunteer(values, () => {
        setIsEditGeneralBlock(false);
      });
  };

  const linkToGenerated = (id: number, type: string): string => {
    return type === 'applications' ? `/applications/list/${id}/main` : `/files/${id}/general`;
  };

  return (
    <div className="file-projects mt10 mr20 ml20">
      <div className="block">
        <Row justify="space-between">
          <Col>
            <h3>Общее</h3>
          </Col>
        </Row>
        <div className="divider">
          <Divider />
        </div>
        <Row gutter={50} style={{ minHeight: 58 }}>
          <Col>
            <Row>Тип:</Row>
            <Row>{personTypes.find((type) => type.id === personFullCard?.personTypeId)?.name}</Row>
          </Col>
          {personFullCard?.personTypeId !== PersonTypes.Подопечный && (
            <Col>
              <Row>Статус:</Row>
              <Row>{personStatuses.find((status) => status.id === personFullCard?.personStatusId)?.name}</Row>
            </Col>
          )}
          {projectsTitles().length > 0 &&
            personFullCard?.personTypeId !== PersonTypes.Подопечный &&
            personFullCard?.personTypeId !== PersonTypes.Доброволец && (
              <Col>
                <Row>Входит в проекты:</Row>
                <Row>{projectsTitles()}</Row>
              </Col>
            )}
          {personFullCard?.personTypeId === PersonTypes.Доброволец && (
            <Col>
              <Form
                form={formGeneralBlock}
                initialValues={{
                  projectVolunteerIds: personFullCard?.projectIdsVolunteer?.map(String),
                }}
                onFinish={onFinishGeneralBlock}
              >
                <Row>
                  <Col>Закреплён за проектами</Col>
                  <Col style={{ marginLeft: isEditGeneralBlock ? 203 : 3 }}>
                    {hasAccess(['PERSON.WRITE', 'PERSON.WRITE.ALLREGIONS']) && (
                      <Row>
                        {!isEditGeneralBlock && personFullCard?.personStatusId !== 5 && (
                          <Button
                            icon={
                              <PencilIcon
                                onClick={() => {
                                  FireBaseService.createLogEventFireBase('Событие. Закрепить за проектом');

                                  setIsEditGeneralBlock(true);
                                }}
                              />
                            }
                            className="button-icon"
                            type="text"
                          />
                        )}
                        {isEditGeneralBlock && (
                          <>
                            <Button
                              icon={
                                <CrossIcon
                                  onClick={() => {
                                    setIsEditGeneralBlock(false);
                                  }}
                                />
                              }
                              className="button-icon mr10"
                              type="text"
                            />
                            <Button
                              icon={
                                <SubmitIcon
                                  onClick={formGeneralBlock.submit}
                                  width={24}
                                  height={23}
                                  viewBox="-5 0 30 18"
                                />
                              }
                              className="button-icon"
                              type="text"
                            />
                          </>
                        )}
                      </Row>
                    )}
                  </Col>
                </Row>
                {!isEditGeneralBlock ? (
                  <Row>
                    <Paragraph
                      style={{
                        maxWidth: 425,
                        whiteSpace: 'break-spaces',
                        marginBottom: '.5em',
                      }}
                      ellipsis={{
                        rows: 999,
                        expandable: true,
                        suffix: '',
                      }}
                      title={`${projectsTitlesVolunteer}`}
                    >
                      {projectsTitlesVolunteer}
                    </Paragraph>
                  </Row>
                ) : (
                  <Row>
                    <Form.Item style={{ width: '100%', marginBottom: 0 }} name="projectVolunteerIds">
                      <Select
                        mode="multiple"
                        allowClear={false}
                        placeholder="Закрепление за проектом"
                        showArrow
                        maxTagCount="responsive"
                        style={{ width: '100%', maxWidth: 425 }}
                        filterOption={(input, option) =>
                          (option!.children as unknown as string).toLowerCase().startsWith(input.toLowerCase())
                        }
                      >
                        {projects.map((proj) => {
                          if (proj.regionId === personFullCard?.regionId) {
                            return <Select.Option key={proj.id}>{proj.title}</Select.Option>;
                          }
                          return null;
                        })}
                      </Select>
                    </Form.Item>
                  </Row>
                )}
              </Form>
            </Col>
          )}
        </Row>
      </div>
      <Row gutter={10} className="mt10">
        <Col span={8}>
          <List title="Список подопечных" data={wards} isLinked linkTo={linkToGenerated} typeLink="wards" />
        </Col>
        <Col span={8}>
          <List title="Список просьб" data={applications} isLinked linkTo={linkToGenerated} typeLink="applications" />
        </Col>
        <Col span={8}>
          <List title="Список добровольцев" data={executors} isLinked linkTo={linkToGenerated} typeLink="executors" />
        </Col>
      </Row>
    </div>
  );
}

export default FileProjects;
