import React from 'react';
import { Divider, Form, Input, Select } from 'antd';
import { replaceFalse } from './replaceFalse';
import { useAppSelector } from '../../store/hooks';
import useLabel from './useLabel';
import { ReactComponent as ArrowDownFigIcon } from '../../lib/img/arrow-down-fig.svg';

const labelWidth = 245;

interface Props {
  isEditMode: boolean;
  isNewUser?: boolean;
}

function WorkEducationBlock(props: Props) {
  const { isEditMode, isNewUser } = props;
  const { personFullCard } = useAppSelector((state) => state.persons);
  const { education } = useAppSelector((state) => state.dictionaries);
  const [makeLabel] = useLabel();

  return (
    <div className="block">
      <h3>Информация о месте работы/учёбы</h3>
      <Divider />
      {isEditMode || isNewUser ? (
        <Form.Item name="educationId" label="Образование" className="card-select">
          <Select suffixIcon={<ArrowDownFigIcon />}>
            {education
              .filter((el) => !el.deleted)
              .map((value) => (
                <Select.Option key={value.id} value={value.id}>
                  {value.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      ) : (
        <div className="info-row">
          <div>{makeLabel('Образование', labelWidth)}</div>
          <div className="info">
            {replaceFalse(education.find((value) => value.id === personFullCard?.education?.educationId)?.name)}
          </div>
        </div>
      )}
      {isEditMode || isNewUser ? (
        <Form.Item name="institution" label="Место учебы">
          <Input />
        </Form.Item>
      ) : (
        <div className="info-row">
          <div>{makeLabel('Место учебы', labelWidth)}</div>
          <div className="info">{replaceFalse(personFullCard?.education?.institution)}</div>
        </div>
      )}
      {isEditMode || isNewUser ? (
        <Form.Item name="addEducation" label="Дополнительное образование">
          <Input />
        </Form.Item>
      ) : (
        <div className="info-row">
          <div>{makeLabel('Дополнительное образование', labelWidth)}</div>
          <div className="info">{replaceFalse(personFullCard?.education?.addEducation)}</div>
        </div>
      )}
      {isEditMode || isNewUser ? (
        <Form.Item name="profession" label="Профессия">
          <Input />
        </Form.Item>
      ) : (
        <div className="info-row">
          <div>{makeLabel('Профессия', labelWidth)}</div>
          <div className="info">{replaceFalse(personFullCard?.education?.profession)}</div>
        </div>
      )}
      {isEditMode || isNewUser ? (
        <Form.Item name="workPlace" label="Место работы">
          <Input />
        </Form.Item>
      ) : (
        <div className="info-row">
          <div>{makeLabel('Место работы', labelWidth)}</div>
          <div className="info">{replaceFalse(personFullCard?.education?.workPlace)}</div>
        </div>
      )}
    </div>
  );
}

export default WorkEducationBlock;
