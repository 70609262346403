/* eslint-disable max-len,no-underscore-dangle,no-prototype-builtins */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import LocalStorageService from './LocalStorageService';
import { getAuthRoute } from '../lib/routes/routes';

axios.interceptors.request.use((config) => {
  const token = LocalStorageService.getAccessToken();
  if (token) {
    // @ts-ignore
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      // @ts-ignore
      prom.reject(error);
    } else {
      // @ts-ignore
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (
      error.response?.status === 401 &&
      // originalRequest._retry &&
      error.config.headers?.GrandType === 'refresh_token'
    ) {
      LocalStorageService.clearToken();
      window.location.reload();
    }
    if (error.response?.status === 401) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          // @ts-ignore
          failedQueue.push({ resolve, reject });
        }).then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axios(originalRequest);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve) => {
        axios
          .post(getAuthRoute(), `grant_type=refresh_token&refresh_token=${LocalStorageService.getRefreshToken()}`, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              GrandType: 'refresh_token',
            },
            auth: { username: 'web', password: 'R@d7vJD6uR' },
          })
          .then((res) => {
            LocalStorageService.setToken(res?.data);
            axios.defaults.headers.common.Authorization = `Bearer ${LocalStorageService.getAccessToken}`;
            originalRequest.headers.Authorization = `Bearer ${LocalStorageService.getAccessToken}`;
            // @ts-ignore
            processQueue(null, LocalStorageService.getAccessToken);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            // LocalStorageService.clearToken();
            // window.location.reload();
            // reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  }
);
