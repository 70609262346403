import React, { FC } from 'react';
import { Button, Checkbox, Col, Divider, Row, Tooltip } from 'antd';
import { ReactComponent as BackArrow } from '../../lib/img/back-arrow.svg';
import './AdministrationDictionaries.less';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAdministrationContext } from '../../containers/AdministrationContainer/AdministrationContext';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setArchivedTermins } from '../../store/slices/administrationDictionariesSlice/administrationDictionariesSlice';
import { useMainContext } from '../../containers/MainContainer/MainContext';

interface IProps {}

export const AdministrationDictionariesNavbar: FC<IProps> = (props) => {
  const location = useLocation();
  const dicTypeId = location.pathname.split('/')[location.pathname.split('/').length - 1];

  const navigate = useNavigate();
  const { saveCurrentLocation, setOpenModalDictionariesTypes } = useAdministrationContext();
  const { hasAccess } = useMainContext();

  const { dicTypes, isArchivedTermins } = useAppSelector((state) => state.administrationDictionaries);

  const { properties } = useAppSelector((state) => state.root);

  const dispatch = useAppDispatch();

  const onBack = () => {
    navigate('/administration/dictionaries');
    saveCurrentLocation('dictionaries');
  };

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    dispatch(setArchivedTermins(e.target.checked));
  };

  const findDicType = dicTypes?.find((dicType) => dicType.id === dicTypeId);

  const title = findDicType?.name;

  const systemDic: string[] = properties.system_dic.split(', ');

  const isSystemDic = systemDic?.includes(String(findDicType?.id));

  return (
    <div className="administration-navbar">
      <div className="display-flex" style={{ justifyContent: 'space-between' }}>
        <div className="display-flex">
          <Tooltip title="К списку справочников">
            <BackArrow className="ml6 mt8 mr10 cursor-pointer" onClick={onBack} />
          </Tooltip>
          <h2 className="text-bold mb5">{title}</h2>
        </div>
      </div>
      <div style={{ width: '453px' }}>
        <Divider className="thin-divider mt0 mb10 ml34" />
      </div>
      <Row className="mb9" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <div className="display-flex">
          <Col className="font-size-18 text-bold mr20" style={{ color: '#48433F', lineHeight: 'normal' }}>
            <span>Значения справочника</span>
          </Col>
          <Col className="font-size-14 font-roboto">
            <Checkbox checked={isArchivedTermins} onChange={(e) => onChangeCheckbox(e)} />
            <span className="ml6">Показать архивные</span>
          </Col>
        </div>
        <Col>
          {hasAccess(['ADMINISTRATION.ADDTERM']) && !isSystemDic && (
            <Button style={{ width: 216 }} onClick={() => setOpenModalDictionariesTypes('create')}>
              Добавить новое значение
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};
