import buildUrl from '../utils/buildUrl';
import { securityBaseUrl } from './baseUrl';

export const rolesRoute = (searchParams: { page?: number; size: number }) =>
  buildUrl({
    url: `${securityBaseUrl}/security/roles`,
    searchParams,
  });

export const updatePermissionsRoleRoute = (searchParams: { roleId: number; body: { include: number[] } }) =>
  buildUrl({
    url: `${securityBaseUrl}/security/roles/${searchParams.roleId}/permissions`,
    searchParams: searchParams.body,
  });

export const createRoleRoute = () =>
  buildUrl({
    url: `${securityBaseUrl}/security/roles`,
  });

export const updateNameRoleRoute = (roleId: number) =>
  buildUrl({
    url: `${securityBaseUrl}/security/roles/${roleId}`,
  });

export const deleteRoleRoute = (roleId: number) =>
  buildUrl({
    url: `${securityBaseUrl}/security/roles/${roleId}`,
  });

export const searchRolesUserRoute = (searchParams: { includedRoleId: number }) =>
  buildUrl({
    url: `${securityBaseUrl}/security/users/search`,
    searchParams,
  });

export const permissionsRoute = (searchParams: { page?: number; size: number }) =>
  buildUrl({
    url: `${securityBaseUrl}/security/permissions`,
    searchParams,
  });
