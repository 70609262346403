import DictionaryItem from '../interfaces/DictionaryItem';
import { Sex } from '../interfaces/Sex';

export const getFio = ({
  lastName,
  firstName,
  middleName,
}: {
  lastName?: string;
  firstName?: string;
  middleName?: string;
}): string => [lastName, firstName, middleName].filter(Boolean).join(' ');

export const getSex = (sex: Sex | '' | undefined): 'Мужской' | 'Женский' | '' => {
  if (sex === 'M') return 'Мужской';
  if (sex === 'F') return 'Женский';
  return '';
};

export const getDictionaryItemById = (
  dictionary: DictionaryItem[],
  id: number | undefined
): DictionaryItem | undefined => {
  if (id === undefined) return undefined;
  return dictionary.find((item) => item.id === id);
};

export const getDictionaryItemByProp = (
  dictionary: DictionaryItem[],
  propName: string | undefined,
  propValue: string | number | undefined
): DictionaryItem | undefined => {
  if (propName === undefined) return undefined;
  return dictionary.find((item) => item[propName] === propValue);
};
