import React from 'react';

export type IChangePersonType = (prop: { personTypeId: number }) => Promise<any>;
export type ILoadUserData = (prop: { userId: number }) => Promise<any>;
export type ILoadPersonAndUserData = () => void;
export type IDeleteUser = () => Promise<any>;
export type IAssignRole = (prop: { roleId: number }) => Promise<any>;
export type IRevokeRole = (prop: { roleId: number }) => Promise<any>;
export type IOnSubmitAssignRole = (props: { assignedRoleId?: number }) => Promise<any>;
export type IOnSubmitAssignPermissions = (props: { include: number[]; exclude: number[] }) => Promise<any>;
export type IOnSubmitBlockPermissions = (props: { include: number[]; exclude: number[] }) => Promise<any>;
export type IChangeRole = (prop: { roleId?: number }) => Promise<any>;
export type IChangePermissions = (prop: { include: number[]; exclude: number[] }) => Promise<any>;

export interface IAdministrationPersonContext {
  changePersonType: IChangePersonType;
  loadUserData: ILoadUserData;
  loadPersonAndUserData: ILoadPersonAndUserData;
  deleteUser: IDeleteUser;
  assignRole: IAssignRole;
  revokeRole: IRevokeRole;
  changeRole: IChangeRole;
  changePermissions: IChangePermissions;
  onSubmitAssignRole: IOnSubmitAssignRole;
  onSubmitAssignPermissions: IOnSubmitAssignPermissions;
  onSubmitBlockPermissions: IOnSubmitBlockPermissions;
}

export const AdministrationPersonContext = React.createContext<IAdministrationPersonContext | null>(null);

export const useAdministrationPersonContext = () => {
  const context = React.useContext(AdministrationPersonContext);
  if (context === null) {
    throw new Error('useContext must be inside a Provider with a value');
  }
  return context;
};
