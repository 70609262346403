import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import requestStatusCodes from '../../../lib/const/requestStatusCodes';
import { RequestStatuses } from '../../../lib/types/RequestStatuses';
import { DicTypes, DicTypesTermin } from '../../../lib/interfaces/AdministrationDictionaries';
import {
  getDictionariesTypeTerminRoute,
  getDictionariesTypesRoute,
} from '../../../lib/routes/administrationDictionaries';
import { store } from '../../store';

interface InitialState {
  dicTypes: DicTypes[];
  dicTypesLoading: RequestStatuses;

  dicTypesTermin: DicTypesTermin[];
  dicTypesTerminNotArchive: DicTypesTermin[];
  dicTypesTerminArchive: DicTypesTermin[];
  dicTypesTerminLoading: RequestStatuses;

  isArchivedTermins: boolean;

  openModalAdministrationDictionaries: 'create' | 'delete' | '';
}

const initialState: InitialState = {
  dicTypes: [],
  dicTypesLoading: requestStatusCodes.idle,

  dicTypesTermin: [],
  dicTypesTerminNotArchive: [],
  dicTypesTerminArchive: [],
  dicTypesTerminLoading: requestStatusCodes.idle,

  isArchivedTermins: false,

  openModalAdministrationDictionaries: '',
};

export const getDicTypes = createAsyncThunk('administrationDictionaries/getDicTypes', async () => {
  const response = axios.get(getDictionariesTypesRoute());
  const { data } = await response;
  return data;
});

export const getDicTypesTermin = createAsyncThunk(
  'administrationDictionaries/getDicTypesTermin',
  async (dicTypeId: string) => {
    const response = axios.get(getDictionariesTypeTerminRoute(dicTypeId));
    const { data } = await response;
    const { properties } = store.getState().root;
    return { data, properties };
  }
);

const administrationDictionariesSlice = createSlice({
  name: 'administrationDictionaries',
  initialState,
  reducers: {
    cleanAdministrationDictionaries: (state) => {
      state.dicTypesTerminLoading = requestStatusCodes.idle;
      state.dicTypesTermin = [];
      state.dicTypesLoading = requestStatusCodes.idle;
      state.dicTypes = [];
    },
    setArchivedTermins: (state, action) => {
      state.isArchivedTermins = action.payload;
    },
    setOpenModalAdministrationDictionaries: (state, action) => {
      state.openModalAdministrationDictionaries = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getDicTypes.pending, (state) => {
        state.dicTypesLoading = requestStatusCodes.pending;
      })
      .addCase(getDicTypes.rejected, (state) => {
        state.dicTypesLoading = requestStatusCodes.rejected;
      })
      .addCase(getDicTypes.fulfilled, (state, { payload }) => {
        state.dicTypesLoading = requestStatusCodes.fulfilled;
        state.dicTypes = payload;
      })

      .addCase(getDicTypesTermin.pending, (state) => {
        state.dicTypesTerminLoading = requestStatusCodes.pending;
      })
      .addCase(getDicTypesTermin.rejected, (state) => {
        state.dicTypesTerminLoading = requestStatusCodes.rejected;
      })
      .addCase(getDicTypesTermin.fulfilled, (state, { payload }) => {
        if (payload.data.length > 0) {
          const system_dic = payload.properties.system_dic.split(', ').includes(payload.data[0].dicTypeId);

          const sortDicTypesTermin = payload.data
            ?.sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((termin) => ({
              ...termin,
              key: termin.id,
              system_dic,
            }));

          state.dicTypesTermin = sortDicTypesTermin;
          state.dicTypesTerminNotArchive = sortDicTypesTermin.filter((termin) => termin.dsc !== 'archive');
          state.dicTypesTerminArchive = sortDicTypesTermin.filter((termin) => termin.dsc === 'archive');
        }
        state.dicTypesTerminLoading = requestStatusCodes.fulfilled;
      });
  },
});

const { reducer, actions } = administrationDictionariesSlice;
const { cleanAdministrationDictionaries, setArchivedTermins, setOpenModalAdministrationDictionaries } = actions;

export { reducer, cleanAdministrationDictionaries, setArchivedTermins, setOpenModalAdministrationDictionaries };
