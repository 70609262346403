import React from 'react';
import { Button, Modal } from 'antd';
import './CreateUserModal.less';
import { useAppSelector } from '../../store/hooks';

interface Props {
  isOpened: boolean;
  handleOk: () => void;
}

function CreateUserModal(props: Props) {
  const { properties } = useAppSelector((state) => state.root);
  const { handleOk, isOpened } = props;
  return (
    <Modal
      wrapClassName="create-user-modal"
      open={isOpened}
      width={1164}
      footer={[
        <Button key={1} type="primary" onClick={handleOk}>
          ОК
        </Button>,
      ]}
      bodyStyle={{
        padding: '60px 120px',
      }}
      maskClosable={false}
      closable={false}
    >
      <div className="logo mt60 mb15" />
      <div dangerouslySetInnerHTML={{ __html: properties.welcome_text }} />
    </Modal>
  );
}

export default CreateUserModal;
