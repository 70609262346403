import { Button, Col, Divider, Modal, Row } from 'antd';
import React, { FC } from 'react';
import { useAdministrationProjectsContext } from '../../containers/AdministrationProjectsContainer/AdministrationProjectsContext';

interface IProps {
  closeModal: () => void;
  open: boolean;
  selectedProjectName: string;
  projectId: number;
}

export const DeleteProjectModal: FC<IProps> = ({ open, closeModal, selectedProjectName, projectId }) => {
  const { deleteProject } = useAdministrationProjectsContext();
  return (
    <Modal open={open} width="690px" onCancel={closeModal} footer={false} bodyStyle={{ backgroundColor: '#F4F4F4' }}>
      <h2 className="mb0 text-bold">Удаление проекта</h2>
      <Divider className="thin-divider mt0" />
      <div className="mb20">{`Вы действительно хотите удалить проект: "${selectedProjectName}"?`}</div>
      <Row justify="center" gutter={20}>
        <Col>
          <Button onClick={closeModal}>Отмена</Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              deleteProject(projectId).then(closeModal);
            }}
          >
            Удалить
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
