import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAdministrationContext } from '../../containers/AdministrationContainer/AdministrationContext';
import { useMainContext } from '../../containers/MainContainer/MainContext';
import { AdministrationMenuItem } from '../../lib/interfaces/Administration';
import { useAppSelector } from '../../store/hooks';
import './Administration.less';

type HasAccessToSection = (menuItem: AdministrationMenuItem) => boolean;

interface IProps {}

export const AdministrationNavbar: FC<IProps> = (props) => {
  const navigate = useNavigate();
  const { hasAccess } = useMainContext();
  const { saveCurrentLocation } = useAdministrationContext();
  const location = useLocation();
  const { currentProjectId } = useAppSelector((state) => state.administration);

  // Доступ к разделам карточки
  const hasAccessToSection: HasAccessToSection = ({ route }) => {
    switch (route) {
      case 'persons':
      case 'projects':
      case 'roles':
      case 'permissions':
      case 'dictionaries':
        return hasAccess(['ADMINISTRATION.READ']);
    }
  };

  const menuFields: AdministrationMenuItem[] = [
    { name: 'Пользователи', route: 'persons', size: 118 },
    { name: 'Проекты', route: 'projects', size: 83 },
    { name: "Роли", route: "roles", size: 57 },
    { name: 'Привилегии', route: 'permissions', size: 104 },
    { name: 'Справочники', route: 'dictionaries', size: 113 },
  ];

  const getCurrentRoute = (route: AdministrationMenuItem['route']) => {
    const baseRoute = `/administration/${route}`;
    switch (route) {
      case 'projects':
        return currentProjectId ? `${baseRoute}/${currentProjectId}` : baseRoute;
      default:
        return baseRoute;
    }
  };

  const menu = (
    <div className="administration-navbar-menu">
      {menuFields
        .filter((menuItem) => hasAccessToSection(menuItem))
        .map(({ route, name, size }) => {
          const width = `${size}px`;
          const onClick = () => {
            navigate(getCurrentRoute(route));
            saveCurrentLocation(route);
          };
          return (
            <div
              key={route}
              onClick={onClick}
              className="administration-navbar-menu-item"
              style={
                location.pathname.includes(route)
                  ? {
                      width,
                      backgroundColor: 'white',
                      fontWeight: 'bold',
                    }
                  : { width }
              }
            >
              {name}
            </div>
          );
        })}
    </div>
  );
  return <div className="administration-navbar">{menu}</div>;
};
