import React, { useEffect } from 'react';
import NewWard from '../../components/NewWard/NewWard';
import axios from 'axios';
import { createWardRoute } from '../../lib/routes/persons';
import { toYearMonthDayLineFormat } from '../../lib/utils/formatDate';
import { checkIsLoaded } from '../../lib/utils/checkLoadingStatus';
import {
  getEducationDictionary,
  getMaritalStatusDictionary,
  getPersonCategoryDictionary,
  getRegionDictionary,
  getSubwayStationDictionary,
} from '../../store/slices/dictionariesSlice/dictionariesSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Modal, Spin } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDictionaries } from '../../lib/utils/useDictionaries';
import { message } from 'antd/es';
import { geocodingRoute } from '../../lib/routes/applications';
import { useForm } from 'antd/lib/form/Form';

const NewWardContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    maritalStatusLoading,
    regionLoading,
    subwayStationLoading,
    personCategoriesLoading,
    educationLoading,
    phoneTypeLoading,
  } = useAppSelector((state) => state.dictionaries);
  const { loadDictionaries } = useDictionaries();

  useEffect(() => {
    loadDictionaries(['phone_type']);
    if (!checkIsLoaded(maritalStatusLoading, regionLoading)) {
      dispatch(getMaritalStatusDictionary());
      dispatch(getRegionDictionary());
      dispatch(getSubwayStationDictionary());
    }
    if (!checkIsLoaded(personCategoriesLoading)) dispatch(getPersonCategoryDictionary());
    if (!checkIsLoaded(educationLoading)) dispatch(getEducationDictionary());
  }, [dispatch]);

  const [form] = useForm();

  const createWard = async (fields) => {
    const newWardData = {
      firstName: fields.firstName,
      lastName: fields.lastName,
      middleName: fields.noMiddleName ? undefined : fields.middleName,
      maritalStatusId: fields?.maritalStatusId,
      birthDate: toYearMonthDayLineFormat(fields.birthDate),
      deathDate: fields.deathDate && toYearMonthDayLineFormat(fields.deathDate),
      sex: fields.sex,
      regionId: fields.regionId,
      hasChildren: fields?.hasChildren,
      hasCar: fields?.hasCar,
      inPrayList: fields?.inPrayList,
      prayListReason: fields?.prayListReason,
      personCategory: fields?.personCategory,
      email: fields?.email?.toLowerCase(),
      address: {
        subwayStationId: fields?.subwayStationId,
        entrance: fields?.entrance,
        floor: fields?.floor,
        intercomCode: fields?.intercomCode,
        apartment: fields?.apartment,
        unstructuredAddress: fields.unstructuredAddress,
      },
      education: {
        educationId: fields?.educationId,
        institution: fields?.institution,
        addEducation: fields?.addEducation,
        profession: fields?.profession,
        workPlace: fields?.workPlace,
      },
      health: {
        chronicDiseases: fields?.chronicDiseases,
        disabledReason: fields?.disabledReason,
        healthRestriction: fields?.healthRestriction,
        hasMedicalBook: fields?.hasMedicalBook,
      },
      official: {
        volunteerReason: fields?.volunteerReason,
        otherSource: fields?.otherSource,
        coordFio: fields?.coordFio,
        coordComment: fields?.coordComment,
        priestFio: fields?.priestFio,
        priestComment: fields?.priestCommentt,
        interviewerFio: fields?.interviewerFio,
        interviewDate: fields?.interviewDate && toYearMonthDayLineFormat(moment(fields?.interviewDate)),
        trainingDate: fields?.trainingDate && toYearMonthDayLineFormat(moment(fields?.trainingDate)),
        passedTraining: fields?.passedTraining,
        mmoWard: fields?.mmoWard,
        constWard: fields?.constWard,
      },
      social: {
        floorCovering: fields?.floorCovering,
        bathroom: fields?.bathroom,
        sleeper: fields?.sleeper,
        straightBed: fields?.straightBed,
        homeDress: fields?.homeDress,
        commonView: fields?.commonView,
        needsProcedures: fields?.needsProcedures,
        needsCooking: fields?.needsCooking,
        needsCleaning: fields?.needsCleaning,
        apartMovements: fields?.apartMovements,
        cityMovements: fields?.cityMovements,
        goingOut: fields?.goingOut,
        livingAlone: fields?.livingAlone,
        partnerFio: fields?.partnerFio,
        isRegistered: fields?.isRegistered,
        hasSocialWorker: fields?.hasSocialWorker,
        socialWorkerFio: fields?.socialWorkerFio,
      },
      religion: {
        church: fields?.church,
        sacramentReason: fields?.sacramentReason,
        baptismalName: fields?.baptismalName,
        nameDays: fields?.nameDays,
        religion: fields?.religion,
        needsPriest: fields?.needsPriest,
        blessed: fields?.blessed,
      },
      phones: fields.phones,
    };

    // Проверка адреса на корректность
    const checkAddressResult: { success: boolean; message?: string } = await axios
      .get(geocodingRoute({ address: fields.unstructuredAddress }))
      .then(() => ({
        success: true,
      }))
      .catch(({ response }) => ({
        success: false,
        message: response.data.message,
      }));

    if (!checkAddressResult.success) {
      form.setFields([{ name: 'unstructuredAddress', errors: [''], validating: false }]);
      return Modal.error({
        content: (
          <>
            <div>{checkAddressResult.message?.replace('. Уточните запрос', '')}</div>
            <div style={{ textAlign: 'center', marginTop: '15px' }}>
              Пожалуйста, выберите адрес из выпадающего списка
            </div>
          </>
        ),
        bodyStyle: { width: '600px', backgroundColor: '#F4F4F4' },
      });
    }

    axios.post(createWardRoute(), newWardData).then((res) => {
      message.success('Анкета успешно добавлена');
      navigate(`../${res?.data?.id}/general`);
    });
  };

  if (checkIsLoaded(maritalStatusLoading, regionLoading, subwayStationLoading, phoneTypeLoading)) {
    return <NewWard form={form} createWard={createWard} />;
  }
  return <Spin />;
};

export default NewWardContainer;
