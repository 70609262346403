import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { ColumnType } from 'antd/es/table/interface';
import './FileHistory.less';
import { useAppSelector } from '../../store/hooks';

function FileHistory() {
  const { fileHistory } = useAppSelector((state) => state.persons);
  const { actionTypes } = useAppSelector((state) => state.dictionaries);
  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    setTableData(
      fileHistory.map((history, index) => {
        return {
          key: index,
          fromDate: history.fromDate,
          actionTypeId: history.actionTypeId,
          user: history.user,
          field: history.field,
          oldValue: history.oldValue,
          newValue: history.newValue,
        };
      })
    );
  }, []);

  const columns: ColumnType<any>[] = [
    {
      key: 'fromDate',
      dataIndex: 'fromDate',
      title: 'Дата операции',
    },
    {
      key: 'actionTypeId',
      dataIndex: 'actionTypeId',
      title: 'Тип действия',
      render: (value) => {
        return actionTypes.find((type) => type.id === value)?.name;
      },
    },
    {
      key: 'user',
      dataIndex: 'user',
      title: 'Пользователь',
    },
    {
      key: 'field',
      dataIndex: 'field',
      title: 'Поле',
    },
    {
      key: 'oldValue',
      dataIndex: 'oldValue',
      title: 'Старое значение',
    },
    {
      key: 'newValue',
      dataIndex: 'newValue',
      title: 'Новое значение',
    },
  ];

  return (
    <div className="file-history mt10 mr20 ml20">
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{
          position: ['bottomCenter'],
        }}
      />
    </div>
  );
}

export default FileHistory;
