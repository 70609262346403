import React from 'react';
import { Divider, Row, Col, Form, Input, Checkbox } from 'antd';
import { replaceFalse } from './replaceFalse';
import { useAppSelector } from '../../store/hooks';
import useLabel from './useLabel';

const labelWidth = 334;

interface Props {
  isEditMode: boolean;
  isNewUser?: boolean;
}

function HealthBlock(props: Props) {
  const { isEditMode, isNewUser } = props;
  const { personFullCard } = useAppSelector((state) => state.persons);
  const [makeLabel] = useLabel();

  return (
    <div className="block">
      <h3>Здоровье</h3>
      <Divider />
      {isEditMode || isNewUser ? (
        <>
          <Row gutter={1} justify={'space-between'}>
            <Col>
              <Form.Item name="chronicDiseases" label="Хронические заболевания">
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="disabledReason" label="Основания для назначения инвалидности">
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="healthRestriction" label="Ограничения по здоровью">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item valuePropName="checked" name="hasMedicalBook">
              <Checkbox>Наличие анализов/медкнижки</Checkbox>
            </Form.Item>
          </Row>
        </>
      ) : (
        <Row gutter={30}>
          <Col>
            <Row>
              <div className="info-row">
                <div>{makeLabel('Хронические заболевания', labelWidth)}</div>
                <div className="info">{replaceFalse(personFullCard?.health?.chronicDiseases)}</div>
              </div>
            </Row>
            <Row>
              <div className="info-row">
                <div>{makeLabel('Основания для назначения инвалидности', labelWidth)}</div>
                <div className="info">{replaceFalse(personFullCard?.health?.disabledReason)}</div>
              </div>
            </Row>
          </Col>
          <Col>
            <Row>
              <div className="info-row">
                <div>{makeLabel('Ограничения по здоровью', 249)}</div>
                <div className="info">{replaceFalse(personFullCard?.health?.healthRestriction)}</div>
              </div>
            </Row>
            <Row>
              <div className="info-row">
                <div>{makeLabel('Наличие анализов/медкнижки', 249)}</div>
                <div className="info">{replaceFalse(personFullCard?.health?.hasMedicalBook, true)}</div>
              </div>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default HealthBlock;
