import React from 'react';
import { ConfigProvider } from 'antd';
import 'moment/locale/ru';
import ru from 'antd/es/locale/ru_RU';
import { tz } from 'moment-timezone';
import './services/AuthServiceInt';
import LocalStorageService from './services/LocalStorageService';
import { MainContainer } from './containers/MainContainer/MainContainer';
import { BrowserRouter } from 'react-router-dom';

function App() {
  const tenantId = LocalStorageService.getTenantId();
  const timezones = {
    1: 'Asia/Almaty',
    2: 'Asia/Bishkek',
    3: 'Asia/Tashkent',
    7: 'Europe/Moscow',
  };
  if (tenantId) {
    tz.setDefault(timezones[tenantId]);
  }

  return (
    <ConfigProvider locale={ru}>
      <BrowserRouter>
        <MainContainer />
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
