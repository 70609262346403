import React from 'react';
import { Button, Divider, Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Comment from '../Comment/Comment';
import './FileComments.less';
import { useAppSelector } from '../../store/hooks';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';

interface Props {
  postComment: (text: string) => void;
}

function FileComments(props: Props) {
  const { postComment } = props;
  const { personCardComments } = useAppSelector((state) => state.persons);
  const [form] = useForm();
  let sortedComments = [...personCardComments].sort((a, b) => {
    if (moment(a.createDate).isAfter(moment(b.createDate))) return -1;
    return 1;
  });

  const deleteUndefined = (str: string | undefined) => {
    if (str) return str;
    return '';
  };

  return (
    <div className="file-comments mt10">
      <Form
        form={form}
        className="mr20 ml20"
        onFinish={(values) => {
          postComment(values.comment);
          form.resetFields();
        }}
      >
        <Form.Item
          name="comment"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите текст комментария',
            },
          ]}
        >
          <TextArea placeholder="Текст комментария" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Добавить комментарий
          </Button>
        </Form.Item>
      </Form>
      <div className="comments-container">
        <h3>Комментрии</h3>
        <div className="divider">
          <Divider className="mt10" />
        </div>
        {sortedComments.map((comment) => {
          return (
            <Comment
              key={comment.id}
              author={`${deleteUndefined(comment.person.lastName)} ${deleteUndefined(
                comment.person.firstName
              )} ${deleteUndefined(comment.person.middleName)}`}
              date={comment.createDate}
              text={comment.value}
            />
          );
        })}
      </div>
    </div>
  );
}

export default FileComments;
