import AuthService from '../../services/AuthService';
import featuresToggle from './featuresToggle';
import featuresConfig from '../configs/features.config';
import { Permission } from '../interfaces/Permission';

export interface HasAccess {
  (permissions: Permission[]): boolean;
}

const currentUser = AuthService.getCurrentUser();
const authorities = currentUser?.authorities || [];

/* Иногда нужно прямое использование как функции, более того по факту, 
здесь не используются встроенные хуки Реакт и именование через use спорно */
export const hasAccess: HasAccess = featuresToggle({
  value: (permissions) => authorities.some((item) => permissions.includes(item)),
  alternativeValue: (): boolean => true,
  condition: featuresConfig.roleSystem,
});

function usePermissions() {
  return [hasAccess];
}

export default usePermissions;
