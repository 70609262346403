import React, { FC, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  AdministrationDictionariesContext,
  CreateDictionariesTypeTermin,
  DeleteDictionariesTypeTermin,
  UpdateDictionariesTypeTermin,
} from './AdministrationDictionariesContext';
import { AdministrationDictionaries } from '../../components/AdministrationDictionaries/AdministrationDictionaries';
import { useMainContext } from '../MainContainer/MainContext';
import { dictionariesTypeTerminRoute } from '../../lib/routes/administrationDictionaries';
import axios from 'axios';
import { Modal } from 'antd';

interface IProps {}

export const AdministrationDictionariesContainer: FC<IProps> = (props) => {
  const { loadDicTypes, loadDicTypesTermin } = useMainContext();

  useEffect(() => {
    loadDicTypes();
  }, []);

  const createDictionariesTypeTermin: CreateDictionariesTypeTermin = ({ id, fromDate, dicTypeId, name }) => {
    return axios
      .post(dictionariesTypeTerminRoute(), {
        id,
        fromDate,
        dicTypeId,
        name,
      })
      .then(() => {
        loadDicTypesTermin(dicTypeId).then(() => {
          Modal.success({
            content: 'Значение добавлено',
          });
        });
      })
      .catch(() => {
        Modal.error({
          content: 'При добавлении значения произошла ошибка. Пожалуйста, повторите попытку позднее.',
        });
      });
  };

  const updateDictionariesTypeTermin: UpdateDictionariesTypeTermin = async (editBody) => {
    return axios
      .put(dictionariesTypeTerminRoute(), editBody)
      .then(() => {
        loadDicTypesTermin(editBody.dicTypeId).then(() => {
          Modal.success({
            content: 'Значение справочника отредактировано',
          });
        });
      })
      .catch(() => {
        Modal.error({
          content: 'Произошла ошибка. Пожалуйста, попробуйте позднее.',
        });
      });
  };

  const deleteDictionariesTypeTermin: DeleteDictionariesTypeTermin = async (deleteBody) => {
    return axios
      .put(dictionariesTypeTerminRoute(), deleteBody)
      .then(() => {
        loadDicTypesTermin(deleteBody.dicTypeId).then(() => {
          Modal.success({
            content: 'Значение справочника перенесено в архив',
          });
        });
      })
      .catch(() => {
        Modal.error({
          content: 'Произошла ошибка. Пожалуйста, попробуйте позднее.',
        });
      });
  };

  return (
    <AdministrationDictionariesContext.Provider
      value={{ createDictionariesTypeTermin, deleteDictionariesTypeTermin, updateDictionariesTypeTermin }}
    >
      <Routes>
        <Route path="/" element={<AdministrationDictionaries />} />
        <Route path="/:dicTypeId" element={<AdministrationDictionaries />} />
      </Routes>
    </AdministrationDictionariesContext.Provider>
  );
};
