import React, { useEffect } from 'react';
import FileProjects from '../../components/FileProjects/FileProjects';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getPersonFullCard, getPersonParticipation } from '../../store/slices/personsSlice/personsSlice';
import { Spin, message } from 'antd';
import { checkIsLoaded, checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import axios from 'axios';
import { updatePersonProjectsVolunteer } from '../../lib/routes/persons';
import { getPersonStatusesDictionary } from '../../store/slices/dictionariesSlice/dictionariesSlice';
import { useDictionaries } from '../../lib/utils/useDictionaries';

function FileProjectsContainer() {
  const { fileId } = useParams();
  const dispatch = useAppDispatch();
  const { participationLoading, personFullCard } = useAppSelector((state) => state.persons);
  const { personStatusesLoading } = useAppSelector((state) => state.dictionaries);
  const { loadDictionaries } = useDictionaries();

  useEffect(() => {
    loadDictionaries(['app_subject']);
    dispatch(getPersonParticipation({ id: Number(fileId) }));
    if (!checkIsLoaded(personStatusesLoading)) dispatch(getPersonStatusesDictionary());
  }, [dispatch]);

  const editProjectsVolunteer = (fields, callBack) => {
    Object.keys(fields).forEach((key) => fields[key] === undefined && delete fields[key]);

    const params = {
      projectIds: fields.projectVolunteerIds.map(Number),
    };

    return axios
      .patch(updatePersonProjectsVolunteer(personFullCard?.id), params)
      .then((response) => {
        dispatch(getPersonFullCard({ id: Number(fileId) }));
        message.destroy();
        message.success('Анкета закреплена за проектами');
        callBack();
        return response;
      })
      .catch((error) => {
        message.error('Произошла ошибка. Пожалуйста, попробуйте позднее');
        return error;
      });
  };

  return (
    <Spin spinning={checkIsLoading(participationLoading)}>
      {checkIsLoaded(participationLoading) && <FileProjects editProjectsVolunteer={editProjectsVolunteer} />}
    </Spin>
  );
}

export default FileProjectsContainer;
