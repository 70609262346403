import React, { useEffect, useState } from 'react';
import './NotificationsDropdown.less';
import { Button, Divider, Row, Table } from 'antd';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { checkIsLoaded, checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { toDisplayFormatWithTime } from '../../lib/utils/formatDate';
import { markAsReadNotification } from '../../store/slices/notificationsSlice/notificationsSlice';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { NotificationTypes } from '../../lib/const/notificationTypes';

interface Props {
  page: number;
  setPage: (number) => void;
  changeDropDown(status: boolean): void;
}

function NotificationsDropdown({ changeDropDown }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const columns = [
    {
      dataIndex: 'createDate',
      key: 'createDate',
      width: '33%',
      render: (item) => toDisplayFormatWithTime(item),
    },
    {
      dataIndex: 'title',
      width: '33%',
    },
    {
      dataIndex: 'body',
      width: '33%',
    },
  ];
  const { unreadNotifications, unreadNotificationsLoading } = useAppSelector((state) => state.notifications);
  const [tableData, setTableData] = useState<any>([]);

  useEffect(() => {
    if (checkIsLoaded(unreadNotificationsLoading)) {
      setTableData(
        unreadNotifications.map((item, index) => {
          return {
            key: item.id,
            id: item.id,
            createDate: item.createDate,
            // @ts-ignore
            title: item.payload.title,
            // @ts-ignore
            body: item.payload.body,
            // @ts-ignore
            data: item.payload.data,
            // @ts-ignore
            readDate: item.readDate,
          };
        })
      );
    }
  }, [unreadNotificationsLoading, unreadNotifications]);

  return (
    <div className="notifications-dropdown">
      <h2>Новые уведомления</h2>
      <Divider />
      <Table
        dataSource={tableData}
        columns={columns}
        showHeader={false}
        loading={checkIsLoading(unreadNotificationsLoading)}
        pagination={{
          pageSize: 300,
          hideOnSinglePage: true,
        }}
        scroll={{ y: 200 }}
        rowClassName={(record, index) => {
          return classNames(
            {
              'even-number': index % 2 !== 0,
              'odd-number': index % 2 === 0,
            },
            { read: Boolean(record.readDate) },
            { unread: Boolean(!record.readDate) }
          );
        }}
        onRow={(record) => ({
          onClick: () => {
            if (record?.data?.link === NotificationTypes.Информация) {
              window.open(`/info`);
              dispatch(markAsReadNotification({ id: record.id }));
            } else if (record?.data?.link === NotificationTypes.Список_Просьб) {
              window.open(`/applications/list`);
              dispatch(markAsReadNotification({ id: record.id }));
            } else {
              window.open(`/applications/list/${record.data.applicationId}/main`);
              dispatch(markAsReadNotification({ id: record.id }));
            }
          },
        })}
      />
      <Row justify="end">
        <Button
          type="primary"
          className="mt5"
          onClick={() => {
            changeDropDown(false);
            navigate('notifications');
          }}
        >
          Посмотреть все
        </Button>
      </Row>
    </div>
  );
}

export default NotificationsDropdown;
