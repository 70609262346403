import buildUrl from '../utils/buildUrl';
import { baseUrl, securityBaseUrl } from './baseUrl';

export const getAuthRoute = () => [`${securityBaseUrl}`, 'security', 'oauth', 'token'].join('/');

export const forgottenPasswordRoute = (login: string) =>
  buildUrl({
    url: `${baseUrl}/gateway/v1/users/forgot-password`,
    searchParams: { login },
  });

export const resetPasswordRoute = ({ token, password }: { token: string; password: string }) =>
  buildUrl({
    url: `${baseUrl}/gateway/v1/users/reset-forgotten-password`,
    searchParams: { token, password },
  });

export const publicConfigurationRoute = (tenantId) =>
  buildUrl({
    url: `${baseUrl}/gateway/v1/configurations/public`,
    searchParams: { tenantId },
  });
