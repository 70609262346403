import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Row, Col, Divider, DatePicker, Input, Form, Tooltip, message, Upload, Modal, Spin } from 'antd';
import './Info.less';
import { InfoNavbar } from './InfoNavbar';
import { useParams } from 'react-router';
import { useInfoContext } from '../../containers/InfoContainer/InfoContext';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { displayFormat, toDisplayFormat } from '../../lib/utils/formatDate';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import { useForm } from 'antd/lib/form/Form';
import { ReactComponent as FileDoc } from '../../lib/img/file-doc.svg';
import { ReactComponent as UploadFileIcon } from '../../lib/img/upload-file-icon.svg';
import { ReactComponent as DeleteFileIcon } from '../../lib/img/delete-file-icon.svg';
import { ReactComponent as FileDocx } from '../../lib/img/formats/docx.svg';
import { ReactComponent as FileJpeg } from '../../lib/img/formats/jpeg.svg';
import { ReactComponent as FileJpg } from '../../lib/img/formats/jpg.svg';
import { ReactComponent as FilePdf } from '../../lib/img/formats/pdf.svg';
import { ReactComponent as FilePng } from '../../lib/img/formats/png.svg';
import { ReactComponent as FileXls } from '../../lib/img/formats/xls.svg';
import { ReactComponent as FileXlsx } from '../../lib/img/formats/xlsx.svg';
import { ReactComponent as PencilIcon } from '../../lib/img/pencil.svg';
import { ReactComponent as CrossIcon } from '../../lib/img/cross.svg';
import { ReactComponent as SubmitIcon } from '../../lib/img/submit-green.svg';
import { uniqueId } from 'lodash';
import axios from 'axios';
import { saveAs } from 'file-saver';
import {
  deleteSectionsIdFileRoute,
  downloadSectionsIdFileRoute,
  getSectionsIdFilesRoute,
} from '../../lib/routes/sections';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { hasAccess } from '../../lib/utils/usePermissions';
import { setSelectedSectionId } from '../../store/slices/sectionsSlice/sectionsSlice';
import { datePikerMaskDefault } from '../../lib/const/masks';
import InputMask from 'react-input-mask';

export const InfoMain: FC = () => {
  const { sectionId } = useParams();
  const dateNow = moment();
  const dispatch = useAppDispatch();
  const { fetchSectionsId, fetchSectionsIdFiles, editSectionId, closedSectionIdDate, fetchSections, isArchivedCheck } =
    useInfoContext();
  const {
    sectionIdData,
    sections,
    sectionIdFilesData,
    sectionsLoading,
    sectionIdDataLoading,
    sectionIdFilesDataLoading,
  } = useAppSelector((state) => state.sections);

  const startDay = moment().startOf('day');

  const [formEdit] = useForm();
  const [formEditDate] = useForm();

  const [isEdit, setIsEdit] = useState(false);
  const [isEditCloseDate, setIsEditCloseDate] = useState(false);

  const [deletedFileName, setDeletedFileName] = useState<string>('');

  const [loadingData, setLoadingData] = useState(false);

  const refBodySection = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsEdit(false);
    setIsEditCloseDate(false);
    formEdit.resetFields();
    formEditDate.resetFields();
  }, [sections, sectionId]);

  useEffect(() => {
    if (sectionId) {
      fetchSectionsId(sectionId);
      hasAccess(['INFO.FILES.READ']) && fetchSectionsIdFiles(sectionId);
      dispatch(setSelectedSectionId(sectionId));
    }
  }, [sectionId]);

  useEffect(() => {
    if (sectionIdData?.section_body && refBodySection?.current) {
      refBodySection.current.innerHTML = sectionIdData?.section_body;
    }
  }, [sectionIdData, refBodySection, isEdit]);

  const onResetEdit = () => {
    setIsEdit(false);
    formEdit.resetFields();
  };

  const fileComponent = {
    doc: FileDoc,
    docx: FileDocx,
    jpeg: FileJpeg,
    jpg: FileJpg,
    pdf: FilePdf,
    png: FilePng,
    xls: FileXls,
    xlsx: FileXlsx,
  };

  const downloadFile = async ({ fileName }) => {
    const url = downloadSectionsIdFileRoute(sectionId, fileName);
    const { data } = await axios.get(url, {
      responseType: 'blob',
    });
    saveAs(data, fileName);
  };

  const renderAttachments = () => {
    const filesList = sectionIdFilesData.map((file) => {
      const fileName = file?.name.replace('/files/', '');
      const fileFormat = file?.name.split('.').pop();

      const SpecificFile = fileFormat && fileComponent[fileFormat];

      return (
        <Col style={{ padding: 0, width: 44, height: 44 }} key={uniqueId()}>
          {hasAccess(['INFO.FILES.DELETE']) && (
            <Tooltip title="Удалить файл">
              <DeleteFileIcon
                style={{ position: 'absolute', top: 3, left: 31 }}
                className="cursor-pointer"
                onClick={() => {
                  setDeletedFileName(file?.name);
                }}
              />
            </Tooltip>
          )}
          <Tooltip title="Скачать файл">
            <SpecificFile
              className="cursor-pointer"
              title={fileName}
              onClick={() => {
                if (hasAccess(['INFO.FILES.DOWNLOAD'])) {
                  downloadFile({ fileName });
                } else {
                  message.error('Скачивание файлов недоступно', 3);
                }
              }}
            />
          </Tooltip>
        </Col>
      );
    });

    const acceptedTypes =
      '.pdf, .doc, .docx, .png, .jpg, .jpeg, .xls, .xlsx, msword, vnd.openxmlformats-officedocument.wordprocessingml.document, vnd.openxmlformats-officedocument.spreadsheetml.sheet, vnd.ms-excel';

    const uploadFiles = ({ file }) => {
      const fileType = file.name.split('.')[file.name.split('.').length - 1];

      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error('Размер файла не должен превышать 5Мб.');
        return;
      }

      if (acceptedTypes.includes(fileType)) {
        if (file.originFileObj !== undefined) {
          const formData = new FormData();
          formData.append('files', file.originFileObj);
          setLoadingData(true);
          axios
            .post(getSectionsIdFilesRoute(sectionId), formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then(() => {
              message.success('Файл добавлен', 3);
              sectionId && fetchSectionsIdFiles(sectionId);
            })
            .catch(() => {
              message.error(
                <>
                  <div>При загрузке файла возникла ошибка.</div>
                  <div>Пожалуйста, повторите попытку позднее</div>
                </>,
                3
              );
            })
            .finally(() => {
              setLoadingData(false);
            });
        }
      } else {
        message.error(
          <>
            <div>Данный формат файлов не поддерживается.</div>
            <div>Пожалуйста, загрузите файл pdf, doc, docx, png, jpg, jpeg, xls, xlsx</div>
          </>,
          3
        );
      }
    };

    return (
      <>
        <h3 className="text-bold mb3">Вложения</h3>
        <Divider className="thin-divider mt0 mb7" />
        <Spin spinning={checkIsLoading(sectionIdFilesDataLoading) || loadingData}>
          <Row className="pl10 mt20" gutter={20}>
            <Col className="display-flex" span={24}>
              {filesList}
              {hasAccess(['INFO.FILES.UPLOAD']) && (
                <Upload
                  accept={acceptedTypes}
                  multiple
                  showUploadList={false}
                  customRequest={() => {}}
                  onChange={uploadFiles}
                >
                  <UploadFileIcon
                    style={{
                      cursor: 'pointer',
                      marginLeft: '15px',
                      position: 'relative',
                      top: 7,
                    }}
                  />
                </Upload>
              )}
            </Col>
          </Row>
        </Spin>
      </>
    );
  };

  const renderDeleteFileModal = () => {
    const handleCloseModal = () => {
      setDeletedFileName('');
    };
    const handleDelete = () => {
      setLoadingData(true);
      axios
        .delete(deleteSectionsIdFileRoute(sectionId, deletedFileName))
        .then(() => {
          message.success('Файл удален');
          sectionId && fetchSectionsIdFiles(sectionId);
        })
        .catch(() => {
          message.error(
            <>
              <div>При удалении файла возникла ошибка.</div>
              <div>Пожалуйста, повторите попытку позднее</div>
            </>,
            3
          );
        })
        .finally(() => {
          handleCloseModal();
          setLoadingData(false);
        });
    };
    return (
      <Modal visible={Boolean(deletedFileName)} width="690px" footer={null} onCancel={handleCloseModal}>
        <h2>Удаление файла</h2>
        <Divider className="thin-divider mt10" />
        <div>Вы действительно хотите удалить выбранный файл: {deletedFileName}?</div>
        <Row gutter={20} className="mt20 mb10">
          <Col className="ml-auto">
            <Button onClick={handleCloseModal}>Нет</Button>
          </Col>
          <Col className="mr-auto">
            <Button type="primary" onClick={handleDelete}>
              Да
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };

  const onFinishEdit = (fields) => {
    if (sectionId) {
      editSectionId(
        sectionId,
        {
          ...fields,
          is_never_closed: sectionIdData?.is_never_closed,
          is_default_section: sectionIdData?.is_default_section,
        },
        () => {
          setIsEdit(false);
          formEdit.resetFields();
          fetchSectionsId(sectionId);
          fetchSections(isArchivedCheck);
          hasAccess(['INFO.FILES.READ']) && fetchSectionsIdFiles(sectionId);
        }
      );
    }
  };

  const onFinishEditDate = (fields) => {
    if (sectionId) {
      closedSectionIdDate(sectionId, fields, () => {
        setIsEditCloseDate(false);
        formEditDate.resetFields();
        fetchSectionsId(sectionId);
        fetchSections(isArchivedCheck);
        hasAccess(['INFO.FILES.READ']) && fetchSectionsIdFiles(sectionId);
      });
    }
  };

  return (
    <div className="info-main">
      <Spin spinning={checkIsLoading(sectionsLoading)}>
        <InfoNavbar />
      </Spin>
      <div className="ml20 mr20" style={{ width: '100%' }}>
        <Spin spinning={checkIsLoading(sectionIdDataLoading)}>
          {!isEdit ? (
            <Row gutter={[0, 20]}>
              {sectionIdData && (
                <>
                  <Col span={24}>
                    <h3 className="mb5 text-bold">Дата создания</h3>
                    <Divider className="thin-divider mt0 mb10" />
                    <div style={{ fontFamily: 'Roboto, sans-serif' }} className="font-size-14">
                      {toDisplayFormat(moment(sectionIdData?.fromDate, 'YYYY-MM-DD'))}
                    </div>
                  </Col>
                  <Col span={24} style={{ marginBottom: isEditCloseDate ? '-16px' : '' }}>
                    <h3 className="mb5 text-bold">Дата закрытия</h3>
                    <Divider className="thin-divider mt0 mb10" />
                    <Row>
                      {isEditCloseDate ? (
                        <>
                          <Col>
                            <Form form={formEditDate} onFinish={onFinishEditDate}>
                              <Form.Item
                                name="onDate"
                                style={{ marginBottom: '0px !important' }}
                                initialValue={dateNow}
                              >
                                <DatePicker
                                  disabledDate={(date) => date.isBefore(moment(sectionIdData?.fromDate).startOf('day'))}
                                  format={displayFormat}
                                  inputRender={(props) => {
                                    return <InputMask {...props} mask={datePikerMaskDefault} maskChar={null} />;
                                  }}
                                />
                              </Form.Item>
                            </Form>
                          </Col>
                        </>
                      ) : (
                        <Col style={{ fontFamily: 'Roboto, sans-serif' }} className="font-size-14 pt4">
                          {toDisplayFormat(moment(sectionIdData?.toDate))}
                        </Col>
                      )}
                      {hasAccess(['INFO.DELETE']) && !sectionIdData?.is_never_closed && (
                        <Col style={{ marginLeft: 10 }}>
                          <Row>
                            {!isEditCloseDate && (
                              <Tooltip title="Изменить дату закрытия">
                                <Button
                                  icon={
                                    <PencilIcon
                                      style={{ position: 'relative', top: '1px' }}
                                      onClick={() => {
                                        formEditDate.setFieldsValue({ onDate: dateNow });
                                        setIsEditCloseDate(true);
                                      }}
                                    />
                                  }
                                  className="button-icon"
                                  type="text"
                                />
                              </Tooltip>
                            )}
                            {isEditCloseDate && (
                              <>
                                <Tooltip title="Отмена">
                                  <Button
                                    icon={
                                      <CrossIcon
                                        style={{ position: 'relative', top: '5px' }}
                                        onClick={() => {
                                          setIsEditCloseDate(false);
                                          formEditDate.resetFields();
                                        }}
                                      />
                                    }
                                    className="button-icon mr10"
                                    type="text"
                                  />
                                </Tooltip>
                                <Tooltip title="Применить">
                                  <Button
                                    icon={
                                      <SubmitIcon
                                        style={{ position: 'relative', top: '5px' }}
                                        onClick={formEditDate.submit}
                                        width={24}
                                        height={23}
                                        viewBox="-5 0 30 18"
                                      />
                                    }
                                    className="button-icon"
                                    type="text"
                                  />
                                </Tooltip>
                              </>
                            )}
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col span={24}>
                    <h3 className="mb5 text-bold">{sectionIdData?.section_name || ''}</h3>
                    <Divider className="thin-divider mt0 mb10" />
                    <div
                      style={{ fontFamily: 'Roboto, sans-serif' }}
                      ref={refBodySection}
                      className="font-size-14"
                      // dangerouslySetInnerHTML={{ __html: sectionIdData?.section_body || '' }}
                    />
                  </Col>
                  {hasAccess(['INFO.FILES.READ']) && (
                    <>
                      <Col span={24}>{renderAttachments()}</Col>
                      {renderDeleteFileModal()}
                    </>
                  )}
                </>
              )}
            </Row>
          ) : (
            <Row gutter={[0, 0]}>
              <Form
                form={formEdit}
                style={{ width: '100%' }}
                initialValues={{
                  section_body: sectionIdData?.section_body,
                  section_name: sectionIdData?.section_name,
                }}
                onFinish={onFinishEdit}
              >
                <Col span={24}>
                  <Form.Item
                    label="Заголовок"
                    name="section_name"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: '',
                      },
                    ]}
                  >
                    <Input disabled={sectionIdData?.is_never_closed} maxLength={64} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Описание"
                    name="section_body"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: '',
                      },
                    ]}
                  >
                    <TextArea value={sectionIdData?.section_body} rows={10} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row justify="start" gutter={20}>
                    <Col>
                      <Button onClick={onResetEdit} style={{ width: '150px' }}>
                        Отмена
                      </Button>
                    </Col>
                    <Col>
                      <Button className="administration-users-searchBtn" type="primary" htmlType="submit">
                        Сохранить
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Form>
            </Row>
          )}
        </Spin>
      </div>
      <div className="info-right-bar">
        <Row className="mb20">
          <Col span={24}>
            {!isEdit &&
              !moment(sectionIdData?.toDate).isBefore(startDay) &&
              sectionIdData &&
              hasAccess(['INFO.WRITE']) && (
                <Button
                  type="primary"
                  className="display-flex"
                  onClick={() => {
                    formEdit.setFieldsValue({
                      section_body: sectionIdData?.section_body,
                      section_name: sectionIdData?.section_name,
                    });
                    setIsEdit(true);
                  }}
                >
                  <PencilIcon className="ml20" />
                  <div className="ml6">Редактировать</div>
                </Button>
              )}
          </Col>
        </Row>
      </div>
    </div>
  );
};
