import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Form, Input, Button, Divider, Modal, Spin } from 'antd';
import { ReactComponent as ListPoint } from '../../lib/img/list-point.svg';
import { resetPasswordRoute } from '../../lib/routes/routes';
import './PasswordRecovery.less';

const PasswordRecovery = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [recoveryForm] = Form.useForm();
  const [loadingData, setLoadingData] = useState(false);

  const handleSubmit = ({ password }) => {
    setLoadingData(true);
    const token = searchParams.get('token') || '';
    axios
      .post(
        resetPasswordRoute({
          password,
          token,
        })
      )
      .then(() => {
        Modal.success({
          content: (
            <>
              <div>Пароль успешно сохранён</div>
              <div>Используйте новый пароль для входа</div>
            </>
          ),
          className: 'password-forgotten-info-modal',
          width: '530px',
          bodyStyle: {
            backgroundColor: '#F4F4F4',
            paddingTop: '20px',
            marginTop: '111px',
          },
          okButtonProps: {
            style: {
              marginRight: '158px',
            },
          },
          okText: 'Войти',
          onOk: () => {
            navigate('/login');
          },
        });
      })
      .catch(() => {
        Modal.error({
          title: 'Произошла ошибка',
          content: 'Пожалуйста, попробуйте позднее',
          className: 'password-recovery-info-modal',
          width: '530px',
          bodyStyle: {
            backgroundColor: '#F4F4F4',
            paddingTop: '20px',
            marginTop: '111px',
          },
          okButtonProps: {
            style: {
              marginRight: '158px',
            },
          },
        });
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  const repeatedPasswordValidator = (field, repeatedPassword) => {
    if (!repeatedPassword) return Promise.resolve();
    const password = recoveryForm.getFieldValue('password');
    if (repeatedPassword !== password) {
      return Promise.reject();
    }
    return Promise.resolve();
  };

  const passwordValidator = (field, password) => {
    if (!password) return Promise.resolve();

    const isValidLength = password.length >= 8;
    const hasLetters = /[a-zA-Z]/.test(password);
    const hasNumOrSymbol = /\W|\d/.test(password);
    const hasNotWhitespace = !/[ ]/.test(password);
    const hasNotCyrillic = !/[а-яА-Я]/.test(password);
    const isValidPassword = isValidLength && hasLetters && hasNumOrSymbol && hasNotWhitespace && hasNotCyrillic;

    return isValidPassword ? Promise.resolve() : Promise.reject();
  };

  const getListItem = (name: string): React.ReactElement<any, any> => (
    <div className="mt10">
      <ListPoint className="mr10" />
      {name}
    </div>
  );

  return (
    <div className="password-recovery-container">
      <div className="password-recovery-innerContainer">
        <div className="logo mb15" />
        <h1>Смена пароля</h1>
        <Divider />
        <Spin spinning={loadingData}>
          <Form
            form={recoveryForm}
            name="basic"
            initialValues={{ remember: true }}
            layout="vertical"
            onFinish={handleSubmit}
          >
            <div className="font-roboto text-left ml5">
              Для восстановления доступа к аккаунту, создайте новый
              <br />
              пароль. Надежный пароль должен содержать:
              {getListItem('Длина – не менее 8 символов')}
              {getListItem('Цифры')}
              {getListItem('Хотя бы одну букву')}
            </div>
            <Form.Item
              label="Новый пароль"
              name="password"
              rules={[
                { required: true, message: '' },
                {
                  validator: passwordValidator,
                  message: 'Пароль ненадежный, введите другой пароль',
                },
              ]}
              className="password-recovery-password mt10"
            >
              <Input.Password placeholder="Введите пароль" allowClear maxLength={64} />
            </Form.Item>
            <Form.Item
              label="Подтверждение нового пароля"
              name="repeatedPassword"
              dependencies={['password']}
              rules={[
                { required: true, message: '' },
                {
                  validator: repeatedPasswordValidator,
                  message: 'Пароли не совпадают',
                },
              ]}
              className="password-recovery-password"
            >
              <Input.Password placeholder="Повторите введенный пароль" allowClear />
            </Form.Item>
            <div className="mt30">
              <Form.Item shouldUpdate>
                {({ getFieldsValue }) => {
                  const { password, repeatedPassword } = getFieldsValue();
                  return (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="ml20 password-recovery-submitBtn"
                      disabled={!password && !repeatedPassword}
                    >
                      Сохранить
                    </Button>
                  );
                }}
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default PasswordRecovery;
