import { createSlice, createAsyncThunk, Draft, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { Modal } from 'antd';
import { changeApi } from '../../../lib/routes/baseUrl';
import { RequestStatuses } from '../../../lib/types/RequestStatuses';
import requestStatusCodes from '../../../lib/const/requestStatusCodes';
import { Config } from '../../../lib/types/config';

interface InitialState {
  configLoading: RequestStatuses;
  config?: Config;
}

const initialState: InitialState = {
  configLoading: requestStatusCodes.idle,
  config: {},
};

export const fetchConfigData = createAsyncThunk('config/fetchConfigData', async () => {
  try {
    // if (window.location.hostname === 'localhost') {
    //   console.warn('Config loading skipped on localhost.');
    //   return; // Прерываем выполнение, если приложение запущено на localhost
    // }

    const { data } = await axios.get<Config>(`${process.env.PUBLIC_URL}/config.json`);

    changeApi(data);

    return data;
  } catch (e) {
    const errorOptions = {
      title: 'Ошибка загрузки файла конфигурации',
      content: 'Что-то пошло не так... ',
    };
    if (e instanceof Error) {
      errorOptions.content = e.message;
    }
    Modal.error(errorOptions);
    throw new Error(errorOptions.content);
  }
});

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchConfigData.pending, (state) => {
        state.configLoading = requestStatusCodes.pending;
      })
      .addCase(fetchConfigData.rejected, (state) => {
        state.configLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchConfigData.fulfilled, (state, action: PayloadAction<Config | undefined>) => {
        state.configLoading = requestStatusCodes.fulfilled;
        state.config = action.payload;
      }),
});

const { actions, reducer } = configSlice;

export { reducer };
