import React, { FC } from 'react';
import { AppTypes } from '../../lib/const/appTypes';
import { SearchApplicationsState } from '../../containers/MainContainer/useSearchApplications';

interface IProps {
  style?: React.CSSProperties;
  className?: string;
  searchApplicationsState: SearchApplicationsState;
}

const ApplicationTypeIdButtons: FC<IProps> = ({ style, className, searchApplicationsState }) => {
  const containerButtonClassName = 'display-flex' + (className ? ' ' + className : '');

  return (
    <div className={containerButtonClassName} style={style}>
      <div
        onClick={() => {
          searchApplicationsState.handleSetSearchFormFields({
            appTypeId: [AppTypes['Денежная']],
          });
        }}
      >
        <svg
          style={{ marginTop: '-11px', cursor: 'pointer' }}
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28" cy="28" r="28" fill="#55AEEF" />
          <g filter="url(#filter0_i_13010_16174)">
            <ellipse cx="27.9997" cy="28.2749" rx="24.1188" ry="23.8824" fill="white" />
          </g>
          <path
            d="M50.6185 28.2749C50.6185 40.6225 40.5057 50.6573 27.9997 50.6573C15.4937 50.6573 5.38086 40.6225 5.38086 28.2749C5.38086 15.9274 15.4937 5.89258 27.9997 5.89258C40.5057 5.89258 50.6185 15.9274 50.6185 28.2749Z"
            stroke="white"
            stroke-width="3"
          />
          <ellipse
            cx="25.6892"
            cy="29.6058"
            rx="12.9373"
            ry="13.2171"
            fill={
              searchApplicationsState.getSearchFormFieldValue('appTypeId')[0] === AppTypes['Денежная']
                ? '#C84015'
                : '#C2C2C2'
            }
          />
          <ellipse cx="30.8634" cy="28.5493" rx="10.8673" ry="10.5737" fill="white" />
          <path
            d="M32.3661 26.9898H28.666V22.7973L28.6674 22.5251L28.6701 22.466C28.6815 22.3952 28.7144 22.3297 28.7641 22.2789C28.8138 22.2281 28.8778 22.1945 28.9472 22.1829C29.0548 22.1799 29.1625 22.1785 29.2701 22.1787H32.3661C32.9906 22.1787 33.5895 22.4322 34.031 22.8833C34.4726 23.3344 34.7207 23.9463 34.7207 24.5843C34.7207 25.2222 34.4726 25.8341 34.031 26.2852C33.5895 26.7363 32.9906 26.9898 32.3661 26.9898Z"
            fill={
              searchApplicationsState.getSearchFormFieldValue('appTypeId')[0] === AppTypes['Денежная']
                ? '#FF611E'
                : '#D9D9D9'
            }
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M43.8022 28.0202C43.8022 35.6121 37.7784 41.7661 30.3474 41.7661C22.9163 41.7661 16.8926 35.6121 16.8926 28.0202C16.8926 20.4284 22.9163 14.2744 30.3474 14.2744C37.7784 14.2744 43.8022 20.4284 43.8022 28.0202ZM25.3018 30.7694C25.3018 30.496 25.4081 30.2338 25.5974 30.0404C25.7866 29.8471 26.0433 29.7385 26.3109 29.7385H26.6473V29.0512H26.3109C26.0433 29.0512 25.7866 28.9426 25.5974 28.7492C25.4081 28.5559 25.3018 28.2937 25.3018 28.0202C25.3018 27.7468 25.4081 27.4846 25.5974 27.2913C25.7866 27.0979 26.0433 26.9893 26.3109 26.9893H26.6473V22.7446C26.6473 22.5439 26.6473 22.3363 26.6769 22.1453C26.7548 21.6438 26.986 21.1805 27.3374 20.8214C27.6888 20.4624 28.1424 20.2262 28.6332 20.1466C28.8202 20.1164 29.0234 20.1164 29.2185 20.1164H32.3656C33.5253 20.1164 34.6376 20.5871 35.4576 21.4249C36.2777 22.2627 36.7384 23.399 36.7384 24.5838C36.7384 25.7686 36.2777 26.9049 35.4576 27.7427C34.6376 28.5805 33.5253 29.0512 32.3656 29.0512H28.6655V29.7385H30.3474C30.615 29.7385 30.8717 29.8471 31.0609 30.0404C31.2502 30.2338 31.3565 30.496 31.3565 30.7694C31.3565 31.0428 31.2502 31.305 31.0609 31.4984C30.8717 31.6917 30.615 31.8003 30.3474 31.8003H28.6655V34.8932C28.6655 35.1666 28.5592 35.4288 28.37 35.6221C28.1807 35.8155 27.924 35.9241 27.6564 35.9241C27.3888 35.9241 27.1321 35.8155 26.9429 35.6221C26.7536 35.4288 26.6473 35.1666 26.6473 34.8932V31.8003H26.3109C26.0433 31.8003 25.7866 31.6917 25.5974 31.4984C25.4081 31.305 25.3018 31.0428 25.3018 30.7694Z"
            fill={
              searchApplicationsState.getSearchFormFieldValue('appTypeId')[0] === AppTypes['Денежная']
                ? '#FF611E'
                : '#D9D9D9'
            }
          />
          <defs>
            <filter
              id="filter0_i_13010_16174"
              x="3.88086"
              y="4.39258"
              width="48.2383"
              height="51.7646"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="12.5" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
              <feBlend mode="normal" in2="shape" result="effect1_innerShadow_13010_16174" />
            </filter>
          </defs>
        </svg>
      </div>

      <div
        className="ml40"
        onClick={() => {
          searchApplicationsState.handleSetSearchFormFields({
            appTypeId: [AppTypes['Стандартная']],
          });
        }}
      >
        <svg
          style={{ marginTop: '-11px', cursor: 'pointer' }}
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28" cy="28" r="28" fill="#55AEEF" />
          <g filter="url(#filter0_i_14162_9027)">
            <ellipse cx="27.7227" cy="28.2744" rx="23.8416" ry="23.8824" fill="white" />
          </g>
          <path
            d="M50.0643 28.2744C50.0643 40.6383 40.0592 50.6568 27.7227 50.6568C15.3862 50.6568 5.38116 40.6383 5.38116 28.2744C5.38116 15.9106 15.3862 5.89209 27.7227 5.89209C40.0592 5.89209 50.0643 15.9106 50.0643 28.2744Z"
            stroke="white"
            stroke-width="3"
          />
          <g clip-path="url(#clip0_14162_9027)">
            <path
              d="M27.9982 40.6245C30.0534 40.6245 32.1077 40.6245 34.1629 40.6245C34.229 40.6245 34.2941 40.6225 34.3602 40.6206C36.5289 40.5545 38.2635 39.1834 38.4776 37.1721C38.7341 34.7515 38.4391 32.3792 37.2975 30.1805C36.674 28.979 35.7268 28.1287 34.3948 27.7569C33.4703 27.4984 32.5833 27.4649 31.786 28.1317C31.5739 28.3093 31.3252 28.4473 31.0845 28.5884C29.4822 29.5314 27.7989 29.8352 26.0446 29.0885C25.3105 28.7758 24.6524 28.2915 23.9331 27.9403C23.5651 27.7608 23.1339 27.5961 22.7373 27.6089C20.9159 27.6671 19.6076 28.5904 18.764 30.1745C17.6481 32.2716 17.3708 34.5512 17.5385 36.8693C17.6757 38.7612 18.8774 40.0573 20.6643 40.4726C21.1231 40.5791 21.6076 40.6156 22.0812 40.6196C24.0535 40.6354 26.0259 40.6265 27.9992 40.6245H27.9982ZM21.7901 21.4537C21.7901 24.8342 24.4728 27.5201 27.8571 27.526C31.2305 27.532 33.9429 24.844 33.9587 21.4784C33.9735 18.1049 31.2394 15.3686 27.8571 15.3726C24.4886 15.3765 21.7901 18.0812 21.7901 21.4537Z"
              fill="white"
            />
            <path
              d="M27.9982 40.6247C26.0258 40.6247 24.0525 40.6345 22.0802 40.6187C21.6066 40.6148 21.1231 40.5793 20.6633 40.4718C18.8774 40.0575 17.6747 38.7613 17.5385 36.8694C17.3708 34.5514 17.6481 32.2718 18.765 30.1737C19.6076 28.5895 20.9169 27.6663 22.7383 27.6081C23.1349 27.5952 23.5661 27.76 23.9341 27.9395C24.6534 28.2907 25.3125 28.775 26.0456 29.0877C27.7989 29.8344 29.4831 29.5306 31.0855 28.5876C31.3262 28.4465 31.5739 28.3084 31.787 28.1309C32.5832 27.4631 33.4712 27.4976 34.3958 27.756C35.7268 28.1279 36.674 28.9782 37.2985 30.1796C38.4401 32.3783 38.7351 34.7516 38.4786 37.1713C38.2655 39.1815 36.5299 40.5526 34.3612 40.6197C34.2951 40.6217 34.23 40.6237 34.1639 40.6237C32.1087 40.6237 30.0544 40.6237 27.9992 40.6237L27.9982 40.6247Z"
              fill={
                searchApplicationsState.getSearchFormFieldValue('appTypeId')[0] === AppTypes['Стандартная']
                  ? '#1C9AF7'
                  : '#C2C2C2'
              }
            />
            <path
              d="M22.0747 21.4537C22.0747 18.0812 24.7733 15.3765 28.1417 15.3726C31.524 15.3686 34.2581 18.1049 34.2423 21.4784C34.2265 24.843 31.5142 27.532 28.1408 27.526C24.7575 27.5201 22.0737 24.8342 22.0737 21.4537H22.0747Z"
              fill={
                searchApplicationsState.getSearchFormFieldValue('appTypeId')[0] === AppTypes['Стандартная']
                  ? '#1C9AF7'
                  : '#C2C2C2'
              }
            />
          </g>
          <defs>
            <filter
              id="filter0_i_14162_9027"
              x="3.88116"
              y="4.39209"
              width="47.6832"
              height="51.7646"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="12.5" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
              <feBlend mode="normal" in2="shape" result="effect1_innerShadow_14162_9027" />
            </filter>
            <clipPath id="clip0_14162_9027">
              <rect width="21.0693" height="25.2549" fill="white" transform="translate(17.1881 15.3726)" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default ApplicationTypeIdButtons;
