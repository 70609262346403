import React, { FC, useState, useEffect } from 'react';
import { Button, Col, Divider, Form, Input, Modal, Row, Spin, Tooltip } from 'antd';
import { useMainContext } from '../../containers/MainContainer/MainContext';
import { useAppSelector } from '../../store/hooks';
import './AdministrationRoles.less';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { EditButtonTitle } from '../common/EditButtonTitle';
import { TransferIcon } from '../../lib/img/TransferIcon';
import { useAdministrationRolesContext } from '../../containers/AdministrationRolesContainer/AdministrationRolesContext';
import { RolesTable } from './RolesTable';
import { RolesPermissionsTableRecord, RolesTableRecord } from '../../lib/interfaces/Roles';
import { PermissionsTable } from './PermissionsTable';
import { AllPermissionsTable } from './AllPermissionsTable';
import { OnRow } from './interfaces';
import { ErrorDeleteRoleModal } from './ErrorDeleteRoleModal';
import { DeleteRoleModal } from './DeleteRoleModal';
import axios from 'axios';
import { searchRolesUserRoute } from '../../lib/routes/roles';
import { CreateRoleModal } from './CreateRoleModal';
import { useParams } from 'react-router-dom';

const tableHeight = 450;

interface IProps {}

export const AdministrationRoles: FC<IProps> = (props) => {
  const roleId = Number(useParams().roleId);

  const { hasAccess } = useMainContext();
  const { deleteRole, updateRole, saveCurrentRoleId } = useAdministrationRolesContext();

  const [editMode, setEditMode] = useState(false);
  const [activeModal, setActiveModal] = useState<'create' | 'delete' | 'error' | ''>('');

  const { roles, rolesLoading, permissions } = useAppSelector((state) => state.roles);

  const { currentRoleId } = useAppSelector((state) => state.administration);

  const [rolesForm] = Form.useForm();

  const [rolesTableData, setRolesTableData] = useState<RolesTableRecord[]>([]);
  const [selectedRole, setSelectedRole] = useState<RolesTableRecord>();

  const [listPersonForRoleError, setListPersonForRoleError] = useState<string[]>([]);

  const [roleName, setRoleName] = useState<string>('');

  // Сохранение id роли, при первом входе
  useEffect(() => {
    if (roleId && !currentRoleId) {
      saveCurrentRoleId(roleId);
    }
  }, [roleId, currentRoleId]);

  const errorRoleNameModal = (type: 'dsc' | 'alias') => {
    type === 'dsc'
      ? Modal.error({
          title: (
            <>
              <h2 className="mb0 text-bold">Ошибка в названии роли</h2>
              <Divider className="thin-divider mt0" />
            </>
          ),
          content: (
            <>
              <div>{`Роль с таким названием уже существует.`}</div>
              <div>Пожалуйста, измените название.</div>
            </>
          ),
          bodyStyle: { width: '450px', backgroundColor: '#D0D8DA' },
        })
      : Modal.error({
          title: (
            <>
              <h2 className="mb0 text-bold">Ошибка в техническом названии роли</h2>
              <Divider className="thin-divider mt0" />
            </>
          ),
          content: (
            <>
              <div>{`Роль с таким техническим названием уже существует.`}</div>
              <div>Пожалуйста, измените название.</div>
            </>
          ),
          bodyStyle: { width: '450px', backgroundColor: '#D0D8DA' },
        });
  };

  const checkNameExisting = (name: string, type: 'dsc' | 'alias') => {
    const isNameExists = roles?.content
      // Если редактирование проекта, игнорирование в исключениях названия текущего проекта
      .filter(({ id }) => (activeModal === '' ? id !== selectedRole?.id : true))
      .some((roleItem) => roleItem[type]?.toLowerCase().trim() === name?.toLowerCase().trim());
    return isNameExists;
  };

  // Заполнение таблицы ролей
  useEffect(() => {
    if (roles) {
      const listRoles = roles.content
        ?.map((roles) => {
          const roleNew = {
            ...roles,
            checked: roleId === roles.id,
            key: roles.id,
          };

          if (roleId === roles.id) {
            setSelectedRole(roleNew);
          }
          return roleNew;
        })
        .sort((a, b) => {
          return a.alias > b.alias ? 1 : -1;
        });
      setRolesTableData(listRoles);
    }
  }, [roles, roleId]);

  // Сброс поиска и режима редактирования при переключении проекта
  useEffect(() => {
    setEditMode(false);
  }, [roleId]);

  // Загрузка текущей роли
  useEffect(() => {
    if (roles) {
      const listRoles = roles.content
        ?.map((roles) => {
          const roleNew = {
            ...roles,
            checked: roleId === roles.id,
            key: roles.id,
          };

          if (roleId === roles.id) {
            setSelectedRole(roleNew);
          }

          return roleNew;
        })
        .sort((a, b) => {
          return a.alias > b.alias ? 1 : -1;
        });
      setRolesTableData(listRoles);
    }
  }, [roleId]);

  const onClickDeleteRole = async () => {
    if (selectedRole) {
      await axios
        .get(searchRolesUserRoute({ includedRoleId: selectedRole?.id }))
        .then((response) => {
          if (response.data.length > 0) {
            setListPersonForRoleError(response.data.map((person) => person.login));
            setActiveModal('error');
          } else {
            deleteRole(selectedRole?.id).then(closeModal);
          }
        })
        .catch(() => {
          Modal.error({
            content: 'Произошла ошибка. Пожалуйста, попробуйте позднее',
          });
        });
    }
  };

  const [permissionsTableData, setPermissionsTableData] = useState<RolesPermissionsTableRecord[]>([]);

  const initializePermissionsTableData = () => {
    if (selectedRole && permissions) {
      const permissionsIds = selectedRole.includedPermissions.map(({ id }) => id);
      const list: RolesPermissionsTableRecord[] = [
        ...selectedRole?.includedPermissions,
        ...permissions.content.filter(({ id }) => !permissionsIds.includes(id)),
      ]
        .map((permission) => ({
          id: permission.id || 0,
          key: permission.id || 0,
          alias: permission.alias,
          dsc: permission.dsc,
          checked: false,
          isParticipant: permissionsIds.includes(permission.id),
        }))
        .sort((a, b) => (a.dsc > b.dsc ? 1 : -1));
      setPermissionsTableData(list);
    }
  };

  const checkAllRolesPermissions = (checked: boolean): void => {
    setPermissionsTableData((prev) =>
      prev.map((permissiion) => {
        return {
          ...permissiion,
          checked: permissiion.isParticipant ? checked : permissiion.checked,
        };
      })
    );
  };

  const toggleCheckAllRolesPermissions = (id: number): void => {
    setPermissionsTableData((prev) =>
      prev.map((permission) => ({
        ...permission,
        checked: id === permission.id && permission.isParticipant ? !permission.checked : permission.checked,
      }))
    );
  };

  const onRowRolesPermissions: OnRow = ({ id }) => ({
    onClick: () => (editMode ? toggleCheckAllRolesPermissions(id) : {}),
  });

  const checkAllRolesPermissionAll = (checked: boolean): void => {
    setPermissionsTableData((prev) =>
      prev.map((permission) => {
        return {
          ...permission,
          checked: !permission.isParticipant ? checked : permission.checked,
        };
      })
    );
  };

  const toggleCheckRolesPermissionAll = (id: number): void => {
    setPermissionsTableData((prev) =>
      prev.map((permission) => ({
        ...permission,
        checked: id === permission.id && !permission.isParticipant ? !permission.checked : permission.checked,
      }))
    );
  };

  const onRowRolesPermissionsAll: OnRow = ({ id }) => ({
    onClick: () => (editMode ? toggleCheckRolesPermissionAll(id) : {}),
  });

  const transferPersons = (from: 'participants' | 'all') => {
    switch (from) {
      case 'participants':
        setPermissionsTableData((prev) =>
          prev.map((permission) =>
            permission.isParticipant && permission.checked
              ? {
                  ...permission,
                  checked: false,
                  isParticipant: false,
                }
              : permission
          )
        );
        break;
      case 'all':
        setPermissionsTableData((prev) =>
          prev.map((permission) =>
            !permission.isParticipant && permission.checked
              ? {
                  ...permission,
                  checked: false,
                  isParticipant: true,
                }
              : permission
          )
        );
    }
  };

  // Заполнение данных привелегий роли
  useEffect(() => {
    initializePermissionsTableData();
    rolesForm.resetFields();
    setRoleName('');
  }, [selectedRole]);

  const scroll = {
    y: `${tableHeight - 60}px`,
  };

  const permissionsRoleTableData = permissionsTableData.filter(({ isParticipant }) => isParticipant);

  const allPermissionsTableData = permissionsTableData.filter(({ isParticipant }) => !isParticipant);

  const formOnValuesChanged = (values) => {
    setRoleName(values.dsc);
  };

  const projectHeader = (
    <Row>
      <Col span={8}>
        {hasAccess(['ADMINISTRATION.ADDROLE']) && (
          <Button className="administration-roles-addBtn" onClick={() => setActiveModal('create')} type="primary">
            Создать роль
          </Button>
        )}
      </Col>
      <Col span={16} className="mt3" style={{ marginTop: editMode ? '-6.2px' : '' }}>
        <Spin spinning={checkIsLoading(rolesLoading)}>
          <Form form={rolesForm} onValuesChange={formOnValuesChanged}>
            <Row gutter={16}>
              <Col span={12} className="font-size-16">
                {editMode ? (
                  <Form.Item
                    className="mb0 mt0"
                    name="dsc"
                    label="Название"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: '',
                      },
                    ]}
                    initialValue={selectedRole ? selectedRole?.dsc : ''}
                  >
                    <Input className="ml4" value={selectedRole?.dsc} />
                  </Form.Item>
                ) : (
                  <Form.Item label="Название" className="mb0 text-bold font-size-18">
                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                      <Tooltip title={selectedRole?.dsc}>{selectedRole?.dsc}</Tooltip>
                    </div>
                  </Form.Item>
                )}
              </Col>
              <Col offset={1} span={11} className="font-size-16">
                {editMode ? (
                  <Form.Item
                    className="mb0 mt0"
                    name="alias"
                    label="Техническое название"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: '',
                      },
                    ]}
                    initialValue={selectedRole ? selectedRole?.alias : ''}
                  >
                    <Input disabled className="ml4" value={selectedRole?.alias} />
                  </Form.Item>
                ) : (
                  <Form.Item label="Техническое название" className="mb0 text-bold font-size-18">
                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                      <Tooltip title={selectedRole?.alias}>{selectedRole?.alias}</Tooltip>
                    </div>
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Form>
        </Spin>
        {!editMode && <Divider className="thin-divider mt5" />}
      </Col>
    </Row>
  );

  const discardEdit = () => {
    setEditMode(false);
    initializePermissionsTableData();
    rolesForm.resetFields();
  };

  const onClickEditRole = () => {
    if (selectedRole) {
      if (checkNameExisting(roleName || selectedRole.dsc, 'dsc')) {
        return errorRoleNameModal('dsc');
      }

      const onChangeEditMode = (value: boolean) => {
        setEditMode(value);
      };

      updateRole(
        {
          roleId: selectedRole?.id,
          body: {
            id: selectedRole.id,
            dsc: roleName || selectedRole.dsc,
            alias: selectedRole.alias,
            tenantId: selectedRole.tenantId,
            includedPermissions: permissionsTableData.flatMap((permission) =>
              permission.isParticipant ? { id: permission.id, alias: permission.alias, dsc: permission.dsc } : []
            ),
          },
        },
        onChangeEditMode
      );
    }
  };

  const buttons = (
    <Row className="mt20" justify="end">
      {editMode && selectedRole ? (
        <>
          <Button className="mr20" onClick={discardEdit}>
            Отменa
          </Button>
          <Button type="primary" onClick={onClickEditRole}>
            Сохранить
          </Button>
        </>
      ) : (
        <>
          {hasAccess(['ADMINISTRATION.ROLE.WRITE']) && (
            <>
              <Button
                className="mr20"
                onClick={() => {
                  setActiveModal('delete');
                }}
              >
                Удалить
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setEditMode(true);
                }}
              >
                <EditButtonTitle />
              </Button>
            </>
          )}
        </>
      )}
    </Row>
  );

  const closeModal = () => {
    setActiveModal('');
  };

  return (
    <div className="administration-roles">
      {projectHeader}
      <Row gutter={15} style={{ height: `${tableHeight}px` }}>
        <Col span={8}>
          <RolesTable dataSource={rolesTableData} scroll={scroll} />
        </Col>
        <Col span={8}>
          <PermissionsTable
            editMode={editMode}
            dataSource={permissionsRoleTableData}
            scroll={scroll}
            onRow={onRowRolesPermissions}
            ckeckAll={checkAllRolesPermissions}
          />
        </Col>
        <Col span={8}>
          <Row gutter={10}>
            <Col span={2} className="mt193">
              {editMode && (
                <>
                  <TransferIcon
                    side="right"
                    active={permissionsRoleTableData.some(({ checked }) => checked)}
                    onClick={() => transferPersons('participants')}
                  />
                  <TransferIcon
                    active={allPermissionsTableData.some(({ checked }) => checked)}
                    onClick={() => transferPersons('all')}
                  />
                </>
              )}
            </Col>

            <Col span={22}>
              <AllPermissionsTable
                editMode={editMode}
                scroll={scroll}
                dataSource={allPermissionsTableData}
                onRow={onRowRolesPermissionsAll}
                ckeckAll={checkAllRolesPermissionAll}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {buttons}
      <DeleteRoleModal closeModal={closeModal} open={activeModal === 'delete'} onClickDeleteRole={onClickDeleteRole} />
      <ErrorDeleteRoleModal
        closeModal={closeModal}
        open={activeModal === 'error'}
        listPersonForRoleError={listPersonForRoleError}
      />
      <CreateRoleModal
        closeModal={closeModal}
        open={activeModal === 'create'}
        checkNameExisting={checkNameExisting}
        errorRoleNameModal={errorRoleNameModal}
      />
    </div>
  );
};
