import React from 'react';
import check from '../../lib/img/flag-check.svg';
import notCheck from '../../lib/img/flag-not-check.svg';

interface Props {
  checked: boolean;
  onCheck: () => void;
  style?: React.CSSProperties;
}

function CheckFavoriteApplication({ checked, onCheck, style }: Props) {
  return (
    <img
      onClick={(e) => {
        e.stopPropagation();
        onCheck();
      }}
      alt=""
      style={{ ...style, cursor: 'pointer' }}
      src={checked ? check : notCheck}
    ></img>
  );
}

export default CheckFavoriteApplication;
