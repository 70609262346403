import { Button, Col, Divider, Modal, Row } from 'antd';
import React, { FC } from 'react';

interface IProps {
  closeModal: () => void;
  open: boolean;
  onClickDeleteTerminApply: () => void;
}

export const DeleteDictionariesTerminModal: FC<IProps> = ({ open, closeModal, onClickDeleteTerminApply }) => {
  return (
    <Modal open={open} width="690px" onCancel={closeModal} footer={false} bodyStyle={{ backgroundColor: '#F4F4F4' }}>
      <h2 className="mb0 text-bold">Удаление значения из справочника</h2>
      <Divider className="thin-divider mt0" />
      <div className="mb20 font-roboto">Вы уверены, что хотите удалить значение из справочника?</div>
      <Row justify="center" gutter={20}>
        <Col>
          <Button onClick={closeModal}>Отмена</Button>
        </Col>
        <Col>
          <Button type="primary" style={{ width: 216 }} onClick={onClickDeleteTerminApply}>
            Удалить
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
