import React, { FC, useEffect } from 'react';
import { Table } from 'antd';
import { ColumnType } from 'antd/es/table/interface';
import uniqueId from 'lodash/uniqueId';
import { useApplicationContainerContext } from '../../containers/ApplicationContainer/ApplicationContainerContext';
import { useAppSelector } from '../../store/hooks';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { getDictionaryItemById } from '../../lib/utils/applications';
import { toDisplayFormatWithTime } from '../../lib/utils/formatDate';
import './Application.less';

export const ApplicationHistory: FC = () => {
  const { getApplicationHistory } = useApplicationContainerContext();
  useEffect(() => {
    getApplicationHistory();
  }, []);
  const { applicationHistory, applicationHistoryLoading } = useAppSelector((state) => state.applications);
  const { actionType } = useAppSelector((state) => state.dictionaries);

  const tableData = applicationHistory.map((item) => ({
    ...item,
    actionTypeName: getDictionaryItemById(actionType, item.actionTypeId)?.name || '',
    fromDate: toDisplayFormatWithTime(item.fromDate),
    key: uniqueId(),
  }));

  const columns: ColumnType<any>[] = [
    {
      key: 'fromDate',
      dataIndex: 'fromDate',
      title: 'Дата операции',
      className: 'application-history__firstColumn',
    },
    {
      key: 'actionTypeName',
      dataIndex: 'actionTypeName',
      title: 'Тип действия',
    },
    {
      key: 'user',
      dataIndex: 'user',
      title: 'Пользователь',
    },
    {
      key: 'oldValue',
      dataIndex: 'oldValue',
      title: 'Старое значение',
    },
    {
      key: 'newValue',
      dataIndex: 'newValue',
      title: 'Новое значение',
    },
    {
      key: 'field',
      dataIndex: 'field',
      title: 'Поле',
      className: 'application-history__lastColumn',
    },
  ];

  return (
    <div className="application application-history">
      <Table
        className="striped-table"
        columns={columns}
        size="small"
        dataSource={tableData}
        rowClassName={(r, i) => (i % 2 ? 'pl20' : 'striped-table__oddRow')}
        loading={checkIsLoading(applicationHistoryLoading)}
        pagination={{
          // onChange: changePage,
          // total: executors?.totalElements,
          position: ['bottomCenter'],
          // current: executorsSearchParams.page,
          // showSizeChanger: false,
          // pageSize: executorsSearchParams.size,
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};
