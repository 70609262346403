import buildUrl from '../utils/buildUrl';
import { baseUrl } from './baseUrl';

export const getNotificationsRoute = (page, size) =>
  buildUrl({
    url: `${baseUrl}/notifications/search?sort=id%2Cas`,
    searchParams: { page, size, sort: 'id%2Casc' },
  });

export const getUnreadNotificationRoute = () =>
  buildUrl({
    url: `${baseUrl}/notifications/count`,
  });

export const markAsReadNotificationRoute = (id: number) =>
  buildUrl({
    url: `${baseUrl}/notifications/${id}/read`,
  });
