import React from 'react';
import { FormInstance } from 'antd';
import { Moment } from 'moment';
import { ApplicationMenuItem } from '../../components/Application/ApplicationMenuItem';
import { Application } from '../../lib/interfaces/Application';
import { ApplicationFileData } from '../../lib/interfaces/ApplicationFileData';
import { PromiseFromDispatch } from '../../lib/interfaces/PromiseFromDispatch';
import { Address } from '../../lib/interfaces/Address';

export type GetApplication = () => PromiseFromDispatch<Application>;
export type GetApplicationFilesList = () => PromiseFromDispatch<ApplicationFileData[]>;
export type DownloadApplicationFile = (props: { fileName: string }) => void;
export type GetExecutors = () => void;
export type GetApplicationComments = () => void;
export type GetApplicationHistory = () => void;
export type GetApplicationResponseHistory = () => void;
export type HasAccessToSection = (props: ApplicationMenuItem['route']) => boolean;

export interface EditApplicationFormValues {
  subjectId: number;
  appText: string;
  regionId: number;
  numberOfPerformers: string;
  projectId: number;
  urgent: boolean;
  regularityAssistanceId: number;
  deadline: Moment;
  executionDate: Moment;
  executionTime: string;
  subwayStationId: number;
  entrance: string;
  floor: string;
  intercomCode: string;
  apartment: string;
  unstructuredAddress: string;
  responsiblePersonId: number;
  wardId?: number;
  phones: string[];
  additionalAddress: Address[];
  appTypeId: number;
}

export type OnFinishEditing = (values: EditApplicationFormValues) => void;
export interface IApplicationContainerContext {
  applicationId: string;
  getApplication: GetApplication;
  getApplicationFilesList: GetApplicationFilesList;
  getExecutors: GetExecutors;
  downloadApplicationFile: DownloadApplicationFile;
  getApplicationComments: GetApplicationComments;
  getApplicationHistory: GetApplicationHistory;
  getApplicationResponseHistory: GetApplicationResponseHistory;
  meIsResponsible: boolean;
  meIsChosenExecutor: boolean;
  meIsPotentialExecutor: boolean;
  hasAccessToSection: HasAccessToSection;
  editModeMain: boolean;
  setEditModeMain: (value: boolean) => void;
  editModeExecutors: boolean;
  setEditModeExecutors: (value: boolean) => void;
  onFinishEditing: OnFinishEditing;
  setLoadingData: (value: boolean) => void;
  loadingData: boolean;
  editApplicationForm: FormInstance;
}

export const ApplicationContainerContext = React.createContext<IApplicationContainerContext | null>(null);

export const useApplicationContainerContext = () => {
  const context = React.useContext(ApplicationContainerContext);
  if (context === null) {
    throw new Error('useContext must be inside a Provider with a value');
  }
  return context;
};
