import { AxiosResponse } from 'axios';
import React from 'react';
import { IAdministrationPermissionType } from '../../lib/interfaces/Administration';
import { PersonNew } from '../../lib/interfaces/PersonNew';

export type ILoadPersonList = () => Promise<any>;
export type CheckLoginExistence = (login: string) => Promise<AxiosResponse>;
export type CreatePerson = (person: PersonNew) => Promise<AxiosResponse>;
export type IResetAdministrationPersonListSearch = () => void;
export type ISaveCurrentLocation = (route: string) => void;
export type SetOpenModalDictionariesTypes = (type: 'create' | 'delete' | '') => void;

export interface IAdministrationContext {
  loadPersonList: ILoadPersonList;
  checkLoginExistence: CheckLoginExistence;
  createPerson: CreatePerson;
  repmissionTypes: IAdministrationPermissionType[];
  resetAdministrationPersonListSearch: IResetAdministrationPersonListSearch;
  saveCurrentLocation: ISaveCurrentLocation;
  setOpenModalDictionariesTypes: SetOpenModalDictionariesTypes;
}

export const AdministrationContext = React.createContext<IAdministrationContext | null>(null);

export const useAdministrationContext = () => {
  const context = React.useContext(AdministrationContext);
  if (context === null) {
    throw new Error('useContext must be inside a Provider with a value');
  }
  return context;
};
