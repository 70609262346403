import React, { useEffect, useState } from 'react';
import NotificationsDropdown from '../../components/NotificationsDropdown/NotificationsDropdown';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getUnreadNotifications } from '../../store/slices/notificationsSlice/notificationsSlice';
import { checkIsLoaded } from '../../lib/utils/checkLoadingStatus';

interface IProps {
  changeDropDown(status: boolean): void;
}

function NotificationsDropdownContainer({ changeDropDown }: IProps) {
  const dispatch = useAppDispatch();
  const { meUserFull } = useAppSelector((state) => state.persons);
  const { unreadNotificationsLoading } = useAppSelector((state) => state.notifications);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (meUserFull?.userId && !checkIsLoaded(unreadNotificationsLoading)) {
      dispatch(getUnreadNotifications({ page: 0, size: 100, userId: meUserFull.userId, searchUnread: true }));
    }
  }, [meUserFull, page, dispatch]);

  return <NotificationsDropdown page={page} setPage={setPage} changeDropDown={changeDropDown} />;
}

export default NotificationsDropdownContainer;
