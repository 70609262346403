import tempPhoneMask from '../const/tempPhoneMask';
import type {} from 'antd/lib/form';

// TODO Eugene. Получить из конфига
const phoneMask = tempPhoneMask;

type GetUnmaskedPhoneNumber = (maskedPhoneNumber: string) => string;
type GetMaskedPhoneNumber = (unmaskedPhoneNumber: string) => string;
type PhoneValidator = (field: any, value: string) => Promise<void>;
type UsePhoneMask = () => {
  phoneMask: string;
  getUnmaskedPhoneNumber: GetUnmaskedPhoneNumber;
  getMaskedPhoneNumber: GetMaskedPhoneNumber;
  phoneInputPlaceholder: string;
  phoneValidator: PhoneValidator;
};

// Удаление из строки всех нечисловых символов
const getUnmaskedPhoneNumber: GetUnmaskedPhoneNumber = (maskedNumber) =>
  maskedNumber ? maskedNumber.replace(/\D/g, '') : maskedNumber;

// Получение телефонного номера стилизованного в соответствии с маской
const getMaskedPhoneNumber: GetMaskedPhoneNumber = (phoneNumber) => {
  const unmaskedPhoneNumber = getUnmaskedPhoneNumber(phoneNumber);
  let result = '';
  for (let phonePos = 0, maskPos = 0; phonePos < unmaskedPhoneNumber.length; ) {
    const isNumberMaskSign = /\d/.test(phoneMask[maskPos]);
    if (isNumberMaskSign) {
      result += unmaskedPhoneNumber[phonePos];
      phonePos += 1;
    } else if (maskPos > phoneMask.length - 1) {
      result += unmaskedPhoneNumber.slice(phonePos);
      break;
    } else {
      result += phoneMask[maskPos];
    }
    maskPos += 1;
  }
  return result;
};

// Получение строки вида "+7 (___) ___-__-__" из строки вида "+7 (000) 000-00-00"
const phoneInputPlaceholder: string = (() => {
  const edgeOfCountryCode = phoneMask.indexOf('(');
  const countryCode = phoneMask.slice(0, edgeOfCountryCode);
  const maskWithoutCountryCode = phoneMask.slice(edgeOfCountryCode);
  return countryCode + maskWithoutCountryCode.replace(/\d/g, '_');
})();

const phoneValidator: PhoneValidator = (any, phoneNumber) => {
  if (!phoneNumber) return Promise.reject();
  const unmaskedPhoneNumberLength = getUnmaskedPhoneNumber(phoneNumber).length;
  const validPhoneNumberLength = getUnmaskedPhoneNumber(phoneMask).length;
  return unmaskedPhoneNumberLength === validPhoneNumberLength ? Promise.resolve() : Promise.reject();
};

const usePhoneMask: UsePhoneMask = () => ({
  phoneMask,
  getUnmaskedPhoneNumber,
  getMaskedPhoneNumber,
  phoneInputPlaceholder,
  phoneValidator,
});

export default usePhoneMask;
