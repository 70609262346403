import React from 'react';
import { Col, Row, Divider, Form, Input, Checkbox } from 'antd';
import { replaceFalse } from './replaceFalse';
import { useAppSelector } from '../../store/hooks';
import useLabel from './useLabel';

interface Props {
  isEditMode: boolean;
  isNewUser?: boolean;
}

function SocialBlock(props: Props) {
  const { isEditMode, isNewUser } = props;
  const { personFullCard } = useAppSelector((state) => state.persons);
  const [makeLabel] = useLabel();

  return (
    <div className="block">
      <h3>Бытовая и социальная оценка</h3>
      <Divider />
      <Row gutter={30} justify="space-between">
        <Col>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item name="floorCovering" label="Какое напольное покрытие">
                <Input />
              </Form.Item>
            ) : (
              <>
                {makeLabel('Какое напольное покрытие', 222)}
                <div className="row-value">{replaceFalse(personFullCard?.social?.floorCovering)}</div>
              </>
            )}
          </Row>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item name="bathroom" label="Ванная комната">
                <Input />
              </Form.Item>
            ) : (
              <>
                {makeLabel('Ванная комната', 222)}
                <div className="row-value">{replaceFalse(personFullCard?.social?.bathroom)}</div>
              </>
            )}
          </Row>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item name="sleeper" label="Спальное место">
                <Input />
              </Form.Item>
            ) : (
              <>
                {makeLabel('Спальное место', 222)}
                <div className="row-value">{replaceFalse(personFullCard?.social?.sleeper)}</div>
              </>
            )}
          </Row>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item valuePropName="checked" name="straightBed">
                <Checkbox>Достаточно прямое ложе</Checkbox>
              </Form.Item>
            ) : (
              <>
                {makeLabel('Достаточно прямое ложе', 222)}
                <div className="row-value">{replaceFalse(personFullCard?.social?.straightBed, true)}</div>
              </>
            )}
          </Row>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item name="homeDress" label="В чём ходит дома?">
                <Input />
              </Form.Item>
            ) : (
              <>
                {makeLabel('В чём ходит дома?', 222)}
                <div className="row-value">{replaceFalse(personFullCard?.social?.homeDress)}</div>
              </>
            )}
          </Row>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item name="commonView" label="Общая картина быта?">
                <Input.TextArea rows={4} />
              </Form.Item>
            ) : (
              <>
                {makeLabel('Общая картина быта', 222)}
                <div className="row-value">{replaceFalse(personFullCard?.social?.commonView)}</div>
              </>
            )}
          </Row>
        </Col>
        <Col>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item valuePropName="checked" name="needsProcedures">
                <Checkbox>Санитарно - гигиенические процедуры</Checkbox>
              </Form.Item>
            ) : (
              <>
                {makeLabel('Санитарно - гигиенические процедуры', 314)}
                {replaceFalse(personFullCard?.social?.needsProcedures, true)}
              </>
            )}
          </Row>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item valuePropName="checked" name="needsCooking">
                <Checkbox>Приготовление пищи</Checkbox>
              </Form.Item>
            ) : (
              <>
                {makeLabel('Приготовление пищи', 314)}
                {replaceFalse(personFullCard?.social?.needsCooking, true)}
              </>
            )}
          </Row>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item valuePropName="checked" name="needsCleaning">
                <Checkbox>Уборка квартиры</Checkbox>
              </Form.Item>
            ) : (
              <>
                {makeLabel('Уборка квартиры', 314)}
                {replaceFalse(personFullCard?.social?.needsCleaning, true)}
              </>
            )}
          </Row>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item valuePropName="checked" name="apartMovements">
                <Checkbox>Передвижение по квартире</Checkbox>
              </Form.Item>
            ) : (
              <>
                {makeLabel('Передвижение по квартире', 314)}
                {replaceFalse(personFullCard?.social?.apartMovements, true)}
              </>
            )}
          </Row>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item valuePropName="checked" name="cityMovements">
                <Checkbox>Передвижение по городу</Checkbox>
              </Form.Item>
            ) : (
              <>
                {makeLabel('Передвижение по городу', 314)}
                {replaceFalse(personFullCard?.social?.cityMovements, true)}
              </>
            )}
          </Row>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item valuePropName="checked" name="goingOut">
                <Checkbox>Выходит на улицу</Checkbox>
              </Form.Item>
            ) : (
              <>
                {makeLabel('Выходит на улицу', 314)}
                {replaceFalse(personFullCard?.social?.goingOut, true)}
              </>
            )}
          </Row>
        </Col>
        <Col>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item valuePropName="checked" name="livingAlone">
                <Checkbox>Живёт один</Checkbox>
              </Form.Item>
            ) : (
              <>
                {makeLabel('Живёт один', 253)}
                <div className="row-value">{replaceFalse(personFullCard?.social?.livingAlone, true)}</div>
              </>
            )}
          </Row>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item name="partnerInfo" label="С кем?">
                <Input />
              </Form.Item>
            ) : (
              <>
                {makeLabel('С кем?', 253)}
                <div className="row-value">{replaceFalse(personFullCard?.social?.partnerInfo)}</div>
              </>
            )}
          </Row>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item name="isRegistered" label="Стоит на учёте? Если да, то где?">
                <Input />
              </Form.Item>
            ) : (
              <>
                {makeLabel('Стоит на учёте? Если да, то где?', 253)}
                <div className="row-value">{replaceFalse(personFullCard?.social?.isRegistered)}</div>
              </>
            )}
          </Row>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item valuePropName="checked" name="hasSocialWorker">
                <Checkbox>Есть соц. работник</Checkbox>
              </Form.Item>
            ) : (
              <>
                {makeLabel('Есть соц. работник', 253)}
                <div className="row-value">{replaceFalse(personFullCard?.social?.hasSocialWorker, true)}</div>
              </>
            )}
          </Row>
          <Row>
            {isEditMode || isNewUser ? (
              <Form.Item name="socialWorkerFio" label="Работник">
                <Input />
              </Form.Item>
            ) : (
              <>
                {makeLabel('Работник', 253)}
                <div className="row-value">{replaceFalse(personFullCard?.social?.socialWorkerFio)}</div>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default SocialBlock;
