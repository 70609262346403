import React, { FC, useEffect } from 'react';
import axios from 'axios';
import { Col, message, Row } from 'antd';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { AdministrationPersonProfile } from '../../components/AdministrationPerson/AdministrationPersonProfile';
import {
  assignRoleRoute,
  changePermissionsRoute,
  changePersonTypeRoute,
  changeRoleRoute,
  deletePersonTypeRoute,
} from '../../lib/routes/administration';
import { checkIsLoaded, checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  fetchAdministrationPersonProfile,
  fetchAdministrationUser,
  resetAdministrationPersonProfile,
  resetAdministrationUser,
} from '../../store/slices/administrationSlice/administrationSlice';
import {
  AdministrationPersonContext,
  IAssignRole,
  IChangePermissions,
  IChangePersonType,
  IChangeRole,
  IDeleteUser,
  ILoadPersonAndUserData,
  ILoadUserData,
  IOnSubmitAssignPermissions,
  IOnSubmitAssignRole,
  IOnSubmitBlockPermissions,
  IRevokeRole,
} from './AdministrationPersonContext';
import { AdministrationPersonPermissionList } from '../../components/AdministrationPerson/AdministrationPersonPermissionList';
import { AdministrationPersonRoles } from '../../components/AdministrationPerson/AdministrationPersonRoles';
import { AdministrationPersonPermisionsAdd } from '../../components/AdministrationPerson/AdministrationPersonPermisionsAdd';
import { AdministrationPersonPermisionsBlock } from '../../components/AdministrationPerson/AdministrationPersonPermisionsBlock';

interface IProps {}

export const AdministrationPersonContainer: FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const personId = Number(useParams().personId);
  const { personProfileLoading, personProfile, user } = useAppSelector((state) => state.administration);

  const loadUserData: ILoadUserData = ({ userId }) =>
    dispatch(fetchAdministrationUser({ userId }))
      .then(() => Promise.resolve())
      .catch(() => {
        message.error('Ошибка загрузки привилегий пользователя');
        return Promise.reject();
      });

  const loadPersonAndUserData: ILoadPersonAndUserData = () => {
    dispatch(fetchAdministrationPersonProfile({ personId }))
      .then(({ payload }) => {
        const { userId } = payload;
        if (userId > 0) {
          loadUserData({ userId });
        } else {
          message.error(
            <>
              <div>Ошибка загрузки данных пользователя</div>
              <div>У пользователя отсутствует userId!</div>
            </>,
            3
          );
        }
      })
      .catch(() => {
        message.error('Ошибка загрузки профиля пользователя');
      });
  };

  // Загрузка профиля (person) и данных (user) пользователя
  useEffect(() => {
    if (!checkIsLoading(personProfileLoading) && !checkIsLoaded(personProfileLoading)) {
      loadPersonAndUserData();
    }
  }, [personId, personProfileLoading]);

  // Сброс профиля (person) и данных (user) пользователя по выходу
  useEffect(
    () => () => {
      dispatch(resetAdministrationPersonProfile());
      dispatch(resetAdministrationUser());
    },
    []
  );

  const changePersonType: IChangePersonType = ({ personTypeId }) =>
    axios
      .patch(changePersonTypeRoute({ personId }), {
        ...personProfile,
        personTypeId,
      })
      .then(() => {
        message.success('Тип анкеты изменен');
        return Promise.resolve();
      })
      .catch(() => {
        message.error('Произошла ошибка. Пожалуйста, попробуйте позднее');
        return Promise.reject();
      });

  const deleteUser: IDeleteUser = () =>
    axios
      .patch(deletePersonTypeRoute({ personId }))
      .then(() => {
        message.success('Пользователь удален');
        return Promise.resolve();
      })
      .catch(() => {
        message.error('Произошла ошибка. Пожалуйста, попробуйте позднее');
        return Promise.reject();
      });

  const revokeRole: IRevokeRole = ({ roleId }) =>
    axios
      .delete(
        assignRoleRoute({
          userId: user!.id,
          roleId,
        })
      )
      .then(() => Promise.resolve())
      .catch(() => {
        message.error('Ошибка удаления роли. Пожалуйста, попробуйте позднее');
        return Promise.reject();
      });

  const assignRole: IAssignRole = ({ roleId }) =>
    axios
      .post(
        assignRoleRoute({
          userId: user!.id,
          roleId,
        })
      )
      .then(() => Promise.resolve())
      .catch(() => {
        message.error('Ошибка назначения роли. Пожалуйста, попробуйте позднее');
        return Promise.reject();
      });

  const changeRole: IChangeRole = ({ roleId }) =>
    axios
      .put(
        changeRoleRoute({
          userId: user!.id,
          roleId,
        })
      )
      .then(() => Promise.resolve())
      .catch(() => {
        message.error('Ошибка замены роли. Пожалуйста, попробуйте позднее');
        return Promise.reject();
      });

  const changePermissions: IChangePermissions = ({ include, exclude }) =>
    axios
      .put(
        changePermissionsRoute({
          userId: user!.id,
          include,
          exclude,
        })
      )
      .then(() => Promise.resolve())
      .catch(() => {
        message.error('Ошибка изменения привилегий. Пожалуйста, попробуйте позднее');
        return Promise.reject();
      });

  const onSubmitAssignRole: IOnSubmitAssignRole = async ({ assignedRoleId }) => {
    await changeRole({ roleId: assignedRoleId });
    message.success('Роль изменена');
    return await loadUserData({
      userId: user!.id,
    });
  };

  const onSubmitAssignPermissions: IOnSubmitAssignPermissions = async ({ include, exclude }) => {
    await changePermissions({ include, exclude });
    message.success('Дополнительные привилегии изменены');
    return await loadUserData({
      userId: user!.id,
    });
  };

  const onSubmitBlockPermissions: IOnSubmitBlockPermissions = async ({ include, exclude }) => {
    await changePermissions({ include, exclude });
    message.success('Заблокированные привилегии изменены');
    return await loadUserData({
      userId: user!.id,
    });
  };

  return (
    <div className="pt15">
      <AdministrationPersonContext.Provider
        value={{
          loadUserData,
          loadPersonAndUserData,
          changePersonType,
          deleteUser,
          revokeRole,
          assignRole,
          changeRole,
          onSubmitAssignRole,
          onSubmitAssignPermissions,
          changePermissions,
          onSubmitBlockPermissions,
        }}
      >
        <Row gutter={20}>
          <Col span={7}>
            <AdministrationPersonProfile />
          </Col>
          <Col span={17}>
            <Routes>
              <Route path="" element={<Navigate to="profile" />} />
              <Route path="profile" element={<AdministrationPersonPermissionList />} />
              <Route path="roles" element={<AdministrationPersonRoles />} />
              <Route path="permissionsadd" element={<AdministrationPersonPermisionsAdd />} />
              <Route path="permissionsblock" element={<AdministrationPersonPermisionsBlock />} />
            </Routes>
          </Col>
        </Row>
      </AdministrationPersonContext.Provider>
    </div>
  );
};
