import React, { FC, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Modal, Row, message } from 'antd';
import { saveAs } from 'file-saver';
import { ApplicationComments } from '../../components/Application/ApplicationComments';
import { ApplicationExecutors } from '../../components/Application/ApplicationExecutors';
import { ApplicationHistory } from '../../components/Application/ApplicationHistory';
import { ApplicationMain } from '../../components/Application/ApplicationMain';
import { ApplicationNavbar } from '../../components/Application/ApplicationNavbar';
import { HasNotPermissions } from '../../components/HasNotPermission/HasNotPermissions';
import { downloadApplicationFileRoute, geocodingRoute, updateApplicationRoute } from '../../lib/routes/applications';
import { checkIsLoaded } from '../../lib/utils/checkLoadingStatus';
import { useDictionaries } from '../../lib/utils/useDictionaries';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  cleanApplicationCard,
  fetchApplication,
  fetchApplicationComments,
  fetchApplicationFilesList,
  fetchApplicationHistory,
  fetchApplicationResponseHistory,
  fetchExecutors,
} from '../../store/slices/applicationsSlice/applicationsSlice';
import { useMainContext } from '../MainContainer/MainContext';
import {
  ApplicationContainerContext,
  DownloadApplicationFile,
  GetApplication,
  GetApplicationComments,
  GetApplicationFilesList,
  GetApplicationHistory,
  GetApplicationResponseHistory,
  GetExecutors,
  HasAccessToSection,
  OnFinishEditing,
} from './ApplicationContainerContext';
import { ApplicationResponseHistory } from '../../components/Application/ApplicationResponseHistory';
import { AppTypes } from '../../lib/const/appTypes';
import { ReactComponent as DonateIco } from '../../lib/img/buy_donate.svg';

interface IProps {}

export const ApplicationContainer: FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { applicationId = '' } = useParams();
  const { hasAccess, loadProjects, searchApplicationsMapStateAll } = useMainContext();
  const { loadDictionaries } = useDictionaries();
  const { projectsLoading } = useAppSelector((state) => state.projects);
  const { application } = useAppSelector((state) => state.applications);
  const { meUserFull } = useAppSelector((state) => state.persons);
  const { executors } = useAppSelector((state) => state.applications);
  const { properties } = useAppSelector((state) => state.root);
  const [editModeMain, setEditModeMain] = useState<boolean>(false);
  const [editModeExecutors, setEditModeExecutors] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false); // Используется для редактирования

  const refBodySection = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (properties?.volunteer_school_text && refBodySection?.current) {
      refBodySection.current.innerHTML = properties?.volunteer_school_text;
    }
  }, [refBodySection, properties]);

  const getApplication: GetApplication = async () => {
    const resultAction = await dispatch(fetchApplication({ applicationId }));

    if (fetchApplication.rejected.match(resultAction) && resultAction.error?.message?.includes('403')) {
      const errorData = JSON.parse(resultAction.error.message)?.data;

      if (errorData?.code === '1500') {
        Modal.error({
          content: (
            <>
              <div
                style={{ fontFamily: 'Roboto, sans-serif' }}
                ref={refBodySection}
                className="font-size-14"
                dangerouslySetInnerHTML={{ __html: properties?.volunteer_school_text || '' }}
              />
              <Row justify="center" className="mt20">
                <Button
                  type="default"
                  className="display-flex"
                  onClick={() => {
                    message.destroy();
                    Modal.destroyAll();
                    //  На прямую указываем в состоянии относящемся к пункту "Все просьбы", чтобы отображались денежные просьбы при переходе
                    searchApplicationsMapStateAll.handleSetSearchFormFields({
                      appTypeId: [AppTypes['Денежная']],
                    });
                    navigate('/applications/map');
                  }}
                >
                  <div className="ml-auto mr-auto display-flex font-size-16" style={{ fontFamily: 'PT Serif' }}>
                    Посмотреть карту
                    <DonateIco className="ml7 mt2" />
                  </div>
                </Button>
              </Row>
            </>
          ),

          className: 'font-roboto password-forgotten-info-modal',
          width: '534px',
          icon: false,
          keyboard: false,
          bodyStyle: {
            backgroundColor: '#F4F4F4',
            paddingTop: '20px',
            marginTop: '111px',
          },
          okButtonProps: { style: { fontFamily: 'PT Serif' } },
          onOk: () => {
            message.destroy();
            Modal.destroyAll();
            navigate('/applications/list');
          },
        });
      }
    }

    return resultAction;
  };

  const getApplicationFilesList: GetApplicationFilesList = async () => {
    const resultAction = await dispatch(fetchApplicationFilesList({ applicationId }));

    // Изза того что Thank не отдавал ответ в catch, сделал проверку через сам Thank
    if (fetchApplicationFilesList.rejected.match(resultAction) && resultAction.error?.message?.includes('403')) {
      Modal.error({
        content: 'У Вас нет прав для доступа к данной информации. Обратитесь к администратору.',
        className: 'font-roboto password-forgotten-info-modal',
        width: '450px',
        icon: false,
        bodyStyle: {
          backgroundColor: '#F4F4F4',
          paddingTop: '20px',
          marginTop: '111px',
        },
        onOk: () => {
          message.destroy();
          Modal.destroyAll();
          navigate('/applications/list');
        },
      });
    }
    if (fetchApplicationFilesList.rejected.match(resultAction) && !resultAction.error?.message?.includes('403')) {
      message.destroy();
      message.error(
        {
          content: 'Ошибка загрузки файлов просьбы: ' + resultAction.error?.message,
        },
        3
      );
    }

    return resultAction;
  };

  const getExecutors: GetExecutors = () => {
    dispatch(fetchExecutors({ applicationId }));
  };

  const [editApplicationForm] = Form.useForm();

  // Загрузка карточки просьбы и списка файлов просьбы
  useEffect(() => {
    getApplication();
    hasAccess(['APPLICATION.FILES.READ']) &&
      getApplicationFilesList().catch((res) => {
      });
    getExecutors();
  }, []);

  // Очистка карточки просьбы по выходу
  useEffect(
    () => () => {
      dispatch(cleanApplicationCard());
    },
    [dispatch]
  );

  // Загрузка словарей
  useEffect(() => {
    loadDictionaries([
      'app_status',
      'app_subject',
      'app_close_reason',
      'person_category',
      'region',
      'regularity_of_assistance',
      'subway_station',
      'person_type',
      'phone_type',
      'action_type',
      'app_type',
    ]);
    if (!checkIsLoaded(projectsLoading)) loadProjects();
  }, []);

  // Текущий пользователь яваляется ответственным просьбы
  const meIsResponsible: boolean =
    !Boolean(application?.responsible?.id) || application?.responsible?.id === meUserFull?.id;
  // Текущий пользователь является выбранным исполнителем просьбы
  const meIsChosenExecutor: boolean = executors.chosenExecutors.map(({ id }) => id).includes(meUserFull?.id);
  // Текущий пользователь является потенциальным исполнителем просьбы
  const meIsPotentialExecutor: boolean = executors.potentialExecutors.map(({ id }) => id).includes(meUserFull?.id);

  const downloadApplicationFile: DownloadApplicationFile = async ({ fileName }) => {
    const url = downloadApplicationFileRoute({ applicationId, fileName });
    const { data } = await axios.get(url, {
      responseType: 'blob',
    });
    saveAs(data, fileName);
  };

  const getApplicationComments: GetApplicationComments = () => {
    dispatch(fetchApplicationComments({ applicationId }));
  };

  const getApplicationHistory: GetApplicationHistory = () => {
    dispatch(fetchApplicationHistory({ applicationId }));
  };

  const getApplicationResponseHistory: GetApplicationResponseHistory = () => {
    dispatch(fetchApplicationResponseHistory({ applicationId }));
  };

  const isDonateApp = application?.appTypeId === AppTypes['Денежная'];

  // Доступ к разделам карточки
  const hasAccessToSection: HasAccessToSection = (route) => {
    switch (route) {
      case 'main':
        return true;
      // return meIsChosenExecutor || hasAccess(['APPLICATIONS.READ', 'APPLICATIONS.READ.ALLREGIONS']);
      case 'executors':
        return !isDonateApp && (meIsChosenExecutor || hasAccess(['APPLICATION.READ.EXECUTORS']));
      case 'comments':
        return meIsChosenExecutor || hasAccess(['APPLICATION.READ.MYCOMMENTS', 'APPLICATION.READ.ALLCOMMENTS']);
      case 'history':
        return hasAccess(['APPLICATION.READ.HISTORY']);
      case 'history-response':
        return !isDonateApp && hasAccess(['APPLICATION.READ.RESPONSEHISTORY']);
    }
  };

  const onFinishEditing: OnFinishEditing = async (values) => {
    setLoadingData(true);

    // Проверка адреса на корректность
    const checkAddressResult: { success: boolean; message?: string } = await axios
      .get(geocodingRoute({ address: values.unstructuredAddress }))
      .then(() => ({
        success: true,
      }))
      .catch(({ response }) => ({
        success: false,
        message: response.data.message,
      }));

    if (!checkAddressResult.success) {
      editApplicationForm.setFields([{ name: 'unstructuredAddress', errors: [''], validating: false }]);
      setLoadingData(false);
      return Modal.error({
        content: (
          <>
            <div>{checkAddressResult.message?.replace('. Уточните запрос', '')}</div>
            <div style={{ textAlign: 'center', marginTop: '15px' }}>
              Пожалуйста, выберите адрес из выпадающего списка
            </div>
          </>
        ),
        bodyStyle: { width: '600px', backgroundColor: '#F4F4F4' },
      });
    }

    if (values?.additionalAddress.length > 0) {
      const checkAddressResultAdditional: { success: boolean; message?: string; unstructuredAddressValue?: string }[] =
        await Promise.all(
          values?.additionalAddress?.map(async (address) => {
            return await axios
              .get(geocodingRoute({ address: address.unstructuredAddress || '' }))
              .then(() => ({
                success: true,
              }))
              .catch(({ response }) => ({
                success: false,
                message: response.data.message,
                unstructuredAddressValue: address.unstructuredAddress,
              }));
          })
        );

      const isErrorAdditionalAddress = await checkAddressResultAdditional?.find((el) => !el.success);

      if (isErrorAdditionalAddress && !isErrorAdditionalAddress?.success) {
        editApplicationForm.setFields([
          {
            name: [
              'additionalAddress',
              values?.additionalAddress?.findIndex(
                (el) => el?.unstructuredAddress === isErrorAdditionalAddress?.unstructuredAddressValue
              ),
              'unstructuredAddress',
            ],
            errors: [''],
            validating: false,
          },
        ]);

        setLoadingData(false);

        return Modal.error({
          content: (
            <>
              <div>{isErrorAdditionalAddress.message?.replace('. Уточните запрос', '')}</div>
              <div style={{ textAlign: 'center', marginTop: '15px' }}>
                Пожалуйста, выберите адрес из выпадающего списка
              </div>
            </>
          ),
          bodyStyle: { width: '600px', backgroundColor: '#F4F4F4' },
        });
      }
    }

    const updatedApplication = {
      id: applicationId,
      statusId: application?.statusId,
      subjectId: values.subjectId,
      text: values.appText,
      regionId: values.regionId,
      numberOfPerformers: values.numberOfPerformers,
      projectId: values.projectId,
      urgent: values.urgent,
      regularityAssistanceId: values.regularityAssistanceId,
      deadline: values.deadline && values.deadline.format('YYYY-MM-DD'),
      executionDate: values.executionDate?.format('YYYY-MM-DD') || '',
      executionTime: values.executionTime,
      additionalAddress: values?.additionalAddress?.map((address) => ({
        id: address?.id,
        subwayStationId: address.subwayStationId,
        entrance: address.entrance,
        floor: address.floor,
        intercomCode: address.intercomCode,
        apartment: address.apartment,
        unstructuredAddress: address.unstructuredAddress,
      })),
      address: {
        id: application?.address?.id,
        subwayStationId: values.subwayStationId,
        entrance: values.entrance,
        floor: values.floor,
        intercomCode: values.intercomCode,
        apartment: values.apartment,
        unstructuredAddress: values.unstructuredAddress,
      },
      responsibleId: values.responsiblePersonId,
      wardId: application?.ward?.id,
      phones: values.phones,
      appTypeId: values.appTypeId,
    };
    axios
      .patch(updateApplicationRoute({ applicationId }), updatedApplication)
      .then(() => {
        getApplication();
        message.success('Просьба отредактирована', 3);
      })
      .catch(() => {
        message.error(
          <>
            <div>При редактировании просьбы возникла ошибка.</div>
            <div>Пожалуйста, повторите попытку позднее</div>
          </>,
          3
        );
      })
      .finally(() => {
        setLoadingData(false);
        setEditModeMain(false);
      });
  };

  return (
    <ApplicationContainerContext.Provider
      value={{
        applicationId,
        getApplication,
        getApplicationFilesList,
        downloadApplicationFile,
        getExecutors,
        getApplicationComments,
        meIsChosenExecutor,
        meIsPotentialExecutor,
        meIsResponsible,
        hasAccessToSection,
        editModeMain,
        setEditModeMain,
        onFinishEditing,
        setLoadingData,
        loadingData,
        editApplicationForm,
        editModeExecutors,
        setEditModeExecutors,
        getApplicationHistory,
        getApplicationResponseHistory,
      }}
    >
      <ApplicationNavbar />
      <Routes>
        <Route path="" element={<Navigate to="main" />} />
        <Route path="main" element={hasAccessToSection('main') ? <ApplicationMain /> : <HasNotPermissions />} />
        <Route
          path="executors"
          element={hasAccessToSection('executors') ? <ApplicationExecutors /> : <HasNotPermissions />}
        />
        <Route
          path="comments"
          element={hasAccessToSection('comments') ? <ApplicationComments /> : <HasNotPermissions />}
        />
        <Route
          path="history"
          element={hasAccessToSection('history') ? <ApplicationHistory /> : <HasNotPermissions />}
        />
        <Route
          path="history-response"
          element={hasAccessToSection('history-response') ? <ApplicationResponseHistory /> : <HasNotPermissions />}
        />
      </Routes>
    </ApplicationContainerContext.Provider>
  );
};
