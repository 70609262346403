import React, { FC } from 'react';
import { ReactComponent as Icon } from '../../lib/img/transfer-icon.svg';

interface ITransferIcon {
  active?: boolean;
  onClick?: () => void;
  side?: 'left' | 'right';
}
export const TransferIcon: FC<ITransferIcon> = ({ active, onClick = () => {}, side = 'left' }) => {
  const style = {
    color: active ? '#315373' : '#C4C4C4',
    cursor: active ? 'pointer' : 'not-allowed',
    transform: side === 'right' ? 'rotate(180deg)' : '',
  };
  return (
    <div>
      <Icon style={style} onClick={active ? onClick : () => {}} />
    </div>
  );
};
