import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { toDisplayFormatWithTime } from '../../lib/utils/formatDate';
import { checkIsLoaded, checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { Divider, Table } from 'antd';
import classNames from 'classnames';
import { markAsReadNotification } from '../../store/slices/notificationsSlice/notificationsSlice';
import './Notifications.less';
import { useNavigate } from 'react-router-dom';
import { NotificationTypes } from '../../lib/const/notificationTypes';

interface Props {
  page: number;
  setPage: (number) => void;
}

function Notifications(props: Props) {
  const { page, setPage } = props;
  const dispatch = useAppDispatch();
  const columns = [
    {
      dataIndex: 'createDate',
      key: 'createDate',
      width: '33%',
      render: (item) => toDisplayFormatWithTime(item),
    },
    {
      dataIndex: 'title',
      width: '33%',
    },
    {
      dataIndex: 'body',
      width: '33%',
    },
  ];
  const { allNotifications, allNotificationsLoading, totalNotifications } = useAppSelector(
    (state) => state.notifications
  );
  const [tableData, setTableData] = useState<any>([]);

  useEffect(() => {
    if (checkIsLoaded(allNotificationsLoading)) {
      setTableData(
        allNotifications.map((item, index) => {
          return {
            key: item.id,
            id: item.id,
            createDate: item.createDate,
            // @ts-ignore
            title: item.payload.title,
            // @ts-ignore
            body: item.payload.body,
            // @ts-ignore
            data: item.payload.data,
            // @ts-ignore
            readDate: item.readDate,
          };
        })
      );
    }
  }, [allNotificationsLoading, allNotifications]);

  return (
    <div className="notifications pt10">
      <h2 className="title ml20">Уведомления</h2>
      <Divider />
      <Table
        dataSource={tableData}
        columns={columns}
        showHeader={false}
        loading={checkIsLoading(allNotificationsLoading)}
        pagination={{
          pageSize: 20,
          current: page,
          onChange: (page) => setPage(page),
          position: ['bottomCenter'],
          total: totalNotifications,
        }}
        scroll={{ y: '75vh' }}
        rowClassName={(record, index) => {
          return classNames(
            {
              'even-number': index % 2 !== 0,
              'odd-number': index % 2 === 0,
            },
            { read: Boolean(record.readDate) },
            { unread: Boolean(!record.readDate) }
          );
        }}
        onRow={(record) => ({
          onClick: () => {
            if (record?.data?.link === NotificationTypes.Информация) {
              window.open(`/info`);
              dispatch(markAsReadNotification({ id: record.id }));
            } else if (record?.data?.link === NotificationTypes.Список_Просьб) {
              window.open(`/applications/list`);
              dispatch(markAsReadNotification({ id: record.id }));
            } else {
              window.open(`/applications/list/${record.data.applicationId}/main`);
              dispatch(markAsReadNotification({ id: record.id }));
            }
          },
        })}
      />
    </div>
  );
}

export default Notifications;
