import { Pagable, SearchResult, Sort } from '../interfaces/SearchResult';

const sort: Sort = {
  empty: true,
  sorted: false,
  unsorted: true,
};

const pageable: Pagable = {
  offset: 0,
  pageNumber: 0,
  pageSize: 20,
  paged: true,
  sort,
  unpaged: false,
};

const defaultSearchResult: SearchResult = {
  content: [],
  empty: false,
  first: true,
  last: true,
  number: 0,
  numberOfElements: 1,
  pageable,
  size: 20,
  sort,
  totalElements: 1,
  totalPages: 1,
};

export default defaultSearchResult;
