import React, { useEffect } from 'react';
import FileComments from '../../components/FileComments/FileComments';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useParams } from 'react-router-dom';
import { getCardComments } from '../../store/slices/personsSlice/personsSlice';
import { Spin } from 'antd';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import axios from 'axios';
import { postCardCommentRoute } from '../../lib/routes/persons';

function FileCommentsContainer() {
  const { fileId } = useParams();
  const dispatch = useAppDispatch();

  const { personCardCommentsLoading } = useAppSelector((state) => state.persons);

  useEffect(() => {
    if (fileId) {
      dispatch(getCardComments({ personId: fileId }));
    }
  }, [dispatch]);

  const postComment = (text: string) => {
    axios
      .post(postCardCommentRoute(fileId), {
        value: text,
      })
      .then(() => {
        if (fileId) {
          dispatch(getCardComments({ personId: fileId }));
        }
      });
  };

  return (
    <Spin spinning={checkIsLoading(personCardCommentsLoading)}>
      {personCardCommentsLoading && <FileComments postComment={postComment} />}
    </Spin>
  );
}

export default FileCommentsContainer;
