import React from 'react';
import { Divider } from 'antd';
import './List.less';
import { Link } from 'react-router-dom';

type ListData = {
  id: number;
  value: string;
};

interface Props {
  title: string;
  data: ListData[];
  typeLink?: 'applications' | 'executors' | 'wards';
  isLinked?: boolean;
  linkTo?: (id: number, type: string) => string;
}

function List(props: Props) {
  const { title, data } = props;

  const isEvenClassname = (num: number): string => {
    if (num % 2 === 0) return 'row even-row';
    return 'row';
  };

  return (
    <div className="list">
      <div className="ml10 mr10 pt10">
        <h3>{title}</h3>
        <div>
          <Divider className="mt10 mb0" />
        </div>
      </div>
      <div className="list-rows">
        {data.map((item, index) => (
          <div
            className={isEvenClassname(index)}
            key={item.id}
            style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
          >
            {props.isLinked && props.linkTo && props.typeLink ? (
              <Link to={props.linkTo(item.id, props.typeLink)} style={{ color: 'black' }} target="_blank">
                {item.value}
              </Link>
            ) : (
              <>{item.value}</>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default List;
