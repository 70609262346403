import buildUrl from '../utils/buildUrl';
import { baseUrl } from './baseUrl';

export const projectsRoute = (searchParams: { regionId?: number; participantId?: number }) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/projects/search`,
    searchParams,
  });

export const projectByIdRoute = (projectId: number) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/projects/${projectId}`,
  });
