import React from 'react';
import { useAppSelector } from '../../store/hooks';

interface Props {
  myProfile: boolean;
  image: any;
  mini?: boolean;
}

function PersonPhoto(props: Props) {
  const { image, mini } = props;
  const { personFullCard, meUserFull } = useAppSelector((state) => state.persons);

  const profile = props.myProfile ? meUserFull : personFullCard;
  const size = {
    height: mini ? '42px' : '217px',
    width: mini ? '42px' : '217px',
  };

  return (
    <div
      style={{
        width: size.width,
        height: size.height,
        borderRadius: '10px',
        backgroundColor: '#7F5A5A',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {image ? (
        <img alt="img" src={`data:image/jpeg;base64,${image}`} height={size.height} width={size.width} />
      ) : (
        <span
          style={{
            fontSize: mini ? '25px' : '120px',
            color: 'white',
            alignSelf: 'center',
          }}
        >
          {profile && profile.firstName && profile.firstName.charAt(0)}
        </span>
      )}
    </div>
  );
}

export default PersonPhoto;
