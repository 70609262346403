import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import requestStatusCodes from '../../../lib/const/requestStatusCodes';
import { RequestStatuses } from '../../../lib/types/RequestStatuses';
import Project from '../../../lib/interfaces/Project';
import { projectByIdRoute, projectsRoute } from '../../../lib/routes/projects';

interface InitialState {
  projects: Project[];
  projectsLoading: RequestStatuses;
  project?: Project;
  projectLoading: RequestStatuses;
}

const initialState: InitialState = {
  projects: [],
  projectsLoading: requestStatusCodes.idle,
  projectLoading: requestStatusCodes.idle,
};

export const getProjects = createAsyncThunk(
  'projects/getProjects',
  async (searchParams: { regionId?: number; participantId?: number } = {}) => {
    const response = axios.get(projectsRoute(searchParams));
    const { data } = await response;
    return data;
  }
);

export const getProjectById = createAsyncThunk('projects/getProjectById', async (projectId: number) => {
  const response = axios.get(projectByIdRoute(projectId));
  const { data } = await response;
  return data;
});

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    cleanProjects: (state) => {
      state.projectsLoading = requestStatusCodes.idle;
      state.projects = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProjects.pending, (state) => {
        state.projectsLoading = requestStatusCodes.pending;
      })
      .addCase(getProjects.rejected, (state) => {
        state.projectsLoading = requestStatusCodes.rejected;
      })
      .addCase(getProjects.fulfilled, (state, { payload }) => {
        state.projectsLoading = requestStatusCodes.fulfilled;
        state.projects = payload;
      })
      .addCase(getProjectById.pending, (state) => {
        state.projectLoading = requestStatusCodes.pending;
      })
      .addCase(getProjectById.rejected, (state) => {
        state.projectLoading = requestStatusCodes.rejected;
      })
      .addCase(getProjectById.fulfilled, (state, { payload }) => {
        state.projectLoading = requestStatusCodes.fulfilled;
        state.project = payload;
      });
  },
});

const { reducer, actions } = projectsSlice;
const { cleanProjects } = actions;

export { reducer, cleanProjects };
