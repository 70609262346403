import React from 'react';
import { Input } from 'antd';
import InputMask from 'react-input-mask';

export const MaskInputFromAntd = (props) => {
  let mask = 'dD.mM';
  let formatChars = {
    d: '[0-3]',
    D: '[0-9]',
    m: '[0-1]',
    M: '[0-9]',
  };

  let beforeMaskedValueChange = (newState, oldState, userInput) => {
    let { value } = newState;

    let dateParts = value.split('.');
    let dayPart = dateParts[0];
    let monthPart = dateParts[1];

    // Условная маска для второй цифры дня по первой цифре
    if (dayPart?.startsWith('3')) formatChars['D'] = '[0-1]'; // To block 39, 32, etc.
    else if (dayPart?.startsWith('0')) formatChars['D'] = '[1-9]'; // To block 00.
    else formatChars['D'] = '[0-9]'; // To allow 05, 15, 25  etc.

    // Условная маска для второй цифры месяца по первой цифре
    if (monthPart?.startsWith('1')) formatChars['M'] = '[0-2]'; // To block 15, 16, etc.
    else formatChars['M'] = '[1-9]'; // To allow 05, 06  etc - but blocking 00.

    // Обработка не предвиденных вариантов вставки чисел

    // Для случаев если сделали ctrl + v месяца больше чем можно
    if (monthPart?.startsWith('1') && monthPart?.[1] > 2) {
      let valueSplit = value.split('');
      valueSplit[4] = '2';
      value = valueSplit.join('');
    }

    // Для случаев если сделали ctrl + v числа больше чем можно
    if (dayPart?.startsWith('3') && dayPart?.[1] > 1) {
      let valueSplit = value.split('');
      valueSplit[1] = '1';
      value = valueSplit.join('');
    }

    // Для случаев если сделали ctrl + v числа меньше чем можно
    if (dayPart?.startsWith('0') && dayPart?.[1] < 1) {
      let valueSplit = value.split('');
      valueSplit[1] = '1';
      value = valueSplit.join('');
    }

    return { value, selection: newState.selection };
  };

  return (
    <InputMask
      mask={mask}
      {...props}
      maskChar={null}
      formatChars={formatChars}
      beforeMaskedValueChange={beforeMaskedValueChange}
    >
      {(inputProps) => <Input {...inputProps} className={`ant-imput-for-mask ${inputProps?.className}`} />}
    </InputMask>
  );
};
