import React, { FC, useEffect } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import axios from 'axios';
import { Divider, message, Modal } from 'antd';
import { omit } from 'lodash';
import { AdministrationNavbar } from '../../components/Administration/AdministrationNavbar';
import { AdministrationPersonList } from '../../components/Administration/AdministrationPersonList/AdministrationPersonList';
import { useDictionaries } from '../../lib/utils/useDictionaries';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  fetchAdministrationPersonList,
  fetchAllPermissions,
  fetchAllRoles,
  resetAdministrationPersonListSearchParams,
  setAdministrationCurrentRoute,
  setAdministrationPersonListSearchParams,
} from '../../store/slices/administrationSlice/administrationSlice';
import {
  AdministrationContext,
  CheckLoginExistence,
  CreatePerson,
  ILoadPersonList,
  IResetAdministrationPersonListSearch,
  ISaveCurrentLocation,
  SetOpenModalDictionariesTypes,
} from './AdministrationContext';
import { checkLoginExistenceRoute } from '../../lib/routes/gateway';
import { createUserBaseRoute } from '../../lib/routes/persons';
import { checkPermission } from '../../components/common/CheckPermission';
import { AdministrationPersonContainer } from '../AdministrationPersonContainer/AdministrationPersonContainer';
import { AdministrationPersonNavbar } from '../../components/Administration/AdministrationPersonNavbar';
import { IAdministrationPermissionType } from '../../lib/interfaces/Administration';
import { checkIsLoaded, checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import PermissionDescriptions from '../../components/PermissionDescriptions/PermissionDescriptions';
import { AdministrationProjectsContainer } from '../AdministrationProjectsContainer/AdministrationProjectsContainer';
import { AdministrationRolesContainer } from '../AdministrationRolesContainer/AdministrationRolesContainer';
import { AdministrationDictionariesContainer } from '../AdministrationDictionariesContainer/AdministrationDictionariesContainer';
import { AdministrationDictionariesNavbar } from '../../components/AdministrationDictionaries/AdministrationDictionariesNavbar';
import { setOpenModalAdministrationDictionaries } from '../../store/slices/administrationDictionariesSlice/administrationDictionariesSlice';


const AdministrationContainer: FC = (props) => {
  const dispatch = useAppDispatch();
  const personPath = useMatch('/administration/persons/:personId/*');
  const dicTerminPath = useMatch('/administration/dictionaries/:dicTypeId/*');

  const { loadDictionaries } = useDictionaries();
  const { currentRoute, currentProjectId, personListSearchParams, allPermissionsLoading, allRolesLoading } =
    useAppSelector((state) => state.administration);

  // Загрузка словарей и справочников
  useEffect(() => {
    loadDictionaries(['person_type', 'region', 'auth_role', 'auth_permission']);
    if (!checkIsLoaded(allPermissionsLoading) && !checkIsLoading(allPermissionsLoading)) {
      dispatch(fetchAllPermissions());
    }
    if (!checkIsLoaded(allRolesLoading) && !checkIsLoading(allRolesLoading)) {
      dispatch(fetchAllRoles());
    }
  }, [dispatch]);

  const loadPersonList: ILoadPersonList = () =>
    dispatch(
      fetchAdministrationPersonList({
        searchParams: {
          size: personListSearchParams.size,
          page: personListSearchParams.page - 1,
        },
        searchBody: omit(personListSearchParams, 'page', 'size', 'force'),
      })
    ).catch(() => {
      message.error('Ошибка загрузки пользователей');
    });

  // Загрузка пользователей
  useEffect(() => {
    loadPersonList();
  }, [personListSearchParams.force]);

  const checkLoginExistence: CheckLoginExistence = (login) => {
    try {
      return axios.get(checkLoginExistenceRoute(login));
    } catch (e) {
      Modal.error({
        title: 'Ошибка проверки Email',
        content: 'Попробуйте пожалуйста позже',
      });
      return Promise.reject('Ошибка проверки Email');
    }
  };

  const createPerson: CreatePerson = (person) =>
    axios
      .post(createUserBaseRoute(), person)
      .then((response) => {
        dispatch(
          setAdministrationPersonListSearchParams({
            page: 1,
            force: !personListSearchParams.force,
          })
        );
        message.success(<div>Пользователь создан</div>, 3);
        return Promise.resolve(response);
      })
      .catch(() => {
        message.error(
          <>
            <div>При создании пользователя произошла ошибка.</div>
            <div>Пожалуйста, повторите попытку позднее</div>
          </>,
          3
        );
        return Promise.reject();
      });

  const repmissionTypes: IAdministrationPermissionType[] = [
    {
      id: 1,
      alias: 'includedRolePermissions',
    },
    {
      id: 2,
      alias: 'excludedRolePermissions',
    },
    {
      id: 3,
      alias: 'includedPermissions',
    },
    {
      id: 4,
      alias: 'excludedPermissions',
    },
  ];

  const resetAdministrationPersonListSearch: IResetAdministrationPersonListSearch = () => {
    dispatch(resetAdministrationPersonListSearchParams());
  };

  const saveCurrentLocation: ISaveCurrentLocation = (route) => {
    dispatch(setAdministrationCurrentRoute(route));
  };

  const currentPath = (() => {
    switch (currentRoute) {
      case 'projects':
        return `${currentRoute}/${currentProjectId}`;
      default:
        return currentRoute;
    }
  })();

  const renderNavbar = () => {
    if (Boolean(personPath)) {
      return (
        <>
          <AdministrationPersonNavbar personId={personPath?.params.personId} />
          <Divider className="thin-divider mt0 mb0" />
        </>
      );
    }
    if (Boolean(dicTerminPath)) {
      return (
        <>
          <AdministrationDictionariesNavbar />
        </>
      );
    }
    return (
      <>
        <AdministrationNavbar />
        <Divider className="thin-divider mt0 mb0" />
      </>
    );
  };

  const setOpenModalDictionariesTypes: SetOpenModalDictionariesTypes = (status) => {
    dispatch(setOpenModalAdministrationDictionaries(status));
  };

  return (
    <AdministrationContext.Provider
      value={{
        checkLoginExistence,
        createPerson,
        repmissionTypes,
        loadPersonList,
        resetAdministrationPersonListSearch,
        saveCurrentLocation,
        setOpenModalDictionariesTypes,
      }}
    >
      {renderNavbar()}
      <div
        style={{ backgroundColor: 'white', minHeight: '800px' }}
        className={`${Boolean(dicTerminPath) ? 'ml20 mr20' : 'pl20 pr20'}`}
      >
        <Routes>
          <Route path="" element={<Navigate to={currentPath} />} />
          <Route path="persons">
            <Route path="" element={<AdministrationPersonList />} />
            <Route path=":personId/*" element={<AdministrationPersonContainer />} />
          </Route>
          <Route path="projects/*" element={<AdministrationProjectsContainer />} />
          <Route path="roles/*" element={<AdministrationRolesContainer />} />
          <Route path="permissions" element={<PermissionDescriptions />} />
          <Route path="dictionaries/*" element={<AdministrationDictionariesContainer />} />
        </Routes>
      </div>
    </AdministrationContext.Provider>
  );
};

export default checkPermission(AdministrationContainer, ['ADMINISTRATION.READ']);
