import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import requestStatusCodes from '../../../lib/const/requestStatusCodes';
import { getSubscriptionsRoute } from '../../../lib/routes/subscriptions';
import { Subscriptions } from '../../../lib/interfaces/Subscriptions';
import { RequestStatuses } from '../../../lib/types/RequestStatuses';

interface InitialState {
  subscriptions: Subscriptions[];
  subscriptionsLoading: RequestStatuses;
}

const initialState: InitialState = {
  subscriptions: [],
  subscriptionsLoading: requestStatusCodes.idle,
};

export const getSubscriptions = createAsyncThunk('subscriptions/getSubscriptions', async (userId: number) => {
  const response = axios.post(getSubscriptionsRoute(userId));
  const { data } = await response;
  return data;
});

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    cleanSubscriptions: (state) => {
      state.subscriptions = [];
      state.subscriptionsLoading = requestStatusCodes.idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSubscriptions.pending, (state) => {
        state.subscriptionsLoading = requestStatusCodes.pending;
      })
      .addCase(getSubscriptions.rejected, (state) => {
        state.subscriptionsLoading = requestStatusCodes.rejected;
      })
      .addCase(getSubscriptions.fulfilled, (state, { payload }) => {
        state.subscriptionsLoading = requestStatusCodes.fulfilled;
        state.subscriptions = payload;
      });
  },
});

const { actions, reducer } = subscriptionsSlice;

const { cleanSubscriptions } = actions;

export { reducer, cleanSubscriptions };
