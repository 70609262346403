import React, { FC, useEffect } from 'react';
import { Spin, Row, Col, Divider, Input, Form, Button, message } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { useApplicationContainerContext } from '../../containers/ApplicationContainer/ApplicationContainerContext';
import { useAppSelector } from '../../store/hooks';
import './Application.less';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { getFio } from '../../lib/utils/applications';
import { toDisplayFormatWithTime } from '../../lib/utils/formatDate';
import { createCommentRoute } from '../../lib/routes/applications';


export const ApplicationComments: FC = (props) => {
  const { getApplicationComments, applicationId } = useApplicationContainerContext();
  const { applicationComments, applicationCommentsLoading } = useAppSelector((state) => state.applications);
  const [form] = Form.useForm();

  // Загрузка списка комментариев
  useEffect(() => {
    getApplicationComments();
  }, []);

  const renderAddComment = () => {
    const onFinish = ({ value }) => {
      axios
        .post(createCommentRoute({ applicationId }), { value })
        .then(() => {
          message.success('Комментарий добавлен', 3);
          getApplicationComments();
          form.resetFields();
        })
        .catch(() => {
          message.error(
            <>
              <div>При редактировании просьбы возникла ошибка.</div>
              <div>Пожалуйста, повторите попытку позднее</div>
            </>,
            3
          );
        });
    };
    return (
      <Form onFinish={onFinish} form={form}>
        <Form.Item name="value" rules={[{ required: true, message: '' }]} className="application-comments__textArea">
          <Input.TextArea placeholder="Текст комментария" />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Добавить комментарий
        </Button>
      </Form>
    );
  };

  const renderComments = () => {
    if (!applicationComments.length) return <>Нет комментариев</>;
    return (
      <>
        {[...applicationComments]
          .sort((a, b) => (moment(a.createDate).isBefore(b.createDate) ? 1 : -1))
          .map(({ value, person, createDate, id }) => (
            <div key={id}>
              <Row justify="space-between" className="mt5">
                <Col>
                  <div className="application-comments__dataLabel">{getFio(person)}</div>
                </Col>
                <Col>
                  <div className="application-comments__createDate">{toDisplayFormatWithTime(createDate)}</div>
                </Col>
              </Row>
              <div className="application-comments__commentText">{value}</div>
            </div>
          ))}
      </>
    );
  };

  return (
    <Spin spinning={checkIsLoading(applicationCommentsLoading)}>
      <div className="application application-comments">
        {/* TODO Eugene: раскомментировать с привилегией, когда будет добавлена в базу */}
        {/* {hasAccess(['APPLICATION.ADDCOMMENT']) && renderAddComment()} */}
        {renderAddComment()}
        <Row>
          <Col span={6}>
            <h3 className="text-bold mb3 mt15">Комментарии</h3>
            <Divider className="thin-divider mt0 mb0" />
          </Col>
        </Row>
        {renderComments()}
      </div>
    </Spin>
  );
};
