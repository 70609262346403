import React from 'react';

export const FindAndFormatLinkForText = ({ txtString }: { txtString?: string }): JSX.Element => {
  const res: JSX.Element[] = [];

  txtString?.replace(
    /((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim,
    (_, link, text) => {
      if (link) {
        res.push(
          <a
            href={(link[0] === 'w' ? '//' : '') + link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            {link}
          </a>
        );
      } else {
        // Разделяем текст по переносам строк и добавляем <br /> для каждого переноса
        text.split('\n').forEach((part, index, array) => {
          res.push(part);
          if (index < array.length - 1) {
            res.push(<br />);
          }
        });
      }
      return '';
    }
  );

  return <span>{res}</span>;
};
