import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import requestStatusCodes from '../../../lib/const/requestStatusCodes';
import {
  AdministrationMenuItem,
  IAdministrationPermission,
  IAdministrationUser,
  IPersonListSearchParams,
} from '../../../lib/interfaces/Administration';
import { Person } from '../../../lib/interfaces/Person';
import { RequestStatuses } from '../../../lib/types/RequestStatuses';
import { searchPersonPOSTRoute, searchPersonRoute } from '../../../lib/routes/persons';
import { SearchParams } from '../../../lib/interfaces/SearchParams';
import { SearchPersonBody } from '../../../lib/interfaces/SearchPersonBody';
import {
  fetchAdministrationPersonProfileRoute,
  fetchAdministrationUserRoute,
  fetchAllPermissionsRoute,
  fetchAllRolesRoute,
} from '../../../lib/routes/administration';
import { Role } from '../../../lib/interfaces/Role';

interface InitialState {
  allRoles: Role[];
  allRolesLoading: RequestStatuses;
  allPermissions: IAdministrationPermission[];
  allPermissionsLoading: RequestStatuses;
  currentRoute: AdministrationMenuItem['route'];
  currentProjectId?: number;
  currentRoleId?: number;
  personListSearchParams: IPersonListSearchParams;
  personList: { content: Person[]; totalElements: number };
  personListLoading: RequestStatuses;
  projectPersonList: { content: Person[]; totalElements: number };
  projectPersonListLoading: RequestStatuses;
  user?: IAdministrationUser;
  userLoading: RequestStatuses;
  personProfile?: Person;
  personProfileLoading: RequestStatuses;
  projectParticipants: { content: Person[]; totalElements: number };
  projectParticipantsLoading: RequestStatuses;
}

const defaultSearchParams: IPersonListSearchParams = {
  fio: '',
  email: '',
  login: '',
  page: 1,
  force: false,
  size: 15,
  personTypeIds: [],
  excludePersonTypeId: [4],
};

const initialState: InitialState = {
  allRoles: [],
  allRolesLoading: requestStatusCodes.idle,
  allPermissions: [],
  allPermissionsLoading: requestStatusCodes.idle,
  currentRoute: 'persons',
  personListSearchParams: defaultSearchParams,
  personList: { content: [], totalElements: 0 },
  personListLoading: requestStatusCodes.idle,
  projectPersonList: { content: [], totalElements: 0 },
  projectPersonListLoading: requestStatusCodes.idle,
  userLoading: requestStatusCodes.idle,
  personProfileLoading: requestStatusCodes.idle,
  projectParticipants: { content: [], totalElements: 0 },
  projectParticipantsLoading: requestStatusCodes.idle,
};

export const fetchAdministrationPersonList = createAsyncThunk(
  'administration/fetchAdministrationPersonList',
  async ({ searchParams, searchBody }: { searchParams: SearchParams; searchBody: SearchPersonBody }) => {
    const response = axios.post(searchPersonPOSTRoute(searchParams), searchBody);
    const { data } = await response;
    return data;
  }
);

export const fetchAdministrationPersonProfile = createAsyncThunk(
  'administration/fetchAdministrationPersonProfile',
  async ({ personId }: { personId: number }) => {
    const response = axios.get(fetchAdministrationPersonProfileRoute({ personId }));
    const { data } = await response;
    return data;
  }
);

export const fetchAdministrationUser = createAsyncThunk(
  'administration/fetchAdministrationUser',
  async ({ userId }: { userId: number }) => {
    const response = axios.get(fetchAdministrationUserRoute({ userId }));
    const { data } = await response;
    return data;
  }
);

export const fetchAllPermissions = createAsyncThunk('administration/fetchAllPermissions', async () => {
  const response = axios.get(fetchAllPermissionsRoute());
  const { data } = await response;
  return data;
});

export const fetchAllRoles = createAsyncThunk('administration/fetchAllRoles', async () => {
  const response = axios.get(fetchAllRolesRoute());
  const { data } = await response;
  return data;
});

export const fetchProjectParticipants = createAsyncThunk(
  'administration/fetchProjectParticipants',
  async (personIds: number[]) => {
    const response = axios.post(searchPersonRoute({ size: 1000, page: 0 }), {
      personIds,
    });
    const { data } = await response;
    return data;
  }
);

export const fetchProjectPersonList = createAsyncThunk(
  'administration/fetchProjectPersonList',
  async (regionId: number) => {
    const response = axios.post(
      searchPersonPOSTRoute({
        page: 0,
        size: 1000,
      }),
      {
        regionId,
        excludePersonTypeId: [3, 4],
      }
    );
    const { data } = await response;
    return data;
  }
);

const administrationSlice = createSlice({
  name: 'administration',
  initialState,
  reducers: {
    setAdministrationCurrentRoute: (state, { payload }) => {
      state.currentRoute = payload;
    },
    setAdministrationCurrentProjectId: (state, { payload }: { payload: number }) => {
      state.currentProjectId = payload;
    },
    setAdministrationCurrentRoleId: (state, { payload }: { payload: number }) => {
      state.currentRoleId = payload;
    },
    setAdministrationPersonListSearchParams: (state, { payload }) => {
      state.personListSearchParams = {
        ...state.personListSearchParams,
        ...payload,
      };
    },
    resetAdministrationPersonListSearchParams: (state) => {
      state.personListSearchParams = {
        ...defaultSearchParams,
        force: !state.personListSearchParams.force,
      };
    },
    resetAdministrationUser: (state) => {
      state.userLoading = requestStatusCodes.idle;
      state.user = undefined;
    },
    resetAdministrationPersonProfile: (state) => {
      state.personProfileLoading = requestStatusCodes.idle;
      state.personProfile = undefined;
    },
    resetProjectParticipants: (state) => {
      state.projectParticipants = { content: [], totalElements: 0 };
      state.projectParticipantsLoading = requestStatusCodes.idle;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAdministrationPersonList.pending, (state) => {
        state.personListLoading = requestStatusCodes.pending;
      })
      .addCase(fetchAdministrationPersonList.rejected, (state) => {
        state.personListLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchAdministrationPersonList.fulfilled, (state, { payload }) => {
        state.personListLoading = requestStatusCodes.fulfilled;
        state.personList = payload;
      })
      .addCase(fetchAdministrationPersonProfile.pending, (state) => {
        state.personProfileLoading = requestStatusCodes.pending;
      })
      .addCase(fetchAdministrationPersonProfile.rejected, (state) => {
        state.personProfileLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchAdministrationPersonProfile.fulfilled, (state, { payload }) => {
        state.personProfileLoading = requestStatusCodes.fulfilled;
        state.personProfile = payload;
      })
      .addCase(fetchAdministrationUser.pending, (state) => {
        state.userLoading = requestStatusCodes.pending;
      })
      .addCase(fetchAdministrationUser.rejected, (state) => {
        state.userLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchAdministrationUser.fulfilled, (state, { payload }) => {
        state.userLoading = requestStatusCodes.fulfilled;
        state.user = payload;
      })
      .addCase(fetchAllPermissions.pending, (state) => {
        state.allPermissionsLoading = requestStatusCodes.pending;
      })
      .addCase(fetchAllPermissions.rejected, (state) => {
        state.allPermissionsLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchAllPermissions.fulfilled, (state, { payload }) => {
        state.allPermissionsLoading = requestStatusCodes.fulfilled;
        state.allPermissions = payload.content;
      })
      .addCase(fetchAllRoles.pending, (state) => {
        state.allRolesLoading = requestStatusCodes.pending;
      })
      .addCase(fetchAllRoles.rejected, (state) => {
        state.allRolesLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchAllRoles.fulfilled, (state, { payload }) => {
        state.allRolesLoading = requestStatusCodes.fulfilled;
        state.allRoles = payload.content;
      })
      .addCase(fetchProjectParticipants.pending, (state) => {
        state.projectParticipantsLoading = requestStatusCodes.pending;
      })
      .addCase(fetchProjectParticipants.rejected, (state) => {
        state.projectParticipantsLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchProjectParticipants.fulfilled, (state, { payload }) => {
        state.projectParticipantsLoading = requestStatusCodes.fulfilled;
        state.projectParticipants = payload;
      })
      .addCase(fetchProjectPersonList.pending, (state) => {
        state.projectPersonListLoading = requestStatusCodes.pending;
      })
      .addCase(fetchProjectPersonList.rejected, (state) => {
        state.projectPersonListLoading = requestStatusCodes.rejected;
      })
      .addCase(fetchProjectPersonList.fulfilled, (state, { payload }) => {
        state.projectPersonListLoading = requestStatusCodes.fulfilled;
        state.projectPersonList = payload;
      });
  },
});

const { reducer, actions } = administrationSlice;
const {
  setAdministrationCurrentRoute,
  resetAdministrationPersonListSearchParams,
  setAdministrationPersonListSearchParams,
  resetAdministrationPersonProfile,
  resetAdministrationUser,
  setAdministrationCurrentProjectId,
  setAdministrationCurrentRoleId,
  resetProjectParticipants,
} = actions;

export {
  reducer,
  setAdministrationCurrentProjectId,
  setAdministrationCurrentRoleId,
  setAdministrationCurrentRoute,
  resetAdministrationPersonListSearchParams,
  setAdministrationPersonListSearchParams,
  resetAdministrationPersonProfile,
  resetAdministrationUser,
  resetProjectParticipants,
};
