import buildUrl from '../utils/buildUrl';
import { baseUrl, securityBaseUrl } from './baseUrl';

export const fetchAdministrationPersonProfileRoute = ({ personId }: { personId: number }): string =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/${personId}/profile`,
  });

export const fetchAdministrationUserRoute = ({ userId }: { userId: number }): string =>
  buildUrl({
    url: [securityBaseUrl, 'security', 'users', userId].join('/'),
  });

export const changePersonTypeRoute = ({ personId }: { personId: number }) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/${personId}/type`,
  });

export const deletePersonTypeRoute = ({ personId }: { personId: number }) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/persons/${personId}/archive`,
  });

export const assignRoleRoute = ({ userId, roleId }: { userId: number; roleId: number }) =>
  buildUrl({
    url: [securityBaseUrl, 'security', 'users', userId, 'roles'].join('/'),
    searchParams: {
      include: roleId,
    },
  });

export const changeRoleRoute = ({ userId, roleId }: { userId: number; roleId?: number }) =>
  buildUrl({
    url: [securityBaseUrl, 'security', 'users', userId, 'roles'].join('/'),
    searchParams: {
      include: roleId,
    },
  });

export const changePermissionsRoute = ({
  userId,
  include,
  exclude,
}: {
  userId: number;
  include: number[];
  exclude?: number[];
}) =>
  buildUrl({
    url: [securityBaseUrl, 'security', 'users', userId, 'permissions'].join('/'),
    searchParams: {
      include,
      exclude,
    },
  });

export const fetchAllPermissionsRoute = () =>
  buildUrl({
    url: [securityBaseUrl, 'security', 'permissions'].join('/'),
    searchParams: {
      size: 1000,
    },
  });

export const fetchAllRolesRoute = () =>
  buildUrl({
    url: [securityBaseUrl, 'security', 'roles'].join('/'),
  });

export const createProjectRoute = () =>
  buildUrl({
    url: `${baseUrl}/applications/v1/projects`,
  });

export const updateProjectRoute = () =>
  buildUrl({
    url: `${baseUrl}/applications/v1/projects`,
  });

export const deleteProjectRoute = (projectId: number) =>
  buildUrl({
    url: `${baseUrl}/applications/v1/projects/${projectId}`,
  });
