import React, { FC, useEffect } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AdministrationProjects } from '../../components/AdministrationProjects/AdministrationProjects';
import { useDictionaries } from '../../lib/utils/useDictionaries';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  fetchProjectParticipants,
  fetchProjectPersonList,
  resetProjectParticipants,
  setAdministrationCurrentProjectId,
} from '../../store/slices/administrationSlice/administrationSlice';
import { useMainContext } from '../MainContainer/MainContext';
import {
  AdministrationProjectsContext,
  CleanProjectParticipants,
  CreateProject,
  DeleteProject,
  LoadProjectParticipants,
  LoadProjectPersonList,
  SaveCurrentProjectId,
  UpdateProject,
} from './AdministrationProjectsContext';
import { createProjectRoute, deleteProjectRoute, updateProjectRoute } from '../../lib/routes/administration';
import { Modal, Row } from 'antd';
import { getDictionaryItemById } from '../../lib/utils/applications';

interface IProps {}

export const AdministrationProjectsContainer: FC<IProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loadProjects } = useMainContext();
  const { loadDictionaries } = useDictionaries();

  const { appSubject } = useAppSelector((state) => state.dictionaries);

  const { projects } = useAppSelector((state) => state.projects);

  const { currentProjectId } = useAppSelector((state) => state.administration);

  const saveCurrentProjectId: SaveCurrentProjectId = (projectId: number) => {
    dispatch(setAdministrationCurrentProjectId(projectId));
  };

  useEffect(() => {
    loadProjects();
    loadDictionaries(['project_type', 'region', 'person_type', 'app_subject']);
  }, []);

  // Переадресация к сохраненному проекту
  useEffect(() => {
    if (currentProjectId) {
      navigate(`/administration/projects/${currentProjectId}`);
    } else if (projects.length > 0) {
      navigate(`/administration/projects/${projects[0].id}`);
    }
  }, [projects, currentProjectId]);

  const loadProjectParticipants: LoadProjectParticipants = (personIds) => {
    dispatch(fetchProjectParticipants(personIds));
  };

  const cleanProjectParticipants: CleanProjectParticipants = () => {
    dispatch(resetProjectParticipants());
  };

  const loadProjectPersonList: LoadProjectPersonList = (regionId) => {
    dispatch(fetchProjectPersonList(regionId));
  };

  const createProject: CreateProject = ({ regionId, title }) => {
    axios
      .post(createProjectRoute(), {
        regionId,
        title,
        projectTypeId: 1,
        tenant: 1,
        participants: [],
      })
      .then(({ data }) => {
        loadProjects().then(() => {
          saveCurrentProjectId(data.id);
        });
        Modal.success({
          content: 'Проект создан',
        });
      })
      .catch(() => {
        Modal.error({
          content: 'При создании проекта произошла ошибка. Пожалуйста, повторите попытку позднее.',
        });
      });
  };

  const updateProject: UpdateProject = async (editedProject) => {
    return axios
      .patch(updateProjectRoute(), editedProject)
      .then(() => {
        Modal.success({
          content: 'Проект отредактирован',
        });
      })
      .catch((error) => {
        if (error?.response.status === 422 && error?.response?.data?.code === '1400') {
          const errorData = error.response.data.data[0];

          Modal.error({
            title: (
              <p className="font-size-16">
                Данный пользователь <b>{errorData?.fio}</b> является отвественным в следующих просьбах выбранного
                проекта:
              </p>
            ),
            content: (
              <>
                <div
                  className="font-size-16"
                  style={{
                    maxHeight: '80px',
                    overflowY: 'auto',
                  }}
                >
                  {errorData?.appInfo?.map((appInfo, i) => (
                    <Row>
                      <a
                        href={`/applications/list/${appInfo?.id}/main`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                        style={{
                          maxHeight: '80px',
                          overflowY: 'auto',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          textDecoration: 'none',
                          color: 'inherit',
                          maxWidth:'96%'
                        }}
                      >
                        {getDictionaryItemById(appSubject, appInfo?.subjectId)?.name}
                        {appInfo?.name ? ` - ${appInfo.name}` : ''}
                        {errorData?.appInfo?.length - 1 === i ? '.' : ';'}
                      </a>
                    </Row>
                  ))}
                </div>
                <p style={{ marginTop: '1em' }}>Пожалуйста, измените ответственного в просьбе или закройте просьбу.</p>
              </>
            ),

            width: '690px',
          });
        } else {
          Modal.error({
            content: 'При редактировании проекта произошла ошибка. Пожалуйста, повторите попытку позднее.',
          });
        }
      });
  };

  const deleteProject: DeleteProject = async (projectId) => {
    return axios
      .delete(deleteProjectRoute(projectId))
      .then(() => {
        loadProjects().then(({ payload }) => {
          saveCurrentProjectId(payload[0].id);
        });
        Modal.success({
          content: 'Проект перенесен в архив',
        });
      })
      .catch(() => {
        Modal.error({
          content: 'При удалении проекта произошла ошибка. Пожалуйста, повторите попытку позднее.',
        });
      });
  };

  return (
    <div className="pt20">
      <AdministrationProjectsContext.Provider
        value={{
          saveCurrentProjectId,
          loadProjectParticipants,
          cleanProjectParticipants,
          loadProjectPersonList,
          createProject,
          updateProject,
          deleteProject,
        }}
      >
        <Routes>
          <Route path="/" element={<AdministrationProjects />} />
          <Route path="/:projectId" element={<AdministrationProjects />} />
        </Routes>
      </AdministrationProjectsContext.Provider>
    </div>
  );
};
