import React from 'react';

function useLabel() {
  const makeLabel = (label: string, width: number) => {
    return <span style={{ width, display: 'inline-block' }}>{label}:</span>;
  };

  return [makeLabel];
}

export default useLabel;
