const LocalStorageService = {
  setToken(tokenObj) {
    localStorage.setItem('access_token', tokenObj.access_token);
    localStorage.setItem('refresh_token', tokenObj.refresh_token);
  },
  getAccessToken() {
    return localStorage.getItem('access_token');
  },
  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  },
  clearToken() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  },
  setTenant(tenantObj) {
    localStorage.setItem('name', tenantObj.name);
    localStorage.setItem('timezone', tenantObj.defaulttimezone);
    localStorage.setItem('tenantId', tenantObj.value);
  },
  getTenantName() {
    return localStorage.getItem('name');
  },
  getTenantTimezone() {
    return localStorage.getItem('timezone');
  },
  getTenantId() {
    return localStorage.getItem('tenantId');
  },
  setObject(key, value) {
    localStorage.setItem(key, value);
  },
  getObject(key) {
    return localStorage.getItem(key);
  },
};

export default LocalStorageService;
