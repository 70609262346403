import React, { FC, useState } from 'react';
import { Button, Col, Divider, Form, Modal, Row, Select, Spin, Tooltip } from 'antd';
import './AdministrationPerson.less';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { useAppSelector } from '../../store/hooks';
import { getDictionaryItemById, getFio } from '../../lib/utils/applications';
import { useAdministrationContext } from '../../containers/AdministrationContainer/AdministrationContext';
import { ReactComponent as PencilIcon } from '../../lib/img/pencil.svg';
import { ReactComponent as CrossIcon } from '../../lib/img/cross.svg';
import { ReactComponent as SubmitIcon } from '../../lib/img/submit.svg';
import { hasAccess } from '../../lib/utils/usePermissions';
import { useAdministrationPersonContext } from '../../containers/AdministrationPersonContainer/AdministrationPersonContext';
import { useNavigate } from 'react-router-dom';

export const AdministrationPersonProfile: FC = () => {
  const navigate = useNavigate();
  const { personProfileLoading, personProfile, user } = useAppSelector((state) => state.administration);
  const { personType } = useAppSelector((state) => state.dictionaries);
  const { resetAdministrationPersonListSearch } = useAdministrationContext();
  const { loadPersonAndUserData, changePersonType, deleteUser } = useAdministrationPersonContext();
  const [activeMode, setActiveMode] = useState<'' | 'edit' | 'delete'>('');
  const [form] = Form.useForm();

  const onChangePersonType = ({ personTypeId }) => {
    changePersonType({ personTypeId }).then(() => {
      setActiveMode('');
      loadPersonAndUserData();
    });
  };

  const deleteUserModal = () => {
    const onReset = () => {
      setActiveMode('');
    };

    const onDeleteSubmit = () => {
      deleteUser().then(() => {
        setActiveMode('');
        resetAdministrationPersonListSearch();
        navigate('/administration/persons');
      });
    };
    return (
      <Modal open={activeMode === 'delete'} bodyStyle={{ backgroundColor: '#F4F4F4' }} footer={null} onCancel={onReset}>
        <div className="mt10 mb20">
          Удаление пользователя приведёт к удалению учетной записи и персональных данных. Вы подтверждаете изменение?
        </div>
        <Row justify="center" gutter={20}>
          <Col>
            <Button onClick={onReset} className="administration-users-searchBtn">
              Отмена
            </Button>
          </Col>
          <Col>
            <Button className="administration-users-searchBtn" type="primary" onClick={onDeleteSubmit}>
              Подтвердить
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };

  return (
    <div className="administrationPerson-profile">
      <h4 className="text-bold font-size-18 mb3">Пользователь</h4>
      <Divider className="thin-divider mt0 mb10" />
      <Spin spinning={checkIsLoading(personProfileLoading)}>
        <div className="font-roboto mb10">
          <span className="pr15">Роль:</span>
          <span>{user?.includedRoles[0]?.dsc?.replace('Роль ', '') || ''}</span>
        </div>
        <div className="font-roboto mb10">
          <span className="pr15">ФИО:</span>
          <span>{personProfile && getFio(personProfile)}</span>
        </div>
        <div className="font-roboto mb10">
          <span className="pr15">Логин:</span>
          <span>{user?.login}</span>
        </div>
        <div className="font-roboto mb10">
          <span className="pr15">Email:</span>
          <span>{personProfile?.email}</span>
        </div>
        <Form form={form} onFinish={onChangePersonType}>
          <Row justify="space-between" className="font-roboto mb10">
            <Col span={19}>
              {activeMode !== 'edit' && (
                <Form.Item label="Тип анкеты" className="form-item-required__hidden-error__mb0">
                  <span className="pl9">{getDictionaryItemById(personType, personProfile?.personTypeId)?.name}</span>
                </Form.Item>
              )}
              {activeMode === 'edit' && (
                <Form.Item
                  initialValue={personProfile?.personTypeId}
                  name="personTypeId"
                  label="Тип анкеты"
                  rules={[{ required: true }]}
                  className="form-item-required__hidden-error__mb0"
                >
                  <Select style={{ width: '100%' }} className="pl9">
                    {personType
                      .filter(({ id }) => id !== 4)
                      .map(({ id, name }) => (
                        <Select.Option key={id} value={id}>
                          {name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
            {hasAccess(['ADMINISTRATION.USER.WRITE']) && (
              <Col className="mt4">
                {activeMode !== 'edit' && (
                  <Button
                    icon={
                      <PencilIcon
                        onClick={() => {
                          setActiveMode('edit');
                        }}
                      />
                    }
                    className="button-icon"
                    type="text"
                  />
                )}
                {activeMode === 'edit' && (
                  <>
                    <Button
                      icon={
                        <CrossIcon
                          onClick={() => {
                            form.resetFields();
                            setActiveMode('');
                          }}
                        />
                      }
                      className="button-icon mr10"
                      type="text"
                    />
                    <Button icon={<SubmitIcon onClick={form.submit} />} className="button-icon" type="text" />
                  </>
                )}
              </Col>
            )}
          </Row>
        </Form>
      </Spin>
      {hasAccess(['ADMINISTRATION.USER.WRITE']) && (
        <Tooltip title={activeMode === 'edit' ? 'Необходимо завершить редактирование' : ''}>
          <Button
            className="mt20"
            onClick={() => {
              setActiveMode('delete');
            }}
            disabled={activeMode === 'edit'}
          >
            Удалить пользователя
          </Button>
        </Tooltip>
      )}
      {deleteUserModal()}
    </div>
  );
};
