import React from 'react';
import './Comment.less';
import { toDisplayFormat } from '../../lib/utils/formatDate';

interface Props {
  author: string;
  date: string;
  text: string;
}

function Comment(props: Props) {
  const { author, date, text } = props;

  return (
    <div className="comment mb10">
      <div className="info-row">
        <div className="author">{author}</div>
        <div className="date">{toDisplayFormat(date)}</div>
      </div>
      <div className="text">{text}</div>
    </div>
  );
}

export default Comment;
